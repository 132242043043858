class Auth {
    constructor() {
      this.authenticated = false;
    }
  
    login(cb) {
      this.authenticated = true;
      localStorage.setItem("isAuthenticated", this.authenticated);
      cb();
    }
  
    logout(cb) {
      this.authenticated = false;
      localStorage.setItem("isAuthenticated", this.authenticated);
      localStorage.setItem("user", null);
      localStorage.removeItem("authToken");
      cb();
    }
  
    isAuthenticated() {

        let auth = localStorage.getItem("isAuthenticated") || false;
        // this.authenticated = auth;
        this.authenticated = auth === 'true';
        return this.authenticated;
    }
  }
  
  export default new Auth();
  