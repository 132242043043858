import React, {useState, useEffect, useRef}  from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBIcon
} from 'mdbreact';
import PartnerShipComponent from '../../components/PartnerShipComponent/PartnerShipComponent';
import ParticularityComponent from '../../components/ParticularityComponent/ParticularityComponent';
import logo_faseya from '../../assets/images/logo_faseya.png';
import olive_loader from '../../assets/images/olive-loader.gif'; 
import { useParams } from 'react-router';
import PartnersComponent from '../../components/PartnersComponent/PartnersComponent';
import serviceService from '../../services/service.service';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './SignUp.scss';
import { withTranslation } from 'react-i18next';
import aService from '../../services/auth.service';
import clientsService from '../../services/clients.service';
import authService from '../../services/auth.service';

  function SignUpPage(props) {

    const { profile } = useParams()
    const { id } = props.location
    const[eye, setEye] = useState('eye-slash')
    const[showHidePassword, setShowHidePassword] = useState('password')
    const[didPwdpass, setDidPwdPass] = useState(false);
    const[pwdpass, setPwdPass] = useState(true);
    const[services, setServices] = useState([]);
    const[error, setError] = useState('');
    const[connecting, setConnecting] = useState(false)

    const[pwCheckLength, setpwCheckLength] = useState(false)
    const[pwCheckMaj, setpwCheckMaj] = useState(false)
    const[pwCheckSpecial, setpwCheckSpecial] = useState(false)


    const refService = useRef(null);
    const[user, setUser] = useState({
        societe: '', 
        nom:'',
        prenom: '',
        username: '',
        telephone: '',
        adresse: '',
        ville: '',
        email: '',
        immatriculation: '',
        password: '',
        confirm: '',
        serviceId: '',
    })

    const history = useHistory();
    useEffect(() => {

        window.scrollTo(0, 0);


        // document.getElementById("username-field").addEventListener('keypress', function(event) {
        //     var inputValue = event.currentTarget.value;
        //     // if(!((inputValue >= 65 && inputValue <=  90) ||  // A-Z
        //     //      (inputValue >= 97 && inputValue <= 122) ||  // a-z
        //     //      (inputValue == 32))) {                      // space
        //     //     event.preventDefault(); 
        //     // }
        // });

        serviceService.getServices().then((res) => {
            // console.log(res);
            setServices(res)
         })
    }, [])
    
    const {t} = props;

    const validForm = (data) => {
        let valid = true
        for(var i in data) {
           if(data[i].length === 0) valid = false
        }
        return valid
    }


    const pattern = {
        charLength: function(val) {
            if( val.length >= 8 ) {
                setpwCheckLength(true)
                return true;
            }
            else{setpwCheckLength(false);return false}
        },
        lowercase: function(val) {
            var regex = /^(?=.*[a-z]).+$/;
    
            // if( regex.test(val) ) {
            //     setpwCheckLength(false)
            // }
            // else{}
        },
        uppercase: function(val) {
            var regex = /^(?=.*[A-Z]).+$/;
    
            if( regex.test(val) ) {
                setpwCheckMaj(true)
                return true;
            }else{setpwCheckMaj(false); return false;}
        },
        special: function(val) {
            var regex = /^(?=.*[0-9_\W]).+$/;
    
            if( regex.test(val) ) {
                setpwCheckSpecial(true)
                return true;
            }else{
                setpwCheckSpecial(false)
                return false;
            }
        }   
    };

    const checkPwd = (str)  => {
        var c1 = pattern.charLength(str);
        var c2 = pattern.uppercase(str);
        var c3 = pattern.special(str);

        
        var pass = c1 && c2 && c3;
        // console.log(pass)
        setDidPwdPass(pass);
    }


    const usernameKeypressHandler = (e) => {
        const { name, value } = e.target;
        var val = value;
        // if(name == "username")
        {
            
            val = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

            setUser({
                    ...user,
                    username: val
                })
        }
        
        // if(name == "telephone")
        // {
        //     val = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
        // }

        // setUser({
        //     ...user,
        //     [name]: val
        // })

       
    }

    const submitHandler = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";

        var username = user.username.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            username = username.replace(/[^\w\s\][^,]/gi, '');
       

        const data = {
            societe: user.societe,
            nom: user.nom,
            prenom: user.prenom,
            email: user.email,
            username: username,
            password: user.password,
            telephone: user.telephone,
            adresse: user.adresse,
            ville: user.ville,
            info:user.adresse
        }
      
        
        if(!validForm(data)) return ;

       if(!didPwdpass)
        {
            setError("Password invalid")
            return;
        }
        else{setError("")}

        setConnecting(true);

        // data.immatriculation =  user.immatriculation;

        clientsService.addClient(data).then((res) => {
    
            setConnecting(false);

            history.push({
                pathname: "/validation-compte", state: { email: user.email }
            }); 
        }).catch((error) => {
            console.log(error)
            setConnecting(false);
            setError(error.message || error.report);
            /* setError(t('faseya.Register.consultants.forms.messages.item_1'));

            if(error.message == "Email and/or username not available")
                setError(t('faseya.Register.consultants.forms.messages.item_1') + '.\n' + t('faseya.Register.consultants.forms.messages.item_5')); */
           
        }) 
        
    }

    const changeHandler = (e) => {
        const { name, value } = e.target;
        var val = value;
        // console.log(name)
        if(name == "username")
        {
            
            val = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            val = val.replace(/[^\w\s\][^,]/gi, '');
            setUser({
                    ...user,
                    username: val
                })
        }
        if(name == "password")
        {
            
            checkPwd(value)
        }
  
        if(name == "telephone")
        {
            val = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
        }

        setUser({
            ...user,
            [name]: val
        })

       
    }
   


    const submitHandlerAdvisor = (data) => {


             
        data.serviceId = refService.current.value ;
        const customer = JSON.stringify(data, null, 2);
        if(refService.current.value === t('faseya.Register.consultants.forms.items.item_1')) {
            refService.current.style.borderColor = "red";
            return ;
        }
 
        if(!didPwdpass)
        { 
            setError("Password invalid")
            return;
        }
        else{
            setError("")
        }
         

        var username = data.username.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        username = username.replace(/[^\w\s\][^,]/gi, '');

        data.username = username;

        var checkUserData = {username : username, email:data.email};
        console.log(checkUserData)
        authService.checkCredentials(checkUserData).then(d=>{

            if(d.status)
            {
                history.push({
                    pathname: "/register/consultant",
                    state: { user: JSON.parse(customer) }
                });
            }
            else
            {
                setError(d.message)
            }

        })
        .catch(e=>{
            setError("Error while verifying user. Please retry again !")
        })

        
        
    }

    const OnChangeEye = () => {
        if(eye === "eye-slash")
        { 
            setEye('eye')
            setShowHidePassword('text')
        }else{
            setEye('eye-slash')
            setShowHidePassword('password')
        }
    }
 
    
    const validationSchema = Yup.object().shape({
        nom: Yup.string().required(t('faseya.Register.consultants.forms.messages.item_2')),
        prenom: Yup.string().required(t('faseya.Register.consultants.forms.messages.item_2')),
        username: Yup.string().required(t('faseya.Register.consultants.forms.messages.item_2')),
        email: Yup.string()
          .required(t('faseya.Register.consultants.forms.messages.item_2'))
          .email(t('faseya.Register.consultants.forms.messages.item_3')),
        // password: Yup.string()
        //   .required(t('faseya.Register.consultants.forms.messages.item_2'))
        //   .matches(
        //     /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        //     t('faseya.Register.consultants.forms.items.messages.item_6')
        //   ),
        confirm: Yup.string()
          .required(t('faseya.Register.consultants.forms.messages.item_2'))
          .oneOf([Yup.ref('password'), null], t('faseya.Register.consultants.forms.messages.item_4')),
      });

      const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = useForm({
        resolver: yupResolver(validationSchema)
      });

      
      

   /*  const validationSchema = Yup.object().shape({
        nom: Yup.string().required('Remplissez le champ ci dessus'),
        prenom: Yup.string().required('Remplissez le champ ci dessus'),
        username: Yup.string().required('Remplissez le champ ci dessus'),
        email: Yup.string()
          .required('Remplissez le champ ci dessus')
          .email('Remplissez une adresse email valide'),
        password: Yup.string()
          .required('Remplissez le champ ci dessus')
          .min(8, 'Saisissez un mot de passe d\'au moins 8 caractères contenant des caractères alphanumeriques,majuscules,miniscules,et speciaux'),
        confirm: Yup.string()
          .required('Remplissez le champ ci dessus')
          .oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas'),
      });
 
      const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = useForm({
        resolver: yupResolver(validationSchema)
      });
*/

    return (

        <>
            { profile === "client" ? 
                <MDBRow>
                    
                        <MDBCol xs="12" md="5" className='' style={{ background: '#f3f4f6'}}>
                            <MDBRow className="d-flex justify-content-center flex-column my-5 mx-5 f-subheader-sign">
                                
                                <div className="d-flex justify-content-between align-items-center ml-lg-3">
                                        <div className="p-1">
                                            <Link to="/">
                                                <img src={logo_faseya} />
                                            </Link>
                                        </div>
                                        <div className="p-1">
                                            <Link to="/se-connecter">
                                                <span className="login-text">{t('faseya.Login.be_connect')}</span>
                                            </Link>
                                        </div>

                                </div>

                                    <div className="p-1 mt-2 ml-lg-3">
                                        <span className="text-accounted">
                                            {t('faseya.Register.clients.add_info')}
                                        </span>
                                    </div>

                                    

                                        <div className="d-flex justify-content-center pt-5">

                                            <form className='needs-validation' onSubmit={submitHandler} >
                                               
                                                { error  && (
                                                    <MDBRow className="mb-3 text-center">
                                                        <MDBCol>
                                                            <span className="error-text">{error}</span>
                                                        </MDBCol>
                                                    </MDBRow>
                                                )}
                                                <MDBRow >
                                                    <MDBCol md="12" className="mb-3">
                                                        <input
                                                            value={user.societe}
                                                            name='societe'
                                                            onChange={changeHandler}
                                                            id='validationStatus'
                                                            required
                                                            className="form-control f-signup-input"
                                                            placeholder={t('faseya.Register.clients.items.item_1')}
                                                            validation='Looks good!'
                                                        /> 
                                                        <div className="invalid-feedback">
                                                            {t('faseya.Register.consultants.forms.messages.item_2')}
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>

                                                <MDBRow>
                                                 

                                                    <MDBCol md="6" className="mb-3">
                                                        <input
                                                            value={user.prenom}
                                                            name='prenom'
                                                            onChange={changeHandler}
                                                            id='validationLname'
                                                            required
                                                            className="form-control f-signup-input"
                                                            placeholder={t('faseya.Register.clients.items.item_3')}
                                                    
                                                        /> 
                                                    </MDBCol>
                                                    
                                                    <MDBCol md="6" className="mb-3">
                                                        <input
                                                            value={user.nom}
                                                            name='nom'
                                                            onChange={changeHandler}
                                                            id='validationFname'
                                                            required
                                                            className="form-control f-signup-input"
                                                            placeholder={t('faseya.Register.clients.items.item_2')}
                                                        /> 
                                                    </MDBCol>
                                                </MDBRow>

                                                <MDBRow >
                                                    <MDBCol md="12" className="mb-3">
                                                        <input
                                                            value={user.username}
                                                            name='username'
                                                            onChange={changeHandler}
                                                            id='validationUserName'
                                                            required
                                                            className="form-control f-signup-input"
                                                            placeholder={t('faseya.Register.clients.items.item_4')}
                                                            validation='Looks good!'
                                                        /> 
                                                        <div className="invalid-feedback">
                                                            {t('faseya.Register.consultants.forms.messages.item_2')}
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>

                                                <MDBRow >
                                                    <MDBCol md="12" className="mb-3">
                                                        
                                                        <input
                                                            value={user.telephone}
                                                            name='telephone'
                                                            onChange={changeHandler}
                                                            id='validationPhone'
                                                            required
                                                            className="form-control f-signup-input"
                                                            placeholder={t('faseya.Register.clients.items.item_5')}
                                                            validation='Looks good!'
                                                        /> 
                                                        <div className="invalid-feedback">
                                                            {t('faseya.Register.consultants.forms.messages.item_2')}
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>

                                                <MDBRow >
                                                    <MDBCol md="12" className="mb-3">
                                                        <input
                                                            value={user.adresse}
                                                            name='adresse'
                                                            onChange={changeHandler}
                                                            id='validationAddress'
                                                            required
                                                            className="form-control f-signup-input"
                                                            placeholder={t('faseya.Register.clients.items.item_6')}
                                                            validation='Looks good!'
                                                        /> 
                                                        <div className="invalid-feedback">
                                                            {t('faseya.Register.consultants.forms.messages.item_2')}
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>

                                                <MDBRow >
                                                    <MDBCol md="12" className="mb-3">
                                                        <input
                                                            value={user.ville}
                                                            name='ville'
                                                            onChange={changeHandler}
                                                            id='validationCity'
                                                            required
                                                            className="form-control f-signup-input"
                                                            placeholder={t('faseya.Register.clients.items.item_7')}
                                                            validation='Looks good!'
                                                        /> 
                                                        <div className="invalid-feedback">
                                                            {t('faseya.Register.consultants.forms.messages.item_2')}
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>

                                                <MDBRow >
                                                    <MDBCol md="12" className="mb-3">
                                                        <input
                                                            type="email"
                                                            value={user.email}
                                                            name='email'
                                                            onChange={changeHandler}
                                                            id='validationEmail'
                                                            required
                                                            className="form-control f-signup-input"
                                                            placeholder={t('faseya.Register.clients.items.item_8')}
                                                            validation='Looks good!'
                                                        /> 
                                                        <div className="invalid-feedback">
                                                            {t('faseya.Register.consultants.forms.messages.item_2')}
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>

                                                <MDBRow >
                                                    <MDBCol md="12" className="mb-3">
                                                        <input
                                                            value={user.immatriculation}
                                                            name='immatriculation'
                                                            onChange={changeHandler}
                                                            className="form-control f-signup-input"
                                                            placeholder={t('faseya.Register.clients.items.item_9')}
                                                            validation='Looks good!'
                                                        /> 
                                                    </MDBCol>
                                                </MDBRow>

                                                <MDBRow >
                                                    <MDBCol md="12" className="mb-1">
                                                        <div className="pass-wrapper">
                                                            <input
                                                                type={showHidePassword}
                                                                value={user.password}
                                                                name='password'
                                                                onChange={changeHandler}
                                                                id='validationPassword'
                                                                required
                                                                // className="form-control f-signup-input"

                                                                className={`form-control f-signup-input ${(pwdpass) ? '' :'is-invalid' }`}
                                                                placeholder={t('faseya.Register.clients.items.item_10')}
                                                                validation='Looks good!'
                                                            /> 
                                                            <MDBIcon icon={eye} onClick={OnChangeEye} />
                                                        </div>
                                                        {/* <div className="invalid-feedback">
                                                            {t('faseya.Register.consultants.forms.messages.item_2')}
                                                        </div> */}
                                                    </MDBCol>
                                                </MDBRow>

                                                <MDBRow className='mt-2 mb-4'>
                                                        <MDBCol> 
                                                            <div className="custom-control custom-checkbox" style={{fontWeight:700, color:'black'}}>
                                                                <input type="checkbox" readOnly checked={pwCheckLength} className="custom-control-input" id="chekLength" />
                                                                <label className="custom-control-label"   htmlFor="chekLength" > <span style={{fontWeight:600, color:'black'}}> &#62;8 {localStorage.getItem("lang") == 'fr'? 'Caractéres':'Characters'} </span></label>
                                                            </div>
                                                        </MDBCol> 
                                                        <MDBCol> 

                                                            <div className="custom-control custom-checkbox" style={{fontWeight:700, color:'black'}}>
                                                                <input type="checkbox" readOnly checked={pwCheckMaj} className="custom-control-input" id="checkMaj" />
                                                                <label className="custom-control-label"   htmlFor="checkMaj" > <span style={{fontWeight:600, color:'black'}}> 1 {localStorage.getItem("lang") == 'fr'? 'Majuscule':'Uppercase'} </span></label>
                                                            </div>
                                                        </MDBCol> 
                                                        <MDBCol> 
                                                            <div className="custom-control custom-checkbox" style={{fontWeight:700, color:'black'}}>
                                                                <input type="checkbox" readOnly checked={pwCheckSpecial} className="custom-control-input" id="checkSpecial" />
                                                                <label className="custom-control-label"   htmlFor="checkSpecial" > <span style={{fontWeight:600, color:'black'}}>1 {localStorage.getItem("lang") == 'fr'? 'Caractére spécial':'Special character'} </span></label>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>

                                                <MDBRow>
                                                    <MDBCol md="12 mt-2">
                                                        {connecting &&
                                                            <img src={olive_loader} style={{position:'absolute', zIndex:1, bottom:'50%', left:'47.5%', width:40}} alt="" />
                                                        }
                                                        {!connecting &&
                                                            <MDBBtn type="submit"  className="sow-submit btn-block btn-color">
                                                                {t('faseya.Register.title')}
                                                            </MDBBtn>
                                                        }
                                                    </MDBCol>
                                                </MDBRow>

                                            </form>
                                            
                                        </div>                                  

                                
                            </MDBRow>
                        </MDBCol>

                        <MDBCol  className='d-flex justify-content-center mt-5 hide-mobile'>
                            <PartnerShipComponent />
                        </MDBCol>

                </MDBRow>:

                <MDBContainer >

                    <MDBRow>

                        <MDBCol xs="12" md="12">

                            <div className="py-1 ml-xs-2 ml-0">
                                <Link to="/">
                                <span className="text-back-to">{t('faseya.back')}</span>
                                </Link>
                            </div>

                        </MDBCol>

                        <MDBCol md="12">
                            <MDBRow className="justify-content-center f-subheader-sign">

                                <div className="p-5">
                                    <h2 className="text-join-us">{t('faseya.Register.consultants.title')}</h2>
                                </div>

                                <div className="p-1">
                                    <p className="black-text text-center" style={{maxWidth: '1030px'}}>
                                        {t('faseya.Register.consultants.description')}
                                    </p>
                                </div>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="justify-content-center">
                        <MDBCol md="6">
                            <MDBRow className="justify-content-center flex-column f-subheader-sign">

                                    <div className="p-3">
                                            {error  && (
                                                <MDBRow className="mb-3 text-center">
                                                    <MDBCol>
                                                        <span className="error-text">{error}</span>
                                                    </MDBCol>
                                                </MDBRow>
                                            )}
                                        <form className='w-100' onSubmit={handleSubmit(submitHandlerAdvisor)} >
                                            
                                            <MDBRow>

                                                <MDBCol md="12" className="mb-3">
                                                    <select 
                                                        className="custom-select" 
                                                        ref={refService}
                                                        name="serviceId"
                                                    >
                                                        <option>{t(`faseya.Register.consultants.forms.items.item_1`)}</option>

                                                        { services.map((service, index) => (
                                                            <option value={ service.id} key={ index }>
                                                                {/* { service.title } */}
                                                                {t(`faseya.Register.consultants.skills.`+service.title)}
                                                            </option>
                                                         ))  
                                                        }
                                                    </select>
                                                </MDBCol>
                                            </MDBRow>

                                            

                                            <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <input
                                                        type="text"
                                                        name='prenom' 
                                                        className={`form-control no-raduis ${errors.prenom ? 'is-invalid' : ''}`}
                                                        placeholder={t(`faseya.Register.consultants.forms.items.item_3`)}
                                                        {...register('prenom')}
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        {errors.prenom?.message}.
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <input
                                                        type="text" 
                                                        name='nom'  
                                                        className={`form-control no-raduis ${errors.nom ? 'is-invalid' : ''}`}
                                                        placeholder={t(`faseya.Register.consultants.forms.items.item_2`)}
                                                        {...register('nom')}
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        {errors.nom?.message}
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <input
                                                        type="text"
                                                        // value={user.username}
                                                        name='username'
                                                        // onChange={changeHandler}
                                                    
                                                        // id="username-field"
                                                        {...register('username')}
                                                        className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                                                        placeholder={t(`faseya.Register.consultants.forms.items.item_4`)}
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        {errors.username?.message}.
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <input
                                                        type="text"
                                                        name='email' 
                                                        {...register('email')}
                                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                        placeholder={t(`faseya.Register.consultants.forms.items.item_5`)}
                                                    /> 
                                                    <div className="invalid-feedback">
                                                         {errors.email?.message}.
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <div className='pass-wrapper'>
                                                        <input
                                                            type={showHidePassword}
                                                            name='password' 
                                                            {...register('password')}
                                                            onChange={(e)=>checkPwd(e.target.value)}
                                                            className="form-control f-signup-input"

                                                            // className={`form-control ${didPwdpass ? '' :'is-invalid' }`}
                                                            placeholder={t(`faseya.Register.consultants.forms.items.item_6`)}
                                                        /> 
                                                        <MDBIcon icon={eye} onClick={OnChangeEye} />
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        {errors.password?.message}
                                                    </div>
                                                    <MDBRow className='mt-2'>
                                                        <MDBCol> 
                                                            <div className="custom-control custom-checkbox" style={{fontWeight:700, color:'black'}}>
                                                                <input type="checkbox" readOnly checked={pwCheckLength} className="custom-control-input" id="chekLength" />
                                                                <label className="custom-control-label"   htmlFor="chekLength" > <span style={{fontWeight:600, color:'black'}}> &#62;8 {localStorage.getItem("lang") == 'fr'? 'Caractéres':'Characters'} </span></label>
                                                            </div>
                                                        </MDBCol> 
                                                        <MDBCol> 
                                                            <div className="custom-control custom-checkbox" style={{fontWeight:700, color:'black'}}>
                                                                <input type="checkbox" readOnly checked={pwCheckMaj} className="custom-control-input" id="checkMaj" />
                                                                <label className="custom-control-label"   htmlFor="checkMaj" > <span style={{fontWeight:600, color:'black'}}> 1 {localStorage.getItem("lang") == 'fr'? 'Majuscule':'Uppercase'} </span></label>
                                                            </div>
                                                        </MDBCol> 
                                                        <MDBCol> 
                                                            <div className="custom-control custom-checkbox" style={{fontWeight:700, color:'black'}}>
                                                                <input type="checkbox" readOnly checked={pwCheckSpecial} className="custom-control-input" id="checkSpecial" />
                                                                <label className="custom-control-label"   htmlFor="checkSpecial" > <span style={{fontWeight:600, color:'black'}}>1 {localStorage.getItem("lang") == 'fr'? 'Caractére spécial':'Special character'} </span></label>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <input
                                                        type="password"
                                                        name='confirm' 
                                                        {...register('confirm')}
                                                        className={`form-control ${errors.confirm ? 'is-invalid' : ''}`}
                                                        placeholder={t(`faseya.Register.consultants.forms.items.item_7`)} 
                                                    /> 
                                                    <div className="invalid-feedback">
                                                       {errors.confirm?.message}
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <div className="py-1">
                                                        {t(`faseya.Register.consultants.help_text`)}
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow>
                                                <MDBCol md="12">
                                                    <MDBBtn type="submit"  className="sow-submit btn-block btn-consult-color">
                                                        {t(`faseya.Register.consultants.button.title`)}
                                                    </MDBBtn>
                                                </MDBCol>
                                            </MDBRow>
                                        

                                        </form>
                                    </div>

                            </MDBRow>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow >
                        <MDBCol md="12">
                           <MDBRow className="">
                                 <PartnersComponent />
                           </MDBRow>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow >
                        <MDBCol md="12">
                           <MDBRow className="justify-content-center">
                                 <ParticularityComponent />
                           </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            }
        </>
    )
  }

  export default withTranslation()(SignUpPage);