const initialState = {
    mainReduxState: {
      user : {
        accessToken: null,
        email: null,
        id: null,
        roles: [],
        username: null
      },
      consultants : [],
      consultantsPage : 1,

      consultantsExperts : [],
      consultantsAvailable : [],
      consultantsNew : [],

      services : [],
      active : "main",

    }
  }


  export default initialState;