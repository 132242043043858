import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdbreact';
import './HowComponent.css';

import light_icon from '../../assets/images/light_icon.png';
import puzzle_icon from '../../assets/images/puzzle_icon.png';
import quality_icon from '../../assets/images/quality_icon.png';
import faseya_video from '../../assets/images/faseya_video.mp4';
import { withTranslation } from 'react-i18next';

class HowComponent extends React.Component {
 
  state = {
    collapseID: ''
  };

 
  render() {

    const {t} = this.props;

    return (

      <div style={{padding:10, marginTop:50, backgroundColor : '#e6e6e6'}}>
      <MDBContainer>
  
        <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}> {t('faseya.how_it_works.title')} </h4>

        <MDBRow>

            
            <MDBCol size="12" sm="12" md="6"  className="mb-4" style={{}} >
              <MDBRow>

                <MDBCol size="12" xs="12" md="12"  className="mb-3 f-how-item" >
                    <MDBRow>
                      <MDBCol size="auto" className="d-flex align-items-center">
                        <img src={quality_icon} style={{width:20, marginRight:0}} alt="" />
                      </MDBCol>
                      <MDBCol>
                        <span className="f-subtitle-3" style={{margin:0}}>{t('faseya.how_it_works.item_1')}</span>
                      </MDBCol>
                    </MDBRow>
                </MDBCol>


                <MDBCol size="12" xs="12" md="12"  className="mb-3 f-how-item" >
                    <MDBRow>
                      <MDBCol size="auto" className="d-flex align-items-center">
                        <img src={quality_icon} style={{width:20, marginRight:0}} alt="" />
                      </MDBCol>
                      <MDBCol>
                        <span className="f-subtitle-3" style={{margin:0}}>{t('faseya.how_it_works.item_2')}</span>
                      </MDBCol>
                    </MDBRow>
                </MDBCol>

                <MDBCol size="12" xs="12" md="12"  className="mb-3 f-how-item" >
                    <MDBRow>
                      <MDBCol size="auto" className="d-flex align-items-center">
                        <img src={quality_icon} style={{width:20, marginRight:0}} alt="" />
                      </MDBCol>
                      <MDBCol>
                        <span className="f-subtitle-3" style={{margin:0}}>{t('faseya.how_it_works.item_3')}</span>
                      </MDBCol>
                    </MDBRow>
                </MDBCol>


                <MDBCol size="12" xs="12" md="12"  className="mb-3 f-how-item" >
                    <MDBRow>
                      <MDBCol size="auto" className="d-flex align-items-center">
                        <img src={quality_icon} style={{width:20, marginRight:0}} alt="" />
                      </MDBCol>
                      <MDBCol>
                        <span className="f-subtitle-3" style={{margin:0}}>{t('faseya.how_it_works.item_4')}</span>
                      </MDBCol>
                    </MDBRow>
                </MDBCol>


                <MDBCol size="12" xs="12" md="12"  className="mb-3 f-how-item" >
                    <MDBRow>
                      <MDBCol size="auto" className="d-flex align-items-center">
                        <img src={quality_icon} style={{width:20, marginRight:0}} alt="" />
                      </MDBCol>
                      <MDBCol>
                        <span className="f-subtitle-3" style={{margin:0}}>{t('faseya.how_it_works.item_5')}</span>
                      </MDBCol>
                    </MDBRow>
                </MDBCol>


                <MDBCol size="12" xs="12" md="12"  className="mb-3 f-how-item" >
                    <MDBRow>
                      <MDBCol size="auto" className="d-flex align-items-center">
                        <img src={quality_icon} style={{width:20, marginRight:0}} alt="" />
                      </MDBCol>
                      <MDBCol>
                        <span className="f-subtitle-3" style={{margin:0}}>{t('faseya.how_it_works.item_6')}</span>
                      </MDBCol>
                    </MDBRow>
                </MDBCol>


              </MDBRow>
            </MDBCol>            

            <MDBCol xs="12" md="6"  className="mb-4" >
              <video style={{width:'100%', borderRadius:6}} controls src={faseya_video}></video>
            </MDBCol>            
  

        </MDBRow>
      
      
      </MDBContainer>

      </div>
    );
  }
}

export default withTranslation()(HowComponent);
