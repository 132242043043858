var  availablties = [
    {
        id: 1,
        name: "temps_plein",
        value: "plein temps",
        label: "plein temps",
    },
    {
        id: 2,
        name: "temps_partiel",
        value: "temps partiel",
        label: "temps partiel",
    },
   
  
]

export default availablties;