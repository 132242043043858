import React, {useState, useEffect, useRef}  from 'react';
import emailjs from '@emailjs/browser'
import { Link } from 'react-router-dom';
import {
    MDBRow,
    MDBCol,
    MDBBtn,
} from 'mdbreact';
import SponsorsComponent from '../../components/SponsorsComponent/SponsorsComponent';
import olive_loader from '../../assets/images/olive-loader.gif';
import projectService from '../../services/project.service';
import serviceService from '../../services/service.service';
import './PostProjectPage.scss';

import logo_faseya from '../../assets/images/logo_faseya.png';
import { withTranslation } from 'react-i18next';

function PostProjectPage(props) {

    const {t} = props;
    const[status, setStatus] = useState(false);
    const[error, setError] = useState('');
    const[services, setServices] = useState([]);
    const[connecting, setConnecting] = useState(false);
    const[project, setProject] = useState(
        {
            serviceId: '',
            description: '',
            email: '',
            telephone: '',
            nbre_consultant: '',
            adresse: '',
            date_debut: '',
            date_fin: '',
            ville: '',
            code_postal: '',
        }
    )
    const selectType = useRef(null);
    const selectAdvisor = useRef(null);

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setProject({
            ...project,
            [name]: value
        })
        
    }

    useEffect(() => {

        window.scrollTo(0, 0);
     
        serviceService.getServices().then((res) => {
            setServices(res)
        })
    },[]);

    const validForm = (data) => {
        let valid = true
        for(var i in data) {
           if(data[i].length === 0 || data[i] === t('faseya.post_project.forms.item_2') || data[i] === t('faseya.post_project.forms.item_7')) {
            valid = false
           } 
        }
        if(selectType.current.value === "Selectionner le type de mission" || selectType.current.value === "Select the type of mission") {
           selectType.current.style.borderColor = "red";
           valid = false;
        } else {
            selectType.current.style.borderColor = "";
        }
        if(selectAdvisor.current.value === "Selectionner le nombre de consultants" || selectAdvisor.current.value === "Select the number of consultants") {
           selectAdvisor.current.style.borderColor = "red";
           valid = false;
        } else {
            selectAdvisor.current.style.borderColor = "";
        }
        return valid
    }

    const submitHandler = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";

        const projectCopy = {
            serviceId:  parseInt(project.serviceId),
            description: project.description,
            email: project.email,
            telephone: project.telephone,
            nbre_consultant: parseInt(project.nbre_consultant),
            adresse: project.adresse,
            date_debut: project.date_debut,
            date_fin: project.date_fin,
            ville: project.ville,
            code_postal: project.code_postal,
        }

        let service = services.find((el)=>el.id==project.serviceId)

        let tmpproject = {
            ...projectCopy,
            service: service.title
        }
        if(validForm(project)) {
            setConnecting(true);
            if(validForm(project)) {
               projectService.addProject(projectCopy).then(() => {
                  setStatus(true)

                  // send email 

                  emailjs.send('service_kian0gc','template_2inarsj',tmpproject, 'CQ_rLPb6EoSosAH_F')
                    .then((result)=>{
                    // this.setState({
                    //     ...this.state,
                    //     isSubmiting: false,
                    //     message: 'Merci,votre demande a été bien pris en compte,notre équipe vous contactera sous 24 heures'
                    // })
                    })
               }).catch((err) => {
                setConnecting(false);

                if(err.code == 404)
                setError(err.message);
                else setError(err.report);
                if(err.msg) setError(err.msg);
               /*  if (error.response) {
                    setError(error.response.data.message);
                }else if(error.request) {
                    setError(t('faseya.Register.consultants.forms.messages.item_1'));
                }else {
                    setError(t('faseya.Register.consultants.forms.messages.item_1'));
                } */
               })
            }
        }
    }


    return (
        <>
            <MDBRow>
                <MDBCol xs="12" md="9" className="" style={{ backgroundColor: '#bbc7a8'}}>
                    <div className="d-flex ml-2 ml-md-5 mt-4">
                        <Link to="/">
                                <p style={{color: 'olivedrab', fontWeight: '400', textDecoration: 'underline'}}>
                                   {t('faseya.back')}
                                </p>
                        </Link>
                    </div>

                    {!status ?
                       <>
                            <MDBRow className="my-3 justify-content-center">
                                <form className='needs-validation form-demo ' onSubmit={submitHandler} noValidate>
                                    
                                    <MDBRow className="mb-2">
                                        <MDBCol>
                                            <p className='title-project-demo'>{t('faseya.post_project.title')}</p >
                                        </MDBCol>
                                    </MDBRow >

                                    { error  && (
                                        <MDBRow className="mb-3 text-center">
                                            <MDBCol>
                                                <span className="error-text">{error}</span>
                                            </MDBCol>
                                        </MDBRow>
                                    )}

                                    <MDBRow className="form-group">
                                       <MDBCol > 
                                            <label htmlFor="type" className="label-project">{t('faseya.post_project.forms.item_1')}</label>
                                            <br/>
                                            <select 
                                                className="add-raduis-select" 
                                                id="type" 
                                                name="serviceId"
                                                onChange={onChangeHandler}  
                                                ref={selectType}
                                            >
                                                <option>{t('faseya.post_project.forms.item_2')}</option>
                                                { services.map((service, index) => (
                                                    <option value={ service.id} key={ index }>
                                                      {t(`faseya.Register.consultants.skills.`+service.title)}
                                                    </option>
                                                ))  
                                                }
                                            </select>
                                        </MDBCol >      
                                    </MDBRow>

                                    <MDBRow  className="form-group mb-3">
                                        <MDBCol >
                                            <label htmlFor="presentation" className="label-project">
                                                {t('faseya.post_project.forms.item_3')}
                                            </label>
                                            <textarea 
                                               className="form-control" 
                                                id="presentation" 
                                                name="description"
                                                onChange={onChangeHandler}
                                                required
                                                style={{resize: 'none'}}
                                                rows="7"
                                            />
                                        </MDBCol >
                                    </MDBRow >
                                    
                                    <MDBRow className="form-row">
                                        <MDBCol md="6" className="form-group mb-3">
                                            <label htmlFor="address" className="label-project">{t('faseya.post_project.forms.item_4')}</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="address" 
                                                name="adresse"
                                                onChange={onChangeHandler}
                                                required
                                            />   
                                        </MDBCol >

                                        <MDBCol md="6" className="form-group mb-3">
                                            <label htmlFor="postal" className="label-project">{t('faseya.post_project.forms.item_5')}</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="postal" 
                                                name="code_postal"
                                                onChange={onChangeHandler}
                                                required
                                            />       
                                        </MDBCol >
                                    </MDBRow>

                                    <MDBRow className="form-row">
                                        <MDBCol md="6" className="form-group mb-3">
                                            <label htmlFor="city" className="label-project">{t('faseya.post_project.forms.item_6')}</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="city" 
                                                name="ville"
                                                onChange={onChangeHandler}
                                                required
                                            />   
                                        </MDBCol >

                                        <MDBCol md="6" className="form-group mb-3">
                                            <label htmlFor="advisor" className="label-project">{t('faseya.post_project.forms.item_7')}</label>
                                            <select 
                                                className="add-raduis-select" 
                                                id="advisor" 
                                                name="nbre_consultant"
                                                onChange={onChangeHandler}
                                                ref={selectAdvisor}
                                            > 
                                                <option>
                                                    {t('faseya.post_project.forms.item_8')}
                                                </option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                            </select>      
                                        </MDBCol >
                                    </MDBRow>

                                    <MDBRow className="form-row">
                                        <MDBCol md="6" className="form-group mb-3">
                                            <label htmlFor="dayStart" className="label-project">
                                                {t('faseya.post_project.forms.item_9')}
                                            </label>
                                            <input 
                                                type="date" 
                                                id="dayStart" 
                                                name="date_debut"
                                                className="form-control"
                                                onChange={onChangeHandler}
                                                required
                                            />
                                        </MDBCol >

                                        <MDBCol md="6" className="form-group mb-3">
                                            <label htmlFor="dayEnd" className="label-project">{t('faseya.post_project.forms.item_10')}</label>
                                            <input 
                                                type="date" 
                                                className="form-control" 
                                                id="dayEnd" 
                                                name="date_fin"
                                                onChange={onChangeHandler}
                                                required
                                            />     
                                        </MDBCol >
                                   </MDBRow >

                                    <MDBRow className="form-row">

                                       <MDBCol md="6" className="form-group mb-3">
                                            <label htmlFor="phone" className="label-project">{t('faseya.post_project.forms.item_11')}</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="phone" 
                                                name="telephone"
                                                onChange={onChangeHandler}
                                                required
                                            />
                                            
                                        </MDBCol>

                                        <MDBCol md="6" className="form-group mb-3">
                                            <label htmlFor="email" className="label-project">{t('faseya.post_project.forms.item_12')}</label>
                                            <input 
                                                type="email" 
                                                className="form-control" 
                                                id="email" 
                                                name="email"
                                                onChange={onChangeHandler}
                                                required
                                            />
                                            
                                        </MDBCol >
                                    </MDBRow >

                                    <MDBRow className="justify-content-end">

                                        {connecting &&
                                            <img src={olive_loader} style={{position:'absolute', zIndex:1, left:'47.5%', width:40}} alt="" />
                                        } 
                                        {!connecting &&
                                            <MDBBtn type="submit"  className="btn-color" style={{borderRadius: '30px', padding: '3px 10px '}}>
                                            {t('faseya.send')}
                                            </MDBBtn>
                                        }
                                    </MDBRow>

                                </form>  
                            </MDBRow>   
                        </>:
                        <div className='d-flex flex-column align-items-center' style={{paddingTop: "20%", paddingBottom: "20%"}}>
                            <div className="p-1">
                                <img src={logo_faseya} className="faseya-logo"/>
                            </div>
                            <div className="pl-5 text-tanks mt-3">
                                {t('faseya.message')}
                            </div>
                        </div>
                    }
                </MDBCol>

                <MDBCol xs="12" md="3" className='hide-mobile' style={{backgroundColor: 'white'}}>
                    <MDBRow className='d-flex justify-content-center align-items-center'>
                       <SponsorsComponent />
                    </MDBRow>
                </MDBCol> 
            </MDBRow>
        </>
    )
}


export default withTranslation()(PostProjectPage);