import React, {useState, useEffect}  from 'react';
import { Link } from 'react-router-dom';
import profilesMock from '../../services/mocks/profiles';
import partnersMock from '../../services/mocks/partners';
import './AccountType.scss';
import {
    MDBRow,
    MDBCol,
    MDBView,
    MDBContainer
  } from 'mdbreact';

import logo_faseya from '../../assets/images/logo_faseya.png';
import PartnerShipComponent from '../../components/PartnerShipComponent/PartnerShipComponent';
import { withTranslation } from 'react-i18next';

function AccountTypePage(props) {

    const[profiles, setProfiles] = useState(profilesMock)
    const[partners, setPartners] = useState(partnersMock)

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])

    const {t} = props;
    
    return (
           

                  <MDBRow>

                    <MDBCol xs="12" md="5" className='' style={{ background: '#f3f4f6'}}>
                        <MDBRow className="d-flex flex-column my-5 mx-5">
                              
                                <div className="d-flex justify-content-between">
                                    <div className="p-1">
                                        <Link to="/">
                                           <img src={logo_faseya} />
                                        </Link>
                                    </div>
                                    <div className="p-1">
                                        <Link to="/se-connecter">
                                            <span className="login-text">{t('faseya.Login.be_connect')}</span>
                                        </Link>
                                    </div>
                                </div>

                                <div className="d-flex flex-column pt-5">
                                        <h2 className="welcome-text">{t('faseya.welcome_to_faseya')}</h2>
                                        <span className="welcome-text">{t('faseya.Register.am')}: </span>
                                </div>

                                <div className="d-flex flex-column" >
                                 {
                                     profiles.map((profile, index) => (
                                        
                                            <Link key={index} to={{ pathname: `/inscription/${profile.name.toLowerCase()}`, profile:profile.name.toLowerCase() }} >
                                                <div className="d-flex flex-column card px-2 py-1 px-lg-5 py-lg-3 mt-2 pointer" key={{ index }}>
                                                    <span className="text-name">{ profile.name }</span>
                                                    <p className="text-description">{t(profile.description)}</p>
                                                </div>
                                            </Link>
                                       
                                     ))
                                 }
                                </div>

                        </MDBRow>
                    </MDBCol>

                    <MDBCol md="7"  className='d-flex hide-mobile justify-content-center align-items-center' style={{ background: 'white'}}>
                            <PartnerShipComponent />
                    </MDBCol>
                  </MDBRow>
         
   
    )
}

export default withTranslation()(AccountTypePage);