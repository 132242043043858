import React, {useState, useRef, useEffect}  from 'react';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBIcon
} from 'mdbreact';
import SponsorsComponent from '../../components/SponsorsComponent/SponsorsComponent';
import './DemoPage.scss';
import demoService from '../../services/demo.service';
import logo_faseya from '../../assets/images/logo_faseya.png';
import olive_loader from '../../assets/images/olive-loader.gif'; 
import serviceService from '../../services/service.service';
import { withTranslation } from 'react-i18next';

function DemoPage(props) {

    const {t} = props;
    const[status, setStatus] = useState(false);
    const[services, setServices] = useState([]);
    const[connecting, setConnecting] = useState(false);
    const[demo, setDomo] = useState(
        {
            nom: '',
            prenom: '',
            email: '',
            telephone: '',
            serviceId: 0,
            adresse: '',
            ville: '',
            societe: '',
        }
    )
    const[error, setError] = useState('');
    const refService = useRef(null);

    useEffect(() => {

        window.scrollTo(0, 0);

        serviceService.getServices().then((res) => {
            setServices(res)
        })
        
    }, []);

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setDomo({
            ...demo,
            [name]: value
        })
        
    }

    const validForm = (data) => {
        let valid = true
        for(var i in data) {
           if(data[i].length === 0 || data[i] === t('faseya.Register.consultants.forms.items.item_1'))  valid = false
        }
        if(refService.current.value === "Sélectionner le service" || refService.current.value === "Select the service" ) {
            refService.current.style.borderColor = "red";
            valid = false;
        } else {
            refService.current.style.borderColor = "";
        }
        return valid
    }

    const submitHandler = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";
        let demoCopy = {
            nom: demo.nom,
            prenom: demo.prenom,
            email: demo.email,
            telephone: demo.telephone,
            serviceId: parseInt(demo.serviceId),
            adresse: demo.adresse,
            ville: demo.ville,
            societe: demo.societe,

        }
        let service = services.find((el)=>el.id==demo.serviceId)

        let tmpdemo = {
            ...demoCopy,
            service: service.title
        }
        if(validForm(demo)) {
            setConnecting(true);
            emailjs.send('service_kian0gc','template_awl9gus',tmpdemo, 'CQ_rLPb6EoSosAH_F')
            .then((result)=>{
            })
            .catch((error)=>console.log(error.text))
           demoService.addDemo(demoCopy).then(() => {
            setConnecting(false);
                setStatus(true)
           }).catch((err) => {
            setConnecting(false);
            
            if(err.code == 404)
            setError(err.message);
            else setError(err.report);
            if(err.msg) setError(err.msg);
           /*  if (error.response) {
                setError(error.response.data.message);
            }else if(error.request) {
                setError(t('faseya.Register.consultants.forms.messages.item_1'));
            }else {
                setError(t('faseya.Register.consultants.forms.messages.item_1'));
            } */
           })
        }
      
    }
    return (

        <>

            <MDBRow className="">

                <MDBCol xs="12" md="9" className="" style={{ backgroundColor: '#bbc7a8'}}>
                    
                    <div className="d-flex ml-2 ml-md-5 mt-4">
                        <Link to="/">
                                <p style={{color: 'olivedrab', fontWeight: '400', textDecoration: 'underline'}}>
                                    {t('faseya.back')}
                                </p>
                        </Link>
                    </div>
                    {!status ? 
                        <>
                        <MDBRow className="my-3 justify-content-center">
                            <form className='needs-validation form-demo ' onSubmit={submitHandler} noValidate>
                                <MDBRow className='mb-2'>
                                    <MDBCol>
                                        <p className='title-project-demo'>{t('faseya.banner.ask_demo')}</p >
                                    </MDBCol>
                                </MDBRow >

                                { error  && (
                                    <MDBRow className="mb-3 text-center">
                                        <MDBCol>
                                            <span className="error-text">{error}</span>
                                        </MDBCol>
                                    </MDBRow>
                                )}

                                <MDBRow className="form-row">
                                    <MDBCol md="6" className="form-group mb-3">
                                        <label htmlFor="name" className="label-project">{t('faseya.ask_demo.forms.item_1')}</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="name" 
                                            name="nom"
                                            onChange={onChangeHandler}
                                            required
                                        />
                                        
                                    </MDBCol >

                                    <MDBCol md="6" className="form-group mb-3">
                                        <label htmlFor="surname" className="label-project">{t('faseya.ask_demo.forms.item_2')}</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="surname" 
                                            name="prenom"
                                            onChange={onChangeHandler}
                                            required
                                        />
                                        
                                    </MDBCol >
                                </MDBRow >

                                <MDBRow className="form-row">
                                    <MDBCol md="6" className="form-group mb-3">
                                        <label htmlFor="email" className="label-project">{t('faseya.ask_demo.forms.item_3')}</label>
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            id="email" 
                                            name="email"
                                            onChange={onChangeHandler}
                                            required
                                        />
                                        
                                    </MDBCol >

                                    <MDBCol md="6" className="form-group mb-3">
                                        <label htmlFor="phone" className="label-project">{t('faseya.ask_demo.forms.item_4')}</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="phone" 
                                            name="telephone"
                                            onChange={onChangeHandler}
                                            required
                                        />
                                        
                                    </MDBCol >
                                </MDBRow >

                                <MDBRow className="form-row">
                                    <MDBCol md="6" className="form-group mb-3">
                                        <label htmlFor="service" className="label-project">{t('faseya.ask_demo.forms.item_5')}</label>
                                        <select 
                                            className="add-raduis-select"
                                            id="service" 
                                            name="serviceId"
                                            onChange={onChangeHandler}
                                            ref={refService}
                                        >
                                            <option>{t('faseya.ask_demo.forms.item_6')}</option>

                                            { services.map((service, index) => (
                                                <option value={ service.id } key={ index }>
                                                    {t(`faseya.Register.consultants.skills.`+service.title)}
                                                </option>
                                                ))  
                                            }
                                        </select>
                                        
                                    </MDBCol >

                                    <MDBCol md="6" className="form-group mb-3">
                                        <label htmlFor="address" className="label-project">{t('faseya.ask_demo.forms.item_7')}</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="address" 
                                            name="adresse"
                                            onChange={onChangeHandler}
                                            required
                                        />
                                        
                                    </MDBCol >
                                </MDBRow >

                                <MDBRow className="form-row">
                                    <MDBCol md="6" className="form-group mb-3">
                                        <label htmlFor="city" className="label-project">{t('faseya.ask_demo.forms.item_8')}</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="city" 
                                            name="ville"
                                            onChange={onChangeHandler}
                                            required
                                        />
                                        
                                    </MDBCol >

                                    <MDBCol md="6" className="form-group mb-3">
                                        <label htmlFor="society" className="label-project">{t('faseya.ask_demo.forms.item_9')}</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="society" 
                                            name="societe"
                                            onChange={onChangeHandler}
                                            required
                                        />
                                        
                                    </MDBCol >
                                </MDBRow >

                                <MDBRow className="justify-content-end">
                                        {connecting &&
                                            <img src={olive_loader} style={{position:'absolute', zIndex:1, left:'47.5%', width:40}} alt="" />
                                        }  
                                        {!connecting &&  
                                            <MDBBtn type="submit"  className="btn-color" style={{borderRadius: '30px', padding: '3px 10px '}}>
                                                {t('faseya.send')}
                                            </MDBBtn> 
                                        }
                                </MDBRow>
                            </form>
                        </MDBRow>
                    </>:
 
                    <div className='d-flex flex-column align-items-center' style={{paddingTop: "20%", paddingBottom: "20%"}}>
                        <div className="p-1">
                            <img src={logo_faseya} className="faseya-logo"/>
                        </div>
                        <div className="pl-5 text-tanks mt-3">
                            {t('faseya.message')}
                        </div>
                    </div>
                    }
                </MDBCol> 

                <MDBCol xs="12" md="3" className='hide-mobile' style={{backgroundColor: 'white'}}>
                    <MDBRow className='d-flex justify-content-center align-items-center'>
                       <SponsorsComponent />
                    </MDBRow>
                </MDBCol>   
            </MDBRow> 

        </>
    )
}

export default withTranslation()(DemoPage);
