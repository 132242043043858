import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdbreact';
import './ServicesComponent.css';

import domain_1 from '../../assets/images/_domain_1.png';
import domain_2 from '../../assets/images/_domain_2.png';
import domain_3 from '../../assets/images/_domain_3.png';
import domain_4 from '../../assets/images/_domain_4.png';
import domain_5 from '../../assets/images/_domain_5.png';
import domain_6 from '../../assets/images/_domain_6.png';
import olive_loader from '../../assets/images/olive-loader.gif'; 

import { withTranslation } from 'react-i18next';
import serviceService from '../../services/service.service';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainReduxActions from '../../store/mainRedux/mainReduxActions';


class ServicesComponent extends React.Component {
  
    
  state = {
    collapseID: '',
    loading : true,
    specs : [
        {image : domain_1},
        {image : domain_2},
        {image : domain_3},
        {image : domain_4},
        {image : domain_5},
        {image : domain_6}
      ],
    services : []
  };  
  
  componentDidMount()
  {

    this.setState({services : this.props.mainReduxState.services})

    setTimeout(() => {
      
      if(this.state.services.length == 0)
      {
        this.fetchServices();
      }
      else
      {
        this.setState({loading:false})
      }

    }, 50);



 
  }

  fetchServices = () =>{

    this.setState({loading:true})

    serviceService.getServices().then((data) => {

      this.setState({loading:false})

      var servData = [];
      for (let i = 0; i < 6; i++) {
        const el = data[i];
        // el.image = this.state.specs[i].image;

        servData.push(el);
        
      }

      
      let oldFirst = servData[0];
      servData[0] = servData[2];
      servData[2] = oldFirst;
      
      for (let i = 0; i < 6; i++) {
        const el = servData[i];
        el.image = this.state.specs[i].image;
      }


      

      this.setState({services : servData})
    
      console.log(this.state.services)
      
      this.props.mainReduxActions.update_services(servData);

      }).catch((err)=>{
        
        this.setState({loading:false})

      })

  }


  render() {

    const { collapseID } = this.state;
    const {t} = this.props;
    const header = this.props.header || 'faseya.our_services.title' ; 

    

    return (
      <MDBContainer  style={{marginTop:80}}>
  
        <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}>{/*  {t('Welcome to React')} */} {t(header)}</h4>

        <MDBRow style={{position:'relative'}}>
          
          {this.state.loading &&
              <div style={{position:'absolute', top:0, left:0, backgroundColor:'#678b2d8a', width:'100%', height:'100%', zIndex:3}}>
                  <img src={olive_loader} style={{position:'absolute', zIndex:1, top:'45%', left:'47.5%', width:40}} alt="" />
              </div>
          }

          {this.state.services.map((serv, index) =>
            <MDBCol key={index} xs="12" sm="12" md="4"  className="mb-2" >
              <MDBCol size="12"  className="mb-4 f-service-item" style={{backgroundImage:'url('+serv.image+')'}} >
                <h6 className="f-service-item-header">{t('faseya.our_services.category_'+serv.id)}</h6>
                <MDBBtn outline color='white' className="btn btn-success-gradiant font-14 f-btn-olive" style={{position:'absolute', bottom: 10, right:10, padding:5, paddingRight:10, paddingLeft:10}} onClick={() => this.props.navigation(serv.type, serv.title, serv.id)}>
                    {t('faseya.our_services.begin')}
                </MDBBtn>    
              </MDBCol> 
            </MDBCol>
            ) 
          }

        </MDBRow>
            
      </MDBContainer>
    );
  }
}


function mapStateToProps(state) {
  return {
    mainReduxState: state.mainReduxState
  }
}

function mapDispatchToProps(dispatch) {
  return {
    mainReduxActions: bindActionCreators(mainReduxActions, dispatch)
  }
}

export default connect(mapStateToProps , mapDispatchToProps)(withTranslation()(ServicesComponent));
