var  levels = [
    {
        id: 1,
        name: "junior",
        value: "junior",
        label: "junior",
    },
    {
        id: 2,
        name: "confirme",
        value: "confirmé(e)",
        label: "confirmé(e)",
    },
    {
        id: 3,
        name: "expert",
        value: "expert(e)",
        label: "expert(e)",
    },
    {
        id: 4,
        name: "senior",
        value: "senior",
        label: "senior",
    },
  
]

export default levels;