import React, {useState, useEffect, useRef}  from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBIcon,
    MDBBadge, 
    MDBModal, 
    MDBModalBody,
    MDBModalHeader,
    MDBNotification,
    MDBToast,
    MDBToastContainer,
    MDBModalFooter,
    MDBPopover, MDBPopoverBody, MDBPopoverHeader,
    MDBListGroup, MDBListGroupItem
} from 'mdbreact';

import _ from 'lodash';

import SponsorsComponent from '../../components/SponsorsComponent/SponsorsComponent';
import Selectable from "react-dropdown-select";
import Select, { StylesConfig } from 'react-select'
import makeAnimated from 'react-select/animated';
// import { colourOptions } from '../data';

import partnersMock from '../../services/mocks/partners';
import yearExperiences from '../../services/mocks/yearExperience';
import levels from '../../services/mocks/levels';
import availablties from '../../services/mocks/availablities';
// import skills from '../../services/mocks/skills';
import degrees from '../../services/mocks/degrees';
import './ConsultantModifyCVPage.scss';

import olive_loader from '../../assets/images/olive-loader.gif'; 

import default_user from './../../assets/images/default_user.png';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';

import consultantsService from '../../services/consultants.service';
import { toast } from '../../mdbreact/dist/mdbreact';
import uploadService from '../../services/upload.service';
import skillService from '../../services/skill.service';
import { withTranslation } from 'react-i18next';
import diplomasService from '../../services/diplomes.service';
import experiencesService from '../../services/experiences.service';
import { string } from 'yup';
import { values } from 'lodash';

function ConsultantModifyCVPage(props) {

    const[partners, setPartners] = useState(partnersMock)
    const[showFormCv, setShowFormCv] = useState(true)
    const[showExperienceModal, setShowExperienceModal] = useState(false)
    const[showDegreeModal, setShowDegreeModal] = useState(false)
    const[showFormationModal, setShowFormationModal] = useState(false)
    const[activeDiploma, setActiveDiploma] = useState(false)
    const[activeExperience, setActiveExperience] = useState(false)
    const[activeFormation, setActiveFormation] = useState(false)
    const[formations, setFormations] = useState([])
    const[experiences, setExperiences] = useState([])
    const[experiencesTemp, setExperiencesTemp] = useState([])
    const[diplomas, setDiplomas] = useState([])
    const[diplomasTemp, setDiplomasTemp] = useState([])
    const[degreeName, setDegreeName] = useState('')
    const[degreeId, setDegreeId] = useState()
    const[degreeSchool, setDegreeSchool] = useState('')
    const[degreeStartedAt, setDegreeStartAt] = useState('')
    const[degreeEndAt, setDegreeEndAt] = useState('')
    const[expStartedAt, setExpStartedAt] = useState('')
    const[expEndAt, setExpEndAt] = useState('')
    const[expCompany, setExpCompany] = useState('')
    const[expFunction, setExpFunction] = useState('')
    const[tasks, setTasks] = useState([])
    const[techs, setTechs] = useState([])
    const[task, setTask] = useState('')
    const[tech, setTech] = useState('')
    const[formationName, setFormationName] = useState('')
    const[formationSchool, setFormationSchool] = useState('')
    const[formationStartedAt, setFormationStartedAt] = useState('')
    const[formationEndAt, setFormationEndAt] = useState('')
    const[selectedFile, setSelectedFile] = useState(default_user)

    const[skills, setSkills] = useState([])

    const[showNotif, setShowNotif] = useState(false);
    const[showNotifError, setShowNotifError] = useState(false);
    const[loadingCV, setLoadingCv] = useState(true);
    const[updatingCv, setUpdatingCv] = useState(false);

    const[consultant, setConsultant] = useState({})
    
    const fileRef = useRef()
    const history = useHistory()

    const avatarBaseUrl = "https://api.faseya-dev.com/api/consultants/files/";


    // Consultant current values
    const[currentPoste, setCurrentPoste] = useState('')
    const[currentTitre, setCurrentTitre] = useState('')
    const[currentPresentation, setCurrentPresentation] = useState('')
    const[currentXpItem, setCurrentXpItem] = useState({})
    const[currentAvailabilityItem, setCurrentAvailabilityItem] = useState({})
    const[currentYearItem, setCurrentYearItem] = useState(yearExperiences[0])
    const[currentSkills, setCurrentSkills] = useState([{id:null, name:null, value:'',label:''}])
   

    // Utilities

    const[xpPopover, toggleXpPopover] = useState(false);
    const[diplomasPopover, toggleDiplomasPopover] = useState(false);

    const[editingXp, setEditingXp] = useState(false);
    const[editingXpId, setEditingXpId] = useState(null);
    const[editingDiploma, setEditingDiploma] = useState(false);
    const[editingDiplomaId, setEditingDiplomaId] = useState(null);
    
    const animatedComponents = makeAnimated();

    const singleItem = (color = 'olivedrab', width = 110) => ({
        alignItems: 'center',
        width:'max-content',
        backgroundColor: color,
        color: 'white',
        borderRadius: 10,
        textIndent:10,
        paddingRight:5,
        marginRight:7
      });
    const customStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = 'olivedrab';
            return {
              ...styles,
              backgroundColor: 'white',
              color: 'black',
              cursor: isDisabled ? 'not-allowed' : 'default',
              ':active': {
                ...styles[':active'],
                backgroundColor: 'olivedrab',
              },
            };
          },
          multiValue: (styles, { data }) => {
            const color = 'olivedrab';
            return {
              ...styles,
              backgroundColor: color,
              borderRadius: 10,
              width:'max-content'

            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: 'white',
            borderRadius: 10,
            width:'max-content'

          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            backgroundColor:'#6b8e23bd',
            borderRadius: 10,

            color: 'white',
            ':hover': {
              backgroundColor: '#dc3545',
              color: 'white',
                borderRadius: 10,

            },
          }),
          singleValue: (styles, { data }) => 
          ({ ...styles, ...singleItem(data.color, data.width) }),
        
      
      }
   
    useEffect(() => {

        window.scrollTo(0, 0);
    
        // console.log('history')
        
    

        var user = JSON.parse(localStorage.getItem("user"));

        if(user.consultantId)
        {
            consultantsService.getConsultant(user.consultantId).then(data=>{
                var cv = data;
                setLoadingCv(false)
                setConsultant(cv)
                // console.log(cv)
                // // console.log(cv)
                    // Get Skills
                getSkills(cv);

                setTimeout(() => {
                    
                    
                    setCurrentPoste(cv.profil);
                    setCurrentTitre(cv.titre);
                    setCurrentPresentation(cv.presentation);
                    setSelectedFile(avatarBaseUrl+cv.photo)
                    var urls = cv.photo.split("https://");
                    var url = urls[urls.length-1];
                    if(cv.photo.includes("api")) // v2 format
                        setSelectedFile(url)
                    getExperienceItem(cv);
                    getAvailabilityItem(cv);
                    getYearsItem(cv);

                    

                }, 50); 

                    
                // var years = parseInt(consultant.nbr_annee_experience);

                // xpIndex = years - 1;
                // if(years > 5)
                // xpIndex = 5;

                // // console.log(yearExperiences);
                // // console.log(xpIndex);

                // var item = yearExperiences[xpIndex];
                // console.log(item);
                // setCurrentYearItem(item)

            }).catch(err=>{
                setLoadingCv(false)
                // console.log(err)
            })
            
                    
            // Exps
            experiencesService.getExperiences(user.consultantId).then(data=>{

                var newData = data;

                newData.forEach(item => {
                    setRealisations(item)
                    setTechEnvs(item)
                });

                // console.log(newData);
                setExperiences(newData);
                setExperiencesTemp(newData);


                /////////// TEST SECTION : UPDATE /////////////
                // var testedItem = data[0];
                // const payload = (({ id, consultantId, createdAt, works, techEnvs, updatedAt,deletedAt,  ...o }) => o)(testedItem) // remove id
                // console.log(payload)
                // experiencesService.updateExperience(consultant.id,testedItem.id,payload)
                // .then(d=>{console.log(testedItem)})
                // .catch(e=>{console.log(e)})
                ///////////////////////////////////////////////

            }).catch(err=>{
                // console.log(err)
            })

            // console.log('diplomas...');

            diplomasService.getDiplomas(user.consultantId).then(data=>{

                var newData = data;

                // console.log(data);
                setDiplomas(newData);
                setDiplomasTemp(data);

            }).catch(err=>{
                // console.log(err)
            })

        }

        // setUser(user);


     
    
    }, [])


    const setRealisations = (item)=>
    {
        var worksString = item.realisation;
        var worksArray = worksString.split(',');
        const _worksArray =  worksArray.filter(e =>  e);
  
        var _works = [];
       
        for (let i = 0; i < _worksArray.length; i++) {
          const el = _worksArray[i];
            _works.push(el); 
           
        }
  
       item.works = _works;
  
    }


    
    const setTechEnvs = (item)=>
    {
        var techEnvsString = item.environement_technique;
        var techEnvsArray = techEnvsString.split(',');
        const _techEnvsArray =  techEnvsArray.filter(e =>  e);
  
        var _techEnvs = [];
       
        for (let i = 0; i < _techEnvsArray.length; i++) {
          const el = _techEnvsArray[i];
            _techEnvs.push(el); 
           
        }
  
       item.techEnvs = _techEnvs;
  
    }

    const openEditXp = (xpId) =>{

        setEditingXp(true) // Indicate that we are editing cv
        setEditingXpId(xpId)
        toggleXpPopover(!xpPopover)
        setShowExperienceModal(true)


        // Values
        var xp = experiences.find(e=>e.id == xpId);
        setExpCompany(xp.societe)
        setExpFunction(xp.intitule_poste)
        var startDate = xp.annee_debut+"-"+xp.mois_debut+"-01";
        setExpStartedAt(startDate)

        var endDate = xp.annee_fin+"-"+xp.mois_fin+"-01";
        setExpEndAt(endDate)

        setTasks(xp.works)
        setTechs(xp.techEnvs)

    }


    
    const openEditDiploma = (dip) =>{

        var dipId = dip.id;

        setEditingDiploma(true) // Indicate that we are editing cv
        setEditingDiplomaId(dipId)
        toggleDiplomasPopover(!diplomasPopover)
        setShowDegreeModal(true);

        // Values
        setDegreeId(dip.niveauId);
        setDegreeName(dip.libelle)
        setDegreeSchool(dip.etablissement)
        var startDate = dip.annee_debut+"-"+dip.mois_debut+"-01";
        setDegreeStartAt(startDate)

        var endDate = dip.annee_obtention+"-"+dip.mois_obtention+"-01";
        setDegreeEndAt(endDate)
 


    }
  
    const getSkills = (cv) =>
    {
        skillService.getSkills().then(data=>{

            var skillsData = data;
            skillsData.forEach(el => {
                el.value = el.name;
                el.label = el.name;
            });
            
            setSkills(skillsData);

            getSkillsItems(skillsData,cv);

         })
         .catch(err=>{
      
         })
    }

    const getExperienceItem = (cv) =>
    {

        var titre = cv.titre;
        var lvl = levels.find(e=>e.name == titre)

        if(!lvl) lvl = levels[1];

        var xpItem = lvl;
        xpItem.value = xpItem.value;
        xpItem.label = xpItem.value;
        setCurrentXpItem(xpItem);
    }
  
    const getAvailabilityItem = (cv) =>
    {
        var index = 0;
        var av = cv.disponibilite;
        
        if(av == "temps_partiel")
            index = 1;

            
        // console.log(availablties[index])
        var availability = availablties[index];
        availability.value = availability.value;
        availability.label = availability.value;
        // console.log(availability)
        setCurrentAvailabilityItem(availability);
    }

  
    const getYearsItem = (cv) =>
    {
        var xpIndex = 0;
        var years = parseInt(cv.nbr_annee_experience);

        xpIndex = years - 1;
        if(years > 5)
        xpIndex = 5;
 
        var item = yearExperiences[xpIndex];
        // console.log(item);
        item.value = item.name;
        item.label = item.name;
        setCurrentYearItem(item)
        // return item;

    }


    const getSkillsItems = (skills,cv) =>
    {
        var skillsString = cv.competence_technique;
        var skillsArray = skillsString.split(',');
        const _skillsArray =  skillsArray.filter(e =>  e);

        var _skills = [];
        // console.log(skills)
        _skillsArray.forEach(str => {
            var el = skills.find(e=>e.name.toLowerCase() == str.toLowerCase());
            if(el)
                _skills.push(el);
        });
        console.log(_skills);

        setCurrentSkills(_skills)
        // return item;

    }




    const onFileChange = e => {
        // console.log(e.target.files[0]);
        var formData = new FormData();
        // Attach file
        formData.append('file', e.target.files[0]); 

        uploadService.uploadImage(formData).then(({ link }) => {
            // console.log(link)
            setSelectedFile(link)
            // setMyFile(link);
        }).catch(err=> {

            // console.log(err)
        })
    }

    const onTriggerFile = () => {
        fileRef.current.click()
    }

    const submitHandler = (e) => {
        e.preventDefault();
        //event.target.className += " was-validated";
        setShowFormCv(true)
    }
    const changeHandler = (e) => {
      ///setUser(e.target.name, e.target.value)
    }

    const submitHandlerDegree = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";
        if(!degreeName || !degreeSchool || !degreeStartedAt || !degreeId ) return ;

        const yearStart = degreeStartedAt.split('-')[0];
        const month_day_start = degreeStartedAt.split('-')[1];
        const monthStart = month_day_start.split('-')[0];
        
        var monthEnd = 'en cours';
        var yearEnd = 'en cours';

        if(!activeDiploma)
        {
            yearEnd = degreeEndAt.split('-')[0];
            const month_day_end = degreeEndAt.split('-')[1];
            monthEnd = month_day_end.split('-')[0];
        }
        
        const degree = {
            niveauId: degreeId,
            libelle: degreeName,
            description: 'aucune',
            etablissement: degreeSchool,
            mois_debut: monthStart,
            annee_debut: yearStart,
            mois_obtention: monthEnd,
            annee_obtention: yearEnd,
        };

        if(editingDiploma)
        {
            var dip = diplomas.find(e=>e.id == editingDiplomaId);
            degree.id = dip.id;
            var dipIndex = diplomas.indexOf(dip);
            var toEdit = dip;
            var newDiplomas = [...diplomas];
            toEdit = {...toEdit, ...degree};
            newDiplomas[dipIndex] = toEdit;
            setDiplomas(newDiplomas);
            // console.log(newDiplomas)
        }
        else
        {
            var newDiplomas = [...diplomas];
            newDiplomas.push(degree);
            setDiplomas(newDiplomas);
        }

        
        setDegreeSchool('');
        setDegreeName('');
        setDegreeStartAt('')
        setDegreeEndAt('')
        setActiveDiploma(false)
        setShowDegreeModal(false);
        setEditingDiploma(false)
        setDegreeId();
        // console.log(diplomas)

    }


    const onChangePoste = (v) => {
        setCurrentPoste(v)
    }

    const onChangePresentation = (v) => {
        setCurrentPresentation(v)
    }

    function onChangeAvailable(values)  {
        // console.log(values)
        setCurrentAvailabilityItem(values)

    }

    
    function onChangeSkills(values)  {
        // console.log(values)
        setCurrentSkills(values)

    }

    function onChangeExperience (values) {
        setCurrentXpItem(values)
        setCurrentTitre(values.name)
    }

    function onChangeYearExperience (values) {
        setCurrentYearItem(values)
    }

    const showDegreeModalFunc = () => {

        setEditingDiploma(false) // Not editing ; But adding new Diploma
        setEditingDiplomaId(null)
        // Values
        setDegreeId(null);
        setDegreeName('')
        setDegreeSchool('')
        setDegreeStartAt('')
        setDegreeEndAt('')

        setShowDegreeModal(!showDegreeModal)
    }

    const showModalExperience = () => {

        setEditingXp(false); // Not editing ; But adding new Xp
        setExpStartedAt('') 
        setExpEndAt('')
        setExpCompany('')
        setExpFunction('')
        setTask('')
        setTech('')
        setTasks([])
        setTechs([])

        setShowExperienceModal(!showExperienceModal)
    }

    const showModalFormation = () => {
        setShowFormationModal(!showFormationModal)
    }

    const onCheckChange = () => {
        setActiveDiploma(!activeDiploma)
    }

    const onCheckChangeExperience = () => {
        setActiveExperience(!activeExperience)
    }

    const onCheckChangeFormation = () => {
        setActiveFormation(!activeFormation)
    }

    const onChangeDegree = (value) => {
        if(value[0])
            setDegreeId(value[0].id)
    }

    const submitHandlerExperience = (e) => {
        e.preventDefault();
 
        if(task != "")
            tasks.push(task)

        if(tech != "")
            techs.push(tech)

        var taskContent = tasks.join(",");
        var techContent = techs.join(",");

        // console.log(expCompany,expFunction,expStartedAt,tasks,techs)
        if(!expCompany || !expFunction || !expStartedAt || !tasks || !techs)
        return ;
    
        const yearStart = expStartedAt.split('-')[0];
        const month_day_start = expStartedAt.split('-')[1];
        const monthStart = month_day_start.split('-')[0];

        var monthEnd = 'en cours';
        var yearEnd = 'en cours';

        if(!activeExperience) {
            yearEnd = expEndAt.split('-')[0];
            const month_day_end = expEndAt.split('-')[1];
            monthEnd = month_day_end.split('-')[0];
        }

        const experience = {
            societe: expCompany,
            intitule_poste: expFunction,
            mois_debut: monthStart,
            annee_debut: yearStart,
            mois_fin: monthEnd,
            annee_fin: yearEnd,
            realisation: taskContent,
            environement_technique : techContent
        };

        // console.log(experience)
        
        if(editingXp)
        {
            var xp = experiences.find(e=>e.id == editingXpId);
            experience.id = xp.id;
            var xpIndex = experiences.indexOf(xp);
            var toEdit = xp;
            var newExperiences = [...experiences];
            toEdit = {...toEdit, ...experience};
            newExperiences[xpIndex] = toEdit;
            setExperiences(newExperiences);
        }
        else
        {
            var newExperiences = [...experiences];
            newExperiences.push(experience);
            setExperiences(newExperiences);
        }
       
        setExpCompany('');
        setExpEndAt('');
        setExpFunction('');
        setExpEndAt('');
        setActiveExperience(false);
        setTechs([]);
        setTasks([]);

        setShowExperienceModal(false);

        console.log(experiences);
 
    }

    const addTask = () => {
        // const task = {name: ''}
        const task = '';
        setTasks(tasks => [...tasks, task])
    }

    const addTech = () => {
        // const tech = {name: ''}
        const tech = '';
        setTechs(techs => [...techs, tech])
    }

    function handleChangeTasks(index, e) {
        const updatedTask = [...tasks]
        updatedTask[index] = e.target.value;
        setTasks(updatedTask) 
    }

    function handleChangeTech(index, e) {
        const updatedTech = [...techs]
        updatedTech[index] = e.target.value
        setTechs(updatedTech) 
    }


    const onChangeNameDegree = (e) => {
        setDegreeName(e.target.value)
    }

    const onChangeSchoolDegree = (e) => {
        setDegreeSchool(e.target.value)
    }

    const onChangeStartAtDegree = (e) => {
        setDegreeStartAt(e.target.value)
    }

    const onChangeEndAtDegree = (e) => {
        setDegreeEndAt(e.target.value)
    }

    const onChangeExpEndAt = (e) => {
        setExpEndAt(e.target.value)
    }

    const onChangeExpStartedAt = (e) => {
        console.log(e.target.value)
        setExpStartedAt(e.target.value)
    }

    const onChangeExpCompany = (e) => {
        setExpCompany(e.target.value)
    }

    const onChangeExpFunction = (e) => {
        setExpFunction(e.target.value)
    }
 
    // default task
    const onChangeDegreeTask = (e) => {
        setTask(e.target.value)
    } 

    // default tech
    const onChangeDegreeTech = (e) => {
        setTech(e.target.value)
    }

    const onChangeEndAtFormation = (e) => {
        setFormationEndAt(e.target.value)
    }

    const onChangeStartedAtFormation = (e) => {
        setFormationStartedAt(e.target.value)
    }

    const onChangeNameFormation = (e) => {
        setFormationName(e.target.value)
    }

    const onChangeSchoolFormation = (e) => {
        setFormationSchool(e.target.value)
    }
    
    const submitHandlerFormation = (e) => {
        e.preventDefault();


        const formation = {
            name: formationName,
            school_name: formationSchool,
            startAt: formationStartedAt,
            endAt: activeFormation ? 'en cours' : formationEndAt
        };

        formations.push(formation);

        setFormationName('');
        setFormationEndAt('');
        setFormationSchool('');
        setFormationStartedAt('');
        setActiveFormation(false);
        
        setShowFormationModal(false);
        //console.log(formations);

    }

    const submitHandlerRegistration = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";

        // Collecte Data
        //console.log('collecting data ...')

        var cv = consultant;
        cv.titre = currentTitre;
        cv.presentation = currentPresentation;
        cv.disponibilite = currentAvailabilityItem.name;
        cv.nbr_annee_experience = currentYearItem.id - 1;
        // var user = JSON.parse(localStorage.getItem("user"));
        // cv.roles = ["consultant"];

        var skillsString = "";
        currentSkills.forEach(el => {
            var s = el.name+",";
            skillsString += s;
        });
        var dataUpdate = 
        {
            photo: selectedFile,
            prenom: cv.prenom,
            nom: cv.nom,
            presentation : currentPresentation,
            adresse: cv.adresse,
            disponibilite: currentAvailabilityItem.name,
            nbr_annee_experience: currentYearItem.id,
            competence_technique: skillsString,
            titre: currentTitre,
            profil: currentPoste,
            serviceId: 1,
          }

        console.log(dataUpdate);

        // setUpdatingCv(true);
        // consultantsService.updateConsultant(consultant.id ,dataUpdate).then(data=>{
           
        //     // Update/Create Exps
            
        //     experiences.forEach((xp,ind) => {
        //         if(xp.id) // Already Exist : Update
        //         { 
        //             // Check if item did change
        //             var unchanged =_.isEqual(xp,experiencesTemp[ind]) 

        //             if(!unchanged)
        //             { 
        //                 const payload = (({ id, consultantId, works,techEnvs, createdAt, updatedAt,deletedAt,  ...o }) => o)(xp) // remove id
        //                 // console.log(payload)
        //                 experiencesService.updateExperience(consultant.id,xp.id,payload)
        //                 .then(d=>{/* console.log(xp.societe) */})
        //                 .catch(e=>{/* console.log(e) */})
        //             }
        //         }
        //         else    // Doesn't exist   : Create
        //         {
        //             // console.log(xp)
        //             experiencesService.addExperience(consultant.id, xp)
        //             .then(d=>{/* console.log(xp.societe) */})
        //             .catch(e=>{/* console.log(e) */})
        //         }
        //     });

        //     // Update/Create Diplomas
        //     diplomas.forEach((dip, ind) => {
        //         if(dip.id) // Already Exist : Update
        //         { 
        //             // Check if item did change
        //             var unchanged = _.isEqual(dip,diplomasTemp[ind]) 
                    
        //             if(!unchanged)
        //             { 
        //                 const payload = (({ id,consultantId,createdAt, updatedAt,deletedAt, description, ...o }) => o)(dip) // remove id
        //                 // console.log(payload)
        //                 diplomasService.updateDiploma(consultant.id,dip.id,payload)
        //                 .then(d=>{/* console.log(d) */})
        //                 .catch(e=>{/* console.log(e) */})
        //             }
        //         }
        //         else    // Doesn't exist   : Create
        //         {
        //             diplomasService.addDiploma(consultant.id, dip)
        //             .then(d=>{/* console.log(d) */})
        //             .catch(e=>{/* console.log(e) */})
        //         }
        //     });

        //     setShowNotif(true)
        //     setUpdatingCv(false);

        //     setTimeout(() => {
        //         setShowNotif(false)
        //         // window.location.reload();
        //         // history.push({
        //         //     pathname: "/",
        //         // });

        //     }, 1400);
            
        // })
        // .catch(err=>{
            
        //     setShowNotifError(true)
        //     setUpdatingCv(false);
        //     console.log(err);

        //     setTimeout(() => {
        //         setShowNotifError(false)
        //     }, 1400);

        // })
        

 
    }


    const {t} = props;


    
    return (
        <>
        
       
        <HeaderComponent history={props.history} />

          <MDBRow style={{ background: 'white', width:'100%'}} className="justify-content-center">
  
            <MDBNotification  
                show
                style={{position:'fixed', right:'4%', top:'4%', zIndex:888, backgroundColor:'olivedrab', color:'white', display:showNotif?'block':'none'}}
                fade
                icon='none'
                iconClassName="text-primary"
                title="Notification"
                message={t('faseya.modify_cv.message_success')}
            />
            
            <MDBNotification  
                show
                style={{position:'fixed', right:'4%', top:'4%', zIndex:888, backgroundColor:'red', color:'white', display:showNotifError?'block':'none'}}
                fade
                icon='none'
                iconClassName="text-primary"
                title="Notification"
                message={t('faseya.modify_cv.message_error')}
            />

            <MDBCol xs="12" md="9" className="z-depth-1 mt-5 mb-3">

                {loadingCV &&
                    <div style={{position:'absolute', top:0, left:0, backgroundColor:'#678b2d8a', width:'100%', height:'100%', zIndex:3}}>
                        <img src={olive_loader} style={{position:'absolute', zIndex:1, top:'40%', left:'47.5%', width:40}} alt="" />
                    </div>
                }
               <MDBRow className="justify-content-center flex-column ml-md-5">
                  
                   
                    <div className="d-flex justify-content-center pt-5">
                        {! showFormCv ?
                            <form className='needs-validation form-responsive' onSubmit={submitHandler} noValidate>
                            
                                <MDBRow className="form-group row"> 
                                
                                    <label htmlFor="inputPhone" className="col-sm-2 col-form-label">
                                        Telephone *
                                    </label>
                                
                                    <MDBCol sm="9">
                                            <input 
                                                type="text" 
                                                className="form-control input-phone-address" 
                                                id="inputPhone" 
                                                id='validationPhone'
                                                required
                                                validation='Looks good!'
                                            />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="form-group row mb-5"> 
                                
                                    <label htmlFor="inputAddress" className="col-sm-2 col-form-label">
                                        Adresse *
                                    </label>
                                    
                                    <MDBCol sm="9">
                                        <input 
                                            type="text" 
                                            className="form-control input-phone-address" 
                                            id="inputAddress" 
                                            id='validationAddress'
                                            required
                                            validation='Looks good!'
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="form-group my-2">
                                    
                                    <label htmlFor="inputAddress" className="col-sm-2 col-form-label">
                                    
                                    </label>
                                    <MDBCol md="9">
                                        <MDBBtn type="submit"  className="btn-block btn-color">
                                            Suivant
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                        </form>:
                          


                        <form className="justify-content-center form-global" onSubmit={submitHandlerRegistration}>
                           
                           <MDBRow  className="form-group row mb-3">
                               
                                <MDBCol md="3" className="order-1 order-md-0">
                                    
                                       <img 
                                        src={"https://"+selectedFile} 
                                        className="profile_image pointer"
                                        onClick={onTriggerFile} 
                                         
                                       />
                                  
                                </MDBCol>

                                <MDBCol md="9" className="order-0 order-md-1">
                                    <MDBRow  className="d-flex flex-column">
                                        <div className="py-1 text-img">
                                            {t('faseya.modify_cv.pp')}
                                         </div>
                                        <div className="d-flex wrap py-3 pr-5 text-img" style={{fontSize:16, fontWeight:400}}>
                                            {t('faseya.modify_cv.pp_specs')}
                                        </div>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label htmlFor="inputPoste" className="col-sm-2 col-form-label label-input">
                                    {t('faseya.modify_cv.poste')}
                                </label>
                                
                                <MDBCol sm="9">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="inputPoste" 
                                        placeholder={t('faseya.modify_cv.poste_placeholder')}
                                        required
                                        validation='Looks good!'
                                        defaultValue={currentPoste}
                                        onChange={(e) => onChangePoste(e.target.value)} 

                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3">

                                <label htmlFor="inputLevel" className="col-sm-2 col-form-label label-input ">
                                    Bio *
                                </label>

                                <MDBCol sm="9">
                                    <textarea
                                        type="text"
                                        name='accroche'
                                        id='validationAcrroche'
                                        // required
                                        className="form-control"
                                        placeholder={t('faseya.Register.consultants.forms.messages.bio_placeholder')}
                                        validation='Looks good!'
                                        style={{resize: 'none'}}
                                        defaultValue={currentPresentation}
                                        onChange={(e) => onChangePresentation(e.target.value)} 

                                    /> 
                                    <div className="invalid-feedback">
                                        {t('faseya.Register.consultants.forms.messages.item_2')}
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            
                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label htmlFor="inputLevel" className="col-sm-2 col-form-label label-input ">
                                    {t('faseya.modify_cv.level_of_experience')}
                                </label>
                                
                              

                                <MDBCol sm="9">
                                    {/* <Selectable 
                                        labelField="name"
                                        valueField="id"
                                        dropdownHandleRenderer={() => {}}
                                        options={levels} 
                                        values={[currentXpItem]}
                                        // values={levels[0]}
                                        keepSelectedInList="true"
                                        addPlaceholder="Select..."
                                        className="custom-select"
                                        style={{backgroundColor: 'white'}}
                                        onChange={(values) => onChangeExperience(values)} 
                                    /> */}
                                    <Select
                                        styles={customStyles}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        defaultValue={[currentXpItem]}
                                        // isMulti
                                        value={[currentXpItem]}
                                        isSearchable
                                        options={levels}
                                        onChange={(values) => onChangeExperience(values)} 

                                        />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label htmlFor="inputAvailable" className="col-sm-2 col-form-label label-input ">
                                {t('faseya.modify_cv.availability')}
                                </label>
                                
                                <MDBCol sm="9">
                                    {/* <Selectable 
                                        labelField="name"
                                        valueField="id"
                                        dropdownHandleRenderer={() => {}}
                                        options={availablties} 
                                        values={[currentAvailabilityItem]}
                                        addPlaceholder="Select..."
                                        color="olivedrab"
                                        className="custom-select"
                                        onChange={(values) => onChangeAvailable(values)} 
                                    /> */}
                                    <Select
                                        styles={customStyles}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        defaultValue={[currentAvailabilityItem]}
                                        // isMulti
                                        value={[currentAvailabilityItem]}
                                        isSearchable
                                        options={availablties}
                                        onChange={(values) => onChangeAvailable(values)}  

                                        />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label htmlFor="inputYear" className="col-sm-2 col-form-label label-input ">
                                    {t('faseya.modify_cv.years_of_xp')}
                                </label>
                                
                                <MDBCol sm="9">
                                {/* <Selectable 
                                        labelField="name"
                                        valueField="id"
                                        dropdownHandleRenderer={() => {}}
                                        options={yearExperiences} 
                                        values={[currentYearItem]}
                                        className="testName"
                                        addPlaceholder="Select..."
                                        color="olivedrab"
                                        className="custom-select"
                                        onChange={(values) => onChangeYearExperience(values)} 
                                    /> */}
                                    
                                    <Select
                                        styles={customStyles}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        defaultValue={[currentYearItem]}
                                        // isMulti
                                        value={[currentYearItem]}
                                        isSearchable
                                        options={yearExperiences}
                                        onChange={(values) => onChangeYearExperience(values)} 
  

                                        />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label htmlFor="inputSkill" className="col-sm-2 col-form-label label-input ">
                                    {t('faseya.modify_cv.skills')}
                                </label>
                                
                                <MDBCol sm="9">
                                {/* <Selectable 
                                        labelField="name"
                                        valueField="id"
                                        dropdownHandleRenderer={() => {}}
                                        options={skills} 
                                        values={currentSkills}
                                        searchable="true"
                                        autoFocus={true}
                                        multi="true"
                                        searchBy="name"
                                        keepSelectedInList={false}
                                        color="olivedrab"
                                        className="custom-select"
                                        placeholder="Select..."
                                        closeOnSelect="true"
                                        
                                        onChange={(values) => onChangeSkills(values)} 
                                /> */}
                                
                                    <Select
                                        styles={customStyles}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        defaultValue={currentSkills}
                                        isMulti
                                        value={currentSkills}
                                        isSearchable
                                        options={skills}
                                        onChange={(values) => onChangeSkills(values)}  
                                        // onKeyDown={()=>console.log("down")}

                                        />

                                </MDBCol>
                            </MDBRow>
                            
                            
                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label className="col-sm-2 col-form-label label-input ">
                                    {t('faseya.consultant_cv.diplomas')} *
                                </label>
                                
                                <MDBCol sm="9">
                                   <div className="d-flex align-items-center"> 
                                        {diplomas.length == 0 ?
                                            <MDBBadge pill className="py-3 px-3" color="danger">
                                                { diplomas.length }
                                            </MDBBadge>:
                                            <MDBBtn style={{padding:0, backgroundColor:'none'}} color='none' flat={true} onClick={()=>toggleDiplomasPopover(!diplomasPopover)}>
                                                <MDBBadge pill className="py-3 px-3 custom-pill">
                                                    {diplomas.length }
                                                </MDBBadge>
                                            </MDBBtn>
                                            

                                        }
                                        <div className="text-add-degree p-3 ml-4 pointer" onClick={showDegreeModalFunc}>
                                            {t('faseya.Register.consultants.second_part.add')}
                                        </div>
                                   </div>
                                </MDBCol>
                            </MDBRow>
                            
                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label className="col-sm-2 col-form-label label-input ">
                                   Expériences  *
                                </label>
                                
                                <MDBCol sm="9">
                                   <div className="d-flex align-items-center"> 
                                        {experiences.length == 0 ?
                                            <MDBBadge pill className="py-3 px-3" color="danger">
                                                { experiences.length }
                                            </MDBBadge>:
                                         
                                            <MDBBtn style={{padding:0, backgroundColor:'none'}} color='none' flat={true} onClick={()=>toggleXpPopover(!xpPopover)}>
                                                <MDBBadge pill className="py-3 px-3 custom-pill">
                                                    { experiences.length }
                                                </MDBBadge>
                                            </MDBBtn>
                                              
                                           
                                        }
                                        <div className="text-add-degree p-3 ml-4 pointer" onClick={showModalExperience}>
                                            {t('faseya.Register.consultants.second_part.add')}
                                        </div>
                                   </div>
                                </MDBCol>
                            </MDBRow>
                           
                            {/* 
                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label className="col-sm-2 col-form-label label-input ">
                                   Certifications / Formations (optionnel)
                                </label>
                                
                                <MDBCol sm="9">
                                   <div className="d-flex align-items-center"> 
                                       
                                        <MDBBadge pill className="py-3 px-3 custom-pill">
                                            { formations.length }
                                        </MDBBadge>
                                        
                                        <div className="text-add-degree p-3 ml-4 pointer" onClick={showModalFormation}>
                                            Ajouter
                                        </div>
                                   </div>
                                </MDBCol>
                            </MDBRow> */}

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label htmlFor="inputPoste" className="warning-important-text col-sm-2 col-form-label">
                                    Important <span className="">*</span> 
                                </label>
                                
                                <MDBCol sm="9">
                                    <div className="text-experience">
                                        {t('faseya.Register.consultants.second_part.forms.item_13')}
                                    </div>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group mb-2 mt-5">   
                                <label htmlFor="inputAddress" className="col-sm-2 col-form-label">
                                
                                </label>
                                <MDBCol md="9">
                                    {updatingCv &&
                                        <img src={olive_loader} style={{position:'absolute', zIndex:1, bottom:'11%', left:'47.5%', width:40}} alt="" />
                                    }
                                    {!updatingCv &&
                                    <MDBBtn type="submit"  className="btn-block btn-color py-3">
                                       {t('faseya.modify_cv.update')}
                                    </MDBBtn>
                                    }
                                </MDBCol>
                            </MDBRow>


                        </form>
                        }

                    </div>
                  
               </MDBRow>
            </MDBCol>

            

          </MDBRow>

          {/* Xp List for Edition  */}

          <MDBModal isOpen={xpPopover} toggle={()=>toggleXpPopover(!xpPopover)} centered>
            <MDBModalHeader toggle={()=>toggleXpPopover(!xpPopover)} ><span style={{fontFamily:'Montserrat', fontWeight:500}}>Experiences</span></MDBModalHeader>
            <MDBModalBody>
                <MDBListGroup style={{ width: "100%" }}>
                    {experiences.map((xp, index) => (
                        <MDBListGroupItem key={index} hover  onClick={()=>openEditXp(xp.id)} style={{cursor:'pointer'}} >
                            <div className="d-flex justify-content-between" style={{fontFamily:'Montserrat'}}>
                                <h6 className="mb-1" style={{fontWeight:500, color:'olivedrab' }}>{xp.intitule_poste}</h6>
                                <small>{xp.annee_debut}</small>
                            </div>
                            <p className="mb-1">{xp.societe}</p> 
                        </MDBListGroupItem>
                    ))} 

                </MDBListGroup>
            </MDBModalBody>
        </MDBModal>


         {/* Diplomas List for Edition  */}

          <MDBModal isOpen={diplomasPopover} toggle={()=>toggleDiplomasPopover(!diplomasPopover)} centered>
            <MDBModalHeader toggle={()=>toggleDiplomasPopover(!diplomasPopover)} ><span style={{fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.consultant_cv.diplomas')}</span></MDBModalHeader>
            <MDBModalBody>
                <MDBListGroup style={{ width: "100%" }}>
                    {diplomas.map((dip, index) => (
                        <MDBListGroupItem key={index} hover onClick={()=>openEditDiploma(dip)} style={{cursor:'pointer'}} >
                            <div className="d-flex justify-content-between" style={{fontFamily:'Montserrat'}}>
                                <h6 className="mb-1" style={{fontWeight:500, color:'olivedrab' }}>{dip.libelle}</h6>
                                <small>{dip.annee_obtention}</small>
                            </div>
                            <p className="mb-1">{dip.etablissement}</p> 
                        </MDBListGroupItem>
                    ))} 

                </MDBListGroup>
                </MDBModalBody>
            
            </MDBModal>
            

        
            

          <MDBModal isOpen={showDegreeModal} toggle={showDegreeModalFunc} size="lg">
                <MDBModalHeader toggle={showDegreeModalFunc}>{t('faseya.Register.consultants.second_part.diplomes.title')}</MDBModalHeader>

                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol size="12">
                                <form className='needs-validation form-responsive mt-5' onSubmit={submitHandlerDegree} noValidate>
                                    <label htmlFor="degreeType" className="label-text">
                                    {t('faseya.Register.consultants.second_part.diplomes.forms.item_1')}
                                    </label>
                                    <Selectable 
                                        labelField="name"
                                        valueField="id"
                                        options={degrees}
                                        placeholder="Choisir"
                                        style={{color: 'black', fontWeight: '300'}}
                                        values={editingDiploma? [degrees.find(d=>d.id == degreeId)] : []}
                                        onChange={(values) => onChangeDegree(values)} 
                                    />
                                    <br />

                                    <label htmlFor="degreeName" className="label-text">
                                    {t('faseya.Register.consultants.second_part.diplomes.forms.item_2')}
                                    </label>
                                    <input 
                                        type="text" 
                                        id="degreeName"
                                        className="form-control grey-background" 
                                        // required 
                                        value={degreeName}
                                        onChange={onChangeNameDegree}
                                    />
                                    <br />

                                    <label htmlFor="degreeSchool" className="label-text">
                                    {t('faseya.Register.consultants.second_part.diplomes.forms.item_3')}
                                    </label>
                                    <input 
                                        type="text" 
                                        id="degreeSchool"
                                        className="form-control grey-background"
                                        // required 
                                        value={degreeSchool}
                                        onChange={onChangeSchoolDegree}
                                    />
                                    <br />

                                    <label htmlFor="degreeStart" className="label-text">
                                    {t('faseya.Register.consultants.second_part.diplomes.forms.item_4')}
                                    </label>
                                    <input 
                                        type="date" 
                                        id="degreeStart" 
                                        className="form-control" 
                                        value={degreeStartedAt}
                                        onChange={onChangeStartAtDegree}
                                    />
                                    <br />

                                    <label htmlFor="degreeEnd" className="label-text">
                                    {t('faseya.Register.consultants.second_part.diplomes.forms.item_5')} &nbsp;&nbsp;&nbsp; 
                                        <input type="checkbox"  checked={activeDiploma} onChange={onCheckChange}/> 
                                          <span className="current-year"> &nbsp;&nbsp;{t('faseya.Register.consultants.second_part.diplomes.forms.item_6')}</span>
                                    </label>
                                    <input 
                                        type="date" 
                                        id="degreeEnd" 
                                        className="form-control"
                                        disabled={activeDiploma} 
                                        value={degreeEndAt}
                                        onChange={onChangeEndAtDegree}
                                    />
                                    <br />

                                    <MDBBtn type="submit" className="btn-block btn-color">
                                        {editingDiploma ? t('faseya.Register.consultants.second_part.diplomes.forms.item_7') : t('faseya.Register.consultants.second_part.diplomes.forms.item_7') }
                                    </MDBBtn>
                                </form>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>  
          </MDBModal>

          <MDBModal isOpen={showExperienceModal} toggle={showModalExperience} size="lg">
               <MDBModalHeader toggle={showModalExperience}>Experience</MDBModalHeader>

                <MDBModalBody>
                    <MDBRow>
                        <MDBCol size="12">

                            <form className='needs-validation form-responsive mt-2' onSubmit={submitHandlerExperience} noValidate>
                                 
                                <label htmlFor="experienceName" className="label-text">
                                    {t('faseya.Register.consultants.second_part.experiences.item_1')}
                                </label>
                                <input 
                                    type="text"
                                    id="experienceName"
                                    className="form-control grey-background" 
                                    value={expCompany}
                                    onChange={onChangeExpCompany} 
                                />
                                <br />

                                <label htmlFor="degreeName" className="label-text">
                                {t('faseya.Register.consultants.second_part.experiences.item_2')}
                                </label>
                                <input 
                                    type="text" 
                                    id="degreeName"
                                    className="form-control grey-background" 
                                    // required 
                                    value={expFunction}
                                    onChange={onChangeExpFunction} 
                                />
                                <br />

                                <label htmlFor="experienceStart" className="label-text">
                                {t('faseya.Register.consultants.second_part.experiences.item_3')}
                                </label>
                                <input 
                                    type="date" 
                                    id="experienceStart" 
                                    className="form-control" 
                                    value={expStartedAt}
                                    onChange={onChangeExpStartedAt} 
                                    />
                                <br />

                                <label htmlFor="experienceEnd" className="label-text">
                                {t('faseya.Register.consultants.second_part.experiences.item_4')} &nbsp;&nbsp;&nbsp;  
                                    <input type="checkbox" className="date_checkbox"  checked={activeExperience} onChange={onCheckChangeExperience}/> 
                                    <span className="current-year"> &nbsp;&nbsp;{t('faseya.Register.consultants.second_part.experiences.item_5')}</span>
                                </label>
                                <input 
                                    type="date" 
                                    id="experienceEnd" 
                                    className="form-control"
                                    disabled={activeExperience} 
                                    value={expEndAt}
                                    onChange={onChangeExpEndAt}
                                    />
                                <br />

                                <label htmlFor="degreeTasks" className="label-text">
                                {t('faseya.Register.consultants.second_part.experiences.item_6')}
                                </label>
                                { tasks.length ==0 && 
                                <input 
                                    type="text" 
                                    id="degreeTasks"
                                    className="form-control grey-background" 
                                    // required
                                    value={task}
                                    onChange={onChangeDegreeTask}  
                                />
                                }

                                { tasks.length !=0 && 
                                tasks.map((task, index) => (
                                    <input 
                                        type="text" 
                                        name="name"
                                        key={index}
                                        className="form-control grey-background mt-3" 
                                        // value={task.name}
                                        value={task}
                                        onChange={() => handleChangeTasks(index,event)}
                                    /> 
                                ))}

                                <div className="d-flex justify-content-center my-3">
                                   <MDBBadge 
                                    pill className="py-2 px-2 pointer" 
                                    style={{fontSize: '16px'}}
                                    onClick={addTask}
                                   >
                                       +
                                    </MDBBadge>
                                </div>
                                <br />
                                
                                <label htmlFor="degreeTech" className="label-text">
                                {t('faseya.Register.consultants.second_part.experiences.item_7')}
                                </label>
                                { techs.length == 0 &&
                                
                                    <input 
                                        type="text" 
                                        id="degreeTech"
                                        className="form-control grey-background" 
                                        // required
                                        value={tech}
                                        onChange={onChangeDegreeTech}  
                                    />
                                }
                                { techs.length != 0 &&
                                 techs.map((tech, index) => (
                                    <input 
                                        type="text" 
                                        name="name"
                                        key={index}
                                        className="form-control grey-background mt-3" 
                                        value={tech}
                                        onChange={() => handleChangeTech(index,event)}
                                    /> 
                                ))}

                                <div className="d-flex justify-content-center my-3">
                                   <MDBBadge 
                                    pill className="py-2 px-2 pointer" 
                                    style={{fontSize: '16px'}}
                                    onClick={addTech}
                                   >
                                       +
                                    </MDBBadge>
                                </div>

                                <MDBBtn type="submit" className="btn-block btn-color">
                                    {editingXp ? t('faseya.Register.consultants.second_part.experiences.item_8') : t('faseya.Register.consultants.second_part.experiences.item_8') }
                                </MDBBtn>
                                

                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>

          </MDBModal>



          <MDBModal isOpen={showFormationModal} toggle={showModalFormation} size="lg">
                <MDBModalHeader toggle={showModalFormation}>Formation / Certification</MDBModalHeader>

                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol size="12">
                                <form className='needs-validation form-responsive mt-5' onSubmit={submitHandlerFormation} >
                                    

                                    <label htmlFor="forName" className="label-text">
                                        Nom de la formation *
                                    </label>
                                    <input 
                                        type="text" 
                                        id="forName"
                                        className="form-control grey-background" 
                                        // required 
                                        value={formationName}
                                        onChange={onChangeNameFormation}
                                    />
                                    <br />

                                    <label htmlFor="forSchool" className="label-text">
                                        Nom de l'etablissement *
                                    </label>
                                    <input 
                                        type="text" 
                                        id="forSchool"
                                        className="form-control grey-background"
                                        // required 
                                        value={formationSchool}
                                        onChange={onChangeSchoolFormation}
                                    />
                                    <br />

                                    <label htmlFor="forStart" className="label-text">
                                        Date de debut
                                    </label>
                                    <input 
                                        type="date" 
                                        id="forStart" 
                                        className="form-control" 
                                        value={formationStartedAt}
                                        onChange={onChangeStartedAtFormation}
                                    />
                                    <br />

                                    <label htmlFor="forEnd" className="label-text">
                                        Date de fin &nbsp;&nbsp;&nbsp; 
                                        <input type="checkbox"  checked={activeFormation} onChange={onCheckChangeFormation}/> 
                                          <span className="current-year"> en cours</span>
                                    </label>
                                    <input 
                                        type="date" 
                                        id="forEnd" 
                                        className="form-control"
                                        disabled={activeFormation} 
                                        value={formationEndAt}
                                        onChange={onChangeEndAtFormation}
                                    />
                                    <br />

                                    <MDBBtn type="submit" className="btn-block btn-color">
                                        Ajouter la formation
                                    </MDBBtn>
                                </form>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>  
            </MDBModal>





            

            
            <MDBRow style={{display: 'none'}}>
                  <input 
                    type="file"
                    ref={fileRef} 
                    name="file" 
                    onChange={onFileChange} 
                   />
            </MDBRow>

        </>
    )
}

export default withTranslation()(ConsultantModifyCVPage);