import React, {Fragment}  from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBFormInline,
  MDBBtn,
  MDBView,
  MDBInput,
  MDBContainer
} from 'mdbreact';
import './PricingPage.css';

import banner_faseya from '../../assets/images/banner_faseya.jpg';
import PartnersComponent from '../../components/PartnersComponent/PartnersComponent';
import ParticularityComponent from '../../components/ParticularityComponent/ParticularityComponent';
import ServicesComponent from '../../components/ServicesComponent/ServicesComponent';
import ExpertsComponent from '../../components/ExpertsComponent/ExpertsComponent';
import HowComponent from '../../components/HowComponent/HowComponent';
import ParcoursComponent from '../../components/ParcoursComponent/ParcoursComponent';
import PacksComponent from '../../components/PacksComponent/PacksComponent';
import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import TestomiesComponent from '../../components/TestimoniesComponent/TestimoniesComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import TalentsComponent from '../../components/TalentsComponent/TalentsComponent';

class PricingPage extends React.Component {
  state = {
    collapsed: false
  };

  handleTogglerClick = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });
  };

  componentDidMount() {
    
    window.scrollTo(0, 0);

    document.querySelector('nav').style.height = '65px';
  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: '0rem' };
    // const overlay = (
    //   <div
    //     id='sidenav-overlay'
    //     style={{ backgroundColor: 'transparent' }}
    //     onClick={this.handleTogglerClick}
    //   />
    // );
    return (

      <>
      <HeaderComponent  history={this.props.history} />

      <div id='minimalistintro'>
     
       </div>

        <PacksComponent  history={this.props.history} intro={true} />
        

        <TalentsComponent  history={this.props.history} />

        <PrefooterComponent />
      </>

    );
  }
}

export default PricingPage;