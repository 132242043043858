var  degrees = [
    {
        id: 1,
        name: "Doctorat",
    },
    {
        id: 2,
        name: "Master",
    },
    {
        id: 3,
        name: "Licence",
    },
  
]

export default degrees;