
import partner_1 from './../../assets/images/partners/partner (1).png';
import partner_2 from './../../assets/images/partners/partner (2).png';
import partner_3 from './../../assets/images/partners/partner (3).png';
import partner_4 from './../../assets/images/partners/partner (4).png';
import partner_5 from './../../assets/images/partners/partner (5).png';
import partner_6 from './../../assets/images/partners/partner (6).png';
import partner_7 from './../../assets/images/partners/partner (7).png';
import partner_8 from './../../assets/images/partners/partner (8).png';
import partner_9 from './../../assets/images/partners/partner (9).png';
import partner_13 from './../../assets/images/partners/partner (13).png';
import partner_10 from './../../assets/images/partners/partner (10).png';
import partner_11 from './../../assets/images/partners/partner (11).png';
//import partner_12 from './../../assets/images/partners/partner (12).png';


var partnersMock = [
    
    { 
        id: 1,
        img: partner_2
    },
    { 
        id: 2,
        img: partner_3
    },
    { 
        id: 3,
        img: partner_4
    },
    { 
        id: 4,
        img: partner_5
    },
    { 
        id: 5,
        img: partner_6
    },
    { 
        id: 6,
        img: partner_7
    },
    { 
        id: 7,
        img: partner_8
    },
    
    { 
        id: 8,
        img: partner_10
    },
    { 
        id: 9,
        img: partner_11
    },
   /*  { 
        id: 12,
        img: partner_12
    }, */
    { 
        id: 10,
        img: partner_13
    },
    { 
        id: 11,
        img: partner_1
    },
    { 
        id: 12,
        img: partner_9
    },
]

export default partnersMock