export const SET_USER = 'SET_USER';
export const UPDATE_ACTIVE = 'UPDATE_ACTIVE';
export const UPDATE_CONSULTANTS_LIST = 'UPDATE_CONSULTANTS_LIST';
export const UPDATE_CONSULTANTS_PAGE = 'UPDATE_CONSULTANTS_PAGE';

export const UPDATE_CONSULTANTS_EXPERTS = 'UPDATE_CONSULTANTS_EXPERTS';
export const UPDATE_CONSULTANTS_AVAILABLE = 'UPDATE_CONSULTANTS_AVAILABLE';
export const UPDATE_CONSULTANTS_NEW = 'UPDATE_CONSULTANTS_NEW';


export const UPDATE_SERVICES = 'UPDATE_SERVICES';

