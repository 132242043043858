
  var seo = 
  {
    home : {
      title : "La façon la plus simple de recruter un profil tech",
      description: "Faseya est une plateforme de mise en relation entre entreprises souhaitant innover et consultants spécialisés dans les domaines de l'IT et du Digital.",
      keywords : "Freelance , Informatique, projets, développement web, Application web, Marketing digital, community manager."
    
    },

    concept : {
      title : "FASEYA.COM trouver la meilleure freelance pour votre projet IT",
      description: "Vous êtes à la recherche d’un Freelance , en gestion de projet IT, développement web ou mobile, et Marketing Digital, faseya.com est la solution pour vos projets numériques.",
      keywords : "Freelance , Informatique, projets, développement web, Application web, Marketing digital, community manager."

    },

    pricing : {
      title : "Offres et tarifs faseya.com “Freelance” avec un couts abordable",
      description: "faseya;com société de conseil numérique, nous vous aidons à réaliser votre projet IT avec des offres et tarifs abordables.",
      keywords : "Tarifs freelance; Offre freelance, consultant IT, Tarifs développeur, offre freelance."

    },



  }
  
export default seo;