import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdbreact';
import './Partners2Component.css';

import partner_1 from '../../assets/images//partners/partner (1).png';
import partner_2 from '../../assets/images//partners/partner (2).png';
import partner_3 from '../../assets/images//partners/partner (3).png';
import partner_4 from '../../assets/images//partners/partner (4).png';
import partner_5 from '../../assets/images//partners/partner (5).png';
import partner_6 from '../../assets/images//partners/partner (6).png';
import partner_7 from '../../assets/images//partners/partner (7).png';
import partner_8 from '../../assets/images//partners/partner (8).png';
import partner_9 from '../../assets/images//partners/partner (9).png';
import partner_10 from '../../assets/images//partners/partner (10).png';
import partner_11 from '../../assets/images//partners/partner (11).png';
import partner_12 from '../../assets/images//partners/partner (12).png';
import partner_13 from '../../assets/images//partners/partner (13).png';
import partner_14 from '../../assets/images//partners/partner (14).png';
import partner_15 from '../../assets/images//partners/partner (15).jpg';
import partner_16 from '../../assets/images//partners/partner (16).png';
import { withTranslation } from 'react-i18next';

class Partners2Component extends React.Component {
 
  state = {
    collapseID: ''
  };

 
  render() {

    const { collapseID } = this.state;

    const { t } = this.props;

    return (
      <MDBContainer  style={{marginTop:80, paddingBottom:60,marginBottom : 40,}}>
  
        <h4 className="font-light f-subtitle f-subheader text-center " style={{fontWeight:'bolder'}}>{t('faseya.partners')}</h4>

        <MDBRow className="justify-content-center align-items-center">
          
          <MDBCol size="4" xs="4" md="3" className="mb-1">
            <a href="https://francedigitale.org/"><img src={partner_14} className="img-fluid f-customer-img" alt="France Digitale" /></a>
          </MDBCol>

          <MDBCol size="4" xs="4" md="3" className="mb-1">
            <a href="https://www.bpifrance.fr/"><img src={partner_15} className="img-fluid f-customer-img" alt="BPI France" /></a>
          </MDBCol>

          <MDBCol size="4" xs="4" md="3" className="mb-1">
            <a href="https://www.credit-agricole.fr/"><img src={partner_16} className="img-fluid f-customer-img" alt="Crédit Agricole" /></a>
          </MDBCol>


        </MDBRow>
      
      
      </MDBContainer>
    );
  }
}

export default withTranslation()(Partners2Component);
