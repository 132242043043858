import React, {Fragment}  from 'react';
import { FiFacebook, FiLinkedin } from 'react-icons/fi'
import emailjs from '@emailjs/browser';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBFormInline,
  MDBBtn,
  MDBView,
  MDBInput,
  MDBContainer,
  MDBAutocomplete,
  MDBModal, 
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact';
import './BusinessCardPreviewPage.css';
import { MDBSpinner } from 'mdb-react-ui-kit';

import banner_faseya from '../../assets/images/banner_faseya.jpg';
import PartnersComponent from '../../components/PartnersComponent/PartnersComponent';
import ParticularityComponent from '../../components/ParticularityComponent/ParticularityComponent';
import ServicesComponent from '../../components/ServicesComponent/ServicesComponent';
import ExpertsComponent from '../../components/ExpertsComponent/ExpertsComponent';
import HowComponent from '../../components/HowComponent/HowComponent';
import ParcoursComponent from '../../components/ParcoursComponent/ParcoursComponent';
import PacksComponent from '../../components/PacksComponent/PacksComponent';
import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import TestomiesComponent from '../../components/TestimoniesComponent/TestimoniesComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import { withTranslation } from 'react-i18next';
import skillService from '../../services/skill.service';
import auth from '../../services/auth';
import Partners2Component from '../../components/Partners2Component/Partners2Component';

import bc_1 from "../../assets/images/bcs/java-dev.jpg";
import bc_2 from "../../assets/images/bcs/bc_2.jpg";
import bc_3 from "../../assets/images/bcs/bc_3.jpg";
import bc_4 from "../../assets/images/bcs/bc_4.jpg";
import businessCards from '../../services/mocks/businesscards';

class BusinessCardPreviewPage extends React.Component {
  form = null
  state = {
    collapsed: false,
    searchKeyword : '',
    contact: {
      entreprise:'',
      nom:'',
      prenom:'',
      email: '',
      telephone: '',
      message: '',
    },
    techs : [],
    message: '',
    user: {},
    showNotif : false,
    isSubmiting: false,
    // businessCard : this.props.history.location.state.metier,
    businessCard : JSON.parse(localStorage.getItem("fiche-metier")),
    businessCards : [businessCards[0], businessCards[1], businessCards[2],businessCards[3]]
  };

  handleTogglerClick = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });
  };

  componentDidMount() {

    window.scrollTo(0, 0);
    document.querySelector('nav').style.height = '65px';

    this.form = React.createRef()

    // Set metadata 

    var _title = this.state.businessCard.title;
    var _desc = this.state.businessCard.description;
    document.title = _title;
    document.querySelector("meta[name='title']").setAttribute("content", _title)
    document.querySelector("meta[name='description']").setAttribute("content", _desc)



  }

  getSkills = () =>
    {
        skillService.getSkills().then(data=>{
            var techs = [];

            data.forEach(el => {
              techs.push(el.name)
            });
            this.setState({techs : techs})
         })
         .catch(err=>{
      
         })
  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  onHandleClik = (name, fullName, id) => {
    this.props.history.push({pathname: `services/${name}`, state:{full_name: fullName, id:id}});
  }

  breakString = (str, limit) => {

    let brokenString = '';

    for(let i = 0, count = 0; i < str.length; i++){
      if(count >= limit && str[i] === ' '){

              count = 0;
              brokenString += '\n' ;
      }else{
              count++;
              brokenString += str[i];
            }
      }
    //console.log(brokenString)
    return brokenString;
}

  getSearchKeyword = (value) => {
    this.setState({searchKeyword : value})
  }

  gotoConsultants = () => {
    // if(this.state.searchKeyword.trim() == "") return;
    var s = "all";
    if(this.state.searchKeyword.trim() != "") s = this.state.searchKeyword;

    this.props.history.push({pathname: `consultants/${s}`, state:{reload : true}});
  }

  gotoPostProject = () => {
    
    var path =/*  auth.isAuthenticated() ?  */"/poster-un-projet"/* :"/se-connecter" */;

    // if(auth.isAuthenticated())
    // {
      this.props.history.push({pathname: path});
    // }
    // else
    // {
    //   this.setState({showNotif : true});

    //   setTimeout(() => {
    //     this.setState({showNotif : false});
    //     this.props.history.push({pathname: path});
    //   }, 4000);
    // }
  }

  gotoRequestDemo = () => {
    
    // var path = auth.isAuthenticated() ? "/demander-une-demo":"/se-connecter";
    var path = "/demander-une-demo";

    // if(auth.isAuthenticated())
    // {
      this.props.history.push({pathname: path});
    // }
    // else
    // {
    //   this.setState({showNotif : true});

    //   setTimeout(() => {
    //     this.setState({showNotif : false});
    //     this.props.history.push({pathname: path});
    //   }, 4000);
    // }

  }  

  handleSubmit = (e)=>{
    e.preventDefault()

    this.setState({
      ...this.state,
      message: '',
      isSubmiting: true
    })

    if(Object.values(this.state.contact).includes('')){
      this.setState({
        ...this.state,
        message: 'Veuillez remplir tous les champs!!!'
      })

      return;
    }

    emailjs.send('service_kian0gc','template_w7ktgwz',this.state.contact, 'CQ_rLPb6EoSosAH_F')
    .then((result)=>{
      this.setState({
        ...this.state,
        isSubmiting: false,
        message: 'Merci,votre demande a été bien pris en compte,notre équipe vous contactera sous 24 heures'
      })
    })
    .catch((error)=>console.log(error.text))
  }

  handleChange=(e)=>{
    const name = e.target.name
    const value = e.target.value

    const state = {
      ...this.state,
      contact:{
        ...this.state.contact,
        [name]: value
      }
    }

    // console.log({state})

    this.setState(state)
  }

  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: '0rem' };

    const {t} = this.props;

    // const overlay = (
    //   <div
    //     id='sidenav-overlay'
    //     style={{ backgroundColor: 'transparent' }}
    //     onClick={this.handleTogglerClick}
    //   />
    // );
    return (

      <>
      
      <HeaderComponent history={this.props.history} />


      <MDBContainer>

        <div style={{position: 'fixed', top: '50%', left: '0', zIndex: 9}}>
          <div style={{width: '3rem', height: '3rem', backgroundColor: '#3B5998', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>
            <FiFacebook />
          </div>
          <div style={{width: '3rem', height: '3rem', backgroundColor: '#007bb5', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>
            <FiLinkedin />
          </div>
        </div>

        <div style={{padding:10, backgroundColor : 'transparent'}}>
    
            <MDBRow style={{marginTop:10}}>
              <MDBCol >
                
                <h1 className="title f-title" style={{color:'olivedrab'}}>
                  Le métier de {this.state.businessCard.name}
                </h1>
                </MDBCol>
            </MDBRow>

            <MDBRow style={{marginTop:10}}>
                
                <MDBCol xs="12" md="12"  className="mb-3" >
                  <img src={this.state.businessCard.image} className="img-fluid" style={{width:'100%', maxHeight:450, objectFit:'cover', borderRadius:20, borderWidth:4, borderColor:'olivedrab'}} alt="" />
                </MDBCol>            

                <MDBCol size="12" xs="12" md="12"  className="mt-4 mb-4" >
                    <MDBBtn className="f-btn-olive" style={{paddingLeft:15, paddingRight:15, fontSize:20}} onClick={()=>{ this.props.history.push({pathname: `consultants/`+this.state.businessCard.name, state:{reload : true}}) }} >Recruter un {this.state.businessCard.name}</MDBBtn>
                </MDBCol>
                

                <MDBCol size="12" sm="12" md="12"  className="mb-1 d-flex mb-1 f-how-item align-items-center"  >

                  <MDBContainer>
                    <MDBRow>
                     
                     <div dangerouslySetInnerHTML={{__html:this.state.businessCard.content}}></div>
                      
                    </MDBRow>
                  </MDBContainer>
                </MDBCol>            


            </MDBRow>

          </div>



          <MDBRow className="justify-content-center" style={{marginTop:50}}>

                     <div>
                      <h2 className='text-center'>Vous souhaitez recruter un {this.state.businessCard.name}</h2>
                      <p>Communiquez-nous vos coordonn&eacute;es, Faseya prendra contact avec sous 24 heures pour analyser votre besoin.</p>
                    </div>
                   
                        <MDBCol xs="12" md="8">
                            <MDBRow className="justify-content-center flex-column f-subheader-sign">

                                    <div className="p-3">
                                            {/* {error  && (
                                                <MDBRow className="mb-3 text-center">
                                                    <MDBCol>
                                                        <span className="error-text">{error}</span>
                                                    </MDBCol>
                                                </MDBRow>
                                            )} */}
                                            {
                                              this.state.isSubmiting && !this.state.success_message &&
                                              <div style={{display:'flex', justifyContent: 'center', margin:'2rem 0'}}>
                                                <MDBSpinner role='status' size='sm'>
                                                  <span className='visually-hidden'></span>
                                                </MDBSpinner>
                                              </div>
                                            }
                                            {
                                              this.state.message && <p className="text-center">{this.state.message}</p>
                                            }
                                        <form className='w-100' ref={this.form} onSubmit={this.handleSubmit} >
                                            <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <input
                                                        type="text"
                                                        value={this.state.contact.entreprise}
                                                        name='entreprise'
                                                        onChange={this.handleChange}
                                                    
                                                        // id="username-field"
                                                        className={`form-control`}
                                                        placeholder='Nom de votre entreprise'
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        {/* {errors.username?.message}. */}
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow >
                                                <MDBCol md="6" className="mb-3">
                                                    <input
                                                        type="text"
                                                        name='prenom' 
                                                        value={this.state.contact.prenom}
                                                        onChange={this.handleChange}
                                                        className={`form-control no-raduis `}
                                                        placeholder='Prénom'
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        {/* {errors.prenom?.message}. */}
                                                    </div>
                                                </MDBCol>
                                            
                                                <MDBCol md="6" className="mb-3">
                                                    <input
                                                        type="text" 
                                                        name='nom'  
                                                        value={this.state.contact.nom}
                                                        onChange={this.handleChange}
                                                        className={`form-control no-raduis`}
                                                        placeholder='Nom'
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        {/* {errors.nom?.message} */}
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow >
                                                <MDBCol md="6" className="mb-3">
                                                    <input
                                                        type="text"
                                                        name='email' 
                                                        value={this.state.contact.email}
                                                        onChange={this.handleChange}
                                                        className={`form-control no-raduis `}
                                                        placeholder='Email'
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        {/* {errors.prenom?.message}. */}
                                                    </div>
                                                </MDBCol>
                                            
                                                <MDBCol md="6" className="mb-3">
                                                    <input
                                                        type="text" 
                                                        name='telephone'  
                                                        value={this.state.contact.telephone}
                                                        onChange={this.handleChange}
                                                        className={`form-control no-raduis`}
                                                        placeholder='Telephone'
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        {/* {errors.nom?.message} */}
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <textarea
                                                        value={this.state.contact.message}
                                                        name='message'
                                                        onChange={this.handleChange}
                                                        // id="username-field"
                                                        className={`form-control`}
                                                        placeholder='Votre besoin spécifique'
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        {/* {errors.username?.message}. */}
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <div className="py-1">
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md="12">
                                                    <MDBBtn type="submit"  className="sow-submit btn-block btn-consult-color">
                                                        Rappelez-moi
                                                    </MDBBtn>
                                                </MDBCol>
                                            </MDBRow>
                                        </form>
                                    </div>

                            </MDBRow>
                        </MDBCol>
                    </MDBRow>



      </MDBContainer>
        
        


      <MDBContainer  style={{marginTop:100, marginBottom:100}}>
        
        <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}> Fiches métiers liées</h4>

        <MDBRow style={{position:'relative'}}>
          
          {this.state.businessCards.map((b, index) =>
            <MDBCol key={index} xs="12" sm="12" md="3"  className="mb-2"  onClick={()=>{
              if(b.active)
              {
                this.setState({businessCard:b});  localStorage.setItem("fiche-metier", JSON.stringify(b));   window.scrollTo(0, 0)
              } 
              }}  >
              <MDBCol size="12"  className="mb-4 f-bc-item-linked" style={{backgroundImage:'url('+b.image+')'}} >
                <h6 className="f-bc-item-linked-header">{b.name}</h6>
              </MDBCol> 
            </MDBCol>
            ) 
          }

        </MDBRow>
            
      </MDBContainer>

        <PrefooterComponent />


        <MDBModal isOpen={this.state.showNotif} toggle={this.showNotif}  size="md" centered modalStyle='warning'>
          <MDBModalHeader ><span style={{fontWeight:500}}>Notification</span></MDBModalHeader>

              <MDBModalBody>
                  <MDBRow>
                      <MDBCol size="12">
                          <p style={{color:'black', fontWeight:500}}> {t('faseya.must_be_logged')} </p>
                      </MDBCol>
                  </MDBRow>
              </MDBModalBody>  
          </MDBModal>

      </>

    );
  }
}

export default withTranslation()(BusinessCardPreviewPage);