import React, {useState, useEffect, useRef}  from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBBadge, 
    MDBModal, 
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter
} from 'mdbreact';
import SponsorsComponent from '../../components/SponsorsComponent/SponsorsComponent';
import Selectable from "react-dropdown-select";
import Select, { StylesConfig } from 'react-select'
import makeAnimated from 'react-select/animated';
import yearExperiences from '../../services/mocks/yearExperience';
import levels from '../../services/mocks/levels';
import availablties from '../../services/mocks/availablities';
import skillService from '../../services/skill.service';
import consultantsService from '../../services/consultants.service';
import degrees from '../../services/mocks/degrees';
import './SignUpAdvisorPage.scss';
import avataaar_0 from './../../assets/images/avataaars/avataaars.png';
import default_user from './../../assets/images/default_user.png';
import skillsData from '../../services/mocks/skills';
import uploadService from '../../services/upload.service';
import { withTranslation } from 'react-i18next';
import ManySelect  from '../../components/ManySelect/ManySelect';
import olive_loader from '../../assets/images/olive-loader.gif';

function SignUpAdvisorPage(props) {

    
    const[showFormCv, setShowFormCv] = useState(false)
    const[showExperienceModal, setShowExperienceModal] = useState(false)
    const[showDegreeModal, setShowDegreeModal] = useState(false)
    const[showFormationModal, setShowFormationModal] = useState(false)
    const[active, setActive] = useState(false)
    const[activeExperience, setActiveExperience] = useState(false)
    const[activeFormation, setActiveFormation] = useState(false)
    const[formations, setFormations] = useState([])
    const[experiences, setExperiences] = useState([])
    const[diplomas, setDiplomas] = useState([])
    const[degreeName, setDegreeName] = useState('')
    const[degreeId, setDegreeId] = useState()
    const[degreeSchool, setDegreeSchool] = useState('')
    const[degreeStartedAt, setDegreeStartAt] = useState('')
    const[degreeEndAt, setDegreeEndAt] = useState('')
    const[expStartedAt, setExpStartedAt] = useState('')
    const[expEndAt, setExpEndAt] = useState('')
    const[expCompany, setExpCompany] = useState('')
    const[expFunction, setExpFunction] = useState('')
    const[tasks, setTasks] = useState([])
    const[techs, setTechs] = useState([])
    const[task, setTask] = useState('')
    const[tech, setTech] = useState('')
    const[formationName, setFormationName] = useState('')
    const[formationSchool, setFormationSchool] = useState('')
    const[formationStartedAt, setFormationStartedAt] = useState('')
    const[formationEndAt, setFormationEndAt] = useState('')
    const[selectedFile, setSelectedFile] = useState();
    const[myFile, setMyFile] = useState(null);
    const phoneRef = useRef()
    const addressRef = useRef()
    const fileRef = useRef()
    const history = useHistory()
   const[profile,setProfile] = useState('')
   const[titre,setTitre] = useState('')
   const[presentation,setPresentation] = useState('')
   const[niveauExperience,setNiveauExperience] = useState('')
   const[disponibilite,setDisponibilite] = useState('')
   const[nbrExperience,setNbrExperience] = useState('')
   const[skills, setSkills] = useState([]);
   const[choosenSkills, setChoosenSkills] = useState([]);
   const[skillSelected, setSkillSelected] = useState('');
   const[dataName, setDataName] = useState('');
   const[dataSurname, setDataSurname] = useState('');
   const[dataEmail, setDataEmail] = useState('');
   const[dataPassword, setDataPassword] = useState('');
   const[dataService, setDataService] = useState('');
   const[dataUserName, setDataUserName] = useState('');
   const[advisorPhone, setAdvisorPhone] = useState('');
   const[advisorAddress, setAdvisorAddress] = useState('');
   const[error, setError] = useState('');
   const[connecting, setConnecting] = useState(false);
   const[infos, setInfos] = useState({
       skills: [],
       other: ''
   });



   const animatedComponents = makeAnimated();

   const singleItem = (color = 'olivedrab', width = 110) => ({
       alignItems: 'center',
       width:'max-content',
       backgroundColor: color,
       color: 'white',
       borderRadius: 10,
       textIndent:10,
       paddingRight:5,
       marginRight:7
     });
   const customStyles = {
       option: (styles, { data, isDisabled, isFocused, isSelected }) => {
           const color = 'olivedrab';
           return {
             ...styles,
             backgroundColor: 'white',
             color: 'black',
             cursor: isDisabled ? 'not-allowed' : 'default',
             ':active': {
               ...styles[':active'],
               backgroundColor: 'olivedrab',
             },
           };
         },
         multiValue: (styles, { data }) => {
           const color = 'olivedrab';
           return {
             ...styles,
             backgroundColor: color,
             borderRadius: 10,

           };
         },
         multiValueLabel: (styles, { data }) => ({
           ...styles,
           color: 'white',
           borderRadius: 10,

         }),
         multiValueRemove: (styles, { data }) => ({
           ...styles,
           backgroundColor:'#6b8e23bd',
           borderRadius: 10,

           color: 'white',
           ':hover': {
             backgroundColor: '#dc3545',
             color: 'white',
               borderRadius: 10,

           },
         }),
         singleValue: (styles, { data }) => 
         ({ ...styles, ...singleItem(data.color, data.width) }),
       
     
     }

     const customStyles2 = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = 'olivedrab';
            return {
              ...styles,
              backgroundColor: 'white',
              color: 'black',
              cursor: isDisabled ? 'not-allowed' : 'default',
              ':active': {
                ...styles[':active'],
                backgroundColor: 'olivedrab',
              },
            };
          },
          multiValue: (styles, { data }) => {
            const color = 'olivedrab';
            return {
              ...styles,
              backgroundColor: color,
              borderRadius: 10,
 
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: 'white',
            borderRadius: 10,
 
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            backgroundColor:'#6b8e23bd',
            borderRadius: 10,
 
            color: 'white',
            ':hover': {
              backgroundColor: '#dc3545',
              color: 'white',
                borderRadius: 10,
 
            },
          }),
        
      
      }

    useEffect(() => {

        window.scrollTo(0, 0);

        // console.log(props.location)
        setDataName(props.location.state.user.nom);
        setDataSurname(props.location.state.user.prenom);
        setDataEmail(props.location.state.user.email);
        setDataPassword(props.location.state.user.password);
        setDataService(parseInt(props.location.state.user.serviceId));
        setDataUserName(props.location.state.user.username);




        skillService.getSkills().then((res) => {

            var skillsData = res;
            skillsData.forEach(el => {
                el.value = el.name;
                el.label = el.name;
            });
            
            setSkills(skillsData);

            let arraySkill = [];
            res.map((skill, index) => (
                arraySkill.push(skill.name)
            ))
            setInfos({skills: arraySkill});
        })
        //setSkills(skillsData)
    }, [])

  

    const {t} = props;

    const onFileChange = e => {
        // setSelectedFile(URL.createObjectURL(e.target.files[0]));

        var formData = new FormData();
        // Attach file
        formData.append('file', e.target.files[0]); 

        uploadService.uploadImage(formData).then(({ link }) => {
            // console.log(link)
            setSelectedFile(link)
            setMyFile(link);
        }).catch(err=> {

            // console.log(err)
        })
        // getBase64(e.target.files[0]).then(
        //     data =>{ 
        //         console.log(data)
        //         setMyFile(data);
        //     }
        //   );
        // setMyFile(e.target.files[0]);
    }



    const onTriggerFile = () => {
        fileRef.current.click()
    }

    const submitHandler = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";
        if(!phoneRef.current.value  || !addressRef.current.value) return ;
        setShowFormCv(true)
    }

    const submitHandlerDegree = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";
        if(!degreeName || !degreeSchool || !degreeStartedAt || !degreeId ) return ;

        const yearStart = degreeStartedAt.split('-')[0];
        const month_day_start = degreeStartedAt.split('-')[1];
        const monthStart = month_day_start.split('-')[0];
        if(!active)
        {
            const yearEnd = degreeEndAt.split('-')[0];
            const month_day_end = degreeEndAt.split('-')[1];
            const monthEnd = month_day_end.split('-')[0];

            const degree = {
                libelle: degreeId,
                description: degreeName,
                etablissement: degreeSchool,
                mois_debut: monthStart,
                annee_debut: yearStart,
                mois_obtention: monthEnd,
                annee_obtention: yearEnd,
            };
            diplomas.push(degree);
        }else {
            const degree = {
                libelle: degreeId,
                description: degreeName,
                etablissement: degreeSchool,
                mois_debut: monthStart,
                annee_debut: yearStart,
                mois_obtention: "en cours",
                annee_obtention: "en cours",
            };
            diplomas.push(degree);
        }

        setDegreeSchool('');
        setDegreeName('');
        setDegreeStartAt('')
        setDegreeEndAt('')
        setDegreeId('');
        setActive(false)

        setShowDegreeModal(false);
        
    }

    function onChangeAvailable(values)  {
        setDisponibilite(values.name);
    }

    function onChangeExperience (values) {
      setNiveauExperience(values.name);
    }

    function onChangeYearExperience(values) {
        setNbrExperience(values.name);
    }

    function onChangeSkill(values) {
    
        setChoosenSkills(values)
        let skill = ''
        values.map((item, index) => (
            skill += item.name+','
        ))
       setSkillSelected(skill);
    }

    const showModal = () => {
        setShowDegreeModal(!showDegreeModal)
    }

    const showModalExperiene = () => {
        setShowExperienceModal(!showExperienceModal)
    }

    const showModalFormation = () => {
        setShowFormationModal(!showFormationModal)
    }

    const onAdvisorPhone = (value) =>{

        var val = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
        setAdvisorPhone(val)
    }

    const onCheckChange = () => {
        setActive(!active)
    }

    const onCheckChangeExperience = () => {
        setActiveExperience(!activeExperience)
    }

    const onCheckChangeFormation = () => {
        setActiveFormation(!activeFormation)
    }

    const onChangeDegree = (value) => {
        setDegreeId(value[0].name)
    }

    const submitHandlerExperience = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";
        if(!expCompany || !expFunction || !expStartedAt || !task || !tech)
        return ;
    
        const yearStart = expStartedAt.split('-')[0];
        const month_day_start = expStartedAt.split('-')[1];
        const monthStart = month_day_start.split('-')[0];
        let taskContent = '';
        let techContent = '';

        tasks.push({name: task});
        techs.push({name: tech});

        tasks.map((task, index) => {
           taskContent += task.name+',';
        })

        techs.map((tech, index) => {
            techContent += tech.name+',';
         })

        if(!activeExperience) {

            const yearEnd = expEndAt.split('-')[0];
            const month_day_end = expEndAt.split('-')[1];
            const monthEnd = month_day_end.split('-')[0];

            const experience = {
                societe: expCompany,
                intitule_poste: expFunction,
                mois_debut: monthStart,
                annee_debut: yearStart,
                mois_fin: monthEnd,
                annee_fin: yearEnd,
                realisation: taskContent,
                environement_technique : techContent
            };
            

            experiences.push(experience);

        }else 
        {
            const experience = {
                societe: expCompany,
                intitule_poste: expFunction,
                mois_debut: monthStart,
                annee_debut: yearStart,
                mois_fin: 'en cours',
                annee_fin: 'en cours',
                realisation: taskContent,
                environement_technique : techContent
            };

            experiences.push(experience);
        }

        setExpCompany('');
        setTask('');
        setTech('');
        setExpEndAt('');
        setExpFunction('');
        setExpEndAt('');
        setActiveExperience(false);
        setTechs([]);
        setTasks([]);

        setShowExperienceModal(false);
    }

    const addTask = () => {
        const task = {name: ''}
        setTasks(tasks => [...tasks, task])
    }

    const addTech = () => {
        const tech = {name: ''}
        setTechs(techs => [...techs, tech])
    }

    function handleChangeTasks(index, e) {
        const updatedTask = [...tasks]
        updatedTask[index] = {...updatedTask[index], name: e.target.value}
        setTasks(updatedTask) 
    }

    function handleChangeTech(index, e) {
        const updatedTech = [...techs]
        updatedTech[index] = {...updatedTech[index], name: e.target.value}
        setTechs(updatedTech) 
    }


    const onChangeNameDegree = (e) => {
        setDegreeName(e.target.value)
    }

    const onChangeSchoolDegree = (e) => {
        setDegreeSchool(e.target.value)
    }

    const onChangeStartAtDegree = (e) => {
        setDegreeStartAt(e.target.value)
    }

    const onChangeEndAtDegree = (e) => {
        setDegreeEndAt(e.target.value)
    }

    const onChangeExpEndAt = (e) => {
        setExpEndAt(e.target.value)
    }

    const onChangeExpStartedAt = (e) => {
        setExpStartedAt(e.target.value)
    }

    const onChangeExpCompany = (e) => {
        setExpCompany(e.target.value)
    }

    const onChangeExpFunction = (e) => {
        setExpFunction(e.target.value)
    }
 
    // default task
    const onChangeDegreeTask = (e) => {
        setTask(e.target.value)
    } 

    // default tech
    const onChangeDegreeTech = (e) => {
        setTech(e.target.value)
    }

    const onChangeEndAtFormation = (e) => {
        setFormationEndAt(e.target.value)
    }

    const onChangeStartedAtFormation = (e) => {
        setFormationStartedAt(e.target.value)
    }

    const onChangeNameFormation = (e) => {
        setFormationName(e.target.value)
    }

    const onChangeSchoolFormation = (e) => {
        setFormationSchool(e.target.value)
    }
    
    const submitHandlerFormation = (e) => {
        e.preventDefault();


        const formation = {
            name: formationName,
            school_name: formationSchool,
            startAt: formationStartedAt,
            endAt: activeFormation ? 'en cours' : formationEndAt
        };

        formations.push(formation);

        setFormationName('');
        setFormationEndAt('');
        setFormationSchool('');
        setFormationStartedAt('');
        setActiveFormation(false);
        
        setShowFormationModal(false);
        

    }

    const submitHandlerRegistration = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";

        if(experiences.length == 0 || diplomas.length == 0) return ;
        
        setConnecting(true);
        var phone = advisorPhone.replace(/\s/g,'');
        if(phone[0] == "+"){
            phone = phone.replace("+", "00")
        }
        var username = dataUserName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        username = username.replace(/[^\w\s\][^,]/gi, '');

        let consultant = {

            username: username,
            prenom: dataSurname,
            nom: dataName,
            email: dataEmail,
            telephone: phone,
            adresse: advisorAddress,
            photo: myFile,
            disponibilite: disponibilite,
            // presentation : presentation,
            // nbr_annee_experience: nbrExperience,
            nbr_annee_experience: 3,
            competence_technique: skillSelected,
            titre: niveauExperience,
            serviceId: dataService,
            position:5,
            profil: profile,
            diplomes: diplomas,
            experiences: experiences,
            password: dataPassword,
            // roles: ["Consultant"]

        };

        console.log(consultant)

        consultantsService.addConsultant(consultant).then((resp) => {
            setConnecting(false);
            history.push({
                pathname: "/validation-compte", state:{ email: dataEmail }
            });
        })
        .catch((err) => {
            console.log(err);
            setConnecting(false);
            if(err.code == 404)
            setError(err.message);
            else setError(err.report);
            // console.log(error)
            /* if (error.response) {
                setError(error.response.data.message);
            }else if(error.request) {
                setError(t('faseya.Register.consultants.forms.messages.item_1'));
            }else {
                setError(t('faseya.Register.consultants.forms.messages.item_1'));
            } */
        });
        
    }


    
    return (
        <>
          <MDBRow style={{ background: 'white'}} className="py-5">

            <MDBCol xs="12" md="9">
               <MDBRow className="justify-content-center flex-column ml-md-5">
                  
                    <div className="py-1 ml-xs-2 ml-0">
                        <Link to="/">
                          <span className="text-back-to">{t('faseya.back')}</span>
                        </Link>
                    </div>

                    <div className="d-flex justify-content-center pt-5">
                        {! showFormCv ?
                            <form className='needs-validation form-responsive' onSubmit={submitHandler} noValidate>
                            
                                <MDBRow className="form-group row"> 
                                
                                    <label htmlFor="inputPhone" className="col-sm-3 col-lg-2 col-form-label">
                                        {t('faseya.Register.consultants.second_part.forms.item_1')}
                                    </label>
                                
                                    <MDBCol sm="8" lg="9">
                                        <input 
                                            type="text" 
                                            className="form-control input-phone-address" 
                                            id='validationPhone'
                                            required
                                            value={advisorPhone}
                                            validation='Looks good!'
                                            ref={phoneRef}
                                            onChange={(e) => onAdvisorPhone(e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="form-group row mb-5"> 
                                
                                    <label htmlFor="inputAddress" className="col-sm-3 col-lg-2 col-form-label">
                                        {t('faseya.Register.consultants.second_part.forms.item_2')}
                                    </label>
                                    
                                    <MDBCol sm="8" lg="9">
                                        <input 
                                            type="text" 
                                            className="form-control input-phone-address" 
                                            id='inputAddress'
                                            required
                                            validation='Looks good!'
                                            ref={addressRef}
                                            onChange={(e) => setAdvisorAddress(e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="form-group my-2">
                                    
                                    <label htmlFor="inputAddress" className="col-sm-3 col-lg-2 col-form-label">
                                    
                                    </label>
                                    <MDBCol sm="8" lg="9">
                                        <MDBBtn type="submit"  className="btn-block btn-color">
                                            {t('faseya.next')}
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                        </form>:
                          


                        <form className="justify-content-center form-global" onSubmit={submitHandlerRegistration}>
                           
                           <MDBRow  className="form-group row mb-3">
                               
                                <MDBCol md="3" className="order-1 order-md-0">
                                    
                                       <img 
                                        src={selectedFile ? "https://"+selectedFile : avataaar_0} 
                                        className="profile_image pointer"
                                        onClick={onTriggerFile} 
                                         
                                       />
                                  
                                </MDBCol>

                                <MDBCol md="9" className="order-0 order-md-1">
                                    <MDBRow  className="d-flex flex-column">
                                        <div className="py-1 text-img">
                                            {t('faseya.Register.consultants.second_part.forms.item_3')}
                                         </div>
                                        <div className="d-flex wrap py-3 pr-5 text-img">
                                            {t('faseya.Register.consultants.second_part.forms.item_4')}
                                        </div>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>

                            { error  && (
                                    <MDBRow className="mb-3 text-center">
                                        <MDBCol>
                                            <span className="error-text">{error}</span>
                                        </MDBCol>
                                    </MDBRow>
                            )}

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label  htmlFor="validationTitle"  className="col-sm-2 col-form-label label-input">
                                    {t('faseya.Register.consultants.second_part.forms.item_5')}
                                </label>
                                
                                <MDBCol sm="9">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="validationTitle" 
                                        placeholder={t('faseya.Register.consultants.forms.messages.title_placeholder')}
                                        required
                                        validation='Looks good!'
                                        onChange={(e) => setProfile(e.target.value)}
                                    />
                                      <div className="invalid-feedback">
                                        {t('faseya.Register.consultants.forms.messages.item_2')}
                                    </div>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3">

                                <label htmlFor="validationAcrroche" className="col-sm-2 col-form-label label-input ">
                                    Bio *
                                </label>

                                <MDBCol sm="9">
                                    <textarea
                                        type="text"
                                        name='accroche'
                                        id='validationAcrroche'
                                        required
                                        className="form-control"
                                        placeholder={t('faseya.Register.consultants.forms.messages.bio_placeholder')}
                                        validation='Looks good!'
                                        style={{resize: 'none'}}
                                        rows="5"
                                        onChange={(e) => setPresentation(e.target.value)}
                                    /> 
                                    <div className="invalid-feedback">
                                        {t('faseya.Register.consultants.forms.messages.item_2')}
                                    </div>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label htmlFor="inputLevel" className="col-sm-2 col-form-label label-input ">
                                    {t('faseya.Register.consultants.second_part.forms.item_6')}
                                </label>
                                
                                <MDBCol sm="9">
                                    {/* <Selectable 
                                        labelField="name"
                                        valueField="id"
                                        dropdownHandleRenderer={() => {}}
                                        options={levels} 
                                        keepSelectedInList="true"
                                        addPlaceholder="Select..."
                                        className="custom-select"
                                        style={{backgroundColor: 'red !important'}}
                                        onChange={(values) => onChangeExperience(values)} 
                                    /> */}
                                    
                                    <Select
                                        styles={customStyles}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        // isMulti
                                        isSearchable
                                        options={levels}
                                        onChange={(values) => onChangeExperience(values)} 

                                        />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label htmlFor="inputAvailable" className="col-sm-2 col-form-label label-input ">
                                    {t('faseya.Register.consultants.second_part.forms.item_7')}
                                </label>
                                
                                <MDBCol sm="9">
                                    {/* <Selectable 
                                        labelField="name"
                                        valueField="id"
                                        dropdownHandleRenderer={() => {}}
                                        options={availablties} 
                                        addPlaceholder="Select..."
                                        color="olivedrab"
                                        className="custom-select"
                                        onChange={(values) => onChangeAvailable(values)} 
                                    /> */}
                                    
                                    <Select
                                        styles={customStyles}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        // isMulti
                                        isSearchable
                                        options={availablties}
                                        onChange={(values) => onChangeAvailable(values)}  

                                        />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label htmlFor="inputYear" className="col-sm-2 col-form-label label-input ">
                                    {t('faseya.Register.consultants.second_part.forms.item_8')}
                                </label>
                                
                                <MDBCol sm="9">
                                {/* <Selectable 
                                        labelField="name"
                                        valueField="id"
                                        dropdownHandleRenderer={() => {}}
                                        options={yearExperiences} 
                                        className="testName"
                                        addPlaceholder="Select..."
                                        color="olivedrab"
                                        className="custom-select"
                                        onChange={(values) => onChangeYearExperience(values)} 
                                    /> */}
                                    
                                    <Select
                                        styles={customStyles}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        // isMulti 
                                        isSearchable
                                        options={yearExperiences}
                                        onChange={(values) => onChangeYearExperience(values)} 
  

                                        />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label htmlFor="inputSkill" className="col-sm-2 col-form-label label-input ">
                                    {t('faseya.Register.consultants.second_part.forms.item_9')}
                                </label>
                                
                                <MDBCol sm="9">
                               {/* <Selectable 
                                        labelField="name"
                                        valueField="id"
                                        dropdownHandleRenderer={() => {}}
                                        options={skills} 
                                        searchable="true"
                                        multi="true"
                                        searchBy="name"
                                        keepSelectedInList="true"
                                        color="olivedrab"
                                        className="custom-select"
                                        placeholder="Select..."
                                        addPlaceholder="add"
                                        closeOnSelect="true"
                                        onChange={(values) => onChangeSkill(values)} 
                                /> */}
                                
                                <Select
                                        styles={customStyles2}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        isMulti
                                        value={choosenSkills}
                                        isSearchable
                                        options={skills}
                                        onChange={(values) => onChangeSkill(values)}  
  

                                        />

                                 

                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label className="col-sm-2 col-form-label label-input ">
                                    {t('faseya.Register.consultants.second_part.forms.item_10')}
                                </label>
                                
                                <MDBCol sm="9">
                                   <div className="d-flex align-items-center"> 
                                        {diplomas.length == 0 ?
                                            <MDBBadge pill className="py-3 px-3" color="danger">
                                                { diplomas.length }
                                            </MDBBadge>:
                                            <MDBBadge pill className="py-3 px-3 custom-pill">
                                                { diplomas.length }
                                            </MDBBadge>

                                        }
                                        <div className="text-add-degree p-3 ml-4 pointer" onClick={showModal}>
                                            {t('faseya.Register.consultants.second_part.add')}
                                        </div>
                                   </div>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label className="col-sm-2 col-form-label label-input ">
                                   {t('faseya.Register.consultants.second_part.forms.item_11')}
                                </label>
                                
                                <MDBCol sm="9">
                                   <div className="d-flex align-items-center"> 
                                        {experiences.length == 0 ?
                                            <MDBBadge pill className="py-3 px-3" color="danger">
                                                { experiences.length }
                                            </MDBBadge>:
                                            <MDBBadge pill className="py-3 px-3 custom-pill">
                                                { experiences.length }
                                            </MDBBadge>
                                        }
                                        <div className="text-add-degree p-3 ml-4 pointer" onClick={showModalExperiene}>
                                            {t('faseya.Register.consultants.second_part.add')}
                                        </div>
                                   </div>
                                </MDBCol>
                            </MDBRow>

                            {/* <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label className="col-sm-2 col-form-label label-input ">
                                   Certifications / Formations (option)
                                </label>
                                
                                <MDBCol sm="9">
                                   <div className="d-flex align-items-center"> 
                                       
                                        <MDBBadge pill className="py-3 px-3 custom-pill">
                                            { formations.length }
                                        </MDBBadge>
                                        
                                        <div className="text-add-degree p-3 ml-4 pointer" onClick={showModalFormation}>
                                            {t('faseya.Register.consultants.second_part.add')}
                                        </div>
                                   </div>
                                </MDBCol>
                            </MDBRow> */}

                            <MDBRow className="form-group row mt-5 mb-3"> 
                                
                                <label className="warning-important-text col-sm-2 col-form-label">
                                    Important <span className="">*</span> 
                                </label>
                                
                                <MDBCol sm="9">
                                    <div className="text-experience">
                                        {t('faseya.Register.consultants.second_part.forms.item_13')}
                                    </div>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="form-group mb-2 mt-5">   
                                <label htmlFor="inputAddress" className="col-sm-2 col-form-label">
                                
                                </label>
                                <MDBCol md="9">
                                    {connecting &&
                                        <img src={olive_loader} style={{position:'absolute', zIndex:1, left:'47.5%', width:40}} alt="" />
                                    }
                                    {!connecting && 
                                        <MDBBtn type="submit"  className="btn-block btn-color py-3">
                                            {t('faseya.Register.consultants.second_part.complete')}
                                        </MDBBtn>
                                    }
                                </MDBCol>
                            </MDBRow>


                        </form>
                        }

                    </div>
                  
               </MDBRow>
            </MDBCol>

            <MDBCol 
                md="3" 
                className='hide-mobile mt-3'
                style={{ background: '#f3f4f6'}} 
            >

                    <MDBRow className='d-flex justify-content-center align-items-center'>
                       <SponsorsComponent />
                    </MDBRow>
            </MDBCol>

          </MDBRow>

          <MDBModal isOpen={showDegreeModal} toggle={showModal} size="lg">
                <MDBModalHeader toggle={showModal}>{t('faseya.Register.consultants.second_part.diplomes.title')}</MDBModalHeader>

                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol size="12">
                                <form className='needs-validation form-responsive mt-2 mt-lg-5' onSubmit={submitHandlerDegree} noValidate>
                                    <label htmlFor="degreeType" className="label-text">
                                        {t('faseya.Register.consultants.second_part.diplomes.forms.item_1')}
                                    </label>
                                    <Selectable 
                                        labelField="name"
                                        valueField="name"
                                        options={degrees}
                                        placeholder={t('faseya.Register.consultants.second_part.diplomes.choose')}
                                        style={{color: 'black', fontWeight: '300'}}
                                        onChange={(values) => onChangeDegree(values)}
                                        required 
                                    />
                                    <br />

                                    <label htmlFor="degreeName" className="label-text">
                                        {t('faseya.Register.consultants.second_part.diplomes.forms.item_2')}
                                    </label>
                                    <input 
                                        type="text" 
                                        id="degreeName"
                                        className="form-control grey-background" 
                                        required 
                                        value={degreeName}
                                        onChange={onChangeNameDegree}
                                    />
                                    <br />

                                    <label htmlFor="degreeSchool" className="label-text">
                                        {t('faseya.Register.consultants.second_part.diplomes.forms.item_3')}
                                    </label>
                                    <input 
                                        type="text" 
                                        id="degreeSchool"
                                        className="form-control grey-background"
                                        required 
                                        value={degreeSchool}
                                        onChange={onChangeSchoolDegree}
                                    />
                                    <br />

                                    <label htmlFor="degreeStart" className="label-text">
                                        {t('faseya.Register.consultants.second_part.diplomes.forms.item_4')}
                                    </label>
                                    <input 
                                        type="date" 
                                        id="degreeStart" 
                                        className="form-control" 
                                        value={degreeStartedAt}
                                        onChange={onChangeStartAtDegree}
                                        required
                                    />
                                    <br />

                                    <label htmlFor="degreeEnd" className="label-text">
                                        {t('faseya.Register.consultants.second_part.diplomes.forms.item_5')} &nbsp;&nbsp;&nbsp; 
                                    </label>

                                    <label htmlFor="check2" className="label-text">
                                        <input type="checkbox" className="date_checkbox" checked={active} onChange={onCheckChange} id="check2" /> 
                                        <span className="current-year"> &nbsp;&nbsp; {t('faseya.Register.consultants.second_part.diplomes.forms.item_6')}</span>
                                    </label>

                                    <input 
                                        id="degreeEnd"
                                        type="date" 
                                        className="form-control"
                                        disabled={active} 
                                        value={degreeEndAt}
                                        onChange={onChangeEndAtDegree}
                                    />
                                    <br />

                                    <MDBBtn type="submit" className="btn-block btn-color">
                                        {t('faseya.Register.consultants.second_part.diplomes.forms.item_7')}
                                    </MDBBtn>
                                </form>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>  
          </MDBModal>

          <MDBModal isOpen={showExperienceModal} toggle={showModalExperiene} size="lg">
               <MDBModalHeader toggle={showModalExperiene}>Experience</MDBModalHeader>

                <MDBModalBody>
                    <MDBRow>
                        <MDBCol size="12">

                            <form className='needs-validation form-responsive mt-2 mt-lg-5' onSubmit={submitHandlerExperience} noValidate>
                                 
                                <label htmlFor="experienceName" className="label-text">
                                    {t('faseya.Register.consultants.second_part.experiences.item_1')}
                                </label>
                                <input 
                                    type="text"
                                    id="experienceName"
                                    className="form-control grey-background" 
                                    value={expCompany}
                                    onChange={onChangeExpCompany} 
                                    required
                                />
                                <br />

                                <label htmlFor="experiencePoste" className="label-text">
                                    {t('faseya.Register.consultants.second_part.experiences.item_2')}
                                </label>
                                <input 
                                    type="text" 
                                    id="experiencePoste"
                                    className="form-control grey-background" 
                                    required 
                                    value={expFunction}
                                    onChange={onChangeExpFunction} 
                                />
                                <br />

                                <label htmlFor="experienceStart" className="label-text">
                                    {t('faseya.Register.consultants.second_part.experiences.item_3')}
                                </label>
                                <input 
                                    type="date" 
                                    id="experienceStart" 
                                    className="form-control" 
                                    value={expStartedAt}
                                    onChange={onChangeExpStartedAt} 
                                    />
                                <br />

                                <label htmlFor="experienceEnd" className="label-text">
                                    {t('faseya.Register.consultants.second_part.experiences.item_4')} &nbsp;&nbsp;&nbsp;
                                </label>

                                <label htmlFor="check3" className="label-text">
                                    <input type="checkbox" className="date_checkbox"  checked={activeExperience} onChange={onCheckChangeExperience} id="check3"/> 
                                    <span className="current-year">&nbsp;&nbsp; {t('faseya.Register.consultants.second_part.experiences.item_5')}</span>
                                </label>
                                
                               
                                <input 
                                    type="date"  
                                    id="experienceEnd"
                                    className="form-control"
                                    disabled={activeExperience} 
                                    value={expEndAt}
                                    onChange={onChangeExpEndAt}
                                    />
                                <br />

                                <label htmlFor="degreeTasks" className="label-text">
                                    {t('faseya.Register.consultants.second_part.experiences.item_6')}
                                </label>
                                <input 
                                    type="text" 
                                    id="degreeTasks"
                                    className="form-control grey-background" 
                                    required
                                    value={task}
                                    onChange={onChangeDegreeTask}  
                                />

                                { tasks.length != 0 && 
                                tasks.map((task, index) => (
                                    <input 
                                        type="text" 
                                        name="name"
                                        key={index}
                                        className="form-control grey-background mt-3" 
                                        value={task.name}
                                        onChange={() => handleChangeTasks(index,event)}
                                    /> 
                                ))}

                                <div className="d-flex justify-content-center my-3">
                                   <MDBBadge 
                                    pill className="py-2 px-2 pointer" 
                                    style={{fontSize: '16px'}}
                                    onClick={addTask}
                                   >
                                       +
                                    </MDBBadge>
                                </div>
                                <br />
                                
                                <label htmlFor="degreeTech" className="label-text">
                                    {t('faseya.Register.consultants.second_part.experiences.item_7')}
                                </label>
                                <input 
                                    type="text" 
                                    id="degreeTech"
                                    className="form-control grey-background" 
                                    required
                                    value={tech}
                                    onChange={onChangeDegreeTech}  
                                />

                                { techs.length != 0 &&
                                 techs.map((tech, index) => (
                                    <input 
                                        type="text" 
                                        name="name"
                                        key={index}
                                        className="form-control grey-background mt-3" 
                                        value={tech.name}
                                        onChange={() => handleChangeTech(index,event)}
                                    /> 
                                ))}

                                <div className="d-flex justify-content-center my-3">
                                   <MDBBadge 
                                    pill className="py-2 px-2 pointer" 
                                    style={{fontSize: '16px'}}
                                    onClick={addTech}
                                   >
                                       +
                                    </MDBBadge>
                                </div>

                                <MDBBtn type="submit" className="btn-block btn-color">
                                    {t('faseya.Register.consultants.second_part.experiences.item_8')}
                                </MDBBtn>
                                

                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>

          </MDBModal>


          <MDBModal isOpen={showFormationModal} toggle={showModalFormation} size="lg">
                <MDBModalHeader toggle={showModalFormation}>Formation / Certification</MDBModalHeader>

                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol size="12">
                                <form className='needs-validation form-responsive mt-5' onSubmit={submitHandlerFormation} noValidate>
                                    

                                    <label htmlFor="forName" className="label-text">
                                        {t('faseya.Register.consultants.second_part.certifications.forms.item_2')}
                                    </label>
                                    <input 
                                        type="text" 
                                        id="forName"
                                        className="form-control grey-background" 
                                        required 
                                        value={formationName}
                                        onChange={onChangeNameFormation}
                                    />
                                    <br />

                                    <label htmlFor="forSchool" className="label-text">
                                     {t('faseya.Register.consultants.second_part.certifications.forms.item_3')}
                                    </label>
                                    <input 
                                        type="text" 
                                        id="forSchool"
                                        className="form-control grey-background"
                                        required 
                                        value={formationSchool}
                                        onChange={onChangeSchoolFormation}
                                    />
                                    <br />

                                    <label htmlFor="forStart" className="label-text">
                                        {t('faseya.Register.consultants.second_part.certifications.forms.item_4')}
                                    </label>
                                    <input 
                                        type="date" 
                                        id="forStart" 
                                        className="form-control" 
                                        value={formationStartedAt}
                                        onChange={onChangeStartedAtFormation}
                                    />
                                    <br />

                                    <label htmlFor="forEnd" className="label-text">
                                        {t('faseya.Register.consultants.second_part.certifications.forms.item_5')} &nbsp;&nbsp;&nbsp; 
                                    </label>

                                    <label htmlFor="check1" className="label-text">
                                        <input type="checkbox" className="date_checkbox" checked={activeFormation} onChange={onCheckChangeFormation} id="check1"/> 
                                        <span className=""> &nbsp;&nbsp; {t('faseya.Register.consultants.second_part.certifications.forms.item_6')}</span>
                                    </label>
                                   
                                    <input 
                                        id="forEnd"
                                        type="date"  
                                        className="form-control"
                                        disabled={activeFormation} 
                                        value={formationEndAt}
                                        onChange={onChangeEndAtFormation}
                                    />
                                    <br />

                                    <MDBBtn type="submit" className="btn-block btn-color">
                                    {t('faseya.Register.consultants.second_part.certifications.forms.item_7')}
                                    </MDBBtn>
                                </form>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>  
            </MDBModal>
            
            <MDBRow style={{display: 'none'}}>
                  <input 
                    type="file"
                    ref={fileRef} 
                    name="file" 
                    onChange={onFileChange} 
                   />
            </MDBRow>

        </>
    )
}

export default withTranslation()(SignUpAdvisorPage);