import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,MDBCardBody, MDBCardTitle, MDBCardImage, MDBCardText
} from 'mdbreact';
import './ParcoursComponent.css';
 
import quality_icon from '../../assets/images/quality_icon.png';
import world_bg from '../../assets/images/world_bg.png';
import demo_1 from '../../assets/images/demo_1.png';
import demo_2 from '../../assets/images/demo_2.png';
import demo_3 from '../../assets/images/demo_3.png';
import { withTranslation } from 'react-i18next';

class ParcoursComponent extends React.Component {
 
  state = {
    collapseID: ''
  };

 
  render() {

    const {t} = this.props;

    return (

      <div style={{padding:10, backgroundColor : 'transparent', marginTop:50, backgroundImage:"url("+world_bg+")", width:'100%'}}>
      <MDBContainer>
  
        <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}>{t('faseya.our_path.title')}</h4>

        <MDBRow>

            
            <MDBCol size="12" sm="12" md="12"  className="mb-4" style={{}} >
              <MDBRow>

                <MDBCol size="12" xs="12" md="4"  className="mb-3 f-parcours-item" >
                  <MDBCard style={{ width: "100%", height:'100%' }}>
                    <MDBCardImage className="img-fluid" src={demo_1} style={{width:"100%"}} waves />
                    <MDBCardBody style={{backgroundColor:'olivedrab', paddingBottom:'auto'}}>
                      <MDBCardTitle style={{color:'#fff', fontWeight:500}}>{t('faseya.our_path.item_1')}</MDBCardTitle>
                      <MDBCardText style={{color:'#fff', fontSize:15}}>
                      {t('faseya.our_path.item_2')}
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>



                <MDBCol size="12" xs="12" md="4"  className="mb-3 f-parcours-item" >
                  <MDBCard style={{ width: "100%", height:'100%' }}>
                    <MDBCardImage className="img-fluid" src={demo_2} style={{width:"100%"}} waves />
                    <MDBCardBody style={{backgroundColor:'olivedrab', paddingBottom:'auto'}}>
                      <MDBCardTitle style={{color:'#fff', fontWeight:500}}>{t('faseya.our_path.item_3')}</MDBCardTitle>
                      <MDBCardText style={{color:'#fff', fontSize:15}}>{t('faseya.our_path.item_4')}
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>



                
                <MDBCol size="12" xs="12" md="4"  className="mb-3 f-parcours-item" >
                  <MDBCard style={{ width: "100%", height:'100%' }}>
                    <MDBCardImage className="img-fluid" src={demo_3} style={{width:"100%"}} waves />
                    <MDBCardBody style={{backgroundColor:'olivedrab', paddingBottom:'auto'}}>
                      <MDBCardTitle style={{color:'#fff', fontWeight:500}}>{t('faseya.our_path.item_5')}</MDBCardTitle>
                      <MDBCardText style={{color:'#fff', fontSize:15}}>{t('faseya.our_path.item_6')}
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
 


              </MDBRow>
            </MDBCol>            
 

        </MDBRow>
      
      
      </MDBContainer>

      </div>
    );
  }
}

export default withTranslation()(ParcoursComponent);
