import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  div,
  MDBBtn
} from 'mdbreact';


import partner_1 from '../../assets/images//partners/partner (1).png';
import partner_2 from '../../assets/images//partners/partner (2).png';
import partner_3 from '../../assets/images//partners/partner (3).png';
import partner_4 from '../../assets/images//partners/partner (4).png';
import partner_5 from '../../assets/images//partners/partner (5).png';
import partner_6 from '../../assets/images//partners/partner (6).png';
import partner_7 from '../../assets/images//partners/partner (7).png';
import partner_8 from '../../assets/images//partners/partner (8).png';
import partner_9 from '../../assets/images//partners/partner (9).png';
import partner_10 from '../../assets/images//partners/partner (10).png';
import partner_11 from '../../assets/images//partners/partner (11).png';
import partner_12 from '../../assets/images//partners/partner (12).png';
import partner_13 from '../../assets/images//partners/partner (13).png';

class PartnerShipComponent extends React.Component {
 
  state = {
    collapseID: ''
  };

 
  render() {

    const { collapseID } = this.state;

    return (
      

            <div className="d-flex justify-content-center align-items-center">

                <div className="d-flex flex-wrap align-items-center justify-content-center w-50 ml-5">

                    <div  className="mr-n5 w-50">
                        <img src={partner_1}  className="f-customer-img-sponsor-plus" alt="" />
                    </div>
                    <div className=" w-50">
                        <img src={partner_2} className="f-customer-img-sponsor-plus"
                        alt="" />
                    </div>
                    <div  className="mr-n5  w-50">
                        <img src={partner_3} className="f-customer-img-sponsor-plus" alt="" />
                    </div>

                    <div className=" w-50">
                        <img src={partner_4} className="f-customer-img-sponsor-plus" alt="" />
                    </div>

                    <div  className="mr-n5  w-50">
                        <img src={partner_5} className="f-customer-img-sponsor-plus" alt="" />
                    </div>

                    <div className=" w-50">
                        <img src={partner_6} className="f-customer-img-sponsor-plus"
                        alt="" />
                    </div>
                    <div className="mr-n5  w-50">
                        <img src={partner_7} className="f-customer-img-sponsor-plus" alt="" />
                    </div>

                    <div className=" w-50">
                        <img src={partner_8} className="f-customer-img-sponsor-plus" alt="" />
                    </div>

                    <div className="mr-n5  w-50">
                        <img src={partner_9} className="f-customer-img-sponsor-plus" alt="" />
                    </div>

                    <div className=" w-50">
                        <img src={partner_10} className="f-customer-img-sponsor-plus" alt="" />
                    </div>

                    <div className="mr-n5  w-50">
                        <img src={partner_11} className="f-customer-img-sponsor-plus" alt="" />
                    </div>

                    <div className=" w-50">
                        <img src={partner_13} className="f-customer-img-sponsor-plus" alt="" />
                    </div>


                </div>

            </div>
      
    
    );
  }
}

export default PartnerShipComponent;
