import React from "react";
import { hydrate, render } from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { Provider } from 'react-redux';
import configureStore from './store/store'

import "./i18n/i18n"

// import registerServiceWorker from "./registerServiceWorker";

let store = configureStore();

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}><I18nextProvider i18n={i18next}><App /></I18nextProvider></Provider>, rootElement);
} else {
  render(<Provider store={store}><I18nextProvider i18n={i18next}><App /></I18nextProvider></Provider>, rootElement);
}

// ReactDOM.render(<Provider store={store}><I18nextProvider i18n={i18next}><App /></I18nextProvider></Provider>, document.getElementById("root"));

// registerServiceWorker();
