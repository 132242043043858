import { api } from "./api.config";

const experiencesService = {
  addExperience,
  getExperiences,
  getExperience,
  updateExperience,
  deleteExperience,
  deleteExperiences,
};

/**
 * resource dynamic data
 *
 * @param {number} id - Refer to a consultant that has to manage
 * @returns
 */
const resource = (id) => "/consultants/" + id + "/experiences";

/**
 * addExperience method
 *
 * @param {number} consultantId
 * @param {{
 *   societe: string;
 *   intitule_poste: string;
 *   mois_debut: string;
 *   annee_debut: string;
 *   mois_fin: string | null;
 *   anne_fin: string | null;
 *   realisation: string;
 *   environement_technique: string;
 * }} payload
 *
 * @returns
 */
function addExperience(consultantId, payload) {
  return api.postData(resource(consultantId), payload);
}

/**
 * getExperiences method
 *
 * @param {number} consultantId
 *
 * @returns
 */
function getExperiences(consultantId) {
  return api.getData(resource(consultantId));
}

/**
 * getExperience method
 *
 * @param {number} id
 * @param {number} consultantId
 *
 * @returns
 */
function getExperience(consultantId, id) {
  return api.getData(resource(consultantId) + "/" + id);
}

/**
 * updateExperience method
 *
 * @param {number} consultantId
 * @param {{
 *   societe?: string;
 *   intitule_poste?: string;
 *   mois_debut?: string;
 *   annee_debut?: string;
 *   mois_fin?: string | null;
 *   anne_fin?: string | null;
 *   realisation?: string;
 *   environement_technique?: string;
 * }} payload
 * @param {number} id
 *
 * @returns
 */
function updateExperience(consultantId, id, payload) {
  return api.putData(resource(consultantId) + "/" + id, payload);
}

/**
 * deleteExperience method
 *
 * @param {number} consultantId
 * @param {number} id
 *
 * @returns
 */
function deleteExperience(consultantId, id) {
  return api.deleteData(resource(consultantId) + "/" + id);
}

/**
 * deleteExperiences method
 *
 * @param {number} consultantId
 *
 * @returns
 */
function deleteExperiences(consultantId) {
  return api.deleteData(resource(consultantId));
}

export default experiencesService;
