import React, {useState, useEffect}  from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBIcon
} from 'mdbreact';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import './UpdatePasswordPage.scss';
import auth from '../../services/auth';
import AuthService from '../../services/auth.service';
import { withTranslation } from 'react-i18next';

function UpdatePasswordPage(props) {

    const[currentPassword, setCurrentPassword] = useState('');
    const[newPassword, setNewPassword] = useState('');
    const[confirmPassword, setConfirmPassword] = useState('');
    const[user, setUser] = useState({});
    const[error, setError] = useState('');

    const changeHandlerCurrentPassword = (e) => {
        setCurrentPassword(e.target.value)
    }
    const changeHandlerNewPassword = (e) => {
        setNewPassword(e.target.value)
    }
    const changeHandlerConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
    }

    const {t} = props;

    useEffect(() => {
        
        window.scrollTo(0, 0);
        
        const userData = localStorage.getItem("user");
        setUser(JSON.parse(userData));
    },[])
    
    const submitHandler = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";

        AuthService.changePassword({
            username: user.username,
            newPassword: newPassword,
            oldPassword: currentPassword
        }).then((res) => {
           auth.logout(() => { 
                props.history.push({pathname:'/se-connecter'});
            }) 
        }).catch((error) => {
            if (error.response) {
                setError(error.response.data.message);
            }else if(error.request) {
                //console.log(error.request);
                setError(t('faseya.Register.consultants.forms.messages.item_1'));
            }else {
                //console.log("Error", error);
                setError(t('faseya.Register.consultants.forms.messages.item_1'));
            }
        });
    
    }

    return (

        <>
            <HeaderComponent  history={props.history} />

            <MDBRow>
                <MDBCol xs="12" md="12">
                    <MDBRow className="d-flex flex-column justify-content-center">
                        <div className="p-1 mt-5 text-center text-fill-fields">
                          {t('faseya.Modify_password.header')}
                        </div>

                        { error  && (
                            <div className="p-1 mt-2 text-center">
                                <span className="error-text">{error}</span>
                            </div>
                        )}
                        

                        <div className="d-flex justify-content-center pt-5">

                            <form className='needs-validation form-update-password' onSubmit={submitHandler} noValidate>

                                <MDBRow >
                                    <MDBCol md="12" className="mb-3">
                                        <input
                                            value={currentPassword}
                                            onChange={changeHandlerCurrentPassword}
                                            id='validationCurrent'
                                            required
                                            className="form-control"
                                            placeholder={t('faseya.Modify_password.current')}

                                            validation='Looks good!'
                                        /> 
                                        <div className="invalid-feedback">
                                            {t('faseya.Register.consultants.forms.messages.item_2')}
                                        </div>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow >
                                    <MDBCol md="12" className="mb-3">
                                        <input
                                            value={newPassword}
                                            onChange={changeHandlerNewPassword}
                                            id='validationNew'
                                            required
                                            className="form-control"
                                            placeholder={t('faseya.Modify_password.new')}

                                            validation='Looks good!'
                                        /> 
                                        <div className="invalid-feedback">
                                            {t('faseya.Register.consultants.forms.messages.item_2')}
                                        </div>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow >
                                    <MDBCol md="12" className="mb-3">
                                        <input
                                            value={confirmPassword}
                                            onChange={changeHandlerConfirmPassword}
                                            id='validationConfirm'
                                            required
                                            className="form-control"
                                            placeholder={t('faseya.Modify_password.repeat')}

                                            validation='Looks good!'
                                        /> 
                                        <div className="invalid-feedback">
                                            {t('faseya.Register.consultants.forms.messages.item_2')}
                                        </div>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol md="12 mt-2 mb-5">
                                        <MDBBtn type="submit"  className="btn-block btn-color">
                                            {t('faseya.Modify_password.validate')}
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </form>

                        </div>
                    </MDBRow>
                </MDBCol>
            </MDBRow>

            <PrefooterComponent />
        </>
    )
}


export default withTranslation()(UpdatePasswordPage);