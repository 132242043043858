import { api } from "./api.config";

const authService = {
  signin,
  signup,
  checkCredentials,
  verifyCaptcha,
  changePassword,
  resetPassword,
  initResetPassword,
  verifyResetPasswordCode,
  activateAccount,
};

const resource = "/auth";

/**
 * signin method
 *
 * @param {{ email: string, password: string }} payload
 * @returns
 */
function signin(payload) {
  return api.postData(resource + "/signin", payload);
}

/**
 * signup method
 *
 * @param {{ username: string, email: string, password: string, roles: string[] }} payload
 * @returns
 */
function signup(payload) {
  return api.postData(resource + "/signup", payload);
}

/**
 * checkCredentials method
 *
 * @param {{ username?: string, email?: string }} payload
 * @returns
 */
function checkCredentials(payload) {
  return api.postData(resource + "/check-credentials", payload);
}

/**
 * verifyCaptcha method
 *
 * @param {{ value: string }} payload
 * @returns
 */
function verifyCaptcha(payload) {
  return api.postData(resource + "/verify-captcha", payload);
}

/**
 * changePassword method
 *
 * @param {{ username: string, newPassword: string, oldPassword: string }} payload
 * @returns
 */
function changePassword(payload) {
  return api.putData(resource + "/change-password", payload);
}

/**
 * resetPassword method
 *
 * @param {{ email: string; code: string; password: string; }} payload
 * @returns
 */
function resetPassword(payload) {
  return api.putData(resource + "/reset-password", payload);
}

/**
 * initResetPassword method
 *
 * @param {{ email: string }} payload
 * @returns
 */
function initResetPassword(payload) {
  return api.putData(resource + "/init-reset-password", payload);
}

/**
 * verifyResetPasswordCode method
 *
 * @param {{ email: string, code: string }} payload
 * @returns
 */
function verifyResetPasswordCode(payload) {
  return api.putData(resource + "/verify-rpc", payload);
}

/**
 * activateAccount method
 *
 * @param {{ email: string, code: string }} payload
 * @returns
 */
 function activateAccount(payload) {
  return api.putData(resource + "/activation", payload);
}

export default authService;
  