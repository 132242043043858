import { api } from "./api.config";

const diplomasService = {
  addDiploma,
  getDiplomas,
  getDiploma,
  updateDiploma,
  deleteDiploma,
  deleteDiplomas,
};

/**
 * resource dynamic data
 *
 * @param {number} id - Refer to a consultant that has to manage
 * @returns
 */
const resource = (id) => "/consultants/" + id + "/diplomes";

/**
 * addDiploma method
 *
 * @param {number} consultantId
 * @param {{
 *   niveauId: number;
 *   libelle: string;
 *   etablissement: string;
 *   mois_debut: string;
 *   annee_debut: string;
 *   mois_obtention: string;
 *   annee_obtention: string;
 *   description: string;
 * }} payload
 *
 * @returns
 */
function addDiploma(consultantId, payload) {
  return api.postData(resource(consultantId), payload);
}

/**
 * getDiplomas method
 *
 * @param {number} consultantId
 *
 * @returns
 */
function getDiplomas(consultantId) {
  return api.getData(resource(consultantId));
}

/**
 * getDiploma method
 *
 * @param {number} id
 * @param {number} consultantId
 *
 * @returns
 */
function getDiploma(consultantId, id) {
  return api.getData(resource(consultantId) + "/" + id);
}

/**
 * updateDiploma method
 *
 * @param {number} consultantId
 * @param {{
 *   niveauId?: number;
 *   libelle?: string;
 *   etablissement?: string;
 *   mois_debut?: string;
 *   annee_debut?: string;
 *   mois_obtention?: string;
 *   annee_obtention?: string;
 *   description?: string;
 * }} payload
 * @param {number} id
 *
 * @returns
 */
function updateDiploma(consultantId, id, payload) {
  return api.putData(resource(consultantId) + "/" + id, payload);
}

/**
 * deleteDiploma method
 *
 * @param {number} consultantId
 * @param {number} id
 *
 * @returns
 */
function deleteDiploma(consultantId, id) {
  return api.deleteData(resource(consultantId) + "/" + id);
}

/**
 * deleteDiplomas method
 *
 * @param {number} consultantId
 *
 * @returns
 */
function deleteDiplomas(consultantId) {
  return api.deleteData(resource(consultantId));
}

export default diplomasService;
