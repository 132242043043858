import React, {Fragment}  from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBCard,MDBCardBody, MDBCardTitle, MDBCardImage, MDBCardText,
  MDBCollapseHeader,
  MDBBreadcrumb, MDBBreadcrumbItem,
  MDBIcon,
  MDBFormInline,
  MDBBadge,
  MDBBtn,
  MDBView,
  MDBInput,
  MDBProgress,
  MDBContainer
} from 'mdbreact';
import './ConsultantCvPage.css';
 
import PrefooterCvComponent from '../../components/PrefooterCvComponent/PrefooterCvComponent';
 
import concept_image from '../../assets/images/concept_image.png';
import concept_image2 from '../../assets/images/concept_image2.png';

import avataaar_0 from '../../assets/images/avataaars/avataaars.png';
import experience_icon from '../../assets/images/experience_icon.png';
import years_icon from '../../assets/images/years_icon.png';
import type_icon from '../../assets/images/type_icon.png';
import experience_icon2 from '../../assets/images/experience_icon2.png';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import levels from '../../services/mocks/levels';
import availablties from '../../services/mocks/availablities';
import { withTranslation } from 'react-i18next';
import diplomasService from '../../services/diplomes.service';
import experiencesService from '../../services/experiences.service';
 
 
class ConsultantCvPage extends React.Component {

  
  state = {
    user : {},
    collapsed: false,
    consultant : this.props.history.location.state.consultant,
    // consultant : JSON.parse(localStorage.getItem("consultant")),
    tech : localStorage.getItem("keyword"), 
    consultants : [],

    collapseID: "",

    avatarBaseUrl : "https://api.faseya-dev.com/api/consultants/files/",

    experiences : [],
    diplomas : []
   

}
 
  toggleCollapse = (collapseID) =>{
  this.setState({collapseID});
}

  handleTogglerClick = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });
  };

  componentDidMount() {

    window.scrollTo(0, 0);

    var user = JSON.parse(localStorage.getItem("user")) || {id : null}
    this.setState({user : user});

    this.setSkillsItems(this.state.consultant)
    // this.setState({tech : tech})

    // Exps
    experiencesService.getExperiences(this.state.consultant.id).then(data=>{

        var newData = data;

        newData.forEach(item => {
          this.setRealisations(item)
        });

        // console.log(newData);

        this.setState({experiences : newData});

    }).catch(err=>{
        // console.log(err)
    })

    // Formations
    diplomasService.getDiplomas(this.state.consultant.id).then(data=>{
  
      var newData = data;

      this.setState({diplomas : newData});
      // console.log(data);

    }).catch(err=>{
      // console.log(err)
    })



    document.querySelector('nav').style.height = '65px';
  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  getExperienceItem = (cv) =>
  {
      var xpIndex = 0;
      var years = cv.nbr_annee_experience;
      
      if(years > 2 && years <= 5 )
      xpIndex = 1;
      if(years > 5)
      xpIndex = 2;

      return levels[xpIndex].value;
  }

  getAvailabilityItem = (cv) =>
  {
      var index = 0;
      var av = cv.disponibilite;
      
      if(av == "temps_partiel")
      index = 1;


      return availablties[index].value;
  }


  setSkillsItems(item)
  {
      var skillsString = item.competence_technique;
      var skillsArray = skillsString.split(',');
      const _skillsArray =  skillsArray.filter(e =>  e);

      var _skills = [];
    
      var max = 3;
      /* if(_skillsArray.length < 3)  */max = _skillsArray.length;
      for (let i = 0; i < max; i++) {
        const el = _skillsArray[i];
          _skills.push(el); 
         
      }

     item.skills = _skills;

  }

  
  setRealisations(item)
  {
      var worksString = item.realisation;
      var worksArray = worksString.split(',');
      const _worksArray =  worksArray.filter(e =>  e);

      var _works = [];
     
      for (let i = 0; i < _worksArray.length; i++) {
        const el = _worksArray[i];
          _works.push(el); 
         
      }

     item.works = _works;

  }

  render() {
    const { collapsed } = this.state;
    const { collapseID } = this.state;
    const navStyle = { marginTop: '0rem' };
  
    const {t} = this.props;

    // var avatar = this.state.avatarBaseUrl+this.state.consultant.photo;
    var avatar = this.state.consultant.photo;
    
    if(this.state.consultant.photo)
      if(!this.state.consultant.photo.includes('https://'))
        avatar = 'https://'+avatar;
    // if(this.state.consultant.photo)
    //   if(this.state.consultant.photo.includes("api"))
    //     avatar = "https://"+this.state.consultant.photo;

    var userType = 'client';
    if(this.state.user.id)
     userType = this.state.user.roles.includes('ROLE_CONSULTANT') ? 'consultant' : 'client';


    return (

      <>
      <HeaderComponent  history={this.props.history} />

      <div>
        <MDBView className="f-fond-cv" >  
          <MDBRow className="d-flex justify-content-center" >
            <MDBCol xs="12" md="10" className="z-depth-1" style={{backgroundColor:'#fff', marginTop:80, marginBottom:80}}>
              <MDBContainer>
              <MDBRow className="mt-4 d-flex ">

                  <MDBCol size="12">
                    <span onClick={()=> this.props.history.goBack()}><strong style={{color:'olivedrab', fontWeight:'bold', fontSize:18, cursor:'pointer'}} >{t('faseya.consultant_cv.back')}</strong> </span> <span style={{fontWeight:400, color:'#000'}}> &#62; {this.state.consultant.profil}</span>
                  </MDBCol>


                  {/* Consultant Bloc */}

                  <MDBCol size="12" className="mt-4">
                    <MDBRow>
                      <MDBCol size="12" xs="12" md="4">
                        {this.state.consultant.photo &&
                          <img className="img-fluid f-cv-avatar" src={avatar} alt="" />
                        }
                        {!this.state.consultant.photo &&
                          <img className="img-fluid f-cv-avatar" src={avataaar_0} alt="" />
                        }
                        </MDBCol>

                      <MDBCol size="12" xs="12" md="8">
                        <MDBCol size="12" xs="12" md="12">
                          <MDBRow className="d-flex justify-content-between mt-1">
                            <MDBCol >
                              <h2 style={{fontWeight:700}}>{this.state.consultant.prenom}</h2>
                              <p style={{fontWeight:500, color:'#000'}} >{this.state.consultant.profil}</p>
                            </MDBCol>
                            <MDBCol className="">
                              <span style={{backgroundColor:'olivedrab', borderRadius:20, padding:10, float:'right', color:'#fff'}}> <strong>{this.state.consultant.tjm} €/{t('faseya.per_day')}</strong> </span>
                            </MDBCol>
                           </MDBRow>
                        </MDBCol>
                        <MDBCol size="12" xs="12" md="12" className="mt-1">
                          <span style={{fontSize:14, color:'black', fontFamily:'Montserrat', fontWeight:400}}>{this.state.consultant.presentation}</span>
                        </MDBCol>

                        <MDBCol size="12" xs="12" md="12" className="mt-4">
                          <MDBRow className="d-flex justify-content-between">
                            <MDBCol size="12">
                              <h2 style={{fontWeight:400}}>{t('faseya.consultant_cv.skills')}</h2>
                            </MDBCol>
                            <MDBCol  size="12">
                              <MDBRow>
                                {this.state.consultant.skills.map((t, index) =>
                                  <MDBCol size="auto" className="mb-1" key={index}>
                                    <MDBBadge className="f-cv-badge" ><strong style={{color:'#000'}}>{t}</strong></MDBBadge>
                                  </MDBCol>
                                  )
                                 }
                              </MDBRow>
                            </MDBCol>
                           </MDBRow>
                        </MDBCol>
                      </MDBCol>

                      <MDBCol size="12" xs="12" md="12" className="mt-4">

                          <MDBContainer>
                            <MDBRow style={{padding:25, backgroundColor:'#ebfbf5', borderRadius:6, fontSize:20}}>
                              <MDBCol className="d-flex align-items-center justify-content-center">
                            
                                <MDBRow>
                                  <MDBCol size="auto" xs="12" className="d-flex align-items-center justify-content-center">
                                    <div style={{width:15, height:15, backgroundColor:'#34d399', borderRadius:8, padding:3, marginRight:5}}></div>
                                  </MDBCol>
                                  <MDBCol size="auto" xs="12" className="d-flex align-items-center justify-content-center">
                                    <span style={{fontWeight:500}}>{this.state.consultant.prenom} {t('faseya.consultant_cv.is')} <span style={{color:'#34d399'}}>{this.state.consultant.occupe ? t('faseya.consultant_cv.unavailable') : t('faseya.consultant_cv.available')}</span></span>
                                  </MDBCol>
                                  <MDBCol size="auto" xs="12" className="d-flex align-items-center justify-content-center">

                                   {/* {this.state.user.id && this.state.user.roles.includes('ROLE_CLIENT') && */}
                                    <MDBBtn className="f-btn-recruit" onClick={()=>{this.props.history.push({pathname: this.state.user.id ? '/dashboard/'+userType+'/recruit' : '/inscription',state :{consultant : this.state.consultant}});}} >{t('faseya.consultant_cv.hire')} {this.state.consultant.name}</MDBBtn>
                                    {/* } */}
                                  </MDBCol>
                                </MDBRow>
                                
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>

                      </MDBCol>


                      {/* 3 horizontal items */}

                      <MDBCol size="12" xs="12" md="12" className="mt-4">

                          <MDBContainer>
                            <MDBRow style={{fontSize:20}}>
                              <MDBCol size="12" xs="12" md="4"  className="mb-4" >
                                <MDBCol size="12" className="mb-3">
                                  <img src={experience_icon} style={{display:'initial', width:20, marginRight:8}} alt="" />
                                  <strong className="" style={{margin:0, color:'#000', marginBottom:-8}}>{t('faseya.consultant_cv.'+this.getExperienceItem(this.state.consultant))}</strong>
                                </MDBCol>
                                 
                              </MDBCol>

                              
                              <MDBCol size="12" xs="12" md="4"  className="mb-4" >
                                <MDBCol size="12" className="mb-3">
                                  <img src={years_icon} style={{display:'initial', width:20, marginRight:8}} alt="" />
                                  <strong className="" style={{margin:0, color:'#000', marginBottom:-8}}>{this.state.consultant.nbr_annee_experience} {t('faseya.consultant_cv.years_of_xp')}</strong>
                                </MDBCol>
                                 
                              </MDBCol>

                              
                              <MDBCol size="12" xs="12" md="4"  className="mb-4" >
                                <MDBCol size="12" className="mb-3">
                                  <img src={type_icon} style={{display:'initial', width:20, marginRight:8}} alt="" />
                                  <strong className="" style={{margin:0, color:'#000', marginBottom:-8}}>{t('faseya.consultant_cv.'+this.getAvailabilityItem(this.state.consultant))}</strong>
                                </MDBCol>
                                
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>

                      </MDBCol>


                      {/* Experience section  */}


                      
                      <MDBCol size="12" xs="12" md="12" className="mt-4">

                          <MDBContainer>
                            <MDBRow style={{fontSize:20}}>
                              <MDBCol size="12" xs="12" md="12"  className="mb-4" >
                                <MDBCol size="12" className="mb-3">
                                  <img src={experience_icon2} style={{display:'initial', width:20, marginRight:8}} alt="" />
                                  <strong className="" style={{margin:0, color:'#000', marginBottom:-8, fontSize:24}}>{t('faseya.consultant_cv.experiences')}</strong>
                                </MDBCol>
                                 
                              </MDBCol>

                              
                              <MDBCol size="12" xs="12" md="12"  className="mb-4" >
                                
                                {/* Exp row */}
                                {this.state.experiences.map((xp, index) =>
                                  <MDBRow>
                                   {/* Tasks bloc */}
                                   <MDBCol size="12" className='mt-4'>
                                     <MDBRow style={{fontWeight:500, color:'#000'}}>
                                       <MDBCol size="12" xs="12" md="5"  className="mb-4">
                                          <span style={{color:'olivedrab'}}>{t('faseya.consultant_cv.tasks_and_achievements')}</span>
                                       </MDBCol>
                                       <MDBCol size="12" xs="12" md="7" style={{position:'relative' ,borderLeft:'dotted 2px gray'}} >
                                          <div style={{position:'absolute', top:-10, left:-9, width:15, height:15, backgroundColor:'olivedrab', borderRadius:8, padding:3, marginRight:5}}></div>

                                          <MDBRow>
                                            <MDBCol size="12" style={{marginTop:-15}}><span>{xp.intitule_poste}</span></MDBCol>
                                            <MDBCol size="12">
                                              <MDBRow style={{color:'gray', fontSize:18}}>
                                                <MDBCol size="12" xs="12" md="6" ><span>{xp.societe}</span></MDBCol>
                                                <MDBCol size="12" xs="12" md="6" className="d-flex justify-content-end" ><span>{xp.annee_debut} - {xp.annee_fin}</span></MDBCol>
                                              </MDBRow>
                                            </MDBCol>
                                            <MDBCol size="12" style={{fontSize:18}}>
                                               <ul>
                                                {xp.works.map((w, index) =>
                                                 <li>{w}</li>
                                                )}
                                               </ul>
                                            </MDBCol>
                                          </MDBRow>
                                       </MDBCol>

                                       <MDBCol size="12" xs="12" md="5" >
                                          <span style={{color:'olivedrab'}}>{t('faseya.consultant_cv.technical_environments')}</span>
                                       </MDBCol>
                                       <MDBCol size="12" xs="12" md="7" style={{position:'relative' ,borderLeft:'dotted 2px gray'}} >
                                          <MDBRow>
                                            <MDBCol size="12" style={{fontSize:18}}>
                                                <ul>
                                                  <li style={{overflowWrap:'break-word'}}>{xp.environement_technique.replace(/,/g, ', ')}</li>
                                                </ul>
                                            </MDBCol>
                                          </MDBRow>
                                       </MDBCol>
                                     </MDBRow>
                                   </MDBCol>
                                 </MDBRow>
                                 )}

                                {/* Exp row end*/}

                        


                              </MDBCol>
 
                            </MDBRow>

                            {/* Formations side */}

                            <MDBRow style={{fontSize:20}}>
                              <MDBCol size="12" xs="12" md="12"  className="mb-4" >
                                <MDBCol size="12" className="mb-3">
                                  <img src={experience_icon2} style={{display:'initial', width:20, marginRight:8}} alt="" />
                                  <strong className="" style={{margin:0, color:'#000', marginBottom:-8, fontSize:24}}>{t('faseya.consultant_cv.formations')}</strong>
                                </MDBCol>
                                 
                              </MDBCol>

                              
                              <MDBCol size="12" xs="12" md="12"  className="mb-4" >
                                
                                {/* Formation row */}
                                 {this.state.diplomas.map((d, index) =>
                                   <MDBRow>
                                   {/* Tasks bloc */}
                                   <MDBCol size="12">
                                     <MDBRow style={{fontWeight:500, color:'#000'}}>
                                       <MDBCol size="12" xs="12" md="5"  className="mb-4">
                                          {/* <span style={{color:'olivedrab'}}>{t('faseya.consultant_cv.tasks_and_achievements')}</span> */}
                                       </MDBCol>
                                       <MDBCol size="12" xs="12" md="7" style={{position:'relative' ,borderLeft:'dotted 2px gray'}} >
                                          <div style={{position:'absolute', top:-10, left:-9, width:15, height:15, backgroundColor:'#000', borderRadius:8, padding:3, marginRight:5}}></div>

                                          <MDBRow>
                                            <MDBCol size="12" style={{marginTop:-15}}><span>{d.libelle}</span></MDBCol>
                                            <MDBCol size="12">
                                              <MDBRow style={{color:'gray', fontSize:18}}>
                                                <MDBCol size="12" xs="12" md="6" ><span>{d.etablissement}</span></MDBCol>
                                                <MDBCol size="12" xs="12" md="6" className="d-flex justify-content-end" ><span>{d.annee_debut} - {d.annee_obtention}</span></MDBCol>
                                              </MDBRow>
                                            </MDBCol>
                                             
                                          </MDBRow>
                                       </MDBCol>

                                     </MDBRow>
                                   </MDBCol>
                                 </MDBRow>
                                 )}
                                {/* Exp row end*/}


                         
                                 


                              </MDBCol>
 
                            </MDBRow>


                          </MDBContainer>

                      </MDBCol>


                    </MDBRow>
                  </MDBCol>
  
              </MDBRow>

             </MDBContainer>
          
            </MDBCol>

          </MDBRow>

          {/* ------------------------ */}

        </MDBView>

      </div>

        <PrefooterCvComponent />
      </>

    );
  }
}

export default withTranslation()(ConsultantCvPage);