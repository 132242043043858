function isDevelopmentMode() {
  return process && process.env && process.env.NODE_ENV === "development";
}

function scanUrlForOnlineTesting() {
  return ["site.faseya-dev.com"].includes(window.location.hostname);
}

const apiUrl =
  isDevelopmentMode() || scanUrlForOnlineTesting()
    ? "https://api.mamyerim.com/api/v2"
    : "https://api.mamyerim.com/api/v2";

export default apiUrl;
