import * as types from './mainReduxActionTypes';
import initialState from './mainReduxInitialState';
 
export default function mainReduxReducer(state = initialState.mainReduxState, action) {
  switch (action.type) {

    case types.UPDATE_ACTIVE:

      return {
        ...state,
        active: action.data,
      };



      
    case types.SET_USER:

      return {
        ...state,
        user: action.data,
      };


          
    case types.UPDATE_CONSULTANTS_LIST:

      return {
        ...state,
        consultants: action.data,
      };

                
    case types.UPDATE_CONSULTANTS_PAGE:

      return {
        ...state,
        consultantsPage: action.data,
      };


    case types.UPDATE_CONSULTANTS_EXPERTS:

      return {
        ...state,
        consultantsExperts: action.data,
      };
  
    
    case types.UPDATE_CONSULTANTS_AVAILABLE:

      return {
        ...state,
        consultantsAvailable: action.data,
      };
    
    
    case types.UPDATE_CONSULTANTS_NEW:

      return {
        ...state,
        consultantsNew: action.data,
      };
      

      case types.UPDATE_SERVICES:

        return {
          ...state,
          services: action.data,
        };

    default:
      return state;
  }
}