import React, {Fragment}  from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBFormInline,
  MDBBtn,
  MDBView,
  MDBInput,
  MDBContainer
} from 'mdbreact';
import './AboutPage.css';

import PartnersComponent from '../../components/PartnersComponent/PartnersComponent';
import ParticularityComponent from '../../components/ParticularityComponent/ParticularityComponent';
import ServicesComponent from '../../components/ServicesComponent/ServicesComponent';
import ExpertsComponent from '../../components/ExpertsComponent/ExpertsComponent';
import ParcoursComponent from '../../components/ParcoursComponent/ParcoursComponent';
import PacksComponent from '../../components/PacksComponent/PacksComponent';
import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import TestomiesComponent from '../../components/TestimoniesComponent/TestimoniesComponent';


import about_banner from '../../assets/images/about_banner.png';
import CommitmentsComponent from '../../components/CommitmentsComponent/CommitmentsComponent';
import ValuesComponent from '../../components/ValuesComponent/ValuesComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import { withTranslation } from 'react-i18next';



class AboutPage extends React.Component {
  state = {
    collapsed: false
  };

  handleTogglerClick = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });
  };

  componentDidMount() {
    document.querySelector('nav').style.height = '65px';
    window.scrollTo(0, 0);

  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: '0rem' };
    const {t} = this.props;
    return (

      <>
      <HeaderComponent  history={this.props.history} />

      <div id='minimalistintro'>
        <MDBView >
          <MDBContainer >
            <MDBRow className="mt-4">
              <MDBCol xs="12" md="7"  className='mb-4 white-text'>
                
                <img src={about_banner} className="img-fluid" style={{borderRadius:8, height:'100%', width:'auto', objectFit:'cover'}} alt="" />
             
              </MDBCol>

              <MDBCol xs="12" md="5"  className='mb-4 '>
                
                <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder', margin:0}}> {t('faseya.about_us.title')} </h4>
                <p style={{fontWeight:500, color:'#000'}}>
                  {t('faseya.about_us.description')}
                </p>
             
              </MDBCol>

            </MDBRow>
          </MDBContainer >

        </MDBView>

        
    



      </div>

        <ServicesComponent header="faseya.about_us.services.title" />

        <CommitmentsComponent />

        <ValuesComponent />

        <ExpertsComponent header='faseya.about_us.our_value_proposition.items.item_7' />

        <PrefooterComponent />
      </>

    );
  }
}

export default withTranslation()(AboutPage);