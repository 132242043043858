import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBFooter,
} from 'mdbreact';
import './FooterComponent.css';

import light_icon from '../../assets/images/light_icon.png';
import puzzle_icon from '../../assets/images/puzzle_icon.png';
import quality_icon from '../../assets/images/quality_icon.png';
import prefooter_banner from '../../assets/images/prefooter_banner.png';

import logo_faseya_white from '../../assets/images/logo_faseya_white.png';


import { withTranslation } from 'react-i18next';

class FooterComponent extends React.Component {
 
  state = {
    collapseID: ''
  };

 
  render() {

    const {t} = this.props;

    return (

      <MDBFooter style={{backgroundColor:'#545454', paddingTop:65, color:'white'}} className="font-normal">
      <MDBContainer fluid className=" text-md-left">
        <MDBRow>
          <MDBCol sm="12" md="4" className="offset-md-1" >
            <MDBContainer >

              <img src={logo_faseya_white} className="img-fluid" style={{width:150}} alt="" />
            
              <p style={{fontWeight:500, color:'white'}} >
                {t('faseya.footer.presentation')}
              </p>
            </MDBContainer >

          </MDBCol>
          <MDBCol sm="12" md="3" className="offset-md-1">
            <h2 className="title mb-3" style={{color:'#fff', fontWeight:500, color:'white'}}>{t('faseya.footer.some_useful_links.title')}</h2>
            <ul style={{padding:0}}>
              <li className="list-unstyled mt-2" style={{fontWeight:500, color:'white'}} >
                <a href="#/concept" >{t('faseya.footer.some_useful_links.concepts')}</a>
              </li>
              <li className="list-unstyled mt-2" style={{fontWeight:500, color:'white'}} >
                <a href="#/pricing" >{t('faseya.footer.some_useful_links.pricing')}</a>
              </li>
              <li className="list-unstyled mt-2" style={{fontWeight:500, color:'white'}} >
                <a href="#/about" >{t('faseya.footer.some_useful_links.about_us')}</a>
              </li>
              <li className="list-unstyled mt-2" style={{fontWeight:500, color:'white'}} >
                <a href="#/blog" >{t('faseya.footer.some_useful_links.blog')}</a>
              </li>
              <li className="list-unstyled mt-2" style={{fontWeight:500, color:'white'}} >
                <a href='#/confidentiality' >{t('faseya.footer.some_useful_links.confidentiality')}</a>
              </li>
              <li className="list-unstyled mt-2" style={{fontWeight:500, color:'white'}} >
                <a href="#/terms-of-use" >{t('faseya.footer.some_useful_links.general_conditions')}</a>
              </li>
            </ul>
          </MDBCol>
         

          <MDBCol sm="12" md="3">
            <h2 className="title mb-3" style={{color:'#fff', fontWeight:500, color:'white'}}>{t('faseya.footer.contact_us.title')}</h2>
            <ul style={{padding:0}}>
              <li className="list-unstyled mt-2" style={{fontWeight:500, color:'white'}} >
                <span>11 Grande Allée du 12 février 1934
                  <br/>Le Luzard 2 - Bâtiment B77186 - Noisiel</span>
              </li>
              <li className="list-unstyled mt-2" style={{fontWeight:500, color:'white'}} >
                <MDBIcon style={{position:'relative'}} icon="phone-alt" /><span className="ml-2">+336 68 82 42 01</span>
              </li>
              <li className="list-unstyled mt-2" style={{fontWeight:500, color:'white'}} >
                <MDBIcon style={{position:'relative'}} far icon="envelope" /><span className="ml-2">contact@faseya.com</span>
              </li>
              
            </ul>
          </MDBCol>


        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3 mt-4">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: <a href="https://www.faseya.com"> Faseya.com </a>
        </MDBContainer>
      </div>

 

    </MDBFooter>
    );
  }
}

export default withTranslation()(FooterComponent);
