import React, {useState, useEffect}  from 'react';
import { Link } from 'react-router-dom';
import {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBIcon,
} from 'mdbreact';
import PartnerShipComponent from '../../components/PartnerShipComponent/PartnerShipComponent';
import logo_faseya from '../../assets/images/logo_faseya.png';
import AuthService from '../../services/auth.service';
import olive_loader from '../../assets/images/olive-loader.gif';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

function ResetNewPassword(props) {

    const[displayResult, setDisplayResult] = useState(false);
    const[error, setError] = useState('');
    const[connecting, setConnecting] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const {t} = props;

    const validationSchema = Yup.object().shape({
        password: Yup.string()
          .required(t('faseya.Register.consultants.forms.messages.item_2'))
          .min(8, t('faseya.Register.consultants.forms.items.item_6')),
        confirmPassword: Yup.string()
          .required(t('faseya.Register.consultants.forms.messages.item_2'))
          .oneOf([Yup.ref('password'), null], t('faseya.Register.consultants.forms.messages.item_4')),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = useForm({
        resolver: yupResolver(validationSchema)
      });

    const submitHandler = (data) => {

        const infos = JSON.stringify(data, null, 2);
        
        setConnecting(true)
        
        AuthService.resetPassword({
            email: props.location.state.email,
            code: props.location.state.code,
            password: JSON.parse(infos).password
        }).then((res) => {

            setConnecting(false);
            if(res) {
                props.history.push({
                    pathname:'/se-connecter'
                })
            } else {
                setDisplayResult(true);
                setError("An error occured");
            }
          
            
        }).catch((err) => {
            setDisplayResult(true);
            setConnecting(false);
            console.log(err)
            if(err.code == 404)
            setError(err.message);
            else setError(err.msg);
        })
        
        
    }

    return (
                <MDBRow style={{height:'90vh'}}>
                    <MDBCol xs="12" md="5" className='' style={{ background: '#f3f4f6'}}>
                            <MDBRow className="d-flex justify-content-center flex-column my-5 mx-5">
                                <div className="d-flex flex-row">
                                    <div className="p-1">
                                        <Link to="/">
                                            <img src={logo_faseya} />
                                        </Link>
                                    </div>
                                </div>

                                <div className="p-1 mt-5">
                                    <span className="text-connect">
                                         {t(`faseya.new_password.title`)}
                                    </span>
                                </div>

                                {displayResult && (
                                    <div  className="p-1 mt-2">
                                        <span className={error ? "error-text" : "success-text"}>
                                           { error }
                                        </span>
                                    </div>
                                )}

                                <div className="d-flex justify-content-center pt-3">
                                    <form className='w-100' onSubmit={handleSubmit(submitHandler)}>

                                        <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <input
                                                        type="password"
                                                        name='password'
                                                        {...register('password')}
                                                        placeholder={t(`faseya.new_password.password_placeholder`)}
                                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        {errors.password?.message}
                                                    </div>
                                                </MDBCol>
                                        </MDBRow>

                                        <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <input
                                                        type="password"
                                                        name='confirmPassword'
                                                        {...register('confirmPassword')}
                                                        className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                                        placeholder={t(`faseya.new_password.confirmPassword_placeholder`)}
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        {errors.confirmPassword?.message}
                                                    </div>
                                                </MDBCol>
                                        </MDBRow>

                                        <MDBRow>
                                                <MDBCol md="12 mt-2">
                                                    {connecting &&
                                                        <img src={olive_loader} style={{position:'absolute', zIndex:1, left:'47.5%', width:40}} alt="" />
                                                    }
                                                    {!connecting && 
                                                        <MDBBtn type="submit"  className="btn-block btn-color">
                                                           {t(`faseya.new_password.text_submit_button`)}
                                                        </MDBBtn>
                                                    }
                                                </MDBCol>
                                        </MDBRow>
                                    </form>
                                </div>
                            </MDBRow>
                    </MDBCol>

                    <MDBCol  className='d-flex justify-content-center align-items-center hide-mobile'>
                       <PartnerShipComponent />
                   </MDBCol>
                </MDBRow>
    )
}

export default withTranslation()(ResetNewPassword);