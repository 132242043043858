import React, {useState, useEffect}  from 'react';
import { Link } from 'react-router-dom';
import {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBIcon,
    MDBAlert
  } from 'mdbreact';
import PartnerShipComponent from '../../components/PartnerShipComponent/PartnerShipComponent';
import logo_faseya from '../../assets/images/logo_faseya.png';
import olive_loader from '../../assets/images/olive-loader.gif'; 
import './SigIn.scss';
import auth from '../../services/auth';
import AuthService from '../../services/auth.service';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainReduxActions from '../../store/mainRedux/mainReduxActions';


const SignInPage = (props) => {

    const[eye, setEye] = useState('eye-slash')
    const[showHidePassword, setShowHidePassword] = useState('password')
    const[user, setUser] = useState({
        email: '', 
        password: '',  
    })
    const[connecting, setConnecting] = useState(false)
    const[showError, setShowError] = useState(false)
    const[textError, setTextError] = useState(false)

    useEffect(() => {
        
      window.scrollTo(0, 0);

    }, [])

    const submitHandler = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        if(!user.email || !user.password) return
        // console.log(user)
        setConnecting(true);
        AuthService.signin(user).then((res) => {
            // setShowError(true);
             if(res.id) {
                
                auth.login(()=>{
                    localStorage.setItem("authToken", res.accessToken);
                    localStorage.setItem("user", JSON.stringify(res));
                    //Redux state
                    // console.log(res);
                    props.mainReduxActions.set_user(res);
                    // Get profile type
                    var type = res.roles.includes('ROLE_CONSULTANT') ? '/consultant' : '/client';
                    props.history.push({pathname:'/dashboard'+type});
                })
             }
             else {
                 setShowError(true);
             }

             setConnecting(false);

             
        }).catch((err) => {
            if(err.code == 404)
            setTextError(err.message);
            else setTextError(err.msg);
            setShowError(true);
            setConnecting(false);

        })
    }

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value
        })
    }

    const OnChangeEye = () => {
        if(eye === "eye-slash")
        { 
            setEye('eye')
            setShowHidePassword('text')
        }else{
            setEye('eye-slash')
            setShowHidePassword('password')
        }
    }


    const {t} = props;

    return (

        <MDBRow>
                <MDBCol xs="12" md="5" className='' style={{ background: '#f3f4f6'}}>
                    <MDBRow className="d-flex justify-content-center flex-column my-5 mx-5">
                         
                            <div className="d-flex flex-row justify-content-between">
                                <div className="p-1">
                                    <Link to="/">
                                      <img src={logo_faseya} />
                                    </Link>
                                </div>

                                <div className="p-1">
                                    <Link to="/">
                                        <span className="login-text">{t('faseya.back')}</span>
                                    </Link>
                                </div>
                            </div>

                            <div className="p-1 mt-3">
                                <span className="text-connect">{t('faseya.Login.title')}</span> <br/> 
                                <Link to="/inscription">
                                    <span className="not-member-text">
                                        {t('faseya.Login.not_a_member')} {t('faseya.Login.subscribe')}
                                    </span>
                                </Link>
                            </div>

                            {showError && (
                                <div className="error-text mt-2">
                                   {/* {t('faseya.Login.credentials_error')} */}
                                   { textError }
                                </div>
                            )}

                            <div className="d-flex justify-content-center pt-3 pt-lg-5">

                                <form className='needs-validation w-100' onSubmit={submitHandler} noValidate>

                                    <MDBRow >
                                        <MDBCol md="12" className="mb-3">
                                            <input 
                                                type="email"
                                                value={user.email}
                                                name='email'
                                                onChange={changeHandler}
                                                id='validationEmail'
                                                required
                                                className="form-control f-signin-input"
                                                placeholder={t('faseya.Login.forms.items.item_1') + ' (*)'}
                                                validation='Looks good!'
                                            /> 
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow >
                                        <MDBCol md="12" className="">
                                            <div className="pass-wrapper">
                                                <input 
                                                    type={showHidePassword}
                                                    value={user.password}
                                                    name='password'
                                                    onChange={changeHandler}
                                                    id='validationPassword'
                                                    required
                                                    className="form-control f-signin-input"
                                                    placeholder={t('faseya.Login.forms.items.item_2') + ' (*)'}
                                                    validation='Looks good!'
                                                /> 
                                                <MDBIcon icon={eye} onClick={OnChangeEye} />
                                            </div>
                                            
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="12 mb-2 mb-lg-5">
                                           <Link to="/mot-de-passe-oublie">
                                             <span className="text-password-forget">{t('faseya.Login.forgot_password')}</span>
                                           </Link>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="12 mt-1">
                                            {connecting &&
                                                <img src={olive_loader} style={{position:'absolute', zIndex:1, left:'47.5%', width:40}} alt="" />
                                            }
                                            {!connecting &&
                                                <MDBBtn type="submit"  className="btn-block btn-color">
                                                    {t('faseya.Login.be_connect')}
                                                </MDBBtn>
                                            }
                                        </MDBCol>
                                    </MDBRow>

                                </form>
                                
                            </div>
                           
                    </MDBRow>
                </MDBCol>

                <MDBCol  className='hide-mobile'>
                    <PartnerShipComponent />
                </MDBCol>

        </MDBRow>
    )
}

function mapStateToProps(state) {
    return {
      mainReduxState: state.mainReduxState
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      mainReduxActions: bindActionCreators(mainReduxActions, dispatch)
    }
  }
  
  export default connect(mapStateToProps , mapDispatchToProps)(withTranslation()(SignInPage));