var  yearExperiences = [
    {
        id: 1,
        name: "1 an",
        value: "1 an",
        label: "1 an",
    },
    {
        id: 2,
        name: "2 ans",
        value: "2 ans",
        label: "2 ans",
    },
    {
        id: 3,
        name: "3 ans",
        value: "3 ans",
        label: "3 ans",
    },
    {
        id: 4,
        name: "4 ans",
        value: "4 ans",
        label: "4 ans",
    },
    {
        id: 5,
        name: "5 ans",
        value: "5 ans",
        label: "5 ans",
    },
    {
        id: 6,
        name: "6 ans",
        value: "6 ans",
        label: "6 ans",
    },
    {
        id: 7,
        name: "7 ans",
        value: "7 ans",
        label: "7 ans",
    },
    {
        id: 8,
        name: "8 ans",
        value: "8 ans",
        label: "8 ans",
    },
    {
        id: 9,
        name: "9 ans",
        value: "9 ans",
        label: "9 ans",
    },
    {
        id: 10,
        name: "10 ans",
        value: "10 ans",
        label: "10 ans",
    },
    {
        id: 11,
        name: "Plus de 10 ans",
        value: "Plus de 10 ans",
        label: "Plus de 10 ans",
    },
  
]

export default yearExperiences;