import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdbreact';

import partner_1 from '../../assets/images//partners/partner (1).png';
import partner_2 from '../../assets/images//partners/partner (2).png';
import partner_3 from '../../assets/images//partners/partner (3).png';
import partner_4 from '../../assets/images//partners/partner (4).png';
import partner_5 from '../../assets/images//partners/partner (5).png';
import partner_6 from '../../assets/images//partners/partner (6).png';
import partner_7 from '../../assets/images//partners/partner (7).png';
import partner_8 from '../../assets/images//partners/partner (8).png';
import partner_9 from '../../assets/images//partners/partner (9).png';
import partner_10 from '../../assets/images//partners/partner (10).png';
import partner_11 from '../../assets/images//partners/partner (11).png';
import partner_12 from '../../assets/images//partners/partner (12).png';
import partner_13 from '../../assets/images//partners/partner (13).png';
import { withTranslation } from 'react-i18next';

class SponsorsComponent extends React.Component {
 
  state = {
    collapseID: ''
  };

 
  render() {

    const { collapseID } = this.state;
    const {t} = this.props;

    return (
      <MDBContainer  style={{paddingBottom:20}}>

        <div className="d-flex justify-content-center flex-column align-items-center">

          <MDBRow className="pt-5 pb-4">
              <span style={{fontWeight:'800', color: '#818181', fontSize: '1vw'}}>
                {t('faseya.they_trust_us')}
              </span>
          </MDBRow>

          <MDBRow className="w-100 ml-5 align-items-center h-75">
              
            <MDBCol xs="6" md="6"  className="mr-n5">
                <img src={partner_1} className="img-fluid f-customer-img-sponsor-more" alt="" />
            </MDBCol>
            <MDBCol  xs="6" md="6" className="">
                <img src={partner_2} className="img-fluid f-customer-img-sponsor-more"
                alt="" />
            </MDBCol>
            <MDBCol  xs="6" md="6" className="mr-n5">
                <img src={partner_3} className="img-fluid f-customer-img-sponsor-more" alt="" />
            </MDBCol>

            <MDBCol xs="6" md="6" className="">
                <img src={partner_4} className="img-fluid f-customer-img-sponsor-more" alt="" />
            </MDBCol>

            <MDBCol xs="6" md="6"  className="mr-n5">
                <img src={partner_5} className="img-fluid f-customer-img-sponsor-more" alt="" />
            </MDBCol>

            <MDBCol  xs="6" md="6" className="">
                <img src={partner_6} className="img-fluid f-customer-img-sponsor-more"
                alt="" />
            </MDBCol>
            <MDBCol xs="6" md="6" className="mr-n5">
                <img src={partner_7} className="img-fluid f-customer-img-sponsor-more" alt="" />
            </MDBCol>

            <MDBCol xs="6" md="6" className="">
                <img src={partner_8} className="img-fluid f-customer-img-sponsor-more" alt="" />
            </MDBCol>

            <MDBCol xs="6" md="6" className="mr-n5">
                <img src={partner_9} className="img-fluid f-customer-img-sponsor-more" alt="" />
            </MDBCol>

            <MDBCol xs="6" md="6" className="">
                <img src={partner_10} className="img-fluid f-customer-img-sponsor-more" alt="" />
            </MDBCol>

            <MDBCol  xs="6" md="6" className="mr-n5">
                <img src={partner_11} className="img-fluid f-customer-img-sponsor-more" alt="" />
            </MDBCol>

            {/* <MDBCol xs="6" md="6" className="">
                <img src={partner_12} className="img-fluid f-customer-img-sponsor-more" alt="" />
            </MDBCol> */}

            <MDBCol xs="6" md="6" className="">
                <img src={partner_13} className="img-fluid f-customer-img-sponsor-more" alt="" />
            </MDBCol>


          </MDBRow>
        </div>
      
      </MDBContainer>
    );
  }
}

export default withTranslation()(SponsorsComponent);
