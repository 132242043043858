import { createStore, combineReducers } from 'redux';
import mainReduxReducer from './mainRedux/mainReduxReducer';


const rootReducer = combineReducers(
{ mainReduxState: mainReduxReducer }
);
    
 
const configureStore = () => {
return createStore(rootReducer);
}
export default configureStore;