

 var profilesMock = 
  
 [
    { 
        id: 1,
        name: 'client',
        description: 'faseya.Register.clients.description'
    },
    
    { 
        id: 2,
        name: 'consultant',
        description: 'faseya.Register.consultants.summary'
    },
 ]

 export default profilesMock;