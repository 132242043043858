import React, {Fragment}  from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBCard,MDBCardBody, MDBCardTitle, MDBCardImage, MDBCardText,
  MDBFormInline,
  MDBBtn,
  MDBView,
  MDBBadge,
  MDBInput,
  MDBIcon,
  MDBContainer
} from 'mdbreact';

import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBTabPane, MDBTabContent, MDBNav} from '../../mdbreact/dist/mdbreact'

import {InlineWidget} from 'react-calendly';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ChatBot from 'react-simple-chatbot';
import PropTypes from 'prop-types';
import './RecruitConsultantPage.css';

import banner_faseya from '../../assets/images/banner_faseya.jpg';
import world_bg from '../../assets/images/world_bg.png';
import chatbot_icon from '../../assets/images/chatbot_icon.png';
import avataaar_0 from '../../assets/images/avataaars/avataaars.png';
import avataaar_1 from '../../assets/images/avataaars/avataaars(1).png';
import avataaar_2 from '../../assets/images/avataaars/avataaars(2).png';
import avataaar_3 from '../../assets/images/avataaars/avataaars(3).png';
import avataaar_4 from '../../assets/images/avataaars/avataaars(4).png'; 

import olive_loader from '../../assets/images/olive-loader.gif'; 


import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import auth from '../../services/auth';
import { ThemeProvider } from 'styled-components';

const theme = {
  background: '#f5f8fb',
  fontFamily: 'Montserrat',
  headerBgColor: '#11b981',
  headerFontColor: '#ffffff !important',
  headerFontSize: '15px',
  botBubbleColor: '#11b981',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class RecruitConsultantPage extends React.Component {
  state = {
    collapsed: false, 
    activeItem: "1",
    consultants : [
      {id:0,  name : "Habibe", avatar : avataaar_0,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:1,  name : "Petito", avatar : avataaar_1,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:2,  name : "Lamine", avatar : avataaar_2,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:3,  name : "Ange", avatar : avataaar_3,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:4,  name : "Marie", avatar : avataaar_4,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:5,  name : "Mamadou", avatar : avataaar_2,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:6,  name : "Saliou", avatar : avataaar_0,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:7,  name : "Henry", avatar : avataaar_1,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:8,  name : "Eric", avatar : avataaar_2,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:10, name : "Jules", avatar : avataaar_3,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:11, name : "Serge", avatar : avataaar_2,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:12, name : "Hamel", avatar : avataaar_4,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:13, name : "Alfred", avatar : avataaar_1,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]}
    ],
  
  };

  toggle = tab => () => {
    if (this.state.activeItem !== tab) {
    this.setState({
      activeItem: tab
    });
    }
  }

  handleTogglerClick = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });
  };

  componentDidMount() {
    
    window.scrollTo(0, 0);

    document.querySelector('nav').style.height = '65px';
  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: '0rem' };
 
    const consultants = this.state.consultants.map((item,index) =>
      
            <MDBCol key={index} xs="12" sm="12" md="12" lg="12" xl="12" className="mb-3 align-items-center" onClick={()=>{this.props.history.push({pathname:'/cv-consultant',state :{consultant : item}});}} >
                <MDBCard className="p-r-0" style={{ width: "100%", position:'relative', cursor:'pointer' }}>
                
                <MDBView style={{position:'relative'}} className="f-card-view">
                  <MDBCardImage className="img-fluid" src={item.avatar} style={{width:"100%"}} waves />
                  <MDBMask className="flex-center" style={{backgroundColor:'#00000093'}}>
                  </MDBMask>
                  
                    {/* Availability */}
                    <div style={{position:'absolute', display:'flex', alignItems:'center', top:10, left:10, padding:2, paddingLeft:6, paddingRight:6, borderRadius:15, backgroundColor:'#fff', }}>
                      <div style={{width:17, height:17, backgroundColor:'olivedrab', borderRadius:17, marginRight:5}}></div>
                      <span className="" style={{color:'#000', fontSize:14}}>Disponible</span>
                    </div>


                    {/* Name */}
                    <div style={{position:'absolute', display:'flex', alignItems:'center', top:'70%', left:10, padding:4, paddingLeft:10, paddingRight:10, borderRadius:15, }}>
                      <strong className="" style={{color:'#fff', fontSize:20, fontWeight:700}}>{item.name}</strong>
                    </div>

                </MDBView>
              
                <MDBCardBody style={{ paddingBottom:'auto', position:'relative'}}>
                  <MDBCardTitle style={{color:'#000', fontWeight:700, fontSize:'100%'}}>{item.title}</MDBCardTitle>
          
                    {/* Biding */}

                    <div style={{position:'absolute', display:'flex', alignItems:'center', top:'-12%', left:10, padding:4, paddingLeft:10, paddingRight:10, borderRadius:15, backgroundColor:'#d1d5db', }}>
                      <strong className="" style={{color:'#000', fontSize:15, fontWeight:700}}>80 € / jour</strong>
                    </div>
  
                  <MDBRow  >
                    { 
                      item.techs.map((t, subindex) =>
                      <MDBCol size="auto" className="mb-1" key={subindex} >
                        <MDBBadge color="light" className="f-cv-badge" ><strong>{t}</strong></MDBBadge>
                      </MDBCol>
                        )
                    }

                  </MDBRow>

                </MDBCardBody>
              </MDBCard>
              
        </MDBCol>
     
      
    );


    return (

      <>
      
      <HeaderComponent  history={this.props.history} />

      <div id='minimalistintro'>
         
        <MDBRow style={{color:'white'}} >
          <MDBCol size="12" xs="12" md="12" xl="12" >
            {/* <img src={olive_loader} style={{position:'absolute', zIndex:1, top:'40%', left:'48%', width:60}} alt="" /> */}
            <InlineWidget styles={{position:'relative', zIndex:2,height:window.innerHeight}}  url="https://calendly.com/yatma-gueye/15min?embed_domain=www.faseya.com&embed_type=Inline&month=2021-10&date=2021-10-14" />
          </MDBCol>
        </MDBRow>

      </div>
 
      <PrefooterComponent />
        
      </>

    );
  }
}

export default RecruitConsultantPage;