import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdbreact';
import './ValuesComponent.css';

import light_icon from '../../assets/images/light_icon.png';
import puzzle_icon from '../../assets/images/puzzle_icon.png';
import quality_icon from '../../assets/images/quality_icon.png';
import { withTranslation } from 'react-i18next';

class ValuesComponent extends React.Component {
 
  state = {
    collapseID: ''
  };

 
  render() {

    const { collapseID } = this.state;
    const {t} = this.props;
    return (
      <MDBContainer style={{marginTop:80}}>
  
        <MDBRow>
          

          <MDBCol size="12" xs="12" md="12"  className="mb-4" >
            <h4 className="font-light f-subtitle f-subheader text-center " style={{fontWeight:700}}>{t('faseya.about_us.our_added_value.title')}</h4>
          </MDBCol>

          <MDBCol size="12" xs="12" md="12"  className="mb-4" >

            <MDBRow>
              <MDBCol size="12" xs="12" md="6"  className="mb-4 text-center" >
                  <div style={{width:'100%', padding:10, borderRadius:18, color:'#000'}} className="z-depth-2">
                  {t('faseya.about_us.our_added_value.items.item_1')}
                  </div>
              </MDBCol>
              <MDBCol size="12" xs="12" md="6"  className="mb-4 text-center" >
                  <div style={{width:'100%', padding:10, borderRadius:18, color:'#000'}} className="z-depth-2">
                  {t('faseya.about_us.our_added_value.items.item_2')}
                  </div>
              </MDBCol>
              <MDBCol size="12" xs="12" md="6"  className="mb-4 text-center" >
                  <div style={{width:'100%', padding:10, borderRadius:18, color:'#000'}} className="z-depth-2">
                  {t('faseya.about_us.our_added_value.items.item_3')}
                  </div>
              </MDBCol>
              <MDBCol size="12" xs="12" md="6"  className="mb-4 text-center" >
                  <div style={{width:'100%', padding:10, borderRadius:18, color:'#000'}} className="z-depth-2">
                  {t('faseya.about_us.our_added_value.items.item_4')}
                  </div>
              </MDBCol>
              <MDBCol size="12" xs="12" md="6"  className="mb-4 text-center" >
                  <div style={{width:'100%', padding:10, borderRadius:18, color:'#000'}} className="z-depth-2">
                  {t('faseya.about_us.our_added_value.items.item_5')}
                  </div>
              </MDBCol>
              
            </MDBRow>

          </MDBCol>

        </MDBRow>


        <h4 className="font-light f-subtitle f-subheader text-center " style={{fontWeight:'bolder'}}>{t('faseya.about_us.our_value_proposition.title')}</h4>

        <MDBRow>
          
          <MDBCol size="12" xs="12" md="4"  className="mb-4 f-particularity-item" >
            <MDBCol size="12" className="mb-3">
              <img src={light_icon} style={{width:20, marginRight:8}} alt="" />
              <strong className="f-subtitle-2" style={{margin:0, marginBottom:-8}}>{t('faseya.about_us.our_value_proposition.items.item_1')}</strong>
            </MDBCol>
            <MDBCol size="12" className="mb-1">
              <p style={{color:'black', fontSize:18, fontWeight:400}}>
              {t('faseya.about_us.our_value_proposition.items.item_2')}</p>
            </MDBCol>
          </MDBCol>

          
          <MDBCol size="12" xs="12" md="4"  className="mb-4 f-particularity-item" >
            <MDBCol size="12" className="mb-3">
              <img src={puzzle_icon} style={{width:20, marginRight:8}} alt="" />
              <strong className="f-subtitle-2" style={{margin:0, marginBottom:-8}}>{t('faseya.about_us.our_value_proposition.items.item_3')}</strong>
            </MDBCol>
            <MDBCol size="12" className="mb-1">
              <p style={{color:'black', fontSize:18, fontWeight:400}}>
              {t('faseya.about_us.our_value_proposition.items.item_4')}
              </p>
            </MDBCol>
          </MDBCol>

          
          <MDBCol size="12" xs="12" md="4"  className="mb-4 f-particularity-item" >
            <MDBCol size="12" className="mb-3">
              <img src={quality_icon} style={{width:20, marginRight:8}} alt="" />
              <strong className="f-subtitle-2" style={{margin:0, marginBottom:-8}}>{t('faseya.about_us.our_value_proposition.items.item_5')}</strong>
            </MDBCol>
            <MDBCol size="12" className="mb-1">
              <p style={{color:'black', fontSize:18, fontWeight:400}}>
              {t('faseya.about_us.our_value_proposition.items.item_6')}
              </p>
            </MDBCol>
          </MDBCol>



        </MDBRow>
      
      
      </MDBContainer>
    );
  }
}

export default withTranslation()(ValuesComponent);
