import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,MDBCardBody, MDBCardTitle, MDBCardImage, MDBCardText,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from 'mdbreact';
import PhoneInput from 'react-phone-input-2';
import startsWith from 'lodash.startswith';
import 'react-phone-input-2/lib/bootstrap.css';
import './PacksComponent.css';
 
import quality_icon from '../../assets/images/quality_icon.png';
import world_bg from '../../assets/images/world_bg.png';
import demo_1 from '../../assets/images/demo_1.png';
import demo_2 from '../../assets/images/demo_2.png';
import demo_3 from '../../assets/images/demo_3.png';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

class PacksComponent extends React.Component {
 
  constructor(props) {
    super(props)
    this.state = {
      collapseID: '',
      modal: false,
      societe: '',
      nom: '',
      prenom: '',
      username: '',
      telephone: '',
      adresse: '',
      ville: '',
      email: '',
      password: '',
      confirm: '',
      immatriculation: '',
      passwordError: false,
      confirmError : false,
      phoneError : false
    };
    this.passwordRef = React.createRef();
    this.confirmRef = React.createRef();
  }  

  

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  validForm = (data) => {
    let valid = true
    for(var i in data) {
       if(data[i].length === 0)  valid = false
    }
    if(this.passwordRef.current.value.length < 8) {
      this.setState({passwordError: true})
      valid = false
    }
    if(this.confirmRef.current.value.length < 8) {
      this.setState({confirmError: true})
      valid = false
    }

    if(this.state.telephone.length < 5) {
      this.setState({phoneError: true})
      document.querySelector('.input-phone-register-modal').classList.remove('input-register-modal');
      document.querySelector('.input-phone-register-modal').classList.add('input-register-modal-bis');
      valid = false
    }

    return valid  
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const newUser = {
      societe: this.state.societe, 
      nom: this.state.nom, 
      prenom: this.state.prenom, 
      username: this.state.username, 
      telephone: this.state.telephone, 
      adresse: this.state.adresse, 
      ville: this.state.ville, 
      email: this.state.email, 
      //immatriculation: this.state.immatriculation, 
      password: this.state.password, 
      confirm: this.state.confirm 
    }
    let elemts = document.querySelectorAll('.input-register-modal');
    for(let i = 0 ; i < elemts.length; i++) {
      if(elemts[i].value.length == 0) {
        elemts[i].classList.remove('input-register-modal');
        elemts[i].classList.add('input-register-modal-bis'); 
      }
    }
    
    if(this.validForm(newUser)) {
      this.setState({
        modal: false,
        telephone: '',
        nom: '',
        prenom: '',
        societe: '',
        adresse: '',
        ville: '',
        password: '',
        confirm: '',
        username: '',
        email: '',
        immatriculation: '',
        phoneError: false,
        passwordError: false,
        confirmError: false
      });
      toast("Merci de nous faire confiance.");
    }
  
  }

  onChangeHandle = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  validate = (inputNumber, country, countries) =>  {
    return countries.some((country) => {
      return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
    });
  
  }
 
  render() {
 
    const {t} = this.props;
    //console.log(this.props)
    const packs =
    [

      {
        "id" : 1,
        "name": t('faseya.pricing.type.starter.title'),
        "price" : 65,
        "options" : [t('faseya.pricing.type.common.items.item_1'),t('faseya.pricing.type.starter.items.item_1'),t('faseya.pricing.type.starter.items.item_2'),t('faseya.pricing.type.common.items.item_2'), t('faseya.pricing.type.common.items.item_3'), t('faseya.pricing.type.common.items.item_4'),t('faseya.pricing.type.common.items.item_5'), t('faseya.pricing.type.common.items.item_6')
    ]
      },
      {
        "id" : 2,
        "name": t('faseya.pricing.type.booster.title'),
        "price" : 80,
        "options" : [t('faseya.pricing.type.common.items.item_1'),t('faseya.pricing.type.booster.items.item_1'),t('faseya.pricing.type.booster.items.item_2'),t('faseya.pricing.type.common.items.item_2'), t('faseya.pricing.type.common.items.item_3'), t('faseya.pricing.type.common.items.item_4'),t('faseya.pricing.type.common.items.item_5'), t('faseya.pricing.type.common.items.item_6')
    ]
      },
      {
        "id" : 3,
        "name": t('faseya.pricing.type.track.title'),
        "price" : 100,
        "options" : [t('faseya.pricing.type.common.items.item_1'),t('faseya.pricing.type.track.items.item_1'),t('faseya.pricing.type.track.items.item_2'),t('faseya.pricing.type.common.items.item_2'), t('faseya.pricing.type.common.items.item_3'), t('faseya.pricing.type.common.items.item_4'),t('faseya.pricing.type.common.items.item_5'), t('faseya.pricing.type.common.items.item_6')
    ]
      },
        
    ];

    const _renderMainPacks = packs.map((item,index) => 
   
      <MDBCol key={item.id} size="12" xs="12" md="4"  className="mb-3 " >
        <MDBCard style={{ width: "100%", height:'100%' }} className="f-pack-item">
          <MDBCardBody style={{paddingBottom:'auto'}}>
            <MDBRow style={{backgroundColor:'olivedrab', margin:-21, marginBottom:20, padding:35}}>
              <MDBCol size="12" xs="12" md="12"  className="d-flex justify-content-center align-items-center " >
                <h2 style={{color:'#fff'}}> <strong style={{fontWeight:700}}>{item.name}</strong></h2>
              </MDBCol>
              {/* <MDBCol size="12" xs="12" md="12"  className="mt-3 d-flex justify-content-center align-items-center " >
                <h5 style={{color:'#fff'}}> <span style={{fontSize:25}}> {item.price} </span> <sup>€ HT</sup> / {t('faseya.per_day')}</h5>
              </MDBCol> */}
            </MDBRow>
            
            <MDBRow>
              { 
                    item.options.map((opt, subindex) =>
                      <MDBCol key={subindex} size="12" xs="12" md="12"  className="mb-3 f-spec-item" >
                          <MDBRow>
                            <MDBCol size="auto" className="d-flex align-items-center">
                              <img src={quality_icon} style={{width:10, marginRight:0}} alt="" />
                            </MDBCol>
                            <MDBCol>
                              <span className="" style={{margin:0, fontWeight:500, fontSize:18, color:'#000'}}>{opt}</span>
                            </MDBCol>
                          </MDBRow>
                      </MDBCol>
                      )
                }

            </MDBRow>

            <MDBRow>
              <MDBCol size="12" xs="12" md="12"  className="d-flex justify-content-center align-items-center " >
                <MDBBtn className="f-btn-olive"  onClick={() => this.props.history.push({pathname:'/inscription/client',state :{}}) }>{t('faseya.pricing.start')}</MDBBtn>
              </MDBCol>
            </MDBRow>
          
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

    );

    return (
      <React.Fragment>
      <div style={{padding:10, backgroundColor : 'transparent'}}>

        
     
      <MDBContainer>
        
      {this.props.intro &&
        <div style={{marginTop:50}}>

         <div style={{width:100, height:5, backgroundColor:"olivedrab", marginLeft:"auto", marginRight:"auto", marginBottom:25, borderRadius:14}}></div>

         <p style={{color:'black', fontSize:18, fontWeight:400}}>
          Faseya offres des services et des tarifs avantageux pour toutes entreprises qui on besoins de developpeur à temps plein.
          Nous avons 3 catégories de prix : </p>

          <MDBRow>
            <MDBCol size="auto" className="d-flex align-items-center">
              <img src={quality_icon} style={{width:10, marginRight:0}} alt="" />
            </MDBCol>
            <MDBCol>
              <span className="" style={{margin:0, fontWeight:700, fontSize:18, color:'#000'}}>Tarification pour un développeur junior</span>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size="auto" className="d-flex align-items-center">
              <img src={quality_icon} style={{width:10, marginRight:0}} alt="" />
            </MDBCol>
            <MDBCol>
              <span className="" style={{margin:0, fontWeight:700, fontSize:18, color:'#000'}}>Tarification pour un développeur confirmé</span>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size="auto" className="d-flex align-items-center">
              <img src={quality_icon} style={{width:10, marginRight:0}} alt="" />
            </MDBCol>
            <MDBCol>
              <span className="" style={{margin:0, fontWeight:700, fontSize:18, color:'#000'}}>Tarification pour un développeur sénior</span>
            </MDBCol>
          </MDBRow>
          
          
          <br />
          <p style={{color:'black', fontSize:18, fontWeight:400}}>
            Nous avons les prix les plus compétitifs du marché. Ainsi nous aidons les entreprises à faire <strong style={{fontWeight:700, fontSize:20}}> 80 % </strong> d’économie sur leur budget de développement. 
          </p>

        </div>
        }

        <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}>{t('faseya.pricing.title')}</h4>

        <MDBRow>

            
            <MDBCol size="12" sm="12" md="12"  className="mb-4" style={{}} >
              <MDBRow>

                {_renderMainPacks}

              </MDBRow>
            </MDBCol>            
 

        </MDBRow>
      
      
      </MDBContainer>

      </div>
 
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
          <MDBModalHeader toggle={this.toggle}></MDBModalHeader>

          <MDBModalBody>
            
            <MDBRow>
                <MDBCol size="12">
                  <div className="d-flex flex-column">
                      <div className="">
                          <span className="create-account">Créer mon compte Faseya</span> <br/>
                          <span className="already-account">
                            Déja inscrit sur Faseya ? 
                            <span className="sign-account">
                               <Link to="/se-connecter"> Connectez-vous</Link>
                            </span>
                          </span>
                      </div>
                      <div className="mt-2">
                        <span className="advice-text">(*) Les champs possédants cet indication doivent être complétés</span>
                      </div>
                  </div>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                 <MDBCol size="12">
                    <form className='mt-4' onSubmit={(e) => this.handleSubmit(e)} >
                        
                        <div className="mb-3">
                          <input  
                            name="societe"
                            value={this.state.societe}
                            onChange={(e) => this.onChangeHandle(e)}
                            className="input-register-modal" 
                            placeholder="Société / ou personne physique"
                          />
                        </div>
                       
                        <div className="mb-3">
                          <input  
                            name="nom"
                            value={this.state.nom}
                            onChange={(e) => this.onChangeHandle(e)}
                            className="input-register-modal" 
                            placeholder="Nom (*)"
                          />
                        </div>

                        <div className="mb-3">
                          <input  
                            name="prenom"
                            value={this.state.prenom}
                            onChange={(e) => this.onChangeHandle(e)}
                            className="input-register-modal" 
                            placeholder="Prenom (*)"
                          />
                        </div>

                        <div className="mb-3">
                          <input  
                            name="username"
                            value={this.state.username}
                            onChange={(e) => this.onChangeHandle(e)}
                            className="input-register-modal" 
                            placeholder="Username (*)"
                          />
                        </div>

                        <div className="mb-3">
                         
                          <PhoneInput
                            country={'fr'}
                            inputClass="input-phone-register-modal"
                            value={this.state.telephone}
                            onChange={(telephone) => this.setState({telephone})}
                            isValid={(inputNumber, country, countries) => {
                              return countries.some((country) => {
                                return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                              });
                            }}
                          />
                          {this.state.phoneError && (
                            <small className="ml-1 text-error">
                              Veuillez mentionné l'indicatif du pays ou verifiez que votre entrez est valide
                            </small>
                          )}
                        </div>

                        <div className="mb-3">
                          <input  
                            name="adresse"
                            value={this.state.adresse}
                            onChange={(e) => this.onChangeHandle(e)}
                            className="input-register-modal" 
                            placeholder="Adresse entreprise (*)"
                          />
                        </div>

                        <div className="mb-3">
                          <input  
                            name="ville"
                            value={this.state.ville}
                            onChange={(e) => this.onChangeHandle(e)}
                            className="input-register-modal" 
                            placeholder="Ville (*)"
                          />
                        </div>

                        <div className="mb-3">
                          <input  
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={(e) => this.onChangeHandle(e)}
                            className="input-register-modal" 
                            placeholder="Adresse email (*)"
                          />
                        </div>

                        <div className="mb-3">
                          <input  
                            name="immatriculation"
                            value={this.state.immatriculation}
                            onChange={(e) => this.onChangeHandle(e)}
                            className="input-register-modal-optional" 
                            placeholder="Immatriculation RCS (facultatif)"
                          />
                        </div>

                        <div className="mb-3">
                          <input  
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={(e) => this.onChangeHandle(e)}
                            className="input-register-modal" 
                            placeholder="Mot de passe (*)"
                            ref={this.passwordRef}
                          />
                          {this.state.passwordError && (
                            <small className="ml-1 text-error">
                              Saisissez un mot de passe d'au moins 8 caractères contenant des 
                              caractères alphanumeriques,majuscules,miniscules,et speciaux
                            </small>
                          )}
                        </div>

                        <div className="mb-3">
                          <input  
                            type="password"
                            name="confirm"
                            value={this.state.confirm}
                            onChange={(e) => this.onChangeHandle(e)}
                            className="input-register-modal" 
                            placeholder="Confirmation de mot de passe (*)"
                            ref={this.confirmRef}
                          />
                          {this.state.confirmError && (
                            <small className="ml-1 text-error">
                              Saisissez un mot de passe d'au moins 8 caractères contenant
                              des caractères alphanumeriques,majuscules,miniscules,et speciaux
                            </small>
                          )}
                        </div>

                        <div className="d-flex justify-content-end mb-3 ">
                          <MDBBtn type="submit"  className="btn-color" style={{borderRadius: '20px'}}>
                                        S'inscrire
                          </MDBBtn>
                        </div>
                    

                    </form>
                 </MDBCol>
             </MDBRow>
          </MDBModalBody>
          
        </MDBModal>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default withTranslation()(PacksComponent);
