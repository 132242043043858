  import en from './en/common.json';
  import fr from './fr/common.json';
  var localization = 
  {
    en: {
      translation: en
    },
    fr: {
      translation: fr
    }
  }
  
export default localization;