import React, {Fragment}  from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBFormInline,
  MDBBtn,
  MDBView,
  MDBInput,
  MDBProgress,
  MDBContainer
} from 'mdbreact';
import './ConceptPage.css';

import {Trans} from 'react-i18next'

import PartnersComponent from '../../components/PartnersComponent/PartnersComponent';
import ParticularityComponent from '../../components/ParticularityComponent/ParticularityComponent';
import ServicesComponent from '../../components/ServicesComponent/ServicesComponent';
import ExpertsComponent from '../../components/ExpertsComponent/ExpertsComponent';
import ParcoursComponent from '../../components/ParcoursComponent/ParcoursComponent';
import PacksComponent from '../../components/PacksComponent/PacksComponent';
import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import TestomiesComponent from '../../components/TestimoniesComponent/TestimoniesComponent';


import concept_image from '../../assets/images/concept_image.png';
import concept_image2 from '../../assets/images/concept_image2.png';
import CommitmentsComponent from '../../components/CommitmentsComponent/CommitmentsComponent';
import ValuesComponent from '../../components/ValuesComponent/ValuesComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import { withTranslation } from 'react-i18next';



class ConceptPage extends React.Component {
  state = {
    collapsed: false
  };

  handleTogglerClick = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });
  };

  componentDidMount() {
    document.querySelector('nav').style.height = '65px';
    
    window.scrollTo(0, 0);
  
  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: '0rem' };
    const {t} = this.props;
    // const overlay = (
    //   <div
    //     id='sidenav-overlay'
    //     style={{ backgroundColor: 'transparent' }}
    //     onClick={this.handleTogglerClick}
    //   />
    // );
    return (

      <>
      <HeaderComponent  history={this.props.history} />

      <div id='minimalistintro'>
        <MDBView >
          <MDBContainer className="f-no-padding">
            <MDBRow className="mt-4">
             
              <MDBCol xs="12" md="5"  className='mb-4 '>
                
                <h3 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder', margin:0, marginBottom:20}}> {t('faseya.the_concepts.title')} </h3>
                <p style={{fontWeight:500, color:'#000'}}>
                  {t('faseya.the_concepts.description')}
                </p>
             


                  <MDBRow>
                    <MDBCol size="12" xs="12" md="6"  className="mb-4 text-center" >
                      <MDBBtn onClick={()=>this.props.history.push({pathname: `blog`, state:{article : 1}})}
                        outline color='white' style={{width:'100%', fontSize:15, fontWeight:500}}  className="btn btn-success-gradiant z-depth-1 font-14 f-btn-fullwhite">
                          {t('faseya.the_concepts.category.item_1')}
                        </MDBBtn>
                    </MDBCol>
                    
                    <MDBCol size="12" xs="12" md="6"  className="mb-4 text-center" >
                      <MDBBtn onClick={()=>this.props.history.push({pathname: `blog`, state:{article : 2}})}
                       outline color='white' style={{width:'100%', fontSize:15, fontWeight:500}}  className="btn btn-success-gradiant z-depth-1 font-14 f-btn-fullwhite">
                          {t('faseya.the_concepts.category.item_2')}
                      </MDBBtn>
                    </MDBCol>
                    
                    <MDBCol size="12" xs="12" md="6"  className="mb-4 text-center" >
                      <MDBBtn onClick={()=>this.props.history.push({pathname: `blog`, state:{article : 3}})}
                       outline color='white' style={{width:'100%', fontSize:15, fontWeight:500}}  className="btn btn-success-gradiant z-depth-1 font-14 f-btn-fullwhite">
                          {t('faseya.the_concepts.category.item_3')}
                      </MDBBtn>
                    </MDBCol>
                    
                    <MDBCol size="12" xs="12" md="6"  className="mb-4 text-center" >
                      <MDBBtn onClick={()=>this.props.history.push({pathname: `blog`, state:{article : 1}})}
                       outline color='white' style={{width:'100%', fontSize:15, fontWeight:500}}  className="btn btn-success-gradiant z-depth-1 font-14 f-btn-fullwhite">
                          {t('faseya.the_concepts.category.item_4')}
                      </MDBBtn>
                    </MDBCol>
                    
                    <MDBCol size="12" xs="12" md="6"  className="mb-4 text-center" >
                      <MDBBtn onClick={()=>this.props.history.push({pathname: `blog`, state:{article : 2}})}
                       outline color='white' style={{width:'100%', fontSize:15, fontWeight:500}}  className="btn btn-success-gradiant z-depth-1 font-14 f-btn-fullwhite">
                          {t('faseya.the_concepts.category.item_5')}
                      </MDBBtn>
                    </MDBCol>
                    
                    
                  </MDBRow>

              </MDBCol>
               <MDBCol xs="12" md="7"  className='mb-4 white-text'>
                
                <img src={concept_image} className="img-fluid" style={{borderRadius:8, width:'100%', height:'auto', objectFit:'cover'}} alt="l’union des compétences donne l’excellence dans le travail." />
             
              </MDBCol>


            </MDBRow>

            {/* ------------------- */}

            <MDBRow style={{marginTop:100}}>
            
              <MDBCol xs="12" md="7"  className='mb-4 white-text'>
               
               <img src={concept_image2} className="img-fluid" style={{borderRadius:8, width:'100%', height:'auto', objectFit:'cover'}} alt="agilité, projet sur mesure, Méthode agile." />
            
             </MDBCol>             
             <MDBCol xs="12" md="5"  className='mb-4 d-flex align-items-center'>
               
               <div>
               <h3 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder', margin:0, marginBottom:20}}> {t('faseya.the_concepts.flexibility')} </h3>
               <p style={{fontWeight:500, color:'#000'}}>
                {t('faseya.the_concepts.flexibility_description')}
                </p>
               </div>

             </MDBCol>



           </MDBRow>



            {/* ------------------------ */}



          </MDBContainer >

        </MDBView>

        
    



      </div>

      
        <MDBRow style={{marginTop:100, backgroundColor:'#f3f3f3', paddingTop:50, paddingBottom:50}}>
          <MDBContainer className="f-no-padding">
                       
              <MDBCol xs="12" md="12"  className='mb-4'>
                
                <h2 style={{fontWeight:700, margin:0, marginBottom:20}}>{t('faseya.the_concepts.price_title')}</h2>
                <p style={{fontWeight:500, color:'#000', fontSize:20}}>
                  {t('faseya.the_concepts.percent1')} <span  style={{color:'olivedrab', fontWeight:600, fontSize:24}}>80%</span> {t('faseya.the_concepts.percent2')}
                </p>

              </MDBCol>

              <MDBCol  xs="12" md="8"  >
                <p style={{fontWeight:400, color:'#000', fontSize:20}}>
                  {t('faseya.the_concepts.cost_of_freelance')}
                 </p>
                <MDBProgress value={100} className="my-2 f-progress" barClassName="f-progress-bar-1" />
              </MDBCol>

              <MDBCol  xs="12" md="8"  >
                <p style={{fontWeight:400, color:'#000', fontSize:20}}>
                  {t('faseya.the_concepts.cost_of_faseya_service')}
                 </p>
                <MDBProgress value={30} className="my-2 f-progress" barClassName="f-progress-bar-2" />
              </MDBCol>


              <MDBCol xs="12" md="10"  className='mb-4'>
                
                <h2 style={{fontWeight:700, margin:0, marginBottom:20}}>{t('faseya.the_concepts.without_commitment')}</h2>
                <p style={{fontWeight:400, color:'#000', fontSize:20}}>
                  {t('faseya.the_concepts.agility_of_heart')}
                </p>

              </MDBCol>
            
            </MDBContainer >

          </MDBRow>

        <PrefooterComponent />
      </>

    );
  }
}

export default withTranslation()(ConceptPage);