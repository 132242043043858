import apiUrl from "../apiUrl";
import axios from "axios";

/**
 * apiClientBuilder function
 *
 * @param {string} baseURL
 * @returns {{
 *   getData: {
 *     (
 *       pathReq: string, queryParams?: JSON, headers?: JSON
 *     ): Promise<any>
 *   };
 *   postData: {
 *     (
 *       pathReq: string, body?: JSON, queryParams?: JSON, headers?: JSON
 *     ): Promise<any>
 *   };
 *   putData: {
 *     (
 *       pathReq: string, body?: JSON, queryParams?: JSON, headers?: JSON
 *     ): Promise<any>
 *   };
 *   deleteData: {
 *     (
 *       pathReq: string, queryParams?: JSON, headers?: JSON
 *     ): Promise<any>
 *   };
 *   uploadFiles: {
 *     (
 *       pathReq: string, formData: FormData, queryParams?: JSON, headers?: JSON
 *     ): Promise<any>
 *   };
 * }}
 */
const apiClientBuilder = (baseURL) => {
  const api = axios.create({ baseURL });

  const getBaseHeaders = () => {
    return {
      authorization:
        localStorage.getItem("authToken") !== null
          ? localStorage.getItem("authToken")
          : "no-token",
      "content-type": "application/json",
    };
  };

  return {
    getData(pathReq, queryParams = undefined, headers = undefined) {
      return new Promise((resolve, reject) => {
        api
          .get(pathReq, {
            params: !queryParams ? {} : queryParams,
            headers: { ...getBaseHeaders(), ...(!headers ? {} : headers) },
          })
          .then(
            (response) => {
              if (response.data.failure) {
                reject(response.data);
                return;
              }
              if (response.data.error) {
                reject(response.data.error);
                return;
              }
              resolve(response.data.result);
            },
            (error) => reject(error)
          )
          .catch((err) => reject(err));
      });
    },

    postData(
      pathReq,
      body = undefined,
      queryParams = undefined,
      headers = undefined
    ) {
      return new Promise((resolve, reject) => {
        api
          .post(pathReq, !body ? {} : body, {
            params: !queryParams ? {} : queryParams,
            headers: { ...getBaseHeaders(), ...(!headers ? {} : headers) },
          })
          .then(
            (response) => {
              if (response.data.failure) {
                reject(response.data);
                return;
              }
              if (response.data.error) {
                reject(response.data.error);
                return;
              }
              resolve(response.data.result);
            },
            (error) => reject(error)
          )
          .catch((err) => reject(err));
      });
    },

    putData(
      pathReq,
      body = undefined,
      queryParams = undefined,
      headers = undefined
    ) {
      return new Promise((resolve, reject) => {
        api
          .put(pathReq, !body ? {} : body, {
            params: !queryParams ? {} : queryParams,
            headers: { ...getBaseHeaders(), ...(!headers ? {} : headers) },
          })
          .then(
            (response) => {
              if (response.data.failure) {
                reject(response.data);
                return;
              }
              if (response.data.error) {
                reject(response.data.error);
                return;
              }
              resolve(response.data.result);
            },
            (error) => reject(error)
          )
          .catch((err) => reject(err));
      });
    },

    deleteData(pathReq, queryParams = undefined, headers = undefined) {
      return new Promise((resolve, reject) => {
        api
          .delete(pathReq, {
            params: !queryParams ? {} : queryParams,
            headers: { ...getBaseHeaders(), ...(!headers ? {} : headers) },
          })
          .then(
            (response) => {
              if (response.data.failure) {
                reject(response.data);
                return;
              }
              if (response.data.error) {
                reject(response.data.error);
                return;
              }
              resolve(response.data.result);
            },
            (error) => reject(error)
          )
          .catch((err) => reject(err));
      });
    },
    
    uploadFiles(
      pathReq,
      formData,
      queryParams = undefined,
      headers = undefined
    ) {
      return new Promise((resolve, reject) => {
        api
          .post(pathReq, formData, {
            params: !queryParams ? {} : queryParams,
            headers: {
              ...getBaseHeaders(),
              "content-type": "multipart/form-data",
              ...(!headers ? {} : headers),
            },
          })
          .then(
            (response) => {
              if (response.data.failure) {
                reject(response.data);
                return;
              }
              if (response.data.error) {
                reject(response.data.error);
                return;
              }
              resolve(response.data.result);
            },
            (error) => reject(error)
          )
          .catch((err) => reject(err));
      });
    },
  };
};

export const api = apiClientBuilder(apiUrl);
