import React, {Fragment}  from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBFormInline,
  MDBBtn,
  MDBView,
  MDBInput,
  MDBContainer,
  MDBAutocomplete,
  MDBModal, 
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact';
import './BusinessCardsPage.css';

import banner_faseya from '../../assets/images/banner_faseya.jpg';
import PartnersComponent from '../../components/PartnersComponent/PartnersComponent';
import ParticularityComponent from '../../components/ParticularityComponent/ParticularityComponent';
import ServicesComponent from '../../components/ServicesComponent/ServicesComponent';
import ExpertsComponent from '../../components/ExpertsComponent/ExpertsComponent';
import HowComponent from '../../components/HowComponent/HowComponent';
import ParcoursComponent from '../../components/ParcoursComponent/ParcoursComponent';
import PacksComponent from '../../components/PacksComponent/PacksComponent';
import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import TestomiesComponent from '../../components/TestimoniesComponent/TestimoniesComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import { withTranslation } from 'react-i18next';
import skillService from '../../services/skill.service';
import auth from '../../services/auth';
import Partners2Component from '../../components/Partners2Component/Partners2Component';
import businessCards from '../../services/mocks/businesscards';


class BusinessCardsPage extends React.Component {
  state = {
    collapsed: false,
    searchKeyword : '',
    techs : [],
    user: {},
    showNotif : false,
    businessCards : businessCards,
    focused : businessCards[0]
  };

  handleTogglerClick = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });
  };

  componentDidMount() {

    window.scrollTo(0, 0);

    document.querySelector('nav').style.height = '65px';

    var user = JSON.parse(localStorage.getItem("user")) || {id : null}
    this.setState({user : user});

    // this.getSkills();
  }

  getSkills = () =>
    {
        skillService.getSkills().then(data=>{
            var techs = [];

            data.forEach(el => {
              techs.push(el.name)
            });
            this.setState({techs : techs})
         })
         .catch(err=>{
      
         })
    }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  onHandleClik = (name, fullName, id) => {
    this.props.history.push({pathname: `services/${name}`, state:{full_name: fullName, id:id}});
  }

  breakString = (str, limit) => {

    let brokenString = '';

    for(let i = 0, count = 0; i < str.length; i++){
      if(count >= limit && str[i] === ' '){

              count = 0;
              brokenString += '\n' ;
      }else{
              count++;
              brokenString += str[i];
            }
      }
    //console.log(brokenString)
    return brokenString;
}

  getSearchKeyword = (value) => {
    this.setState({searchKeyword : value})
  }

  gotoConsultants = () => {
    // if(this.state.searchKeyword.trim() == "") return;
    var s = "all";
    if(this.state.searchKeyword.trim() != "") s = this.state.searchKeyword;

    this.props.history.push({pathname: `consultants/${s}`, state:{reload : true}});
  }

  gotoPostProject = () => {
    
    var path =/*  auth.isAuthenticated() ?  */"/poster-un-projet"/* :"/se-connecter" */;

    // if(auth.isAuthenticated())
    // {
      this.props.history.push({pathname: path});
    // }
    // else
    // {
    //   this.setState({showNotif : true});

    //   setTimeout(() => {
    //     this.setState({showNotif : false});
    //     this.props.history.push({pathname: path});
    //   }, 4000);
    // }
  }

  gotoRequestDemo = () => {
    
    // var path = auth.isAuthenticated() ? "/demander-une-demo":"/se-connecter";
    var path = "/demander-une-demo";

    // if(auth.isAuthenticated())
    // {
      this.props.history.push({pathname: path});
    // }
    // else
    // {
    //   this.setState({showNotif : true});

    //   setTimeout(() => {
    //     this.setState({showNotif : false});
    //     this.props.history.push({pathname: path});
    //   }, 4000);
    // }

  }  

  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: '0rem' };

    const {t} = this.props;

    // const overlay = (
    //   <div
    //     id='sidenav-overlay'
    //     style={{ backgroundColor: 'transparent' }}
    //     onClick={this.handleTogglerClick}
    //   />
    // );
    return (

      <>
      
      <HeaderComponent history={this.props.history} />


      <MDBContainer>

        <div style={{padding:10, backgroundColor : 'transparent'}}>
    
            <MDBRow style={{marginTop:10}}>
              <MDBCol>
                <h1 className="title f-title" style={{color:'olivedrab'}}>
                  Découvrir les fiches métiers <br/> de nos experts
                </h1>
                </MDBCol>
            </MDBRow>

            <MDBRow style={{marginTop:60}}>
                
                <MDBCol xs="12" md="7"  className="mb-1" >
                  <img src={this.state.focused.image} className="img-fluid" style={{width:'100%', borderRadius:20}} alt="" />
                  <h2 style={{position:"absolute", borderTopLeftRadius:10, padding:10, borderBottomRightRadius:10 ,fontWeight:"bolder", top:-20, left:40, paddingRight:20,  paddingLeft:20, backgroundColor:"olivedrab", color:"white"}}>
                  ★ Métier du jour</h2>
                </MDBCol>            

                <MDBCol size="12" sm="12" md="5"  className="mb-1 d-flex mb-1 f-how-item align-items-center"  >

                  <MDBContainer>
                    <MDBRow>

                      <MDBCol size="12" xs="12" md="12"  className=" mb-1 f-how-item " >
                          <h2 className="f-title" style={{color:'#000'}}>{this.state.focused.name}</h2>
                      </MDBCol>

                      <MDBCol size="12" xs="12" md="12"  className=" mb-1 f-how-item " >
                          <p style={{color:"black", fontWeight:'bolder'}}>Malgré son âge et son héritage, Java reste l'un des langages de programmation les plus populaires à ce jour. Selon un rapport Statista de 2021 , près de 65 % des développeurs de logiciels dans le monde utilisent Java comme langage de programmation . Pas étonnant que les développeurs Java soient très demandés... </p>
                      </MDBCol>
                      
                      <MDBCol size="12" xs="12" md="12"  className="mt-4 " >
                        <MDBBtn className="f-btn-olive" style={{paddingLeft:15, paddingRight:15, fontSize:20}} onClick={()=>{ localStorage.setItem("fiche-metier", JSON.stringify(this.state.focused)); this.props.history.push({pathname: `fiche-metier`, state:{metier :this.state.focused}})}} >Découvrir le métier</MDBBtn>
                      </MDBCol>


                    </MDBRow>
                  </MDBContainer>
                </MDBCol>            


            </MDBRow>

          </div>
      </MDBContainer>
        


      <MDBContainer  style={{marginTop:100, marginBottom:100}}>
        
        <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}> Toutes les fiches métiers </h4>

        <MDBRow style={{position:'relative'}}>
          
          {/* {this.state.loading &&
              <div style={{position:'absolute', top:0, left:0, backgroundColor:'#678b2d8a', width:'100%', height:'100%', zIndex:3}}>
                  <img src={olive_loader} style={{position:'absolute', zIndex:1, top:'45%', left:'47.5%', width:40}} alt="" />
              </div>
          } */}

          {this.state.businessCards.map((b, index) =>
            <MDBCol key={index} xs="12" sm="12" md="4"  className="mb-2"  onClick={()=>{ 
              if(b.active)
              {
                localStorage.setItem("fiche-metier", JSON.stringify(b)); window.open("/fiches-metiers-tech/"+b.url);
              }
              }} >
              <MDBCol size="12"  className="mb-4 f-bc-item" style={{backgroundImage:'url('+b.image+')'}} >
                <h6 className="f-bc-item-header" style={{paddingLeft: '0', paddingRight: '0'}}>{b.name}</h6>
              </MDBCol> 
            </MDBCol>
            ) 
          }

        </MDBRow>
            
      </MDBContainer>

        <PrefooterComponent />


        <MDBModal isOpen={this.state.showNotif} toggle={this.showNotif}  size="md" centered modalStyle='warning'>
          <MDBModalHeader ><span style={{fontWeight:500}}>Notification</span></MDBModalHeader>

              <MDBModalBody>
                  <MDBRow>
                      <MDBCol size="12">
                          <p style={{color:'black', fontWeight:500}}> {t('faseya.must_be_logged')} </p>
                      </MDBCol>
                  </MDBRow>
              </MDBModalBody>  
          </MDBModal>

      </>

    );
  }
}

export default withTranslation()(BusinessCardsPage);