import bc_1 from "../../assets/images/bcs/java-dev.jpg";
import bc_2 from "../../assets/images/bcs/bc_2.jpg";
import design from "../../assets/images/bcs/design.jpg";
import dev from "../../assets/images/bcs/dev.jpg";
import android from "../../assets/images/bcs/android.jpg";
import website from "../../assets/images/bcs/website.jpg";
import mobile_dev from "../../assets/images/bcs/ios-dev.jpg";
import seo from "../../assets/images/bcs/seo.jpg";
import community_manager from "../../assets/images/bcs/community-manager.jpg";
import ios_dev from "../../assets/images/bcs/ios-dev.jpg";
import product_manager from "../../assets/images/bcs/product-manager.jpg";
import testeur from "../../assets/images/bcs/testeur.jpg";
import bc_3 from "../../assets/images/bcs/developper.jpg";
import bc_4 from "../../assets/images/bcs/bc_4.jpg";
import bc_5 from "../../assets/images/bcs/dev-two.jpg";
import scrum from "../../assets/images/bcs/scrum-master.jpg";
import project from "../../assets/images/bcs/project-manager.jpg";
import bc_6 from "../../assets/images/bcs/bc_6.jpg";
import bc_7 from "../../assets/images/bcs/bc_7.jpg";

var businessCards = 
  
[
    {
        name: "Développeur JAVA", 
        url:'tout-savoir-sur-le-metier-de-developpeur-java',
        title:`Description du poste de développeur Java : Rôle et responsabilités`,
        description: `Explorez des concepts clés tels que la description du métier de développeur Java✅ les missions et compétences du développeur Java ✅les entreprises où travail le développeur Java de Faseya✅ Comment Faseya recrute ses développeurs Java✅ Vous souhaitez recruter un développeur Android ?`,
        image: bc_1, active:true,
    content:`
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">Malgr&eacute; son &acirc;ge et son h&eacute;ritage, Java reste l'un des langages de programmation les plus populaires &agrave; ce jour.&nbsp;Selon un&nbsp;</span><span style="color: black;"><a style="color: blue !important" style="color:blue !important;" class="external" title="Rapport statistique" href="https://www.statista.com/statistics/793628/worldwide-developer-survey-most-used-languages/" target="_blank" rel="noopener"><span style="">rapport Statista</span></a></span><span style=" color: #51565e;">&nbsp;de 2021 , pr&egrave;s de 65 % des&nbsp;</span><span style=" color: black;">d&eacute;veloppeurs de logiciels</span><span style=" color: #51565e;">&nbsp;dans le monde utilisent&nbsp;</span><span style=" color: black;">Java</span><span style=" color: #51565e;">&nbsp;comme&nbsp;</span><span style=" color: black;">langage de programmation</span><span style=" color: #51565e;">&nbsp;.&nbsp;Pas &eacute;tonnant que&nbsp;</span><span style="color: black;"><a style="color: blue !important" style="color:blue !important;" class="external" title="D&eacute;veloppeurs Java" href="https://faseya.com/#/consultants/developer" target="_blank" rel="noopener"><span style="">les d&eacute;veloppeurs Java</span></a></span><span style=" color: #51565e;">&nbsp;soient tr&egrave;s demand&eacute;s&nbsp;!&nbsp;Si vous &ecirc;tes passionn&eacute; par la technologie et que vous souhaitez faire carri&egrave;re en tant que d&eacute;veloppeur Java, ne cherchez pas plus loin.&nbsp;Dans cet article de blog, nous vous donnerons une description&nbsp;</span><span style=" color: black;">compl&egrave;te du poste de d&eacute;veloppeur Java</span><span style=" color: #51565e;">&nbsp;et tout ce que vous devez savoir pour devenir un expert Java professionnel.&nbsp;</span></p>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">D&eacute;velopp&eacute; pour la premi&egrave;re fois en 1995 par Sun Microsystems, Java est un langage de programmation concurrent, bas&eacute; sur les classes et orient&eacute; objet, bas&eacute; sur la syntaxe de&nbsp;</span><span style=" color: black;">C et C++.&nbsp;</span><span style=" color: #51565e;">Java est rapide, s&eacute;curis&eacute; et extr&ecirc;mement polyvalent.&nbsp;Ainsi, il est pr&eacute;f&eacute;rable de d&eacute;velopper des applications telles que la conception de logiciels, la technologie de jeu et les applications Web et mobiles.&nbsp;Java est un excellent choix pour les applications multiplateformes telles que les applications Web, les applications JAVA, les applications cloud, les environnements d'apprentissage automatique et l&rsquo;Internet</span><span style=" color: black;"> des objets.</span></p>
        <h2 style="margin: 0cm 0cm 8"><span style="">Qu&rsquo;est-ce qu&rsquo;un D&eacute;veloppeur Java ? </span></h2>
        <p style="margin: 0cm 0cm 6.5px; background: white;"><span style=" color: #51565e;">Un d&eacute;veloppeur Java est un&nbsp;</span><span style=" color: black;">programmeur</span><span style=" color: #51565e;">&nbsp;qui con&ccedil;oit, d&eacute;veloppe et g&egrave;re des applications et des logiciels bas&eacute;s sur Java.&nbsp;La plupart des grandes organisations utilisant&nbsp;</span><span style=" color: black;">Java</span><span style=" color: #51565e;">&nbsp;pour impl&eacute;menter des syst&egrave;mes logiciels et des services backend, un d&eacute;veloppeur Java est l'un des emplois les plus recherch&eacute;s aujourd'hui.</span></p>
        <p style="margin: 0cm 0cm 6.5px; background: white;"><span style=" color: #51565e;">&nbsp;</span></p>
        <h3 style="margin: 0cm 0cm 8"><span style="">Quels sont les missions d&rsquo;un d&eacute;veloppeur JAVA&nbsp;?</span><span style=""> </span></h3>
        <p style="margin: 0cm 0cm 6.5px; background: white;"><span class="external"><span style="">&nbsp;</span></span></p>
        <h2>R&eacute;partition d&eacute;taill&eacute;e des fonctions de description de poste de d&eacute;veloppeur Java</h2>
        <p style="margin: 0cm 0cm 10.5 background: white;"><span class="external"><span style="">&nbsp;</span></span></p>
        <h3 style="margin: 24pt 0cm 18 background: white; break-after: avoid; color: rgb(31, 55, 99); font-weight: normal;"><strong><span style=" font-weight:bolder">Architecture du programme&nbsp;</span></strong></h3>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">Une fonction cl&eacute; que les d&eacute;veloppeurs Java fournissent pendant le d&eacute;veloppement du projet</span></p>
        <h3 style="margin: 24pt 0cm 18 background: white; break-after: avoid; color: rgb(31, 55, 99); font-weight: normal;"><strong><span style=" font-weight:bolder">Recueil des exigences</span></strong></h3>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">La premi&egrave;re &eacute;tape pour travailler sur un projet consiste &agrave; recueillir les exigences de toutes les personnes impliqu&eacute;es, &agrave; hi&eacute;rarchiser les t&acirc;ches importantes, &agrave; d&eacute;terminer l'&eacute;tendue du travail dans son ensemble et le plan d'action.&nbsp;</span></p>
        <h3 style="margin: 24pt 0cm 18 background: white; break-after: avoid; color: rgb(31, 55, 99); font-weight: normal;"><strong><span style=" font-weight:bolder">D&eacute;veloppement&nbsp;</span></strong></h3>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">Dans cette phase, le d&eacute;veloppeur Java &eacute;crit le code du programme, le teste et apporte des modifications si n&eacute;cessaires&nbsp;</span></p>
        <h3 style="margin: 24pt 0cm 18 background: white; break-after: avoid; color: rgb(31, 55, 99); font-weight: normal;"><strong><span style=" font-weight:bolder">Essai&nbsp;</span></strong></h3>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">Cette t&acirc;che implique de tester et de d&eacute;boguer chaque fonctionnalit&eacute; une fois qu'elle est termin&eacute;e.</span></p>
        <h3 style="margin: 24pt 0cm 18 background: white; break-after: avoid; color: rgb(31, 55, 99); font-weight: normal;"><strong><span style=" font-weight:bolder">D&eacute;ploiement</span></strong></h3>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">Dans cette phase, le code enti&egrave;rement fonctionnel est transf&eacute;r&eacute; dans un environnement en direct</span></p>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">Les autres responsabilit&eacute;s du d&eacute;veloppeur Java incluent la maintenance et l'optimisation des logiciels, la gestion de projet, la direction et la liaison, et la gestion des fournisseurs.&nbsp;</span></p>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">&nbsp;</span></p>
        <h3 style="margin: 0cm 0cm 8"><span style="">Quel sont les comp&eacute;tences d&rsquo;un d&eacute;veloppeur JAVA&nbsp;?</span></h3>
        <p style="margin: 0cm 0cm 8"><span style=" color: #51565e;">Les d&eacute;veloppeurs Java ont besoin d'un large &eacute;ventail de comp&eacute;tences, allant d'une connaissance approfondie des bases &agrave; une compr&eacute;hension approfondie des d&eacute;veloppements actuels.&nbsp;Les experts en Java doivent &eacute;galement savoir comment fonctionne le processus de d&eacute;veloppement et comment effectuer la transition entre les environnements dans lesquels le code s'ex&eacute;cute.&nbsp;&nbsp;</span></p>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">Un d&eacute;veloppeur Java complet ma&icirc;trise les comp&eacute;tences de d&eacute;veloppeur Java full stack et les comp&eacute;tences de d&eacute;veloppeur&nbsp;</span><span style=" color: black;">Java backend.</span></p>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">Certaines des comp&eacute;tences cl&eacute;s du d&eacute;veloppeur Java incluent&nbsp;:</span></p>
        <ul style="margin-bottom: 10.5 margin-top: 0px;">
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style="color: black;"><a style="color: blue !important" style="color:blue !important;" class="external" href="https://subscription.packtpub.com/book/application-development/9781789801736/1/ch01lvl1sec03/the-java-ecosystem">Ma&icirc;trise de Java</a></span><span style=" color: #51565e;">, avec une bonne compr&eacute;hension de ses &eacute;cosyst&egrave;mes</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Bonne connaissance des&nbsp;mod&egrave;les et concepts de&nbsp;</span><span style=" color: black;">programmation orient&eacute;e objet (POO)</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Connaissance des diff&eacute;rents mod&egrave;les de conception et d'architecture</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Comp&eacute;tence pour &eacute;crire des biblioth&egrave;ques Java r&eacute;utilisables&nbsp;</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Connaissance des mod&egrave;les de concurrence Java</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Compr&eacute;hension de base des concepts de mod&egrave;le&nbsp;</span><span style=" color: black;">MVC (</span><span style=" color: #51565e;">&nbsp;Model-View-Controller), JDBC (Java Database Connectivity) et des services Web RESTful</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Exp&eacute;rience de travail avec des frameworks d'applications Web populaires comme Play et Spark</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Connaissance pertinente des frameworks Java GUI comme Swing, SWT, AWT selon les exigences du projet</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Capacit&eacute; &agrave; &eacute;crire du code Java propre et lisible</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Connaissance de base du m&eacute;canisme de chargement de classe en Java</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Exp&eacute;rience dans la gestion de bases de donn&eacute;es externes et embarqu&eacute;es</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Comprendre les principes de conception de base derri&egrave;re une application &eacute;volutive</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Habilet&eacute; &agrave; cr&eacute;er des sch&eacute;mas de base de donn&eacute;es qui caract&eacute;risent et soutiennent les processus d'affaires</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Connaissance de base de&nbsp;</span><span style=" color: black;">JVM (Java Virtual Machine)</span><span style=" color: #51565e;">&nbsp;, ses inconv&eacute;nients, ses faiblesses et ses solutions de contournement</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Mise en place de plateformes de tests automatis&eacute;s et de tests unitaires&nbsp;</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Connaissance approfondie des outils de gestion des versions de code, par exemple, Git</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Compr&eacute;hension des outils de construction comme Ant, Maven, Gradle, etc.</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Expertise en int&eacute;gration continue</span></li>
        </ul>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">Les autres comp&eacute;tences requises du d&eacute;veloppeur Java incluent les connaissances de base de&nbsp;:</span></p>
        <ul style="margin-bottom: 10.5 margin-top: 0px;">
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Pages JavaServer (JSP) et servlets</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Frameworks Web comme Struts et Spring</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Architecture orient&eacute;e services&nbsp;</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Technologies Web telles que&nbsp;</span><span style=" color: black;">HTML</span><span style=" color: #51565e;">&nbsp;,&nbsp;</span><span style=" color: black;">JavaScript</span><span style=" color: #51565e;">&nbsp;,&nbsp;</span><span style=" color: black;">CSS</span><span style=" color: #51565e;">&nbsp;,&nbsp;</span><span style=" color: black;">JQuery</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Langages de balisage tels que&nbsp;</span><span style=" color: black;">XML</span><span style=" color: #51565e;">&nbsp;, JSON</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Classes abstraites et interfaces</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Constructeurs, listes, cartes, ensembles</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">File IO et s&eacute;rialisation</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Exceptions</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">G&eacute;n&eacute;riques</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Mots-cl&eacute;s Java tels que statique, volatil, synchronis&eacute;, transitoire, etc.</span></li>
        <li style="margin: 0cm 0cm 10.5pt 0px; line-height: normal; background: white; text-indent: 0.4px;"><span style=" color: #51565e;">Multithreading et synchronisation</span></li>
        </ul>
        <p style="margin: 0cm 0cm 8pt 36"><span style="">&nbsp;</span></p>
        <h3 style="margin: 0cm 0cm 8"><span style="">Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre d&eacute;veloppeur JAVA&nbsp;?</span></h3>
        <p style="margin: 0cm 0cm 8"><span style="">Les professionnels qualifi&eacute;s de divers milieux peuvent d&eacute;velopper un ensemble de comp&eacute;tences de d&eacute;veloppeur Java pour devenir des d&eacute;veloppeurs Java r&eacute;ussis. Plusieurs employeurs pr&eacute;f&egrave;rent une licence ou un </span><a style="color: blue !important" style="color:blue !important;" class="external" href="https://www.onisep.fr/Ressources/Univers-Formation/Formations/Post-bac/master-mention-informatique">master en informatique</a><span style="">, en g&eacute;nie informatique ou dans un domaine connexe. Une exp&eacute;rience pratique du d&eacute;veloppement de logiciels, en particulier en Java, augmentera vos chances d'obtenir un poste de d&eacute;veloppeur Java bien pay&eacute;.</span></p>
        <p style="margin: 0cm 0cm 8"><span style="">&nbsp;</span></p>
        <h2 >Quel est le domaine d&rsquo;intervention de Faseya dans le d&eacute;veloppement Java&nbsp;? </h2>
        <p style="margin: 0cm 0cm 6.5px; background: white;"><span style=" color: #51565e;">&nbsp;</span></p>
        <h3 style="margin: 0cm 0cm 4"><span style="">Quels sont les entreprises o&ugrave; travail le d&eacute;veloppeur Java&nbsp;de Faseya?</span></h3>
        <ul style="margin-bottom: 0cm; margin-top: 0px;">
        <li>ESN (Entreprise de service num&eacute;rique). </li>
        <li>Start Up</li>
        <li>Petites et moyenne entreprise</li>
        <li>Agence de d&eacute;veloppement mobile</li>
        <li>Agence de d&eacute;veloppement de site internet</li>
        <li>Entreprises innovantes</li>
        <li>Entreprise de Fintech</li>
        <li>Editeur de logiciel</li>
        </ul>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">Lorsque vous poss&eacute;dez une connaissance approfondie de Java et des comp&eacute;tences fondamentales en gestion de projet Java, vous devez d&eacute;velopper un portfolio, cr&eacute;er un CV et postuler &agrave; des postes de d&eacute;veloppeur Java correspondant &agrave; votre expertise.&nbsp;N'oubliez pas que vous devez personnaliser votre CV pour chaque offre d'emploi &agrave; laquelle vous postulez.&nbsp;</span></p>
        <h3 style="margin: 0cm 0cm 8"><span style="">Comment Faseya recrute ses d&eacute;veloppeurs java&nbsp;?</span></h3>
        <p style="margin: 0cm 0cm 8"><span style="">Faseya </span><a style="color: blue !important" style="color:blue !important;" class="external" href="https://faseya.com/#/inscription/consultants">recrute</a><span style=""> ses d&eacute;veloppeurs Java en leur faisant passer plusieurs entretiens techniques avec un chef de projet senior. A la suite de ces entretiens, un test de validation technique sur Java est effectu&eacute;.</span></p>
        <p style="margin: 0cm 0cm 19.5 background: white;"><span style=" color: #51565e;">Lorsque vous poss&eacute;dez une connaissance approfondie de Java et des comp&eacute;tences fondamentales en gestion de projet Java, vous devez d&eacute;velopper un portfolio, cr&eacute;er un CV et postuler &agrave; des postes de d&eacute;veloppeur Java correspondant &agrave; votre expertise.&nbsp;N'oubliez pas que vous devez personnaliser votre CV pour chaque offre d'emploi &agrave; laquelle vous postulez.&nbsp;</span></p>
        <h3 style="margin: 0cm 0cm 8"><span style="">Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un d&eacute;veloppeur Java?</span></h3>
        <p style="margin: 0cm 0cm 8"><span style="">Au bout de quelques ann&eacute;es d&rsquo;exp&eacute;rience le d&eacute;veloppeur Java peut pr&eacute;tendre devenir un Tech Lead Java, c&rsquo;est-&agrave;-dire un leader technique encadrant une &eacute;quipe de d&eacute;veloppeur.</span></p>
        <p style="margin: 0cm 0cm 8"><strong>&nbsp;</strong></p>
        <p style="margin: 0cm 0cm 8"><span style="">&nbsp;</span></p>`

},
    {
        name: "UI/UX Designer", 
        url: 'tout-savoir-sur-le-metier-de-design',
        title:`Tout savoir sur le métier de UI/UX Designer`,
        description: `Qu’est-ce qu’un UI/UX Designer ? Quelles études ou formations pour devenir UI/UX Designer ? Ses missions et compétences?`,
        image: design, active:true,
        content:`<h2>Qu&rsquo;est-ce qu&rsquo;un UI/UX designer</h2>
        <p>Le designer UX (user eXperience) et le designer UI (interface utilisateur) est une &eacute;volution r&eacute;cente et une sp&eacute;cialisation de la profession de web designer. Le premier s&rsquo;int&eacute;resse &agrave; l&rsquo;exp&eacute;rience utilisateur, le second &agrave; la conception de l&rsquo;interface produit.</p>
        <p>L&apos;&eacute;volution tr&egrave;s rapide des technologies li&eacute;es au Web conduit &agrave; de nouvelles comp&eacute;tences dans le domaine du web designer, en particulier sur les tablettes, les smartphones et les objets connect&eacute;s.</p>
        <p>En effet, les&nbsp;<a style="color:blue !important" href="https://www.faseya.com/#/fiche-metier">concepteurs de sites Web</a> et d&apos;applications cr&eacute;ent leurs interfaces en portant une attention croissante aux nouvelles technologies. Comme la navigation tactile, la rotation portrait/paysage sur supports mobiles, l&rsquo;interactivit&eacute; des zones d&rsquo;action, la vocalisation</p>
        <p>L&apos;exp&eacute;rience utilisateur et la recherche d&apos;&eacute;motion face &agrave; l&apos;outil digital sont d&eacute;sormais &agrave; l&apos;ordre du jour.</p>
        <p>Ces designers doivent adapter ces technologies &agrave; leurs diff&eacute;rents clients : boutique e-commerce, service mobile, site corporate&hellip;et savoir travailler ensemble sur un projet.</p>
        <p><br></p>
        <h3>Quels sont les missions d&rsquo;un UI/UX designer&nbsp;?&nbsp;</h3>
        <p><a style="color:blue !important" href="https://www.ziggourat.com/formations-creativite-design.html">Le designer UI</a> (design de l&rsquo;interface utilisateur) s&rsquo;occupe du lien entre la machine et l&rsquo;homme. Il est responsable de la conception globale de l&apos;interface, de la clart&eacute; de la navigation, de l&apos;optimisation des trajets et aussi de la qualit&eacute; du contenu. Son objectivit&eacute; est guid&eacute;e par l&rsquo;efficacit&eacute;. Il organise des &eacute;l&eacute;ments graphiques et textuels sur la base de normes techniques.</p>
        <p>Le designer UX (design de l&rsquo;exp&eacute;rience utilisateur) a pour objectif d&rsquo;ins&eacute;rer du storytelling (mise en r&eacute;cit) dans une exp&eacute;rience d&rsquo;utilisation pour faire na&icirc;tre de l&rsquo;&eacute;motion chez l&rsquo;internaute ; son r&ocirc;le est donc plus strat&eacute;gique. Celui-ci tient compte des attentes et des besoins de l&apos;utilisateur. Il doit rendre le site facile &agrave; trouver, accessible, cr&eacute;er la confiance, facile &agrave; prendre en main, cr&eacute;dible et productif.</p>
        <p><br></p>
        <h3>Quel sont ses comp&eacute;tences&nbsp;?</h3>
        <p>Les designers UX et UI sont &agrave; la fois cr&eacute;atifs et imaginatifs mais aussi techniciens et programmeurs. Ils doivent aussi poss&eacute;der une connaissance de la psychologie et de la sociologie.</p>
        <ul>
            <li>
                <p>Agilit&eacute; &nbsp;</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; travailler en &eacute;quipe</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; livrer un produit en respectant les d&eacute;lais</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; coacher des &eacute;quipes</p>
            </li>
            <li>
                <p>Capacit&eacute; manag&eacute;riale&nbsp;</p>
            </li>
            <li>
                <p>Capacit&eacute; relationnelle</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; faire &eacute;voluer une organisation vers l&rsquo;agilit&eacute;</p>
            </li>
            <li>
                <p>Capacit&eacute; p&eacute;dagogique</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Quel est son niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral&nbsp;?</h3>
        <p>Il n&rsquo;existe pas de dipl&ocirc;me sp&eacute;cifique au m&eacute;tier de web designer tant dans sa dimension UX que UI. Il y a cependant des formations en art num&eacute;rique, graphisme, infographie, informatique, multim&eacute;dia.</p>
        <p>L&rsquo;apprentissage sur le terrain, en situation, en agence Web ou en studio, en stage ou en premier emploi reste une bonne &eacute;cole d&rsquo;approche de ces m&eacute;tiers en constante &eacute;volution.</p>
        <p>En g&eacute;n&eacute;ral, ils sont dipl&ocirc;m&eacute;s de master en &eacute;tude d&rsquo;<a style="color:blue !important" href="https://m.onisep.fr/recherche?context=advanced&text=ingenieur+informatique">ing&eacute;nierie informatique</a>. Il existe plusieurs masters pr&eacute;parant &agrave; ce dipl&ocirc;me qui sont dispens&eacute;s dans les &eacute;coles d&rsquo;ing&eacute;nieurs ou &agrave; l&rsquo;universit&eacute;.</p>
        <p><br></p>
        <h3>Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un UI/UX designer ?</h3>
        <p><br></p>
        <p>Les perspectives d&rsquo;&eacute;volution sont nombreuses. Les plus passionn&eacute;s vont se sp&eacute;cialiser dans l&rsquo;UX ou l&rsquo;UI. Compte tenu du large spectre de comp&eacute;tences &agrave; d&eacute;velopper, un Concepteur Designer peut int&eacute;grer des postes d&rsquo;architecte d&rsquo;information, de gestion de projet, de lead UI Designer&hellip; bref, les possibilit&eacute;s sont nombreuses !</p>
        <p><br></p>
        <p><br></p>
        <h2>Faseya met &agrave; disposition des entreprises des UI/UX designer en mode agile</h2>
        <p><a style="color:blue !important" href="https://www.faseya.com/#/">Faseya est une plateforme</a> de mise en relation entre entreprises souhaitant innover et UI/UX designers mobiles sp&eacute;cialis&eacute;s dans les domaines de l&apos;IT et du Digital.</p>
        <p><br></p>
        <h3>Quels sont les entreprises o&ugrave; travail le UI/UX designer&nbsp;?</h3>
        <p><br></p>
        <p>Ces professionnels travaillent principalement dans des agences web, des agences de communication ou des agences de design. Ils peuvent aussi exercer au sein de grandes entreprises ou &agrave; l&apos;inverse dans des startups.&nbsp;</p>
        <ul>
            <li>
                <p>ESN (Entreprise de service num&eacute;rique).&nbsp;</p>
            </li>
            <li>
                <p>Start Up</p>
            </li>
            <li>
                <p>Agence de d&eacute;veloppement</p>
            </li>
            <li>
                <p>Entreprises innovantes</p>
            </li>
            <li>
                <p>Entreprise de Fintech</p>
            </li>
            <li>
                <p>Editeur de logiciel</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Comment Faseya recrute un UI/UX designer?</h3>
        <p><br></p>
        <p><a style="color:blue !important" href="https://www.faseya.com/#/consultants/developer">Faseya recrute</a> ses UX/UI designers en leur faisant passer plusieurs entretiens techniques avec un chef de projet senior. A la suite de ces entretiens, une mise en situation est effectu&eacute;e avec un scenario.</p>`
    },
    {
        name: "Developpeur Mobile", 
        url:'tout-savoir-sur-le-metier-de-developpeur-mobile',
        title:`Tout savoir sur le métier de Développeur mobile`,
        description: `Qu’est-ce qu’un Développeur mobile ? Quelles études ou formations pour devenir Développeur mobile ? Ses missions et compétences?`,
        image: bc_3, active:true,
        content:`
        <p >Les applications mobiles sont apparues dans les ann&eacute;es 1990, elles sont li&eacute;es aux d&eacute;veloppements d&apos;Internet et des t&eacute;l&eacute;communications, des r&eacute;seaux sans fils et des technologies agents, et &agrave; l&rsquo;apparition et la d&eacute;mocratisation des terminaux mobiles : smartphones, tablettes tactiles... Le nombre d&apos;applications mobiles disponible augmente rapidement &agrave; partir de 2007 date du lancement de l&apos;iPhone. Les deux principaux services de plateformes de t&eacute;l&eacute;chargement, l&apos;App Store et le Google Play, <a style="color: blue !important" style="color:blue !important;" style="color:blue !important;" href="https://fr.wikipedia.org/wiki/Application_mobile">sont lanc&eacute;s en 2008</a></p>
        <h2 >Qu&rsquo;est-ce qu&rsquo;un D&eacute;veloppeur mobile ?</h2>
        <p >Le d&eacute;veloppeur mobile est un type de d&eacute;veloppeur de logiciel qui se sp&eacute;cialise dans la technologie mobile, comme la cr&eacute;ation d&apos;applications pour les plates-formes Android de Google, iOS d&apos;Apple et Windows Phone de Microsoft. Pour cette raison, les titres de poste pour ce type de r&ocirc;le incluent &eacute;galement le <a style="color: blue !important" style="color:blue !important;" href="https://www.faseya.com/#/concept" style="color:blue;">d&eacute;veloppeur Mobile</a>  et le d&eacute;veloppeur iOS.&nbsp;</p>
        <h3 >Quels sont les missions d&rsquo;un d&eacute;veloppeur Mobile ?</h3>
        <p >Le d&eacute;veloppeur donne corps &agrave; l&rsquo;application mobile telle qu&rsquo;elle a &eacute;t&eacute; con&ccedil;ue. Il traduit en d&eacute;veloppements techniques des attentes fonctionnelles et travaille en lien &eacute;troit avec le Chef de Projet Mobile avec un retour r&eacute;gulier sur l&rsquo;avanc&eacute;e de son travail. Il se coordonne &eacute;galement avec le web designer pour le graphisme (certains d&eacute;veloppeurs mobiles peuvent avoir des comp&eacute;tences en graphisme) et le responsable des contenus.</p>
        <p >La comp&eacute;tence d&rsquo;un d&eacute;veloppeur mobile repose essentiellement sur la connaissance de langages informatiques sp&eacute;cifiques (Java, C, suivant qu&rsquo;il est d&eacute;veloppeur Android ou iOS, mais aussi une bonne base en html), et sur la connaissance de l&rsquo;environnement et des usages des applis mobiles.</p>
        <p >Principales missions :</p>
        <ul>
            <li >Analyse du cahier des charges fonctionnel (r&eacute;dig&eacute; par le Chef de Projet fonctionnel) et du cahier des charges technique (r&eacute;dig&eacute; par le responsable technique du projet)</li>
            <li >Choix d&rsquo;une solution technique adapt&eacute;e, d&eacute;finition de l&rsquo;architecture technique du projet, dans l&rsquo;interface Android ou IOS</li>
            <li >D&eacute;veloppement interface front end</li>
            <li >D&eacute;veloppement back end</li>
            <li >Planification des diff&eacute;rentes s&eacute;quences du projet et des points de validation</li>
            <li >Programmation / codage</li>
            <li >Montage des pages</li>
            <li >Int&eacute;gration des maquettes graphiques, des images et des textes</li>
            <li >Participation au recettage et aux phases de test</li>
            <li >Recadrage, d&eacute;buggage, am&eacute;liorations et d&eacute;veloppements compl&eacute;mentaires</li>
            <li >Pr&eacute;vision des &eacute;volutions de l&rsquo;application</li>
            <li >Publication de l&rsquo;application mobile sur Google Store ou Apple Store</li>
            <li >Participation &agrave; la phase de test</li>
            <li >Correction des beugs de programmation</li>
            <li >Maintenance et mise &agrave; jour de l&rsquo;application</li>
        </ul>
        <h3 >Quel sont les comp&eacute;tences d&rsquo;un d&eacute;veloppeur Mobile ?</h3>
        <p ><span style="font-size: 17px; font-weight: bold !important;"><strong>Comp&eacute;tences techniques</strong></span></p>
        <ul>
            <li >Parfaite connaissance de l&rsquo;univers &laquo; mobile &raquo; : les multiples plateformes (mobile, tablette, smartphone) et les &eacute;cosyst&egrave;mes de d&eacute;veloppements propres &agrave; Apple et Google.</li>
            <li >Capacit&eacute; &agrave; mener une veille pointue sur les nouvelles technologies et &eacute;volutions digitales ainsi que sur les tendances du march&eacute; pour rester comp&eacute;titif.</li>
            <li >Solides fondations en math&eacute;matiques, algorithmes, g&eacute;om&eacute;trie et analyses.</li>
            <li >Ma&icirc;trise du Front-end : Html/CSS JavaScript/JQuery &ndash; Backbone / Bootstrap</li>
            <li >Compr&eacute;hension des diff&eacute;rents outils de d&eacute;veloppement (Xcode, Visual Studio Mobile, etc.)</li>
        </ul>
        <p ><span style="font-size: 17px;font-weight: bold !important;"><strong>Comp&eacute;tences relationnelles</strong></span></p>
        <ul>
            <li >Rigueur, curiosit&eacute;, cr&eacute;ativit&eacute;, efficacit&eacute; et flexibilit&eacute;.</li>
            <li >Un bon sens de l&rsquo;&eacute;coute pour &ecirc;tre capable de traduire en termes techniques les demandes de son client ou de son chef de projet.</li>
            <li >Un go&ucirc;t de l&rsquo;&eacute;change pour pouvoir travailler en transverse avec un large panel de m&eacute;tiers, d&eacute;fendre la qualit&eacute; de ses r&eacute;alisations et &ecirc;tre force de proposition.</li>
        </ul>
        <h3 >Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre d&eacute;veloppeur Mobile ?</h3>
        <p >En g&eacute;n&eacute;ral, le d&eacute;veloppeur mobile est dipl&ocirc;m&eacute; d&rsquo;un master en <a style="color: blue !important" style="color:blue !important;" href="https://m.onisep.fr/recherche?context=advanced&text=ingenieur+en+informatique">&eacute;tude d&rsquo;ing&eacute;nierie informatique</a> . Il existe plusieurs masters pr&eacute;parant &agrave; ce dipl&ocirc;me qui sont dispens&eacute;s dans les &eacute;coles d&rsquo;ing&eacute;nieurs ou &agrave; l&rsquo;universit&eacute;.</p>
        <p >Formation sup&eacute;rieure : IT / informatique, ing&eacute;nieur, d&eacute;veloppeur&hellip;</p>
        <h3 >Quel sont les perspectives d&rsquo;avenir d&rsquo;un d&eacute;veloppeur mobile?</h3>
        <p >Au bout de quelques ann&eacute;es d&rsquo;exp&eacute;rience le d&eacute;veloppeur mobile peut pr&eacute;tendre devenir un Tech mobile, c&rsquo;est-&agrave;-dire un leader technique encadrant une &eacute;quipe de d&eacute;veloppeur.</p>
        <p >Le d&eacute;veloppeur mobile exp&eacute;riment&eacute; gagne des responsabilit&eacute;s, notamment manag&eacute;riales. Il peut ainsi pr&eacute;tendre aux fonctions de chef d&apos;&eacute;quipe puis chef de projet mobile.</p>
        <p >A tout moment de sa carri&egrave;re, le d&eacute;veloppeur mobile peut valider des formations compl&eacute;mentaires pour &eacute;largir son champ de comp&eacute;tences (ex : d&eacute;veloppeur web).</p>
        <p >Enfin, le d&eacute;veloppeur mobile peut s&apos;appuyer sur son exp&eacute;rience et son r&eacute;seau pour se lancer &agrave; son compte.</p>
        <h3 >Faseya met &agrave; disposition des entreprises des d&eacute;veloppeurs mobiles en mode agile</h3>
        <p ><a style="color: blue !important" style="color:blue !important;" href="https://www.faseya.com/#/">Faseya est une plateforme</a> de mise en relation entre entreprises souhaitant innover et d&eacute;veloppeurs mobiles sp&eacute;cialis&eacute;s dans les domaines de l&apos;IT et du Digital.</p>
        <h3 >Quels sont les entreprises o&ugrave; travail le d&eacute;veloppeur mobile ?</h3>
        <ul>
            <li >ESN (Entreprise de service num&eacute;rique).&nbsp;</li>
            <li >Start Up</li>
            <li >Petites et moyenne entreprise</li>
            <li >Agence de d&eacute;veloppement de site internet</li>
            <li >Entreprises innovantes</li>
            <li >Entreprise de Fintech</li>
            <li >Editeur de logiciel</li>
            <li >Les agences web&nbsp;</li>
            <li >Les op&eacute;rateurs de t&eacute;l&eacute;phonie</li>
            <li >Les grandes entreprises qui internalisent ces fonctions.</li>
            <li >Le d&eacute;veloppeur mobile peut &eacute;galement exercer &agrave; son compte, en freelance.</li>
        </ul>
        <h3 >Comment Faseya recrute ses d&eacute;veloppeurs mobiles?</h3>
        <p >Faseya <a style="color: blue !important" style="color:blue !important;" href="https://faseya.com/#/inscription/consultants">recrute</a> ses d&eacute;veloppeurs mobiles en leur faisant passer plusieurs entretiens techniques avec un chef de projet senior. A la suite de ces entretiens, un test de validation technique sur le langage de programmation s&eacute;lectionn&eacute; par les clients est effectu&eacute;.</p>
        <p><br></p>`
    },
    {
        name: "Développeur Android", 
        url:'tout-savoir-sur-le-metier-de-developpeur-android',
        title:`Tout sur la profession de Développeur Android`,
        description: `Qu’est-ce qu’un Développeur Android ? Quel est le niveau d’étude en général pour être développeur Android ? Quels sont les entreprises où travail le développeur Android de Faseya ?`,
        image: android, active:true,
        content:`
        <p><br></p>
        <h2 >Qu&rsquo;est-ce qu&rsquo;un D&eacute;veloppeur Android ?&nbsp;</h2>
        <br>
        <p >Un d&eacute;veloppeur Android programme uniquement des applications pour les tablettes et les smartphones fonctionnant avec le syst&egrave;me d&rsquo;exploitation Android d&eacute;velopp&eacute; par Google.</p>
        <p >Android est le syst&egrave;me d&rsquo;exploitation le plus utilis&eacute; pour le d&eacute;veloppement mobile au monde avec pr&egrave;s de 80 % de parts de <a style="color: blue !important" style="color:blue !important;" href="https://fr.wikipedia.org/wiki/Android?">march&eacute;</a>&nbsp;</p>
        <p >Il dispose d&rsquo;une grande communaut&eacute; de d&eacute;veloppeurs, ce qui rend son &eacute;volution tr&egrave;s dynamique.</p>
        <h3 >Quels sont les missions d&rsquo;un d&eacute;veloppeur Android ?</h3>
        <p >La mission principale d&rsquo;un d&eacute;veloppeur Android est de d&eacute;velopper une application mobile avec le langage Android. Il est amen&eacute; dans son travail &agrave; r&eacute;aliser plusieurs missions&nbsp;:</p>
        <ul>
            <li>
                <p >R&eacute;daction du cahier des charges ou cahier d&rsquo;analyse du besoin</p>
            </li>
            <li>
                <p >Travail en m&eacute;thode agile&nbsp;</p>
            </li>
            <li>
                <p >Tenue de r&eacute;union d&rsquo;&eacute;quipes avec le chef de projet et le Scrum Master</p>
            </li>
            <li>
                <p >D&eacute;veloppement interface front end</p>
            </li>
            <li>
                <p >D&eacute;veloppement back end</p>
            </li>
            <li>
                <p >Publication de l&rsquo;application mobile sur Google Store</p>
            </li>
            <li>
                <p >Participation &agrave; la phase de test</p>
            </li>
            <li>
                <p >Correction des beugs de programmation</p>
            </li>
            <li>
                <p >Maintenance et mise &agrave; jour de l&rsquo;application</p>
            </li>
        </ul>
        <h3 >Quel sont les comp&eacute;tences d&rsquo;un d&eacute;veloppeur Android ?</h3>
        <ul>
            <li>
                <p >Agilit&eacute; &nbsp;</p>
            </li>
            <li>
                <p >Capacit&eacute; &agrave; travailler en &eacute;quipe</p>
            </li>
            <li>
                <p >Capacit&eacute; &agrave; pr&eacute;senter son programme informatique (code sources)</p>
            </li>
            <li>
                <p >Capacit&eacute; &agrave; faire des pr&eacute;sentations d&rsquo;&eacute;crans mobiles avec un <a style="color: blue !important" style="color:blue !important;" href="about%3Ablank">&eacute;mulateur</a>&nbsp;</p>
            </li>
            <li>
                <p >Capacit&eacute; &agrave; r&eacute;diger de la documentation technique</p>
            </li>
        </ul>
        <h3 >Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre d&eacute;veloppeur Android&nbsp;?</h3>
        <p >En g&eacute;n&eacute;ral, le d&eacute;veloppeur Android est dipl&ocirc;m&eacute; d&rsquo;un master en &eacute;tude d&rsquo;ing&eacute;nierie informatique. Il existe plusieurs masters pr&eacute;parant &agrave; ce dipl&ocirc;me qui sont dispens&eacute;s dans les <a style="color: blue !important" style="color:blue !important;" href="https://www.onisep.fr/recherche?text=d%C3%A9veloppeur%20android">&eacute;coles d&rsquo;ing&eacute;nieurs ou &agrave; l&rsquo;universit&eacute;.</a></p>
        <h2 >Faseya met &agrave; disposition des entreprises des d&eacute;veloppeurs Android en mode agile.</h2>
        <br>
        <h3 >Une solution faite pour les PME</h3>
        <p >Faseya met &agrave; disposition des talents du d&eacute;veloppement Android en mode remote &agrave; des co&ucirc;ts tr&egrave;s bas. Nos talents sont des consultants confirm&eacute;s dans leurs domaines de comp&eacute;tences. Nous mettons &agrave; dispositions pour r&eacute;aliser vos missions des <a style="color: blue !important" style="color:blue !important;" href="https://www.faseya.com/#/concept">consultants</a>.</p>
        <h3 >Quels sont les entreprises o&ugrave; travail le d&eacute;veloppeur Android de Faseya?</h3>
        <ul>
            <li>
                <p >ESN (Entreprise de service num&eacute;rique).&nbsp;</p>
            </li>
            <li>
                <p >Start Up</p>
            </li>
            <li>
                <p >Petites et moyenne entreprise</p>
            </li>
            <li>
                <p >Agence de d&eacute;veloppement mobile</p>
            </li>
            <li>
                <p >Agence de d&eacute;veloppement de site internet</p>
            </li>
            <li>
                <p >Entreprises innovantes</p>
            </li>
            <li>
                <p >Entreprise de Fintech</p>
            </li>
            <li>
                <p >Editeur de logiciel</p>
            </li>
        </ul>
        <h3 >Comment Faseya recrute ses d&eacute;veloppeurs Android&nbsp;?</h3>
        <p ><a style="color: blue !important" style="color:blue !important;" href="https://www.faseya.com/#/consultants/developer">Faseya recrute</a> ses d&eacute;veloppeurs Android en leur faisant passer plusieurs entretiens techniques avec un chef de projet senior. A la suite de ces entretiens, un test de validation technique sur Android est effectu&eacute;.</p>
        
        <h3 >Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un d&eacute;veloppeur Android ?</h3>
        <p >Au bout de quelques ann&eacute;es d&rsquo;exp&eacute;rience le d&eacute;veloppeur Android peut pr&eacute;tendre devenir un Tech Lead Android, c&rsquo;est-&agrave;-dire un leader technique encadrant une &eacute;quipe de d&eacute;veloppeur.</p>
        `
    },
    {
        name: "Développeur de site web", 
        url:'tout-savoir-sur-le-metier-de-developpeur-de-site-web',
        title:`Tout savoir sur le métier de Développeur de site web`,
        description: `Qu’est-ce qu’un Développeur de site web ? Quelles études ou formations pour devenir Développeur web ? Ses missions et compétences?`,
        image: website, active:true,
        content:`
        <h2 >Qu&rsquo;est-ce qu&rsquo;un D&eacute;veloppeur de site web ?</h2>
        <p ><a style="color: blue !important" style="color:blue !important;" href="https://fr.wikipedia.org/wiki/D%C3%A9veloppeur_web#:~:text=Un%20d%C3%A9veloppeur%20web%20est%20un%20informaticien%20sp%C3%A9cialis%C3%A9%20dans,protocole%20HTTP%20comme%20vecteur%20de%20transmission%20de%20l%27information.">Les d&eacute;veloppeurs Web</a> sont des ing&eacute;nieurs Web ou des d&eacute;veloppeurs Web qui s&apos;occupent du front-end et du back-end (et de tout ce qui se trouve entre les deux) d&apos;une application ou d&apos;un site Web. Ils peuvent fonctionner avec n&apos;importe quelle pile : applications mobiles, Web ou natives.</p>
        <p >Les d&eacute;veloppeurs frontaux travaillent avec les parties visibles d&apos;une application ou d&apos;un site Web, y compris les fonctionnalit&eacute;s avec lesquelles les utilisateurs interagissent.&nbsp;</p>
        <p >Les d&eacute;veloppeurs back-end s&apos;occupent des aspects en coulisses, les &laquo; &eacute;crous et boulons &raquo;, tels que les bases de donn&eacute;es et l&apos;infrastructure.&nbsp;</p>
        <p >En plus du front-end et du back-end, les applications disposent &eacute;galement d&apos;une couche de base de donn&eacute;es. Cette couche est le c&oelig;ur de l&apos;application et contient tous les processus des syst&egrave;mes de gestion de base de donn&eacute;es, y compris l&apos;administration, la cr&eacute;ation, la suppression et l&apos;extraction des donn&eacute;es. <a style="color: blue !important" style="color:blue !important;" href="https://www.faseya.com/#/consultants/developer">Les d&eacute;veloppeurs Web full-stack</a> sont form&eacute;s pour g&eacute;rer n&apos;importe quelle &eacute;tape du d&eacute;veloppement, du d&eacute;but &agrave; la fin.&nbsp;</p>
        <h3 >Quels sont les missions d&rsquo;un d&eacute;veloppeur Web ?&nbsp;</h3>
        <p >L&apos;approche touche-&agrave;-tout dans ce r&ocirc;le signifie qu&apos;ils ont un large &eacute;ventail de responsabilit&eacute;s &agrave; assumer. Ils peuvent g&eacute;rer des projets impliquant des bases de donn&eacute;es, cr&eacute;er des sites Web destin&eacute;s aux utilisateurs ou m&ecirc;me travailler avec des clients pendant la phase de planification des projets.&nbsp;</p>
        <p >Voici une liste partielle des r&ocirc;les et responsabilit&eacute;s qu&apos;ils remplissent&nbsp;:</p>
        <ul>
            <li>
                <p >&Eacute;crire du code front-end et back-end</p>
            </li>
            <li>
                <p >Concevoir des interfaces utilisateur sur des pages Web</p>
            </li>
            <li>
                <p >G&eacute;n&eacute;rer un produit r&eacute;alisable par eux-m&ecirc;mes, avec peu ou pas d&apos;aide des autres</p>
            </li>
            <li>
                <p >Cr&eacute;er des bases de donn&eacute;es et des serveurs pour la fonctionnalit&eacute;</p>
            </li>
            <li>
                <p >Assurer l&apos;optimisation multiplateforme (par exemple, ordinateurs portables, tablettes, smartphones)</p>
            </li>
            <li>
                <p >Avoir une compr&eacute;hension de haut niveau des syst&egrave;mes et des processus de l&apos;organisation</p>
            </li>
            <li>
                <p >D&eacute;tecter les erreurs dans le front ou le back-end</p>
            </li>
            <li>
                <p >Maintenir une bonne communication avec les concepteurs UX et les autres membres de l&apos;&eacute;quipe concernant des questions telles que les probl&egrave;mes d&apos;exp&eacute;rience utilisateur et la justification de la conception</p>
            </li>
            <li>
                <p >Superviser un projet de d&eacute;veloppement du d&eacute;but &agrave; la fin&nbsp;</p>
            </li>
        </ul>
        <p >Chaque organisation a ses propres besoins et attentes. Certains peuvent ne pas n&eacute;cessiter tous les r&ocirc;les ci-dessus ; d&apos;autres peuvent avoir d&apos;autres responsabilit&eacute;s non &eacute;num&eacute;r&eacute;es.&nbsp;</p>
        <h3 >Quel sont les comp&eacute;tences d&rsquo;un d&eacute;veloppeur web&nbsp;?</h3>
        <p >&Eacute;tant donn&eacute; que les entreprises attendent tant de ces professionnels, il n&apos;est pas surprenant qu&apos;un professionnel appr&eacute;ci&eacute; ait besoin d&apos;un ensemble de comp&eacute;tences solides et diversifi&eacute;es.&nbsp;Voici une bonne s&eacute;lection des comp&eacute;tences n&eacute;cessaires et d&apos;autres exigences sugg&eacute;r&eacute;es.&nbsp;Bien que toutes les entreprises ou organisations ne s&apos;attendent pas &agrave; ce que le d&eacute;veloppeur ait la liste compl&egrave;te ci-dessous, la sagesse conventionnelle dit que ce sont les comp&eacute;tences les plus pr&eacute;cieuses pour une personne capable dans ce r&ocirc;le&nbsp;:</p>
        <ul>
            <li>
                <p >Un dipl&ocirc;me en informatique ou dans une discipline connexe</p>
            </li>
            <li>
                <p >Soucieux des d&eacute;tails</p>
            </li>
            <li>
                <p >Solides comp&eacute;tences en r&eacute;solution de probl&egrave;mes</p>
            </li>
            <li>
                <p >Comp&eacute;tences &eacute;prouv&eacute;es en communication verbale</p>
            </li>
            <li>
                <p >Ma&icirc;trise des <a style="color: blue !important" style="color:blue !important;" href="https://www.blogdumoderateur.com/top-25-langages-programmation-populaires-developpeurs/">langages frontaux</a> tels que CSS, HTML et JavaScript</p>
            </li>
            <li>
                <p >Familiarit&eacute; avec les frameworks JavaScript comme Amber, Angular JS et React</p>
            </li>
            <li>
                <p >Connaissance des langages c&ocirc;t&eacute; serveur tels que Java, .Net, PHP, Python et Ruby</p>
            </li>
            <li>
                <p >Exp&eacute;rience avec les technologies de bases de donn&eacute;es telles que MongoDB, MySQL et Oracle</p>
            </li>
            <li>
                <p >Compr&eacute;hension des diff&eacute;rentes API, syst&egrave;mes de gestion de base de donn&eacute;es, biblioth&egrave;ques et services Web</p>
            </li>
            <li>
                <p >Bonne gestion de projet et sens de l&apos;organisation</p>
            </li>
            <li>
                <p >Compr&eacute;hension de DevOps</p>
            </li>
            <li>
                <p >Ma&icirc;trise des outils li&eacute;s &agrave; l&apos;int&eacute;gration continue/d&eacute;ploiement continu (CI/CD)</p>
            </li>
        </ul>
        <p >Si vous souhaitez examiner plus en d&eacute;tail les comp&eacute;tences des d&eacute;veloppeurs Web full-stack, consultez cet&nbsp;article.</p>
        <h3 >Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre d&eacute;veloppeur web&nbsp;?</h3>
        <p >En g&eacute;n&eacute;ral, le d&eacute;veloppeur Web est dipl&ocirc;m&eacute; d&rsquo;un master en &eacute;tude d&rsquo;ing&eacute;nierie informatique. Il existe plusieurs masters pr&eacute;parant &agrave; ce dipl&ocirc;me qui sont dispens&eacute;s dans <a style="color: blue !important" style="color:blue !important;" href="https://www.onisep.fr/recherche?context=what_where&search_onisep=true&text=D%C3%A9veloppeur%20web">les &eacute;coles d&rsquo;ing&eacute;nieurs ou &agrave; l&rsquo;universit&eacute;</a>.</p>
        <p ><br></p>
        <h2 >Quel est le domaine d&rsquo;intervention de Faseya dans le d&eacute;veloppement web ?&nbsp;</h2>
        <h3 ><br></h3>
        <h3 >Quels sont les entreprises o&ugrave; travail le d&eacute;veloppeur web ?</h3>
        <p >Les d&eacute;veloppeurs web ont un large champ d&rsquo;action. Ils sont tr&egrave;s recherch&eacute;s autant dans des entreprises du web que dans des PME ou grandes entreprises cherchant &agrave; se digitaliser et &agrave; se diff&eacute;rencier. Voici les types d&rsquo;entreprises dans lesquels travaillent ces professionnels.</p>
        <p >Les principaux employeurs des d&eacute;veloppeurs web sont&nbsp;:</p>
        <ul>
            <li>
                <p >ESN (Entreprise de service num&eacute;rique).&nbsp;</p>
            </li>
            <li>
                <p >Start Up</p>
            </li>
            <li>
                <p >Petites et moyenne entreprise</p>
            </li>
            <li>
                <p >Agence de d&eacute;veloppement de site internet</p>
            </li>
            <li>
                <p >Entreprises innovantes</p>
            </li>
            <li>
                <p >Entreprise de Fintech</p>
            </li>
            <li>
                <p >Editeur de logiciel</p>
            </li>
        </ul>
        <h3 >Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un d&eacute;veloppeur web?</h3>
        <p >Au bout de quelques ann&eacute;es d&rsquo;exp&eacute;rience le d&eacute;veloppeur Web peut pr&eacute;tendre devenir un chef de projet, c&rsquo;est-&agrave;-dire un avoir la responsabilit&eacute; de manag&eacute; l&rsquo;ensemble d&rsquo;un projet informatique.</p>

        <h3 >Comment faseya recrute ces d&eacute;veloppeurs web.</h3>
        <p >Faseya est une plateforme de mise en relation entre entreprises souhaitant innover et consultants sp&eacute;cialis&eacute;s dans les domaines de l&apos;IT et du Digital.</p>
        <p ><a style="color: blue !important" style="color:blue !important;" href="https://faseya.com/#/inscription/consultants">Faseya recrute</a> ses d&eacute;veloppeurs Web en leur faisant passer plusieurs entretiens techniques avec un chef de projet senior. A la suite de ces entretiens, un test de validation technique sur le langage de programmation s&eacute;lectionn&eacute; par les clients est effectu&eacute;.</p>
        `
    },
    {
        name: "Data Scientist", 
        title:`Tout sur la profession de Data Scientist`,
        url: 'tout-savoir-sur-le-metier-de-data-scientist',
        description: `Qu’est-ce qu’un Data Scientist ? Quel est le niveau d’étude en général pour être Data Scientist ? Quels sont les entreprises où travail le Data Scientist ?`,
        image: bc_6, active:true,
        content:`<h2>Qu&rsquo;est-ce qu&rsquo;un data scientist ?</h2>
        <p>Les data scientist sont une nouvelle race d&apos;experts en&nbsp;<a style="color:blue !important" href="https://fr.wikipedia.org/wiki/Analyse_des_donn%C3%A9es">donn&eacute;es analytiques</a> qui ont les comp&eacute;tences techniques pour r&eacute;soudre des probl&egrave;mes complexes - et la curiosit&eacute; d&apos;explorer quels probl&egrave;mes doivent &ecirc;tre r&eacute;solus.</p>
        <p>Ils sont &agrave; la fois math&eacute;maticiens, informaticiens et observateurs de tendances. Et, parce qu&apos;ils chevauchent &agrave; la fois les mondes des affaires et de l&apos;informatique, ils sont tr&egrave;s recherch&eacute;s et bien pay&eacute;s. Qui&nbsp;ne voudrait pas&nbsp;en &ecirc;tre un ?<br><br>Ils sont aussi un signe des temps. Les scientifiques des donn&eacute;es n&apos;&eacute;taient pas sur beaucoup de radars il y a dix ans, mais leur popularit&eacute; soudaine refl&egrave;te la fa&ccedil;on dont les entreprises envisagent aujourd&apos;hui le&nbsp;<a style="color:blue !important" href="https://fr.wikipedia.org/wiki/Big_data">Big Data</a>. Cette masse encombrante d&apos;informations non structur&eacute;es ne peut plus &ecirc;tre ignor&eacute;e et oubli&eacute;e. C&apos;est une mine d&apos;or virtuelle qui aide &agrave; augmenter les revenus - tant qu&apos;il y a quelqu&apos;un qui creuse et d&eacute;terre des informations commerciales que personne ne pensait rechercher auparavant.&nbsp;</p>
        <p>G&eacute;n&eacute;ralement rattach&eacute; &agrave; la&nbsp;direction des syst&egrave;mes d&apos;information&nbsp;(DSI) d&rsquo;une entreprise, le Data Scientist a pour objectif d&rsquo;analyser et d&rsquo;exploiter toutes les datas des clients, des prospects ou bien encore des employ&eacute;s que l&rsquo;entreprise r&eacute;cup&egrave;re via diff&eacute;rents canaux. L&rsquo;objectif est de cr&eacute;er des mod&egrave;les pr&eacute;dictifs et d&rsquo;aider la prise de d&eacute;cision par la construction d&apos;algorithmes.</p>
        <h3>Quels sont les missions d&rsquo;un data scientist&nbsp;?</h3>
        <ul>
            <li>
                <p>Identification des outils d&rsquo;analyse&nbsp;;</p>
            </li>
            <li>
                <p>D&eacute;finition de solutions&nbsp;de stockage des donn&eacute;es ;</p>
            </li>
            <li>
                <p>Recueil et analyse des donn&eacute;es&nbsp;pertinentes pour l&rsquo;entreprise ;</p>
            </li>
            <li>
                <p>Construction d&rsquo;algorithmes&nbsp;permettant d&rsquo;am&eacute;liorer les r&eacute;sultats de recherches et de ciblage&nbsp;;</p>
            </li>
            <li>
                <p>&Eacute;laboration de mod&egrave;les de pr&eacute;dictions&nbsp;afin d&apos;anticiper les &eacute;volutions des donn&eacute;es et des tendances ;</p>
            </li>
            <li>
                <p>Cr&eacute;ation de tableaux de bord&nbsp;adapt&eacute;s afin de rendre les r&eacute;sultats lisibles et exploitables par tous les m&eacute;tiers ;</p>
            </li>
            <li>
                <p>Veille technologique&nbsp;(collecte de donn&eacute;es, plateformes de traitement, exp&eacute;rimentation).</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Quel sont les comp&eacute;tences d&rsquo;un data scientist&nbsp;?</h3>
        <p>Le Data Scientist poss&egrave;de plusieurs comp&eacute;tences :</p>
        <ul>
            <li>
                <p>Informatique&nbsp;: maitrise des&nbsp;algorithmes d&rsquo;apprentissage automatique&nbsp;(Machine Learning) et&nbsp;des langages de programmation,</p>
            </li>
            <li>
                <p>Marketing&nbsp;: connaissances en&nbsp;outils de data management&nbsp;(SAS, SPSS, SAP, Python, R, Excel, Access) et des bases de donn&eacute;es (SQL, no-SQL)</p>
            </li>
            <li>
                <p>Statistiques&nbsp;: ma&icirc;trise&nbsp;des outils de web analyse (Omniture, Google Analytics)</p>
            </li>
        </ul>
        <p>Outre ses comp&eacute;tences techniques, il est dot&eacute; d&apos;un&nbsp;esprit d&apos;analyse, lui permettant d&rsquo;identifier et de comprendre les probl&eacute;matiques business&nbsp;de l&rsquo;entreprise.&nbsp;Rigoureux, organis&eacute; et pr&eacute;cis, un bon data scientist devra aussi&nbsp;faire preuve d&rsquo;excellentes capacit&eacute;s de communication&nbsp;lui permettant de rendre intelligible&nbsp;son travail au plus grand nombre et de convaincre les d&eacute;cideurs. Il est&nbsp;force de proposition&nbsp;et coordonne les actions avec les diff&eacute;rentes &eacute;quipes m&eacute;tiers afin de satisfaire au mieux les clients. La&nbsp;maitrise de l&rsquo;anglais&nbsp;technique est&nbsp;indispensable pour exercer cette fonction.</p>
        <p>&nbsp;</p>
        <h3>Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre data scientist&nbsp;?</h3>
        <p>Le Data Scientist est g&eacute;n&eacute;ralement issu d&rsquo;une&nbsp;<a style="color:blue !important" href="https://www.pagepersonnel.fr/advice/tendances-de-march%C3%A9/orientation-professionnelle-quelles-sont-les-formations-les-plus-adapt%C3%A9es">formation Bac +5&nbsp;</a>type universit&eacute;, &eacute;cole d&rsquo;ing&eacute;nieurs (MINES ParisTech, ENSI, ENSAE Paris, etc.) ou de commerce &nbsp;(ESSEC, HEC, etc.) ) avec une sp&eacute;cialisation en informatique, en statistiques, en marketing ou en big data.</p>
        <p><br></p>
        <p><br></p>
        <h3>Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un data scientist&nbsp;?</h3>
        <p>A l&rsquo;heure du big data et de l&rsquo;augmentation croissante de donn&eacute;es strat&eacute;giques &agrave; exploiter par les entreprises, les perspectives d&rsquo;&eacute;volution du poste sont nombreuses, notamment dans les secteurs des&nbsp;services financiers, des&nbsp;nouvelles technologies, du&nbsp;conseil, de la&nbsp;sant&eacute;&nbsp;ou de l&rsquo;industrie.</p>
        <p>Selon l&rsquo;entreprise, apr&egrave;s quelques ann&eacute;es en poste, le Data Scientist peut monter en comp&eacute;tences et &eacute;voluer vers des fonctions manag&eacute;riales tels que&nbsp;Data Scientist Senior,&nbsp;Chief Data Scientist&nbsp;ou&nbsp;Lead Data Scientist.</p>
        <h2>Faseya met &agrave; disposition des entreprises data Scientist en mode agile.</h2>
        <p><a style="color:blue !important" href="https://www.faseya.com/#/">Faseya est une plateforme</a> de mise en relation entre entreprises souhaitant innover et data Scientist sp&eacute;cialis&eacute;s dans les domaines de l&apos;IT et du Digital.</p>
        <p><br></p>
        <h3>Quels sont les entreprises o&ugrave; travail le data Scientist ?</h3>
        <ul>
            <li>
                <p>Les structure de la&nbsp;sant&eacute;&nbsp;ou de l&rsquo;industrie.</p>
            </li>
            <li>
                <p>Start Up</p>
            </li>
            <li>
                <p>Les secteurs des&nbsp;services financiers.</p>
            </li>
            <li>
                <p>ESN (Entreprise de service num&eacute;rique).&nbsp;</p>
            </li>
            <li>
                <p>Agence de d&eacute;veloppement de site internet</p>
            </li>
            <li>
                <p>Entreprises innovantes</p>
            </li>
            <li>
                <p>Grandes entreprises de tous secteurs d&rsquo;activit&eacute;</p>
            </li>
            <li>
                <p>Entreprise de Fintech</p>
            </li>
            <li>
                <p>Editeur de logiciel</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Comment Faseya recrute ses data Scientist?</h3>
        <p><br></p>
        <p>Faseya&nbsp;<a style="color:blue !important" href="https://faseya.com/#/inscription/consultants">recrute</a> ses data Scientist en leur faisant passer plusieurs entretiens. A la suite de ces entretiens, un projet test est soumis au chef de projet pour &eacute;valuer sa capacit&eacute; &agrave; mener &agrave; bien un projet d&rsquo;envergure.</p>`
    },
    {
        name: "Scrum Master", 
        url:'tout-savoir-sur-le-metier-de-scrum-master',
        title:`Description du poste de Scrum Master : Rôle et responsabilités`,
        description: `Explorez des concepts clés tels que la description du métier de Scrum Master✅ les missions et compétences du Scrum Master ✅les entreprises où travail le Scrum Master✅ Comment Faseya recrute ses Scrum Master✅ Vous souhaitez recruter un Scrum Master ?`,
        image: scrum, active:true,
        content:`<h2>Qu&rsquo;est-ce qu&rsquo;un Scrum Master</h2>
        <p>Un&nbsp;<a style="color: blue !important" href="https://www.scrum.org/resources/what-is-a-scrum-master">Scrum Master</a> est un consultant qui a comme r&ocirc;le d&rsquo;assurer que l&rsquo;&eacute;quipe en charge du d&eacute;veloppement du projet respecte la m&eacute;thodologie Scrum. Il a la responsabilit&eacute; de mener &agrave; bien la livraison du projet avec les diff&eacute;rentes contraintes techniques et les nouvelles adaptations du client.</p>
        <p>Scrum est une m&eacute;thodologie de travail en &eacute;quipe agile. C&rsquo;est une mani&egrave;re de travailler de mani&egrave;re interactive impliquant de la souplesse dans l&rsquo;ex&eacute;cution du projet et de collaboration quotidienne entre les diff&eacute;rents membres de l&rsquo;&eacute;quipe. Le projet est ainsi d&eacute;coup&eacute; en plusieurs lots, et chaque lot est finalis&eacute; &agrave; l&rsquo;issue d&rsquo;un sprint.</p>
        <p><br></p>
        <p>QU&rsquo;EST-CE QUE LA M&Eacute;THODE SCRUM ?</p>
        <p><a style="color: blue !important" href="https://www.clementine.jobs/fiches-metiers/metiers-techniques-du-web/scrum-master/#:~:text=La%20m%C3%A9thode%20Scrum%2C%20expliqu%C3%A9e%20dans,la%20maintenance%20de%20produits%20complexes.">La m&eacute;thode Scrum</a>, expliqu&eacute;e dans le Guide Scrum a &eacute;t&eacute; con&ccedil;ue par les deux inventeurs de la m&eacute;thode : Ken Schwaber et Jeff Sutherland. Ces derniers d&eacute;finissent la m&eacute;thode comme &eacute;tant &ldquo;un cadre de travail (framework) pour le d&eacute;veloppement, la livraison et la maintenance de produits complexes. &ldquo;</p>
        <p>Scrum est une m&eacute;thode l&eacute;g&egrave;re, simple &agrave; comprendre mais difficile &agrave; ma&icirc;triser. Elle s&rsquo;appuie sur trois piliers :</p>
        <p>La transparence, en effet, les &eacute;l&eacute;ments importants du projet seront visibles de tous les individus de chaque &eacute;quipe. Ce pilier implique que le langage utilis&eacute; soit compr&eacute;hensible par tous.</p>
        <p>L&rsquo;inspection, une &eacute;tape destin&eacute;e &agrave; suivre l&rsquo;avanc&eacute;e du projet par rapport &agrave; un objectif du sprint. Un sprint est une p&eacute;riode pendant laquelle les personnes en charge du projet doivent concevoir un &eacute;l&eacute;ment sp&eacute;cifique du livrable avant r&eacute;vision. Un sprint dure en moyenne deux semaines et peut aller jusqu&rsquo;&agrave; un mois.</p>
        <p>Et enfin, l&rsquo;adaptation, repr&eacute;sentant un ajustement du livrable si celui-ci a d&eacute;riv&eacute; hors des limites acceptables.</p>
        <p><br></p>
        <h3>Quels sont les missions d&rsquo;un Scrum Master&nbsp;?&nbsp;</h3>
        <p>La mission principale d&rsquo;un Scrum Master est de manager l&rsquo;organisation de l&rsquo;&eacute;quipe de d&eacute;veloppement et de contr&ocirc;ler le travail effectif de chaque membre de l&rsquo;&eacute;quipe. Il est amen&eacute; dans son travail &agrave; r&eacute;aliser plusieurs missions&nbsp;:</p>
        <ul>
            <li>
                <p>Travail en m&eacute;thode agile</p>
            </li>
            <li>
                <p>Recueil du besoin aupr&egrave;s du Product Owner</p>
            </li>
            <li>
                <p>Etre le coach de l&rsquo;&eacute;quipe</p>
            </li>
            <li>
                <p>Tenue de r&eacute;unions d&rsquo;&eacute;quipe chaque matin (Les Dailys Scrum)</p>
            </li>
            <li>
                <p>Organisation de Sprint (Le Sprint Planning)</p>
            </li>
            <li>
                <p>Organisation de r&eacute;union d&rsquo;&eacute;valuation du Sprint (Le Sprint Review)</p>
            </li>
            <li>
                <p>Gestion de l&rsquo;interactivit&eacute; des communications de l&rsquo;&eacute;quipe</p>
            </li>
            <li>
                <p>Suivi du Product Backlog</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Quel sont ses comp&eacute;tences&nbsp;?<h3>
        <ul>
            <li>
                <p>Agilit&eacute; &nbsp;</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; travailler en &eacute;quipe</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; livrer un produit en respectant les d&eacute;lais</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; coacher des &eacute;quipes</p>
            </li>
            <li>
                <p>Capacit&eacute; manag&eacute;riale&nbsp;</p>
            </li>
            <li>
                <p>Capacit&eacute; relationnelle</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; faire &eacute;voluer une organisation vers l&rsquo;agilit&eacute;</p>
            </li>
            <li>
                <p>Capacit&eacute; p&eacute;dagogique</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Quel est son niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral&nbsp;?</h3>
        <p>Ce coach sp&eacute;cialis&eacute; en informatique est parfois autodidacte. Il est recommand&eacute; d&apos;avoir d&eacute;j&agrave; suivi une formation d&apos;ing&eacute;nieur informatique ou d&apos;&ecirc;tre titulaire d&apos;un dipl&ocirc;me de d&eacute;veloppeur dans une &eacute;cole d&apos;ing&eacute;nieurs ou des professions de l&apos;internet.</p>
        <p>En g&eacute;n&eacute;ral, le scrum master est dipl&ocirc;m&eacute; d&rsquo;un master en &eacute;tude d&rsquo;<a style="color: blue !important" href="https://m.onisep.fr/recherche?context=advanced&text=ingenieur+informatique">ing&eacute;nierie informatique</a>. Il existe plusieurs masters pr&eacute;parant &agrave; ce dipl&ocirc;me qui sont dispens&eacute;s dans les &eacute;coles d&rsquo;ing&eacute;nieurs ou &agrave; l&rsquo;universit&eacute;. Une certification PSM est n&eacute;cessaire pour etre Scrum Master. Il s&rsquo;agit d&rsquo;un questionnaire de 80 questions sous forme de choix multiples r&eacute;alis&eacute; dans un d&eacute;lai d&rsquo;une heure de temps. Il faut obtenir au moins 85 % de bonnes r&eacute;ponses pour valider la certification.&nbsp;</p>
        <p><br></p>
        <h3>Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un scrum master?</h3>
        <p>Au bout de quelques ann&eacute;es d&rsquo;exp&eacute;rience le Scrum Master peut pr&eacute;tendre devenir un responsable de direction informatique.</p>
        <p><br></p>
        <h2>Faseya met &agrave; disposition des entreprises des scrum master en mode agile</h2>
        <p><a style="color: blue !important" href="https://www.faseya.com/#/">Faseya est une plateforme</a> de mise en relation entre entreprises souhaitant innover et d&eacute;veloppeurs mobiles sp&eacute;cialis&eacute;s dans les domaines de l&apos;IT et du Digital.</p>
        <p><br></p>
        <h3>Quels sont les entreprises o&ugrave; travail le scrum master&nbsp;?</h3>
        <p><br></p>
        <ul>
            <li>
                <p>ESN (Entreprise de service num&eacute;rique).&nbsp;</p>
            </li>
            <li>
                <p>Start Up</p>
            </li>
            <li>
                <p>Agence de d&eacute;veloppement</p>
            </li>
            <li>
                <p>Entreprises innovantes</p>
            </li>
            <li>
                <p>Entreprise de Fintech</p>
            </li>
            <li>
                <p>Editeur de logiciel</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Comment Faseya recrute un scrum master?</h3>
        <p><a style="color: blue !important" href="https://www.faseya.com/#/consultants/developer">Faseya recrute</a> ses Scrum Master en leur faisant passer plusieurs entretiens techniques avec un chef de projet senior. A la suite de ces entretiens, une mise en situation est effectu&eacute;e avec un scenario.</p>
        <p><br></p>`
    },
    {
        name: "Chef de projet informatique", 
        url:'tout-savoir-sur-le-metier-de-chef-de-projet-informatique',
        title:`Description du poste de Chef de projet informatique : Rôle et responsabilités`,
        description: `Explorez des concepts clés tels que la description du métier de Chef de projet informatique✅ les missions et compétences du Chef de projet informatique ✅les entreprises où travail le Chef de projet informatique✅ Comment Faseya recrute ses Chef de projet informatique✅ Vous souhaitez recruter un Chef de projet informatique ?`,
        image: project, active:true,
        content:`<h2>Qu&rsquo;est-ce qu&rsquo;un chef de projet informatique ?</h2>
        <p>&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</p>
        <p>Un chef de projet informatique est un&nbsp;<a style="color: blue !important" href="https://www.faseya.com/#/inscription/consultants">consultant</a> qui est le chef d&rsquo;orchestre dans la r&eacute;alisation d&rsquo;un projet informatique. Il est impliqu&eacute; dans toutes les phases du projet, d&egrave;s la r&eacute;daction des sp&eacute;cificit&eacute; techniques et fonctionnels du projet &agrave; la livraison du projet. Il a en charge la coordination de tous les intervenants du projet. Il g&egrave;re le budget et s&rsquo;assure que le projet soit livr&eacute; avec la qualit&eacute; attendu par le client. Le chef de projet est en relation direct avec le client et est en charge de transmette la vision du client aux &eacute;quipes de d&eacute;veloppement. Il manage l&rsquo;&eacute;quipe en s&rsquo;assurant de la productivit&eacute; de toutes les personnes impliqu&eacute;es dans le projet. Ayant une visibilit&eacute; de 360&deg; sur l&rsquo;ex&eacute;cution du planning de livraison, il rend r&eacute;guli&egrave;rement compte au client de l&rsquo;avancement du projet.</p>
        <p><br></p>
        <h3>Quels sont les missions d&rsquo;un chef de projet informatique&nbsp;?</h3>
        <p>Ses missions sont vari&eacute;es que ce soit dans le secteur priv&eacute; ou dans le secteur public. Le poste de chef de projet informatique est tr&egrave;s recherch&eacute; dans les entreprises utilisatrices comme l&rsquo;industrie automobile, la grande distribution, les banques, la t&eacute;l&eacute;communication, les administrations, les grandes entreprises, etc. Dans une soci&eacute;t&eacute; de services en ing&eacute;nierie informatique ou SSII, il se voit confier une mission distincte pour une entreprise cliente (syst&egrave;me informatique externalis&eacute;).</p>
        <p>La mission principale du chef de projet est de mener &agrave; bien l&rsquo;ex&eacute;cution du projet du client. Il est amen&eacute; dans son travail &agrave; r&eacute;aliser plusieurs missions&nbsp;:</p>
        <ul>
            <li>
                <p>Elaboration de dossier technique pour r&eacute;pondre aux appels d&rsquo;offre</p>
            </li>
            <li>
                <p>R&eacute;daction du cahier des charges ou cahier d&rsquo;analyse du besoin</p>
            </li>
            <li>
                <p>Participe au recrutement de l&rsquo;&eacute;quipe en charge de d&eacute;velopper les syst&egrave;mes informatiques</p>
            </li>
            <li>
                <p>Travail en m&eacute;thode agile&nbsp;</p>
            </li>
            <li>
                <p>Tenue de r&eacute;unions d&rsquo;&eacute;quipe avec le Scrum Master et l&rsquo;ensemble de l&rsquo;&eacute;quipe de d&eacute;veloppement</p>
            </li>
            <li>
                <p>Gestion du budget</p>
            </li>
            <li>
                <p>Gestion des plannings</p>
            </li>
            <li>
                <p>Participation &agrave; la livraison des recettes clients</p>
            </li>
            <li>
                <p>Participe au recrutement de l&rsquo;&eacute;quipe en charge de d&eacute;velopper les syst&egrave;mes informatiques.</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Quel sont les comp&eacute;tences d&rsquo;un chef de projet&nbsp;informatique?</h3>
        <p>Le chef de projet informatique doit &ecirc;tre familier avec la technologie informatique et doit conna&icirc;tre le m&eacute;tier de l&rsquo;entreprise cliente. Il doit se tenir inform&eacute; des &eacute;volutions juridiques et r&eacute;glementaires de son secteur. Mais le plus important est qu&rsquo;il sache s&rsquo;adapter aux diff&eacute;rents contextes auxquels il est confront&eacute;, notamment lorsque les interlocuteurs et les missions changent.</p>
        <p>En plus du savoir-faire technique, le CPI doit poss&eacute;der des qualit&eacute;s relationnelles et de coordination relatives &agrave;&nbsp;<a style="color: blue !important" href="https://www.cegos.fr/formations/management-et-leadership/manager-une-equipe-niveau-1-2019">un bon manager</a> : sens de la communication, sens de l&rsquo;organisation, sens commercial, sens de la responsabilit&eacute;, m&eacute;thodologie, dynamisme, rigueur, une grande capacit&eacute; d&rsquo;&eacute;coute, etc.</p>
        <p><br></p>
        <ul>
            <li>
                <p>Agilit&eacute; &nbsp;</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; travailler en &eacute;quipe</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; faire des pr&eacute;sentations devant le client</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; r&eacute;diger des dossiers de r&eacute;ponse &agrave; des appels d&rsquo;offres</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; manager une &eacute;quipe pluridisciplinaire</p>
            </li>
            <li>
                <p>Capacit&eacute; de communication orale</p>
            </li>
            <li>
                <p>Capacit&eacute; de gestion de planning</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre chef de projet informatique&nbsp;?</h3>
        <p>Ce m&eacute;tier aux multiples facettes est rarement accessible aux d&eacute;butants. Ce poste demande g&eacute;n&eacute;ralement une formation de niveau bac+3 avec 5 ans d&rsquo;exp&eacute;rience sur le terrain, surtout dans le d&eacute;veloppement voire plus selon la difficult&eacute; ou l&rsquo;ampleur du projet &agrave; piloter. Les recrutements de CPI se font par promotion interne.</p>
        <p>La formation en <a style="color: blue !important" href="https://www.onisep.fr/Choisir-mes-etudes/ma-premiere-annee-en-ecole-d-ingenieurs/Ecole-d-ingenieurs-en-informatique">&nbsp;&eacute;cole d&rsquo;ing&eacute;nieurs</a> est souvent la plus appropri&eacute;e. Toutefois, des titulaires de DUT/BTS peuvent se retrouver &agrave; ce poste apr&egrave;s avoir compl&eacute;t&eacute; leurs cursus par une licence pro ou un master (en g&eacute;nie logiciel, en informatique de gestion, en informatique appliqu&eacute;e, en informatique productique ou en imagerie num&eacute;rique) ou encore apr&egrave;s avoir int&eacute;gr&eacute; une &eacute;cole avec un bac+2.</p>
        <h3>Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un chef de projet informatique?</h3>
        <p>Au bout de quelques ann&eacute;es d&rsquo;exp&eacute;rience le chef de projet peut pr&eacute;tendre devenir directeur des services informatique, c&rsquo;est-&agrave;-dire avoir la responsabilit&eacute; de tous les services informatiques de l&rsquo;entreprise.</p>
        <p>&nbsp;</p>
        <h2>Faseya met &agrave; disposition des entreprises des chefs de projet informatique en mode agile.</h2>
        <p><a style="color: blue !important" href="https://www.faseya.com/#/">Faseya est une&nbsp;plateforme</a> de mise en relation entre entreprises souhaitant innover et chefs de projet sp&eacute;cialis&eacute;s dans les domaines de l&apos;IT et du Digital.</p>
        <p><br></p>
        <h3>Quels sont les entreprises o&ugrave; travail le chef de projet informatique ?</h3>
        <ul>
            <li>
                <p>ESN (Entreprise de service num&eacute;rique).&nbsp;</p>
            </li>
            <li>
                <p>Start Up</p>
            </li>
            <li>
                <p>Agence de d&eacute;veloppement de site internet</p>
            </li>
            <li>
                <p>Entreprises innovantes</p>
            </li>
            <li>
                <p>Grandes entreprises de tous secteurs d&rsquo;activit&eacute;</p>
            </li>
            <li>
                <p>Entreprise de Fintech</p>
            </li>
            <li>
                <p>Editeur de logiciel</p>
            </li>
        </ul>
        <h3>Comment Faseya recrute ses chefs de projet informatique ?</h3>
        <p>Faseya&nbsp;<a style="color: blue !important" href="https://faseya.com/#/inscription/consultants">recrute</a> ses chefs de projet en leur faisant passer plusieurs entretiens. A la suite de ces entretiens, un projet test est soumis au chef de projet pour &eacute;valuer sa capacit&eacute; &agrave; mener &agrave; bien un projet d&rsquo;envergure.</p>
        <p><br></p>`
    },
    {
        name: "Developpeur", 
        title:``,
        url: 'tout-savoir-sur-le-metier-de-developpeur',
        description: ``,
        image: dev, active:true,
        content:`<h2>Qu&rsquo;est-ce qu&rsquo;un D&eacute;veloppeur&nbsp; ?</h2>
        <p>Le d&eacute;veloppeur est un ing&eacute;nieur informatique responsable de cr&eacute;er des programmes informatiques bas&eacute;s sur diff&eacute;rents langages de programmation tels que Java, Phyton, PHP pour les mieux connus. pour les plus connues. Son r&ocirc;le est central dans la conception des logiciels informatique. Il participe m&ecirc;me avant la conception &agrave; la r&eacute;daction du cahier de charge et de son analyse. Il permet ainsi aux clients de mettre en &oelig;uvre leurs vision ou solution informatique.</p>
        <p>Un d&eacute;veloppeur est avant tout un expert <a style="color: blue !important" href="https://fr.wikipedia.org/wiki/Langage_informatique">des langages informatiques</a>. Il doit donc ma&icirc;triser un ou plusieurs langages ainsi que les concepts. La connaissance du secteur d&apos;activit&eacute; dans lequel va &ecirc;tre utilis&eacute; le logiciel est un atout.</p>
        <p>*Quels sont les langages de programmation utilis&eacute;e par les d&eacute;veloppeurs&nbsp;?</p>
        <p>Il existe plusieurs langages de programmation, la plupart disponible en Open Source. C&rsquo;est-&agrave;-dire pouvant etre utilis&eacute; sans licences. Toutefois les langages les plus utilis&eacute;s tels que Java, Python, PHP sont maintenus et &eacute;volu&eacute; par une communaut&eacute; de d&eacute;veloppeur &agrave; travers tout le monde entier ce qui rend ces langages de programmation universels et compris par tous les d&eacute;veloppeurs quelques soit sa situation g&eacute;ographie dans le monde.</p>
        <p><br></p>
        <p>Le d&eacute;veloppeur web front-end</p>
        <p>Le front-end correspond &agrave; la partie visible d&rsquo;un site ou d&rsquo;un logiciel par un utilisateur, c&rsquo;est-&agrave; -dire le site en lui-m&ecirc;me : la charte graphique, les pages, les diff&eacute;rents &eacute;l&eacute;ments graphiques (vid&eacute;os, textes, images, etc.), les menus de navigation, les fonctionnalit&eacute;s, etc.</p>
        <p>Le d&eacute;veloppement front-end consiste donc &agrave; la cr&eacute;ation du projet visuel dans son int&eacute;gralit&eacute;. Le d&eacute;veloppeur web peut donc intervenir sur plusieurs aspects :</p>
        <p>&Eacute;crire le code du site (instructions des actions &agrave; r&eacute;aliser par l&rsquo;ordinateur);</p>
        <p>Structurer le site;</p>
        <p>Concevoir les &eacute;l&eacute;ments graphiques;</p>
        <p>D&eacute;velopper les modules et autres sp&eacute;cificit&eacute;s;</p>
        <p>Cr&eacute;er les contenus m&eacute;dias;</p>
        <p>R&eacute;diger le contenu des pages;&nbsp;</p>
        <p>Proc&eacute;der au d&eacute;bogage (correction des erreurs).</p>
        <p>Cependant, face &agrave; l&rsquo;&eacute;volution permanente du secteur informatique et des outils de d&eacute;veloppement et &agrave; l&rsquo;accroissement des demandes clients sp&eacute;cifiques pour se d&eacute;marquer de la forte concurrence sur internet, de nouveaux m&eacute;tiers sp&eacute;cialis&eacute;s apparaissent.</p>
        <p>De ce fait, le d&eacute;veloppeur web front-end ne r&eacute;dige quasi plus le contenu des pages, mission confi&eacute;e aux experts en r&eacute;daction web optimis&eacute;e (SEO), la cr&eacute;ation graphique est davantage c&eacute;d&eacute;e aux graphistes et l&rsquo;optimisation du r&eacute;f&eacute;rencement du site peut &ecirc;tre r&eacute;alis&eacute;e par des web designer ou des web content manager.</p>
        <p><br></p>
        <p>Le d&eacute;veloppeur web back-end</p>
        <p>La partie back-end est peu connue des utilisateurs d&rsquo;internet ou d&rsquo;applications, et pourtant, elle est capitale, car un site ou une application internet ne sont pas fonctionnels ni consultables, sans d&eacute;veloppement back-end.</p>
        <p>Le d&eacute;veloppeur back-end est charg&eacute; de l&rsquo;ensemble des activit&eacute;s li&eacute;es &agrave; l&rsquo;int&eacute;gration d&rsquo;un site ou d&rsquo;une application dans son environnement :</p>
        <p>L&rsquo;int&eacute;gration du site au serveur d&rsquo;h&eacute;bergement, lieu o&ugrave; tous les sites internet sont stock&eacute;s, il s&rsquo;agit de sa mise en ligne sur internet et le param&eacute;trage de l&rsquo;h&eacute;bergement (adresse URL, syst&egrave;me du nom de domaine, r&eacute;seau de diffusion de contenus, etc.);</p>
        <p>L&rsquo;&eacute;laboration des bases de donn&eacute;es permettant la sauvegarde des donn&eacute;es n&eacute;cessaires au fonctionnement du site ou de l&rsquo;application (ID client, mots de passe, pr&eacute;f&eacute;rences utilisateurs, etc.);</p>
        <p>L&rsquo;installation du PHP et du protocole HTML afin d&rsquo;assurer la communication entre les diff&eacute;rents langages de programmation propres au front-end et au back-end ;</p>
        <p>Le param&eacute;trage du certificat SSL afin d&rsquo;assurer la s&eacute;curit&eacute; des utilisateurs du site ou de l&rsquo;application, contre les sites frauduleux par exemple ou les activit&eacute;s ill&eacute;gales.</p>
        <p><br></p>
        <p>Le d&eacute;veloppeur web back-end</p>
        <p>La partie back-end est peu connue des utilisateurs d&rsquo;internet ou d&rsquo;applications, et pourtant, elle est capitale, car un site ou une application internet ne sont pas fonctionnels ni consultables, sans d&eacute;veloppement back-end.</p>
        <p>Le d&eacute;veloppeur back-end est charg&eacute; de l&rsquo;ensemble des activit&eacute;s li&eacute;es &agrave; l&rsquo;int&eacute;gration d&rsquo;un site ou d&rsquo;une application dans son environnement :</p>
        <p>L&rsquo;int&eacute;gration du site au serveur d&rsquo;h&eacute;bergement, lieu o&ugrave; tous les sites internet sont stock&eacute;s, il s&rsquo;agit de sa mise en ligne sur internet et le param&eacute;trage de l&rsquo;h&eacute;bergement (adresse URL, syst&egrave;me du nom de domaine, r&eacute;seau de diffusion de contenus, etc.);</p>
        <p>L&rsquo;&eacute;laboration des bases de donn&eacute;es permettant la sauvegarde des donn&eacute;es n&eacute;cessaires au fonctionnement du site ou de l&rsquo;application (ID client, mots de passe, pr&eacute;f&eacute;rences utilisateurs, etc.);</p>
        <p>L&rsquo;installation du PHP et du protocole HTML afin d&rsquo;assurer la communication entre les diff&eacute;rents langages de programmation propres au front-end et au back-end ;</p>
        <p>Le param&eacute;trage du certificat SSL afin d&rsquo;assurer la s&eacute;curit&eacute; des utilisateurs du site ou de l&rsquo;application, contre les sites frauduleux par exemple ou les activit&eacute;s ill&eacute;gales.</p>
        <p><br></p>
        <h3>Quels sont les missions d&rsquo;un d&eacute;veloppeur ?&nbsp;</h3>
        <p>Les <a style="color: blue !important" href="https://www.faseya.com/#/pricing">missions des d&eacute;veloppeurs</a> sont plurielles (conception de sites internet ou intranet, d&eacute;veloppement d&rsquo;applications informatiques ou mobiles, maintenance de site, etc.) et les domaines de comp&eacute;tences vari&eacute;s : full stack, front-end, back-end ou encore la connaissance de diff&eacute;rents langages de programmation (HTML, Javascript, C++, etc.).</p>
        <p><br></p>
        <h3>Quel sont les comp&eacute;tences d&rsquo;un d&eacute;veloppeur&nbsp;?</h3>
        <ul>
            <li>
                <p>Passion du m&eacute;tier et des nouvelles technologies;</p>
            </li>
            <li>
                <p>Cr&eacute;ativit&eacute;, notamment s&rsquo;il se charge de la cr&eacute;ation graphique;</p>
            </li>
            <li>
                <p>Curiosit&eacute;, qu&rsquo;il s&rsquo;agisse de l&rsquo;univers du client ou de la veille permanente sur les &eacute;volutions du digital et du num&eacute;rique;</p>
            </li>
            <li>
                <p>Forte capacit&eacute; d&rsquo;adaptation, car le d&eacute;veloppement est un m&eacute;tier en constance &eacute;volution;</p>
            </li>
            <li>
                <p>Sens de la rigueur et rapidit&eacute; d&rsquo;ex&eacute;cution;&nbsp;</p>
            </li>
            <li>
                <p>Esprit d&rsquo;analyse et de synth&egrave;se;</p>
            </li>
            <li>
                <p>Logique et pr&eacute;cision;</p>
            </li>
            <li>
                <p>Qualit&eacute;s intrins&egrave;ques aux ind&eacute;pendants s&rsquo;il souhaite &ecirc;tre freelance (aisance relationnelle, discipline, gestion d&rsquo;entreprise, etc.)</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre d&eacute;veloppeur&nbsp;?</h3>
        <p>Apr&egrave;s le bac</p>
        <p>2 ans pour obtenir le BTS services informatiques aux organisations ; 3 ans pour le BUT informatique, pour le BUT statistique et informatique d&eacute;cisionnelle parcours sciences des donn&eacute;es : visualisation, conception d&apos;outils d&eacute;cisionnels, pour concevoir des applications d&apos;aide &agrave; la d&eacute;cision &agrave; partir de donn&eacute;es ; 5 ans pour un master, un dipl&ocirc;me d&apos;ing&eacute;nieur ou <a style="color: blue !important" href="https://www.onisep.fr/Ressources/Univers-Metier/Metiers/developpeur-developpeuse-informatique">d&apos;&eacute;cole sp&eacute;cialis&eacute;e, avec une sp&eacute;cialit&eacute; programmation.</a></p>
        <p><br></p>
        <h3>Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un d&eacute;veloppeur ?</h3>
        <p>Si en plus, notre d&eacute;veloppeur ma&icirc;trise plusieurs langages et un large &eacute;ventail de technologies, il devient alors un d&eacute;veloppeur polyvalent plus qu&rsquo;int&eacute;ressant.</p>
        <p><br></p>
        <h2>Faseya met &agrave; disposition des entreprises des d&eacute;veloppeurs en mode agile.</h2>
        <p><a style="color: blue !important" href="https://www.faseya.com/#/">Faseya est une&nbsp;plateforme</a> de mise en relation entre entreprises souhaitant innover et d&eacute;veloppeurs sp&eacute;cialis&eacute;s dans les domaines de l&apos;IT et du Digital.</p>
        <p><br></p>
        <h3>Quels sont les entreprises o&ugrave; travail le d&eacute;veloppeur ?</h3>
        <p>Les entreprises sont les premi&egrave;res entit&eacute;s ayant recours &agrave; des d&eacute;veloppeurs. Ayant un besoin de plus en plus croissant de faire &eacute;voluer leurs syst&egrave;mes informatiques et de vouloir innover pour proposer toujours de plus en plus de nouveaux services &agrave; leurs clients.&nbsp;</p>
        <p>Les administrations publiques ont besoin de d&eacute;veloppeurs ou d&rsquo;entreprises qui les fournissent car elles sont constamment dans la recherche d&rsquo;am&eacute;lioration de leurs syst&egrave;mes informatique ainsi que la digitalisation des services de l&rsquo;Etat.</p>
        <p>Les multinationales ont besoin &eacute;galement de d&eacute;veloppeurs pour pouvoir rester &agrave; la pointe de l&rsquo;innovation. Des soci&eacute;t&eacute;s comme Google, Apple, Facebook sont aujourd&rsquo;hui les entreprises les plus innovante et disposent aujourd&rsquo;hui de la plus grande masse salariale consacr&eacute; aux d&eacute;veloppeurs.</p>
        <p>Juste en prenant l&rsquo;exemple de Facebook, 10000 empois devrait etre cr&eacute;e en Europe &agrave; partir de 2022 sur la conception de r&eacute;alit&eacute; augment&eacute;e.</p>
        <p><br></p>
        <h3>Comment Faseya recrute ses d&eacute;veloppeurs ?</h3>
        <p>Faseya <a style="color: blue !important" href="https://faseya.com/#/inscription/consultants">recrute</a> ses d&eacute;veloppeurs en leur faisant passer plusieurs entretiens techniques avec un chef de projet senior. A la suite de ces entretiens, un test de validation technique est effectu&eacute;.</p>
        <p>Vous &ecirc;tes d&eacute;veloppeur et cherchez une mission&nbsp;ou bien un CDI&nbsp;?&nbsp;Rejoignez la communaut&eacute; faseya et d&eacute;couvrez les missions publi&eacute;es.</p>
        <p><br></p>`
    },
    {
        name: "Développeur IOS", 
        title:``,
        description: ``,
        url: 'tout-savoir-sur-le-metier-de-developpeur-ios',
        image: ios_dev, active:true,
        content:`
        <h2>Qu&rsquo;est-ce qu&rsquo;un D&eacute;veloppeur IOS ?&nbsp;</h2>
        <p>&nbsp; &nbsp;&nbsp;</p>
        <p>Le d&eacute;veloppeur IOS est en charge de la programmation d&rsquo;applications pour iPhone et iPad sous syst&egrave;me d&rsquo;exploitation iOS. Il s&rsquo;occupe du d&eacute;veloppement, des tests et de la correction des bugs des applications dans l&rsquo;Apple store. Pour cela il travaille avec divers collaborateurs tels que le&nbsp;chef de projet&nbsp;ou le&nbsp;graphiste&nbsp;afin de suivre les indications et &eacute;crire un cahier des charges. Lorsque le d&eacute;veloppement de l&rsquo;application est termin&eacute;, le d&eacute;veloppeur iOS doit effectuer plusieurs tests pour trouver les bugs et optimiser l&rsquo;utilisation de l&rsquo;application. Cela rend l&rsquo;application plus appr&eacute;ciable aupr&egrave;s des utilisateurs. Pour finir, il assure le support technique de l&rsquo;application et r&eacute;dige une documentation technique et fonctionnelle pour les utilisateurs.</p>
        <p>D&rsquo;autres appareils sont &eacute;galement concern&eacute;s, le d&eacute;veloppeur iOS doit &ecirc;tre capable de d&eacute;velopper des applications sur les appareils tels que l&rsquo;Apple Watch ou les ordinateurs Mac.</p>
        <p>Si vous &ecirc;tes recruteur, consultez notre guide pour&nbsp;<a style="color blue !important" href="https://www.faseya.com/#/consultants/developer">recruter un bon d&eacute;veloppeur</a>.</p>
        <h3>Quels sont les missions d&rsquo;un d&eacute;veloppeur IOS&nbsp;?&nbsp;</h3>
        <p>Les d&eacute;veloppeurs d&rsquo;applications iOS sont charg&eacute;s de cr&eacute;er des applications intuitives et accrocheuses pour les appareils mobiles aliment&eacute;s par le&nbsp;<a style="color blue !important" href="https://www.apple.com/cm/macos/what-is/#:~:text=macOS%20est%20le%20syst%C3%A8me%20d,l'accueille%2C%20et%20inversement.">syst&egrave;me d&rsquo;exploitation d&rsquo;Apple</a>. &Agrave; bien des &eacute;gards, ces d&eacute;veloppeurs contribuent de mani&egrave;re significative &agrave; l&rsquo;image de marque, car des applications mal con&ccedil;ues peuvent donner lieu &agrave; une opinion n&eacute;gative sur la soci&eacute;t&eacute;.</p>
        <p>Les d&eacute;veloppeurs d&rsquo;applications d&rsquo;Apple sont cens&eacute;s travailler dans une &eacute;quipe diversifi&eacute;e comprenant des gestionnaires, des concepteurs et d&rsquo;autres d&eacute;veloppeurs. Parmi les autres responsabilit&eacute;s, citons :</p>
        <p>&nbsp;</p>
        <p>La mission principale d&rsquo;un d&eacute;veloppeur IOS est de d&eacute;velopper une application mobile avec le langage Swift. Il est amen&eacute; dans son travail &agrave; r&eacute;aliser plusieurs missions&nbsp;:</p>
        <ul>
            <li>
                <p>R&eacute;daction du cahier des charges ou cahier d&rsquo;analyse du besoin</p>
            </li>
            <li>
                <p>Travail en m&eacute;thode agile</p>
            </li>
            <li>
                <p>Tenue de r&eacute;union d&rsquo;&eacute;quipe avec le chef de projet et le Scrum Master</p>
            </li>
            <li>
                <p>D&eacute;veloppement interface front end</p>
            </li>
            <li>
                <p>D&eacute;veloppement back end</p>
            </li>
            <li>
                <p>Publication de l&rsquo;application sur Apple Store</p>
            </li>
            <li>
                <p>Concevoir et cr&eacute;er des applications natives avanc&eacute;es sur la plate-forme Apple;</p>
            </li>
            <li>
                <p>Travailler avec des &eacute;quipes inter fonctionnelles pour d&eacute;finir, concevoir et exp&eacute;dier de nouvelles fonctionnalit&eacute;s;</p>
            </li>
            <li>
                <p>Code de test unitaire pour la robustesse, y compris les cas extr&ecirc;mes, la facilit&eacute; d&rsquo;utilisation et la fiabilit&eacute; g&eacute;n&eacute;rale;</p>
            </li>
            <li>
                <p>Identifier et corriger les goulots d&rsquo;&eacute;tranglement et corriger les bugs;</p>
            </li>
            <li>
                <p>D&eacute;couvrir, &eacute;valuer et impl&eacute;menter continuellement de nouvelles technologies pour maximiser l&rsquo;efficacit&eacute; du d&eacute;veloppement;</p>
            </li>
            <li>
                <p>Aider &agrave; maintenir la qualit&eacute; du code, l&rsquo;organisation et l&rsquo;automatisation;</p>
            </li>
            <li>
                <p>Construire des applications multithreads sophistiqu&eacute;es.</p>
            </li>
            <li>
                <p>Participation &agrave; la phase de test</p>
            </li>
            <li>
                <p>Correction beugs de programmation</p>
            </li>
            <li>
                <p>Maintenance et mise &agrave; jour de l&rsquo;application</p>
            </li>
        </ul>
        <h3>Quel sont les comp&eacute;tences d&rsquo;un d&eacute;veloppeur IOS ?</h3>
        <p><br></p>
        <p>Agilit&eacute; &nbsp;</p>
        <ul>
            <li>
                <p>Capacit&eacute; &agrave; travailler en &eacute;quipe</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; presenter son programme informatique (code sources)</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; faire des pr&eacute;sentations d&rsquo;&eacute;crans mobiles avec un &eacute;mulateur</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; r&eacute;diger de la documentation technique</p>
            </li>
        </ul>
        <h3>Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre d&eacute;veloppeur IOS&nbsp;?</h3>
        <p>En g&eacute;n&eacute;ral, le d&eacute;veloppeur IOS est dipl&ocirc;m&eacute; d&rsquo;un master en &eacute;tude d&rsquo;ing&eacute;nierie informatique. Il existe plusieurs masters pr&eacute;parant &agrave; ce dipl&ocirc;me qui sont dispens&eacute;s dans&nbsp;<a style="color blue !important" href="https://www.onisep.fr/Choisir-mes-etudes/ma-premiere-annee-en-ecole-d-ingenieurs/Ecole-d-ingenieurs-en-informatique">les &eacute;coles d&rsquo;ing&eacute;nieur ou &agrave; l&rsquo;universit&eacute;.</a></p>
        <h3>Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un d&eacute;veloppeur IOS?</h3>
        <p>Au bout de quelques ann&eacute;es d&rsquo;exp&eacute;rience le d&eacute;veloppeur IOS peut pr&eacute;tendre devenir un Tech Lead IOS, c&rsquo;est-&agrave;-dire un leader technique encadrant une &eacute;quipe de d&eacute;veloppeur.</p>
        <p><br></p>
        <h2>Faseya met &agrave; disposition des entreprises des d&eacute;veloppeurs IOS en mode agile.</h2>
        <h3>Une solution faite pour les PME</h3>
        <p><a style="color blue !important" href="https://www.faseya.com/#/">Faseya est une plateforme</a> de mise en relation entre entreprises souhaitant innover et d&eacute;veloppeurs ios sp&eacute;cialis&eacute;s dans les domaines de l&apos;IT et du Digital.</p>
        <p><br></p>
        <h3>Quels sont les entreprises o&ugrave; travail le d&eacute;veloppeur ios&nbsp;?</h3>
        <p><br></p>
        <ul>
            <li>
                <p>ESN (Entreprise de service num&eacute;rique).&nbsp;</p>
            </li>
            <li>
                <p>Start Up</p>
            </li>
            <li>
                <p>Petites et moyenne entreprise</p>
            </li>
            <li>
                <p>Agence de d&eacute;veloppement mobile</p>
            </li>
            <li>
                <p>Agence de d&eacute;veloppement de site internet</p>
            </li>
            <li>
                <p>Entreprises innovantes</p>
            </li>
            <li>
                <p>Entreprise de Fintech</p>
            </li>
            <li>
                <p>Editeur de logiciel</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Comment Faseya recrute ses d&eacute;veloppeurs IOS?</h3>
        <p>Faseya&nbsp;<a style="color blue !important" href="https://faseya.com/#/inscription/consultants">recrute</a> ses d&eacute;veloppeurs IOS en leur faisant passer plusieurs entretiens techniques avec un chef de projet senior. A la suite de ces entretiens, un test de validation technique est effectu&eacute;.</p>
        <p>Vous &ecirc;tes d&eacute;veloppeur iOS et cherchez une mission ou bien un CDI ? Rejoignez la communaut&eacute; faseya et d&eacute;couvrez les missions publi&eacute;es.</p>
        `
    },
    {
        name: "Testeur", 
        title:``,
        description: ``,
        image: testeur, active:true,
        content:`<h2>Qu&rsquo;est-ce qu&rsquo;un Testeur ?&nbsp;</h2>
        <p>Le&nbsp;<a style="color: blue !important" href="https://fr.wikipedia.org/wiki/Testeur">testeur logiciel</a> est un professionnel de l&rsquo;informatique qui r&eacute;alise des tests et v&eacute;rifications sur divers logiciels, afin de s&rsquo;assurer qu&rsquo;ils sont fonctionnels. Il parcourt un logiciel et d&eacute;tecte tout dysfonctionnement ou anomalie. Pour cela, il effectue un travail pr&eacute;paratoire &agrave; l&rsquo;aide du cahier des charges qui a d&eacute;termin&eacute; la conception du logiciel. Puis, il analyse les bugs de performance et d&apos;utilisation, et r&eacute;dige un rapport qu&rsquo;il transmet aux d&eacute;veloppeurs, qui s&rsquo;occupent ensuite d&rsquo;effectuer corrections et modifications. Le champ d&rsquo;intervention du testeur logiciel s&rsquo;&eacute;tend de la performance &agrave; la programmation, tout en passant par l&apos;exp&eacute;rience utilisateur ou UX (User eXperience), la s&eacute;curit&eacute; et l&rsquo;adaptabilit&eacute;. Le r&ocirc;le du testeur logiciel est fondamental au sein d&rsquo;une &eacute;quipe informatique. Son m&eacute;tier fait partie des fonctions relatives &agrave; la qualit&eacute; du logiciel, aussi appel&eacute;e SQA (Software Quality Assurance).</p>
        <p><br></p>
        <h3>Quels sont les missions d&rsquo;un testeur&nbsp;?</h3>
        <p>Les attributions du testeur peuvent contenir&nbsp;:</p>
        <ul>
            <li>
                <p>R&eacute;daction des plans, sc&eacute;narios et cas de test manuels.</p>
            </li>
            <li>
                <p>Revue de l&apos;expression du besoin, des exigences, des User Stories et des crit&egrave;res d&apos;acceptations.</p>
            </li>
            <li>
                <p>Ex&eacute;cution des tests manuels.</p>
            </li>
            <li>
                <p>Automatisation des tests.</p>
            </li>
            <li>
                <p>&Eacute;valuation des r&eacute;sultats des tests.</p>
            </li>
            <li>
                <p>R&eacute;daction des rapports de&nbsp;<a style="color: blue !important" href="https://fr.wikipedia.org/wiki/Bug_(informatique)">bug</a>.</p>
            </li>
            <li>
                <p>Reproduction des anomalies.</p>
            </li>
            <li>
                <p>Qualification des d&eacute;fauts du logiciel.</p>
            </li>
            <li>
                <p>Constitution des jeux de donn&eacute;es des tests.</p>
            </li>
            <li>
                <p>&Eacute;valuation des outils de tests.</p>
            </li>
            <li>
                <p>Conception et ex&eacute;cution des tests dits non-fonctionnels (s&eacute;curit&eacute;, performance, accessibilit&eacute;, etc).</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Quel sont les comp&eacute;tences d&rsquo;un testeur&nbsp;?</h3>
        <p>Le testeur doit faire preuve de certaines qualit&eacute;s afin de mener &agrave; bien les diff&eacute;rents projets qui lui sont confi&eacute;s&nbsp;:</p>
        <ul>
            <li>
                <p>La curiosit&eacute;</p>
            </li>
            <li>
                <p>La rigueur</p>
            </li>
            <li>
                <p>Une excellente communication</p>
            </li>
        </ul>
        <p>Il &eacute;volue avec l&apos;exp&eacute;rience.</p>
        <p>&nbsp;</p>
        <h3>Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre Testeur&nbsp;?</h3>
        <p>&Agrave; ce jour, la majorit&eacute; des offres d&rsquo;emploi recherchent un candidat dipl&ocirc;m&eacute; d&rsquo;une formation niveau bac +2 &agrave; bac +5. En deux ann&eacute;es d&rsquo;&eacute;tudes sup&eacute;rieures, le BTS (Brevet de technicien Sup&eacute;rieur) SIO (Services Informatiques aux Organisations) option SLAM (Solutions Logicielles et Applications M&eacute;tiers) figure en bonne place. En 3 ans, vous pouvez pr&eacute;parer la Licence professionnelle M&eacute;tiers de l&apos;Informatique mention Conception, D&eacute;veloppement et Tests de Logiciels, qui vous destine express&eacute;ment au m&eacute;tier de testeur logiciel. En 5 ans, vous pouvez pr&eacute;parer le dipl&ocirc;me d&rsquo;ing&eacute;nieur informatique en &eacute;cole d&rsquo;ing&eacute;nieur, le Master Informatique mention Ing&eacute;nierie des Logiciels&nbsp;<a style="color: blue !important" href="https://www.onisep.fr/Ressources/Univers-Metier/Metiers/testeur-testeuse-en-informatique">sp&eacute;cialit&eacute; Ing&eacute;nierie Logicielle</a> ou le Master Informatique mention G&eacute;nie Informatique et interactions Humain-Machines sp&eacute;cialit&eacute; G&eacute;nie Informatique &agrave; l&rsquo;universit&eacute;.</p>
        <p>&nbsp;</p>
        <h2>Faseya met &agrave; disposition des entreprises des testeurs en mode agile.</h2>
        <h3>Une solution faite pour les PME</h3>
        <p>Faseya met &agrave; disposition des testeurs en mode remote &agrave; des co&ucirc;ts tr&egrave;s bas. Nos talents sont des consultants confirm&eacute;s dans leurs domaines de comp&eacute;tences. Nous mettons &agrave; dispositions pour r&eacute;aliser vos missions des&nbsp;testeurs.</p>
        <p><br></p>
        <h3>Quels sont les entreprises o&ugrave; travail le testeur ?</h3>
        <ul>
            <li>
                <p>ESN (Entreprise de service num&eacute;rique).&nbsp;</p>
            </li>
            <li>
                <p>Start Up</p>
            </li>
            <li>
                <p>Petites et moyenne entreprise</p>
            </li>
            <li>
                <p>Agence de d&eacute;veloppement mobile</p>
            </li>
            <li>
                <p>Agence de d&eacute;veloppement de site internet</p>
            </li>
            <li>
                <p>Entreprises innovantes</p>
            </li>
            <li>
                <p>Entreprise de Fintech</p>
            </li>
            <li>
                <p>Editeur de logiciel</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Comment Faseya recrute ses Testeurs&nbsp;?</h3>
        <p><a style="color: blue !important" href="https://www.faseya.com/#/consultants/developer">Faseya recrute</a> ses testeurs en leur faisant passer plusieurs entretiens techniques avec un chef de projet senior. A la suite de ces entretiens, un test de validation technique est effectu&eacute;.</p>
        <p>&nbsp;</p>
        <h3>Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un testeur ?</h3>
        <p>Le m&eacute;tier de testeur en informatique est tr&egrave;s sollicit&eacute; par les &eacute;diteurs de logiciels, les agences web, les cabinets sp&eacute;cialis&eacute;s dans le test ainsi que les studios de d&eacute;veloppement de jeux vid&eacute;o. C&rsquo;est &eacute;galement une fonction qui se professionnalise de plus en plus, ce qui la rend beaucoup moins accessible aux autodidactes. Enfin, plusieurs perspectives d&rsquo;&eacute;volution de carri&egrave;re sont envisageables : un testeur op&eacute;rationnel travaillant sur des projets simples peut &eacute;voluer vers des projets plus complexes aupr&egrave;s de testeurs exp&eacute;riment&eacute;s. Il peut par la suite occuper le poste de responsable qualit&eacute; et m&eacute;thodes, responsable en d&eacute;veloppement informatique ou encore celui de directeur technique chez un &eacute;diteur de logiciels ou dans une entreprise de services du num&eacute;rique.</p>`
    },
    {
        name: "Product Manager", 
        title:``,
        description: ``,
        url: 'tout-savoir-sur-le-metier-de-product-manager',
        image: product_manager, active:false,
        content:`<h2>Qu&rsquo;est-ce qu&rsquo;un Product Manager ?&nbsp;</h2>
        <p>Un&nbsp;<a style="color: blue !important" href="https://en.wikipedia.org/wiki/Product_manager">product manager</a> travaille sur le d&eacute;veloppement de produits et/ou services digitaux qui n&eacute;cessitent des mises &agrave; jour r&eacute;guli&egrave;res tels que des applications, des sites web, des logiciels en SaaS, au sein d&rsquo;une &eacute;quipe dont il est le chef d&rsquo;orchestre.</p>
        <p>Dans les grandes lignes, il doit identifier le probl&egrave;me, le r&eacute;soudre, livrer le produit et continuer de l&rsquo;am&eacute;liorer. Il est par cons&eacute;quent responsable de son succ&egrave;s (ou de son &eacute;chec).</p>
        <p>&nbsp;</p>
        <p>Moins d&apos;une d&eacute;cennie auparavant, cette profession n&apos;existait m&ecirc;me pas en France. Maintenant, on retrouve des PM dans des entreprises de toutes tailles, que ce soit dans des grandes entreprises traditionnelles ou en startups. Ce m&eacute;tier est proche de celui de Chef de Produit, sauf que dans son cas, le PM travaille g&eacute;n&eacute;ralement dans l&rsquo;&eacute;quipe technique plut&ocirc;t qu&rsquo;au sein de l&rsquo;&eacute;quipe marketing.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h3>Quels sont les missions d&rsquo;un Product Manager&nbsp;?</h3>
        <p>Un Product Manager a pour mission g&eacute;n&eacute;ral de d&eacute;velopper et d&rsquo;am&eacute;liorer un produit digital.</p>
        <p>Pour mener &agrave; bien cet objectif, voici les &eacute;tapes par lesquelles passe le PM :</p>
        <p>&nbsp;</p>
        <ul>
            <li>
                <p>Vision strat&eacute;gique :&nbsp;d&eacute;finition de la vision produit et des roadmaps, &eacute;laboration d&rsquo;un benchmark</p>
            </li>
            <li>
                <p>Identification des besoins utilisateurs</p>
            </li>
            <li>
                <p>D&eacute;ploiement de la strat&eacute;gie et gestion de projet :&nbsp;mise en place et coordination de la roadmap avec les &eacute;quipes IT, priorisation et mise en production via la m&eacute;thodologie Agile afin de d&eacute;livrer de la valeur aux utilisateurs, pilotage et optimisation des parcours gr&acirc;ce au suivi des KPIs.</p>
            </li>
            <li>
                <p>Management et interface :&nbsp;travail en collaboration avec les &eacute;quipes produit (UX, innovation, &hellip;) et transverses (IT, Data, Finance, Juridique, Business, &hellip;) ainsi que les prestataires.</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Quel sont les comp&eacute;tences d&rsquo;un Product Manager&nbsp;?</h3>
        <ul>
            <li>
                <p><a style="color: blue !important" href="https://www.faseya.com/concept">M&eacute;thodologie Agile</a></p>
            </li>
            <li>
                <p>Tr&egrave;s bonne connaissance du march&eacute;</p>
            </li>
            <li>
                <p>Gestion des relations&nbsp;</p>
            </li>
            <li>
                <p>Travail transverse</p>
            </li>
            <li>
                <p>Culture du r&eacute;sultat</p>
            </li>
            <li>
                <p>Ing&eacute;niosit&eacute;</p>
            </li>
            <li>
                <p>Sens du relationnel</p>
            </li>
            <li>
                <p>Intelligence &eacute;motionnelle (EQ)</p>
            </li>
            <li>
                <p>Capacit&eacute; &agrave; prendre de la hauteur et &agrave; synth&eacute;tiser</p>
            </li>
            <li>
                <p>Rigueur</p>
            </li>
            <li>
                <p>Gestion des relations&nbsp;</p>
            </li>
            <li>
                <p>Anticipation</p>
            </li>
            <li>
                <p>Conscience de soi</p>
            </li>
            <li>
                <p>Gestion des relations&nbsp;</p>
            </li>
            <li>
                <p>Tr&egrave;s bonne connaissance du march&eacute;</p>
            </li>
            <li>
                <p>Sensibilisation sociale</p>
            </li>
        </ul>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h3>Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre Product Manager&nbsp;?</h3>
        <p>La meilleure solution est d&rsquo;int&eacute;grer le&nbsp;Mast&egrave;re Expert Strat&eacute;gies Digitales&nbsp;(reconnu Bac+5 / Niveau 7). Pour les novices, il existe un&nbsp;<a style="color: blue !important" href="https://www.onisep.fr/recherche?text=d%C3%A9veloppeur%20android">Mast&egrave;re 1 en Cycle Intensif</a>. Il s&rsquo;agit d&rsquo;une mise &agrave; niveau d&rsquo;1 an en mati&egrave;re de digital. Pour la seconde ann&eacute;e, il faudra choisir le Mast&egrave;re Expert Strat&eacute;gies Digitales comme pr&eacute;cis&eacute; plus haut.</p>
        <p><br></p>
        <h2>Faseya met &agrave; disposition des entreprises des products managers en mode agile.</h2>
        <p><br></p>
        <h3>Une solution faite pour les PME</h3>
        <p>Faseya met &agrave; disposition des talents du Product management en mode remote &agrave; des co&ucirc;ts tr&egrave;s bas. Nos talents sont des consultants confirm&eacute;s dans leurs domaines de comp&eacute;tences. Nous mettons &agrave; dispositions pour r&eacute;aliser vos missions des&nbsp;product managers.</p>
        <p><br></p>
        <h3>Quels sont les entreprises o&ugrave; travail les Product managers?</h3>
        <ul>
            <li>
                <p>ESN (Entreprise de service num&eacute;rique).&nbsp;</p>
            </li>
            <li>
                <p>Start Up</p>
            </li>
            <li>
                <p>Petites et moyenne entreprise</p>
            </li>
            <li>
                <p>Agence de d&eacute;veloppement mobile</p>
            </li>
            <li>
                <p>Agence de d&eacute;veloppement de site internet</p>
            </li>
            <li>
                <p>Entreprises innovantes</p>
            </li>
            <li>
                <p>Entreprise de Fintech</p>
            </li>
            <li>
                <p>Editeur de logiciel</p>
            </li>
        </ul>
        <p><br></p>
        <h3>Comment Faseya recrute ses Product managers&nbsp;?</h3>
        <p><a style="color: blue !important" href="https://www.faseya.com/#/consultants/developer">Faseya recrute</a> ses product managers en leur faisant passer plusieurs entretiens techniques avec un chef de projet senior. A la suite de ces entretiens, un test de validation technique est effectu&eacute;.</p>
        <p><br></p>
        <h3>Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un Product manager ?</h3>
        <p>Qui dit nouveaux m&eacute;tiers, dit que les profils exp&eacute;riment&eacute;s sont encore difficiles &agrave; trouver. C&rsquo;est pourquoi, ceux qui d&eacute;passent 7 &agrave; 8 ann&eacute;es d&rsquo;exp&eacute;rience sont tr&egrave;s courtis&eacute;s. Ainsi, il n&rsquo;est pas rare que les plus beaux profils se fassent chasser pas moins de 10 fois par semaine ! Il suffit de jeter un &oelig;il aux grilles de salaires des product managers pour comprendre que les startups et scaleups leur font les yeux doux.</p>`
    },
    {
        name: "Community Manager", 
        title:`Tout savoir sur le métier de community manager`,
        description: `Qu’est-ce qu'un community manager? Quelles études ou formations pour devenir community manager ? Ses missions et compétences?`,
        url: 'tout-savoir-sur-le-metier-de-community-manager',
        image: community_manager, active:true,
        content:`<h2>Qu&rsquo;est-ce qu&rsquo;un Community manager ?&nbsp;</h2>
        <p><a style={{color: "blue !important"}} href="https://fr.wikipedia.org/wiki/Community_manager">Animateur de communaut&eacute;</a>, ou gestionnaire de communaut&eacute;, aussi community manager (CM), est un m&eacute;tier. L&rsquo;animation de communaut&eacute;, gestion de communaut&eacute;, ou community management consiste &agrave; animer et &agrave; f&eacute;d&eacute;rer des communaut&eacute;s sur Internet pour le compte d&apos;une soci&eacute;t&eacute;, d&apos;une marque, d&rsquo;une c&eacute;l&eacute;brit&eacute;, d&rsquo;une institution ou d&apos;une collectivit&eacute; territoriale.</p>
        <p>Profond&eacute;ment li&eacute; au web 2.0 et au d&eacute;veloppement des r&eacute;seaux sociaux, le m&eacute;tier est aujourd&apos;hui encore en &eacute;volution. Le c&oelig;ur de la profession r&eacute;side dans l&apos;interaction et l&apos;&eacute;change avec les internautes (animation, mod&eacute;ration) ; mais le gestionnaire de communaut&eacute; peut occuper des activit&eacute;s diverses selon les contextes.</p>
        <p>L&apos;appellation community manager est int&eacute;gr&eacute;e au Petit Larousse 2016, confirmant l&apos;utilisation de cet anglicisme dans la langue fran&ccedil;aise.</p>
        <h3>Quels sont les missions d&rsquo;un community manager ?&nbsp;</h3>
        <ul>
            <li>
                <p>Le community manager doit avoir conscience de la ligne &eacute;ditoriale de l&rsquo;entreprise avant de commencer son travail.</p>
            </li>
            <li>
                <p>Faire de la veille informationnelle et concurrentielle&nbsp;:</p>
            </li>
        </ul>
        <p>Ce sera une question de vigilance sur ce qui est dit sur la marque, sur sa r&eacute;putation. Et surveillez la comp&eacute;tition et l&apos;actualit&eacute;.</p>
        <ul>
            <li>
                <p>Animer une communaut&eacute; : le r&ocirc;le principal d&rsquo;un community manager : &nbsp; Il s&apos;agit d&apos;alimenter les r&eacute;seaux sociaux comme Facebook, Linkedin et Instagram par la production de contenu tout en cr&eacute;ant de l&rsquo;engagement pour susciter l&rsquo;interaction avec les fans.</p>
            </li>
            <li>
                <p>Le community manager doit pouvoir produit du contenu avec des comp&eacute;tences qui vont au-del&agrave; du r&eacute;dactionnel : texte, image, son, vid&eacute;o, etc&hellip;</p>
            </li>
            <li>
                <p>&Ecirc;tre en contact avec les blogueurs qui sont dans le m&ecirc;me secteur d&apos;activit&eacute; que la soci&eacute;t&eacute;. Les blogueurs sont tr&egrave;s utiles pour partager l&rsquo;information de l&rsquo;entreprise avec un nouveau public.</p>
            </li>
            <li>
                <p>Faire de la relation client : de plus en plus de clients s&rsquo;adressent directement aux marques &agrave; travers les r&eacute;seaux sociaux.</p>
            </li>
            <li>
                <p>Organiser des &eacute;v&egrave;nements : cr&eacute;er des concours, retranscrire un live directement sur les r&eacute;seaux sociaux.</p>
            </li>
            <li>
                <p>Faire du SEO (R&eacute;f&eacute;rencement Naturel) : en plus d&rsquo;&eacute;crire des articles, le CM doit les optimiser. C&rsquo;est-&agrave;-dire qu&rsquo;il doit r&eacute;ussir &agrave; bien positionner son article sur le web afin qu&rsquo;il ait une bonne visibilit&eacute; et qu&rsquo;il g&eacute;n&egrave;re du trafic.</p>
            </li>
            <li>
                <p>D&eacute;finir la strat&eacute;gie de communication ou strat&eacute;gie digitale : il s&rsquo;agit de d&eacute;finir un ensemble d&rsquo;actions pour parvenir &agrave; un r&eacute;sultat. Il faut fixer des objectifs tels que le nombre d&rsquo;abonn&eacute;s, le positionnement, le nombre de conversions&hellip; Etc.</p>
            </li>
        </ul>
        <h3>Quel sont les comp&eacute;tences d&rsquo;un Community Manager ?</h3>
        <ul>
            <li>
                <p>Souplesse</p>
            </li>
            <li>
                <p>La cr&eacute;ativit&eacute;</p>
            </li>
            <li>
                <p>Pens&eacute;e analytique</p>
            </li>
            <li>
                <p>Empathie</p>
            </li>
            <li>
                <p>Rigueur</p>
            </li>
            <li>
                <p>Comp&eacute;tences en communication</p>
            </li>
            <li>
                <p>Innovation</p>
            </li>
            <li>
                <p>Vision &agrave; long terme</p>
            </li>
            <li>
                <p>Perspicacit&eacute;</p>
            </li>
        </ul>
        <h3>Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre Community manager&nbsp;?</h3>
        <p>En g&eacute;n&eacute;ral, le community manager est dipl&ocirc;m&eacute; d&rsquo;un <a style={{color: "blue !important"}} href="https://www.onisep.fr/Ressources/Univers-Formation/Formations/Post-bac/master-mention-information-communication">master dans une &eacute;cole de communication</a>, de marketing, de relations publiques ou de journalisme : Celsa, ECS (European Communication School), Iscom (&Eacute;cole sup&eacute;rieure de communication et de publicit&eacute;), CFJ (Centre de formation des journalistes), Isefac : maketing digital et r&eacute;seaux sociaux, EEMI (Ecole europ&eacute;enne des m&eacute;tiers de l&apos;internet).</p>
        <h3>Quel sont les perspectives d&rsquo;avenir d&rsquo;un Community manager?</h3>
        <p>Un community manager peut se d&eacute;velopper dans des postes comme gestionnaire des communications num&eacute;riques, gestionnaire de projet, gestionnaire des r&eacute;seaux sociaux.</p>
        <h2>Faseya met &agrave; disposition des entreprises des community manager en mode agile</h2>
        <p><a style={{color: "blue !important"}} href="https://www.faseya.com/#/">Faseya est une plateforme</a> de mise en relation entre entreprises souhaitant innover et community manager sp&eacute;cialis&eacute;s dans les domaines de l&apos;IT et du Digital.</p>
        <h3>Quels sont les entreprises o&ugrave; travail le community manager ?</h3>
        <ul>
            <li>
                <p>Agence web ;</p>
            </li>
            <li>
                <p>Agence de communication ;</p>
            </li>
            <li>
                <p>M&eacute;dia ;</p>
            </li>
            <li>
                <p>SSII ;</p>
            </li>
            <li>
                <p>Grands groupes industriels.</p>
            </li>
            <li>
                <p>ESN (Entreprise de service num&eacute;rique).&nbsp;</p>
            </li>
        </ul>
        <h3>Comment Faseya recrute un community manager?</h3>
        <p><a style={{color: "blue !important"}} href="https://www.faseya.com/#/consultants/developer">Faseya recrute</a> ses community managers en leur faisant passer plusieurs entretiens avec un community manager s&eacute;nior. A la suite de ces entretiens, une mise en situation est effectu&eacute;e.</p>
        <p><br></p>
        <p><br></p>`
    },
    {
        name:'Consultant SEO',
        title:`Description du poste de consultant SEO: missions et compétences`,
        description: `Explorez des concepts clés tels que la description du métier de consultant SEO  ✅ les missions et compétences du consultant SEO ✅les entreprises où travail le consultant SEO✅ Comment Faseya recrute ses consultants SEO✅ Vous souhaitez recruter un consultant SEO ?`,
        url: 'tout-savoir-sur-le-metier-de-consultant-seo',
        image: seo, active:true,
        content: `
        <h1>Le m&eacute;tier de Consultant SEO (Search Engine Optimization) ?</h1>
        <p>C&apos;est l&apos;une des professions web les plus appr&eacute;ci&eacute;es.&nbsp;</p>
        <p>Le consultant SEO tient une place importante dans la construction et l&apos;optimisation d&apos;un site web. En effet, aujourd&rsquo;hui, ce poste est tr&egrave;s demand&eacute; dans les offres d&rsquo;emplois. Voici un tour d&apos;horizon d&apos;un consultant SEO.</p>
        <h2>Qu&rsquo;est-ce qu&rsquo;un Consultant SEO (Search Engine Optimization) ?&nbsp;</h2>
        <p><a style={{color: "blue !important"}} href="https://fr.wikipedia.org/wiki/Optimisation_pour_les_moteurs_de_recherche">Un consultant</a> est un prestataire de services en conseil. Souvent regroup&eacute; au sein de soci&eacute;t&eacute;s de conseil ou intervenant de fa&ccedil;on ind&eacute;pendante, il dispose d&apos;un savoir-faire av&eacute;r&eacute; dans un ou plusieurs domaines.</p>
        <p>Un sp&eacute;cialiste ext&eacute;rieur &agrave; une organisation &agrave; qui l&apos;on fait appel afin d&apos;obtenir un avis au sujet d&apos;une question ou de l&apos;aide pour r&eacute;soudre un probl&egrave;me pr&eacute;cis.&nbsp;</p>
        <p>Le terme &laquo; consultant &raquo;, associ&eacute; au r&eacute;f&eacute;rencement SEO ou &agrave; l&apos;optimisation des moteurs de recherche, d&eacute;signe toute personne sp&eacute;cialis&eacute;e en r&eacute;f&eacute;rencement naturel.</p>
        <p>L&rsquo;optimisation des moteurs de recherche (SEO) est le processus d&rsquo;am&eacute;lioration de la qualit&eacute; et de la quantit&eacute; du trafic de site Web vers un site Web ou une page Web &agrave; partir des moteurs de recherche. Le r&eacute;f&eacute;rencement cible le trafic non r&eacute;mun&eacute;r&eacute; (connu sous le nom de r&eacute;sultats &laquo; naturels &raquo; ou &laquo; organiques &raquo;) plut&ocirc;t que le trafic direct ou le trafic payant. Le trafic non r&eacute;mun&eacute;r&eacute; peut provenir de diff&eacute;rents types de recherches, y compris la recherche d&rsquo;images, la recherche vid&eacute;o, la recherche universitaire, la recherche d&rsquo;actualit&eacute;s.</p>
        <h3>Quels sont les missions d&rsquo;un consultant SEO (Search Engine Optimization) ?&nbsp;</h3>
        <p>Le travail d&rsquo;un consultant SEO consiste en g&eacute;n&eacute;rale &agrave;&nbsp;:</p>
        <ul>
            <li>
                <p>Cr&eacute;ation de contenu de haute qualit&eacute;. D&rsquo;autant plus que Google privil&eacute;gie l&rsquo;aspect qualitatif et non quantitatif du contenu. Un site de r&eacute;f&eacute;rence dispose d&rsquo;un bon contenu et d&rsquo;une interface intuitive, facilitant la navigation.</p>
            </li>
            <li>
                <p>Faciliter le travail des robots d&rsquo;indexation de Google.</p>
            </li>
            <li>
                <p>Le r&eacute;f&eacute;renceur SEO pratique un audit technique, &eacute;ditorial et marketing des sites web appel&eacute; audit SEO.&Agrave; l&rsquo;issue de l&rsquo;analyse SEO, le r&eacute;f&eacute;renceur SEO, &eacute;met des pr&eacute;conisations pour optimiser le r&eacute;f&eacute;rencement naturel. Ces actions combin&eacute;es am&eacute;liorent la notori&eacute;t&eacute; de chaque page sur Google.</p>
            </li>
            <li>
                <p>Travaillez sur la responsivit&eacute; des sites.</p>
            </li>
            <li>
                <p>Comprendre l&rsquo;importance des liens internes et externes, de la campagne de marketing, du netlinking, des nouvelles tendances &eacute;mergentes du SEO et des m&eacute;dias sociaux.</p>
            </li>
            <li>
                <p>Optimisation des mots-cl&eacute;s et des balises META (notamment la meta description).</p>
            </li>
        </ul>
        <h3>Quel sont les comp&eacute;tences d&rsquo;un consultant SEO (Search Engine Optimization) ?&nbsp;</h3>
        <ul>
            <li>
                <p>De solides connaissances en marketing digital.</p>
            </li>
            <li>
                <p>Conna&icirc;tre les principaux algorithmes des moteurs de recherche</p>
            </li>
            <li>
                <p>Les langages web et le code informatique</p>
            </li>
            <li>
                <p>Administration et animation d&apos;un site web</p>
            </li>
            <li>
                <p>Des connaissances en UX Design (Exp&eacute;rience Utilisateur)</p>
            </li>
            <li>
                <p>La ma&icirc;trise de Google Analytics et des autres outils SEO</p>
            </li>
            <li>
                <p>Des connaissances pouss&eacute;es en r&eacute;daction web</p>
            </li>
            <li>
                <p>P&eacute;dagogie (pour pouvoir expliquer ses actions &agrave; des clients peu au fait des techniques SEO)</p>
            </li>
            <li>
                <p>Patience (les r&eacute;sultats d&apos;une optimisation SEO peuvent mettre plusieurs semaines, voire des mois &agrave; se faire sentir)</p>
            </li>
            <li>
                <p>Excellent relationnel (que cela soit avec ses clients ou ses coll&egrave;gues de travail)</p>
            </li>
            <li>
                <p>Esprit d&apos;&eacute;quipe (travailler au sein d&apos;une &eacute;quipe pluridisciplinaire est la norme lorsque l&apos;on exerce la profession de r&eacute;f&eacute;renceur SEO)</p>
            </li>
            <li>
                <p>Capacit&eacute; d&apos;analyse (aimer r&eacute;soudre les probl&egrave;mes est un vrai plus pour pouvoir s&apos;&eacute;panouir dans ce m&eacute;tier)</p>
            </li>
            <li>
                <p>Grande curiosit&eacute; (les &eacute;volutions sont constantes dans ce domaine, ce qui fait aussi tout le charme de la discipline !)</p>
            </li>
        </ul>
        <h3>Quel est le niveau d&rsquo;&eacute;tude en g&eacute;n&eacute;ral pour &ecirc;tre consultant SEO (Search Engine Optimization) ?&nbsp;</h3>
        <p>En g&eacute;n&eacute;ral, le consultant SEO doit disposer d&rsquo;un <a style={{color: "blue !important"}} href="https://www.onisep.fr/Ressources/Univers-Metier/Metiers/charge-chargee-de-referencement-web">bac+3 en marketing digital</a>, informatique, m&eacute;tiers du multim&eacute;dia et de l&apos;internet, ou une licence professionnelle en activit&eacute;s et techniques de communication ou en &eacute;conomie-gestion avec un parcours en r&eacute;f&eacute;rencement web, en marketing ou en e-commerce ; 5 ans pour un master en marketing direct et/ou en e-commerce.</p>
        <h3>Quel sont les perspectives d&rsquo;avenir&nbsp;d&rsquo;un consultant SEO (Search Engine Optimization)&nbsp;?</h3>
        <p>Un expert en r&eacute;f&eacute;rencement naturel pourra postuler en agence de webmarketing ou de r&eacute;f&eacute;rencement. Salari&eacute;s ou freelance, les d&eacute;bouch&eacute;s sont aujourd&rsquo;hui nombreux.</p>
        <p>Le consultant en r&eacute;f&eacute;rencement voit donc un bel avenir &agrave; l&rsquo;horizon avec des perspectives d&rsquo;&eacute;volutions telles que Consultant SEO Analytics, Consultant SEO technique, R&eacute;dacteur web ou encore Chef de projet SEO&hellip;</p>
        <h2>Faseya met &agrave; disposition des entreprises des consultants SEO (Search Engine Optimization) en mode agile.</h2>
        <p><a style={{color: "blue !important"}} href="https://www.faseya.com/#/">Faseya est une plateforme</a> de mise en relation entre entreprises souhaitant innover et consultants SEO sp&eacute;cialis&eacute;s dans les domaines de l&apos;IT et du Digital.</p>
        <h3> Quels sont les entreprises o&ugrave; travail le consultant SEO (Search Engine Optimization) ?</h3>
        <ul>
            <li>
                <p>Agence web ;</p>
            </li>
            <li>
                <p>Agence de communication ;</p>
            </li>
            <li>
                <p>Agence marketing&nbsp;;</p>
            </li>
            <li>
                <p>Agence de solution informatique IT&nbsp;;</p>
            </li>
            <li>
                <p>M&eacute;dia ;</p>
            </li>
            <li>
                <p>SSII ;</p>
            </li>
            <li>
                <p>Grands groupes industriels.</p>
            </li>
            <li>
                <p>ESN (Entreprise de service num&eacute;rique).&nbsp;</p>
            </li>
        </ul>
        <h3>Comment Faseya recrute un consultant SEO (Search Engine Optimization) ?</h3>
        <p><a style={{color: "blue !important"}} href="https://www.faseya.com/#/consultants/developer">Faseya recrute</a> ses consultants SEO en leur faisant passer plusieurs entretiens avec un consultant du r&eacute;f&eacute;rencement S&eacute;nior. A la suite de ces entretiens, une mise en situation est effectu&eacute;e.</p>
        <p><br></p>
        `

    }
]

export default businessCards;