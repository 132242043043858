import React, {Fragment}  from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBAutocomplete,
  MDBCard,MDBCardBody, MDBCardTitle, MDBCardImage, MDBCardText,
  MDBFormInline,
  MDBBtn,
  MDBView,
  MDBInput,
  MDBContainer
} from 'mdbreact';
import './DashboardPage.css';

import banner_dashboard from '../../assets/images/banner_dashboard.jpg';
import world_bg from '../../assets/images/world_bg.png';
import avataaar_0 from '../../assets/images/avataaars/avataaars.png';
import olive_loader from '../../assets/images/olive-loader.gif'; 

import PartnersComponent from '../../components/PartnersComponent/PartnersComponent';
import ParticularityComponent from '../../components/ParticularityComponent/ParticularityComponent';
import ServicesComponent from '../../components/ServicesComponent/ServicesComponent';
import ExpertsComponent from '../../components/ExpertsComponent/ExpertsComponent';
import HowComponent from '../../components/HowComponent/HowComponent';
import ParcoursComponent from '../../components/ParcoursComponent/ParcoursComponent';
import PacksComponent from '../../components/PacksComponent/PacksComponent';
import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import TestomiesComponent from '../../components/TestimoniesComponent/TestimoniesComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import auth from '../../services/auth';
import { withTranslation } from 'react-i18next';
import skillService from '../../services/skill.service';
import consultantsService from '../../services/consultants.service';

class DashboardPage extends React.Component {
  state = {
    collapsed: false,
    consultants : [],
    techs : [],
    searchKeyword : '',
    loading : true
  };

  handleTogglerClick = () => {
    const { collapsed } = this.state;
    const {t} = this.props;

    this.setState({
      collapsed: !collapsed
    });
  };

  componentDidMount() {

    window.scrollTo(0, 0);

    document.querySelector('nav').style.height = '65px';
    this.getSkills();

    this.fetchData();
  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  fetchData = () =>{

    // this.setState({consultants : this.state.consultants2, hasMore : false});
     consultantsService.searchConsultants({ keyword : "all", page: 1, size: 8 }).then(data=>{
        // console.log(data);

        var newConsultants = this.state.consultants.slice();
        newConsultants.push(...data);
        
        newConsultants.forEach(item => {
          this.setSkillsItems(item)
        });

        this.setState({consultants : newConsultants,loading : false});

    })
    .catch(err=>{
      
      this.setState({loading : false})
      // console.log(err)

    })
    
  }

  getSkills = () =>
  {
      skillService.getSkills().then(data=>{
          var techs = [];

          data.forEach(el => {
            techs.push(el.name)
          });
          this.setState({techs : techs})
       })
       .catch(err=>{
    
       })
  }


  
  setSkillsItems(item)
    {
        var skillsString = item.competence_technique;
        var skillsArray = skillsString.split(',');
        const _skillsArray =  skillsArray.filter(e =>  e);

        var _skills = [];
      
        var max = 3;
        if(_skillsArray.length < 3) max = _skillsArray.length;
        for (let i = 0; i < max; i++) {
          const el = _skillsArray[i];
            _skills.push(el); 
           
        }

       item.skills = _skills;

    }
  
  getSearchKeyword = (value) => {
    this.setState({searchKeyword : value})
  }


  gotoConsultants = () => {
    var s = "all";
    if(this.state.searchKeyword.trim() != "") s = this.state.searchKeyword;

    this.props.history.push({pathname: `/consultants/${s}`,  state:{reload : false}});
  }

  discoverConsultant(item)
  {

    localStorage.setItem("consultant", JSON.stringify(item)); 
    localStorage.setItem("keyword", this.state.tech); 
    this.props.history.push({pathname:'/cv-consultant',state :{consultant : item}});

  }



  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: '0rem' };
    const {t} = this.props;

    const consultants = this.state.consultants.map((item,index) => 
   
        <MDBCol key={item.id} xs="12" sm="12" md="4" lg="4" xl="3" className="mb-3 align-items-center" onClick={()=>this.discoverConsultant(item)} >
              <MDBCard className="p-r-0 f-featured-consultant-item" style={{ width: "100%", height:'100%', position:'relative', cursor:'pointer' }}>
              
              <MDBView style={{position:'relative'}}>
                <MDBCardImage className="img-fluid" src={item.photo || avataaar_0} style={{width:"100%"}} waves />
                <MDBMask className="flex-center f-" style={{backgroundColor:'#00000025'}}>
                </MDBMask>
              </MDBView>
              <MDBCardBody style={{ paddingBottom:'auto', position:'relative', padding:0}}>
                <h5 style={{color:'#11b981', fontWeight:700, fontSize:'100%', textAlign:'center', marginTop:5}}>{item.prenom}</h5>
                <p  style={{textAlign:'center', fontWeight:500, color:'#000', fontSize:14}} className='f-profil-text'>{item.profil}</p>
              </MDBCardBody>
            </MDBCard>
            
      </MDBCol>
      
    )


    return (

      <>
      
      <HeaderComponent  history={this.props.history} />

      <div id='minimalistintro'>
        <MDBView src={banner_dashboard}>
          <MDBMask className='rgba-black-light' />
          <MDBContainer
            className='d-flex f-hero-margin'
            style={{ height: '100%', width: '100%', paddingTop: '17px' }}
          >
            <MDBRow>
              <MDBCol xs="12" md="12"  className='mb-4 white-text'>
              { !auth.isAuthenticated() &&
               <div>
                <h1 className="title f-title">Le talent dont vous avez besoin. <br /> La flexibilité que vous voulez.</h1>        
                <h4 className="subtitle font-light f-subtitle" style={{fontWeight:'bolder'}}>{/*  {t('Welcome to React')} */} Faseya met à disposition des entreprises <br/> des consultants en mode agile. </h4>
               </div>
              }
              { auth.isAuthenticated() &&
               <div>
                <h3 className="title f-title">{t('faseya.dashboard.banner.title')}<br /> {t('faseya.dashboard.banner.subtitle')}</h3>        
                <h4 className="subtitle font-light f-subtitle" style={{fontWeight:'bolder', width:'60%'}}> {t('faseya.dashboard.banner.description')} </h4>
               </div>
              }
              
              { !auth.isAuthenticated() &&

              <div>
                <MDBBtn outline color='white' className="btn btn-success-gradiant font-14 f-btn-olive">Poster un projet
                </MDBBtn>
                <MDBBtn outline color='white'  className="btn btn-success-gradiant font-14 f-btn-white">Demander une demo
                </MDBBtn>
              </div>
              }
                <MDBRow>
                  <MDBCol xs="10" md="9"  className='offset-xs-1 f-search-box'>
                    <form>
                    <MDBAutocomplete
                      data={this.state.techs}
                      label={t('faseya.banner.find_dev')}
                      clear
                      // id="input"
                      style={{color:'black'}}
                      className='f-input-autocomplete' 
                      getValue={(v)=>this.getSearchKeyword(v)}
                    />
                      {/* <input className="form-control f-input" type="search" name="search" placeholder={t('faseya.banner.find_dev')} onChange={(e)=>this.getSearchKeyword(e)}
                          /> */}
                        <MDBBtn className="f-btn-olive" onClick={this.gotoConsultants} style={{marginTop:18, paddingTop:13,paddingBottom:13}}>{t('faseya.banner.find_a_consultant')}</MDBBtn>
                    </form>
                  </MDBCol>
              </MDBRow>
              </MDBCol>

            </MDBRow>
          </MDBContainer>
        </MDBView>

        
    



      </div>


      <div style={{padding:10, backgroundColor : 'transparent', marginTop:0, backgroundImage:"url("+world_bg+")", width:'100%', backgroundSize:'cover'}}>
      <MDBCol>
  
        <MDBRow>

            
            <MDBCol size="12" sm="12" md="12"  className="mb-4" style={{}} >
              <MDBRow>
 
                <MDBCol size="12" xs="12" md="5"  className="mb-3" >
                  <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}>{t('faseya.dashboard.body.title')}</h4>
                    <p id="f-break" style={{fontSize:20, color:'#000',fontWeight:400}}>

                      {t('faseya.dashboard.body.description')}

                    </p>
                </MDBCol>
 
                <MDBCol size="12" xs="12" md="7"  className="mb-3 mt-4" >
                  <MDBContainer>
                    {this.state.loading &&
                      <img src={olive_loader} style={{position:'absolute', zIndex:1, bottom: '50%', left:'47.5%', width:40}} alt="" />
                    }
                    {this.state.consultants.length > 0 && !this.state.loading &&
                      <MDBRow>
                        {consultants}
                      </MDBRow>
                    }
                   </MDBContainer>
                </MDBCol>


              </MDBRow>
            </MDBCol>            
 

        </MDBRow>
      
      
      </MDBCol>

      </div>


        {/* <ExpertsComponent /> */}

        {/* <HowComponent /> */}

        {/* <ParcoursComponent /> */}

        {/* <PacksComponent /> */}
        
        {/* <TestomiesComponent /> */}

        <PrefooterComponent />
      </>

    );
  }
}

export default withTranslation()(DashboardPage);