import { api } from "./api.config";

const questionnaireService = {
  addQuestionnaire,
  getAllQuestionnaires,
  getQuestionnaire,
};

const resource = "/questionnaires";

/**
 * addQuestionnaire method
 *
 * @param {{
 *   service: {
 *     id: number;
 *     needs: string;
 *     technologies?: string[]
 *   };
 *   budget: string;
 *   mission_duration: string;
 *   project_status: string;
 *   specification_docs: boolean;
 *   talents_needed: string;
 *   start_project: string;
 *   company_type: string;
 *   company_info: {
 *     name: string;
 *     first_name: string;
 *     last_name: string;
 *     phone: string;
 *     email: string;
 *   };
 * }} payload
 *
 * @returns
 */
function addQuestionnaire(payload) {
  return api.postData(resource, payload);
}

/**
 * getAllQuestionnaires method
 *
 * @param {{ page: number; size: number; }} pagination
 *
 * @returns
 */
function getAllQuestionnaires(pagination = undefined) {
  return api.getData(
    resource,
    !pagination || !pagination.page || !pagination.size ? {} : pagination
  );
}

/**
 * getQuestionnaire method
 *
 * @param {number} id
 *
 * @returns
 */
function getQuestionnaire(id) {
  return api.getData(resource + "/" + id);
}

export default questionnaireService;
