import { api } from "./api.config";

const demoService = {
  addDemo,
  getDemos,
  getDemo,
  updateDemo,
  deleteDemo,
  deleteDemos,
};

const resource = "/demo";

/**
 * addDemo method
 *
 * @param {{
 *   prenom: string;
 *   nom: string;
 *   telephone: string;
 *   societe: string;
 *   ville: string;
 *   email: string;
 *   adresse: string;
 *   serviceId: number;
 * }} payload
 *
 * @returns
 */
function addDemo(payload) {
  return api.postData(resource, payload);
}

/**
 * getDemos method
 *
 * @returns
 */
function getDemos() {
  return api.getData(resource);
}

/**
 * getDemo method
 *
 * @param {number} id
 *
 * @returns
 */
function getDemo(id) {
  return api.getData(resource + "/" + id);
}

/**
 * updateDemo method
 *
 * @param {{
 *   prenom?: string;
 *   nom?: string;
 *   telephone?: string;
 *   societe?: string;
 *   ville?: string;
 *   email?: string;
 *   adresse?: string;
 *   serviceId?: number;
 * }} payload
 * @param {number} id
 *
 * @returns
 */
function updateDemo(id, payload) {
  return api.putData(resource + "/" + id, payload);
}

/**
 * deleteDemo method
 *
 * @param {number} id
 *
 * @returns
 */
function deleteDemo(id) {
  return api.deleteData(resource + "/" + id);
}

/**
 * deleteDemos method
 *
 * @returns
 */
function deleteDemos() {
  return api.deleteData(resource);
}

export default demoService;
