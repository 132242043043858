import React, {Fragment}  from 'react';
import { BrowserRouter as Router, useParams } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBCard,MDBCardBody, MDBCardTitle, MDBCardImage, MDBCardText,
  MDBFormInline,
  MDBBtn,
  MDBView,
  MDBInput,
  MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter,
  MDBContainer
} from 'mdbreact';
import './BlogPage.css';

import banner_blog from '../../assets/images/banner_blog.jpg';
import world_bg from '../../assets/images/world_bg.png';
import blog_1 from '../../assets/images/blog_1.png'; 
import blog_2 from '../../assets/images/blog_2.png'; 
import blog_3 from '../../assets/images/blog_3.jpg'; 

import blog_1_1 from '../../assets/images/blog_1_1.jpg'; 
import blog_1_2 from '../../assets/images/blog_1_2.jpg'; 
import blog_2_1 from '../../assets/images/blog_2_1.jpg'; 
import blog_2_2 from '../../assets/images/blog_2_2.jpg'; 
import blog_2_3 from '../../assets/images/blog_2_3.jpg'; 
import blog_3_1 from '../../assets/images/blog_3_1.jpg'; 
import blog_3_2 from '../../assets/images/blog_3_2.jpg'; 
import blog_3_3 from '../../assets/images/blog_3_3.jpg'; 


import PartnersComponent from '../../components/PartnersComponent/PartnersComponent';
import ParticularityComponent from '../../components/ParticularityComponent/ParticularityComponent';
import ServicesComponent from '../../components/ServicesComponent/ServicesComponent';
import ExpertsComponent from '../../components/ExpertsComponent/ExpertsComponent';
import HowComponent from '../../components/HowComponent/HowComponent';
import ParcoursComponent from '../../components/ParcoursComponent/ParcoursComponent';
import PacksComponent from '../../components/PacksComponent/PacksComponent';
import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import TestomiesComponent from '../../components/TestimoniesComponent/TestimoniesComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import auth from '../../services/auth';
import { withTranslation } from 'react-i18next';

class BlogPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    collapsed: false,
    blogs : [
      {id:0, poster: blog_1 , category: 'faseya.blog.blog_1.category' ,title: 'faseya.blog.blog_1.title', desc: 'faseya.blog.blog_1.p_1'},
      {id:1, poster: blog_2 , category: 'faseya.blog.blog_2.category' ,title: 'faseya.blog.blog_2.title', desc: 'faseya.blog.blog_2.p_1'},
      {id:2, poster: blog_3 , category: 'faseya.blog.blog_3.category' ,title: 'faseya.blog.blog_3.title', desc: 'faseya.blog.blog_3.p_1'},
      ],
      selectedBlog : {},
      openModal1 : false,
      openModal2 : false,
      openModal3 : false

    };

  }

  handleTogglerClick = () => {
    const { collapsed } = this.state;
    const {t} = this.props;

    this.setState({
      collapsed: !collapsed,
    });
  };

  componentDidMount() {


    document.querySelector('nav').style.height = '65px';
   
    window.scrollTo(0, 0);


    //
    if(this.props.location.state)
    { 
        const { article } = this.props.location.state;
        // console.log(article)
        if(article)
        {
          var item = this.state.blogs[article - 1];
          this.readArticle(item, article);
        }
      }


  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  toggleModal =(nr)=> {
    let modalNumber = 'openModal' + nr
    this.setState({[modalNumber]: !this.state[modalNumber]})
  }

  readArticle(item, nr){
    
    this.setState({selectedBlog : item});
    this.toggleModal(nr);
  }


  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: '0rem' };
    const {t} = this.props;

    const blogs = this.state.blogs.map((item,index) => 
   
      <MDBCol key={index} size="12" xs="12" md="4"  className="mb-3 f-blog-item" >
        <MDBCard style={{ width: "100%", height:'100%' }} className='f-blog-item-card' onClick={()=>this.readArticle(item, (index+1))} >
          <MDBCardImage className="img-fluid" src={item.poster} style={{height:'100%'}} waves />
          <MDBCardBody style={{backgroundColor:'#fff', paddingBottom:'auto'}}>
            <span style={{color:'white', fontSize:14, backgroundColor:'olivedrab', padding:5, borderRadius:5}}>{t(item.category)}</span>
            <MDBCardTitle style={{color:'#000', fontWeight:800, marginTop:10, marginBottom:10}}>{t(item.title)}</MDBCardTitle>
            <MDBCardText style={{color:'#000', fontSize:20}}>
              <p className='f-blog-desc-text'>{t(item.desc)}</p>
            </MDBCardText>
            <span style={{float:'right', color:'#000', fontWeight:800,}}>6 Juin 2021</span>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      
    )


    return (

      <>
      
      <HeaderComponent  history={this.props.history} />

      <div id='minimalistintro'>
        <MDBView src={banner_blog}>
          <MDBMask className='rgba-black-light' />
          <MDBContainer
            className='d-flex f-hero-margin'
            style={{ height: '100%', width: '100%', paddingTop: '17px' }}
          >
            <MDBRow>
              <MDBCol xs="12" md="12"  className='mb-4 white-text'>

               <div>
                <h3 className="title f-title">{t('faseya.blog.header')}</h3>        
                <h4 className="subtitle font-light f-subtitle" style={{fontWeight:'bolder'}}>{t('faseya.blog.subheader_1')} <br />{t('faseya.blog.subheader_2')}</h4>
               </div>
               
                <MDBRow>
                  <MDBCol xs="10" md="9"  className='offset-xs-1 f-search-box'>
                    <form>
                      <input className="form-control f-input" type="search" name="search" placeholder={t('faseya.blog.search_placeholder')} validate="true" error="wrong"
                          />
                        <MDBBtn className="f-btn-olive" style={{marginTop:18, paddingTop:13,paddingBottom:13}}>{t('faseya.blog.search')}</MDBBtn>
                    </form>
                  </MDBCol>
              </MDBRow>
             
              </MDBCol>

            </MDBRow>
          </MDBContainer>
        </MDBView>

        
    



      </div>


      <div style={{padding:10, backgroundColor : 'transparent', marginTop:0, backgroundImage:"url("+world_bg+")", width:'100%', backgroundSize:'cover'}}>
      <MDBCol>
  
        <MDBRow>

            
            <MDBCol size="12" sm="12" md="12"  className="mb-4" style={{}} >
              <MDBRow>
 
                <MDBCol size="12" xs="12" md="10"  className="mb-3 offset-md-1" >
                  <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}>{t('faseya.blog.title')}</h4>
                </MDBCol>
 
                <MDBCol size="12" xs="12" md="12"  className="mb-3 mt-4" >
                  <MDBContainer>
                    <MDBRow>
                      {blogs}
                    </MDBRow>
                   </MDBContainer>
                </MDBCol>


              </MDBRow>
            </MDBCol>            
 

        </MDBRow>
      
      
      </MDBCol>

      </div>

      <PrefooterComponent />
    
      {/* MODAL 1 */}
      
      <MDBModal isOpen={this.state.openModal1} toggle={()=>this.toggleModal(1)} size='xl' >
        <MDBModalHeader toggle={()=>this.toggleModal(1)}  >
          <h4 style={{color:'#000', fontWeight:800, marginTop:10, marginBottom:10}}>
            {t(this.state.selectedBlog.title)}
          </h4>
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size='12'>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_1.p_1')}</p>
                <h4 style={{color:'black', fontFamily:'Montserrat', fontWeight:700}}>{t('faseya.blog.blog_1.p_2')}</h4>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_1.p_3')}</p>
                <h5 style={{color:'black', fontFamily:'Montserrat', fontWeight:600}}>{t('faseya.blog.blog_1.p_4')}</h5>

            </MDBCol>
            <MDBCol size='12'>
                <img src={blog_1_1} className='img-fluid' alt="" />
            </MDBCol>
            <MDBCol size='12' className='mt-4'>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_1.p_5')}</p>
                <h4 style={{color:'black', fontFamily:'Montserrat', fontWeight:700}}>{t('faseya.blog.blog_1.p_6')}</h4>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_1.p_7')}</p>
                <h5 style={{color:'black', fontFamily:'Montserrat', fontWeight:600}}>{t('faseya.blog.blog_1.p_8')}</h5>
            </MDBCol>
            <MDBCol size='12'>
                <img src={blog_1_2} className='img-fluid' alt="" />
            </MDBCol>
            <MDBCol size='12' className='mt-4'>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_1.p_9')}</p>
            </MDBCol>
          </MDBRow>
        </MDBModalBody> 
      </MDBModal>


      {/* MODAL 2 */}
        
      <MDBModal isOpen={this.state.openModal2} toggle={()=>this.toggleModal(2)} size='xl' >
        <MDBModalHeader toggle={()=>this.toggleModal(2)}  >
          <h4 style={{color:'#000', fontWeight:800, marginTop:10, marginBottom:10}}>
            {t(this.state.selectedBlog.title)}
          </h4>
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size='12'>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_2.p_1')}</p>
                <h5 style={{color:'black', fontFamily:'Montserrat', fontWeight:600}}>{t('faseya.blog.blog_2.p_2')}</h5>

            </MDBCol>
            <MDBCol size='12'>
                <img src={blog_2_1} className='img-fluid' alt="" />
            </MDBCol>
            <MDBCol size='12' className='mt-4'>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_2.p_3')}</p>
                <h5 style={{color:'black', fontFamily:'Montserrat', fontWeight:600}}>{t('faseya.blog.blog_2.p_4')}</h5>
            </MDBCol>
            <MDBCol size='12'>
                <img src={blog_2_2} className='img-fluid' alt="" />
            </MDBCol>
            <MDBCol size='12' className='mt-4'>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_2.p_5')}</p>
                <ul>
                  <li>
                     <p style={{color:'black', fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.blog.blog_2.p_5_items.item_1')}</p>
                  </li>
                  <li>
                     <p style={{color:'black', fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.blog.blog_2.p_5_items.item_2')}</p>
                  </li>
                  <li>
                     <p style={{color:'black', fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.blog.blog_2.p_5_items.item_3')}</p>
                  </li>
                </ul>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_2.p_6')}</p>

                <h5 style={{color:'black', fontFamily:'Montserrat', fontWeight:600}}>{t('faseya.blog.blog_2.p_7')}</h5>

            </MDBCol>
            <MDBCol size='12'>
                <img src={blog_2_3} className='img-fluid' alt="" />
            </MDBCol>
            <MDBCol size='12' className='mt-4'>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_2.p_8')}</p>
                <ul>
                  <li>
                     <p style={{color:'black', fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.blog.blog_2.p_8_items.item_1')}</p>
                  </li>
                  <li>
                     <p style={{color:'black', fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.blog.blog_2.p_8_items.item_2')}</p>
                  </li>
                  <li>
                     <p style={{color:'black', fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.blog.blog_2.p_8_items.item_3')}</p>
                  </li>
                  <li>
                     <p style={{color:'black', fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.blog.blog_2.p_8_items.item_4')}</p>
                  </li>
                </ul>

                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_2.p_9')}</p>
                <ul>
                  <li>
                     <p style={{color:'black', fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.blog.blog_2.p_9_items.item_1')}</p>
                  </li>
                  <li>
                     <p style={{color:'black', fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.blog.blog_2.p_9_items.item_2')}</p>
                  </li>
                  <li>
                     <p style={{color:'black', fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.blog.blog_2.p_9_items.item_3')}</p>
                  </li>
                  <li>
                     <p style={{color:'black', fontFamily:'Montserrat', fontWeight:500}}>{t('faseya.blog.blog_2.p_9_items.item_4')}</p>
                  </li>
                </ul>

                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_2.p_10')}</p>

              
            </MDBCol>
          </MDBRow>
        </MDBModalBody> 
      </MDBModal>


      {/* MODAL 3 */}

              
      <MDBModal isOpen={this.state.openModal3} toggle={()=>this.toggleModal(3)} size='xl' >
        <MDBModalHeader toggle={()=>this.toggleModal(3)}  >
          <h4 style={{color:'#000', fontWeight:800, marginTop:10, marginBottom:10}}>
            {t(this.state.selectedBlog.title)}
          </h4>
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size='12'>
                <h5 style={{color:'black', fontFamily:'Montserrat', fontWeight:600}}>{t('faseya.blog.blog_3.subtitle')}</h5>
            </MDBCol>
            <MDBCol size='12'>
                <img src={blog_3_1} className='img-fluid' alt="" />
            </MDBCol>
            <MDBCol size='12'>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_3.p_1')}</p>
                <h5 style={{color:'black', fontFamily:'Montserrat', fontWeight:600}}>{t('faseya.blog.blog_3.p_2')}</h5>
            </MDBCol>
             <MDBCol size='12'>
                <img src={blog_3_2} className='img-fluid' alt="" />
            </MDBCol>
            <MDBCol size='12' className='mt-4'>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_3.p_3')}</p>
                <h5 style={{color:'black', fontFamily:'Montserrat', fontWeight:600}}>{t('faseya.blog.blog_3.p_4')}</h5>
            
            </MDBCol>
          
       
            <MDBCol size='12'>
                <img src={blog_3_3} className='img-fluid' alt="" />
            </MDBCol>
            <MDBCol size='12' className='mt-4'>
                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_3.p_5')}</p>
            
                <h5 style={{color:'black', fontFamily:'Montserrat', fontWeight:600}}>{t('faseya.blog.blog_3.p_6')}</h5>

                <p style={{color:'black', fontFamily:'Montserrat', fontWeight:400}}>{t('faseya.blog.blog_3.p_7')}</p>

            </MDBCol>
          </MDBRow>
        </MDBModalBody> 
      </MDBModal>


    </>

    );
  }
}

export default withTranslation()(BlogPage);