import React, {Fragment}  from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBCard,MDBCardBody, MDBCardTitle, MDBCardImage, MDBCardText,
  MDBFormInline,
  MDBBtn,
  MDBView,
  MDBInput,
  MDBIcon,
  MDBContainer
} from 'mdbreact';

import { MDBTabPane, MDBTabContent, MDBNav} from '../../mdbreact/dist/mdbreact'

import ChatBot from 'react-simple-chatbot';
import PropTypes from 'prop-types';
import './ConsultantMissionsPage.css';

import banner_faseya from '../../assets/images/banner_faseya.jpg';
import world_bg from '../../assets/images/world_bg.png';
import chatbot_icon from '../../assets/images/chatbot_icon.png';
 
import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import auth from '../../services/auth';
import { ThemeProvider } from 'styled-components';

const theme = {
  background: '#f5f8fb',
  fontFamily: 'Montserrat',
  headerBgColor: '#11b981',
  headerFontColor: '#ffffff !important',
  headerFontSize: '15px',
  botBubbleColor: '#11b981',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};


class Review extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      gender: '',
      age: '',
    };
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    const { steps } = this.props;
    const { name, gender, age } = steps;

    this.setState({ name, gender, age });
  }

  render() {
    const { name, gender, age } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <h3>Summary</h3>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{name.value}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{gender.value}</td>
            </tr>
            <tr>
              <td>Age</td>
              <td>{age.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}


Review.propTypes = {
  steps: PropTypes.object,
};

Review.defaultProps = {
  steps: undefined,
};
class ConsultantMissionsPage extends React.Component {
  state = {
    collapsed: false,
    activeItem: "1"
  
  };


  toggle = tab => () => {
    if (this.state.activeItem !== tab) {
    this.setState({
      activeItem: tab
    });
    }
  }


  handleTogglerClick = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });
  };

  componentDidMount() {
    document.querySelector('nav').style.height = '65px';
  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: '0rem' };
 
 


    return (

      <>
      
      <HeaderComponent  history={this.props.history} />

      <div id='minimalistintro'>
         
        <MDBRow style={{marginTop:20, color:'white', marginBottom:50}} className="d-flex justify-content-center">
          <MDBCol size="12" xs="12" md="11" xl="11" >
           <MDBNav tabs style={{backgroundColor:'olivedrab'}}>
            <MDBNavItem style={{marginRight:10 , fontWeight:400}}>
              <MDBNavLink
                link
                to="#"
                active={this.state.activeItem === "1"}
                onClick={this.toggle("1")}
                role="tab"
              >
                Missions à venir (0)
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem style={{marginRight:10 , fontWeight:400}}>
              <MDBNavLink
                link
                to="#"
                active={this.state.activeItem === "2"}
                onClick={this.toggle("2")}
                role="tab"
              >
                Missions en cours (0)
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem style={{marginRight:10 , fontWeight:400}}>
              <MDBNavLink
                link
                to="#"
                active={this.state.activeItem === "3"}
                onClick={this.toggle("3")}
                role="tab"
              >
                Missions terminées (0)
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent
            className="card"
            activeItem={this.state.activeItem}
          >
            <MDBTabPane  style={{color:'#000'}} tabId="1" role="tabpanel">
              <h4 style={{fontWeight:600}}>Mes assignations</h4>
              <h6 style={{fontWeight:600}}>Aucune action à traiter</h6>
              <p style={{marginTop:50}}>
                Sur cet onglet, vous retrouverez l’ensemble de vos missions assignées, en cours et terminées nécessitant une action de votre part pour mener à bien toutes vos missions.
              </p>


            </MDBTabPane>
            <MDBTabPane  style={{color:'#000'}} tabId="2" role="tabpanel">
              <h4 style={{fontWeight:600}}>Missions en cours</h4>
              <h6 style={{fontWeight:600}}>Aucune mission à afficher</h6>
              <p style={{marginTop:50}}>
                Sur cet onglet, vous retrouverez l’ensemble des missions en cours.
              </p>
            </MDBTabPane>
            <MDBTabPane  style={{color:'#000'}} tabId="3" role="tabpanel">
              <h4 style={{fontWeight:600}}>Missions terminées</h4>
              <h6 style={{fontWeight:600}}>Aucune mission à afficher</h6>
              <p style={{marginTop:50}}>
                Sur cet onglet, vous retrouverez l’ensemble des missions terminées.
              </p>
            </MDBTabPane>
          </MDBTabContent>
          </MDBCol>
        </MDBRow>
          
      </div>

 
        <PrefooterComponent />
          <ThemeProvider theme={theme}>
            <ChatBot headerTitle="Discutez avec l'equipe Faseya" floating
                 steps={[
                  {
                    id: '1',
                    message: 'What is your name?',
                    trigger: 'name',
                  },
                  {
                    id: 'name',
                    user: true,
                    trigger: '3',
                  },
                  {
                    id: '3',
                    message: 'Hi {previousValue}! What is your gender?',
                    trigger: 'gender',
                  },
                  {
                    id: 'gender',
                    options: [
                      { value: 'male', label: 'Male', trigger: '5' },
                      { value: 'female', label: 'Female', trigger: '5' },
                    ],
                  },
                  {
                    id: '5',
                    message: 'How old are you?',
                    trigger: 'age',
                  },
                  {
                    id: 'age',
                    user: true,
                    trigger: '7',
                    validator: (value) => {
                      if (isNaN(value)) {
                        return 'value must be a number';
                      } else if (value < 0) {
                        return 'value must be positive';
                      } else if (value > 120) {
                        return `${value}? Come on!`;
                      }
        
                      return true;
                    },
                  },
                  {
                    id: '7',
                    message: 'Great! Check out your summary',
                    trigger: 'review',
                  },
                  {
                    id: 'review',
                    component: <Review />,
                    asMessage: true,
                    trigger: 'update',
                  },
                  {
                    id: 'update',
                    message: 'Would you like to update some field?',
                    trigger: 'update-question',
                  },
                  {
                    id: 'update-question',
                    options: [
                      { value: 'yes', label: 'Yes', trigger: 'update-yes' },
                      { value: 'no', label: 'No', trigger: 'end-message' },
                    ],
                  },
                  {
                    id: 'update-yes',
                    message: 'What field would you like to update?',
                    trigger: 'update-fields',
                  },
                  {
                    id: 'update-fields',
                    options: [
                      { value: 'name', label: 'Name', trigger: 'update-name' },
                      { value: 'gender', label: 'Gender', trigger: 'update-gender' },
                      { value: 'age', label: 'Age', trigger: 'update-age' },
                    ],
                  },
                  {
                    id: 'update-name',
                    update: 'name',
                    trigger: '7',
                  },
                  {
                    id: 'update-gender',
                    update: 'gender',
                    trigger: '7',
                  },
                  {
                    id: 'update-age',
                    update: 'age',
                    trigger: '7',
                  },
                  {
                    id: 'end-message',
                    message: 'Thanks! Your data was submitted successfully!',
                    end: true,
                  },
                ]}
              />
          </ThemeProvider>
        
      </>

    );
  }
}

export default ConsultantMissionsPage;