import React, {Fragment} from 'react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  // MDBNavbarToggler,
  // MDBCollapse,
  // MDBNavItem,
  // MDBNavLink,
  MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
  MDBTooltip,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdbreact';
import calendrier from './../../assets/images/calendrier.png'
import { withTranslation } from 'react-i18next';
import {MDBCollapse, MDBNavbarToggler, MDBNavItem, MDBNavLink} from '../../mdbreact/dist/mdbreact';
import { HashRouter as Router } from 'react-router-dom';
import './HeaderComponent.css';

import logo_faseya from '../../assets/images/logo_faseya.png';
import f_logo from '../../assets/images/f_logo.png';
import whatsapp_icon from '../../assets/images/whatsapp_icon.png'
import mail_icon from '../../assets/images/mail_icon.png'
import login_icon from '../../assets/images/login_icon.png'
import fr_icon from '../../assets/images/fr_icon.png'
import en_icon from '../../assets/images/en_icon.png'
import es_icon from '../../assets/images/es_icon.png'
import ar_icon from '../../assets/images/ar_icon.png'
import auth from '../../services/auth';
import localization from '../../i18n/localization';
import i18n from '../../i18n/i18n';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainReduxActions from '../../store/mainRedux/mainReduxActions';
import seo from '../../i18n/seo';

class HeaderComponent extends React.Component {
 
  state = {
    menuCollapseID: false,
    user : {}
  };

  componentDidMount(){

    var user = JSON.parse(localStorage.getItem("user")) || {id : null}
    this.setState({user : user});


    var path = document.location.hash.split("#")[1];
    var key = "home";
    switch (path) {
      case '/':
        key = "home";
        break;
      case '/concept':
        key = "concept";
        break;
      case "/pricing":
        key = "pricing";
      break;

      default: key = "home";
        break;
    }


    
    // Set metadata
    document.title = seo[key].title;
    document.querySelector("meta[name='title']").setAttribute("content", seo[key].title)
    document.querySelector("meta[name='description']").setAttribute("content", seo[key].description)
    document.querySelector("meta[name='keywords']").setAttribute("content", seo[key].keywords)


  }



  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lang", lng);
  }

  menuToggleCollapse = menuCollapseID => () =>{

    // If pc screen : return
    var isMobile = false;
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      // some code..
      isMobile = true;
    }
    if(!isMobile) return;


    var collapsible = document.getElementById(menuCollapseID);
    var navbar = collapsible.parentElement;

    if (collapsible.classList.contains('hidden')) {
      //console.log("***")
      collapsible.classList.remove('hidden');
      setTimeout(function () {
        collapsible.classList.remove('visuallyhidden');
      }, 20);
    } else {
      collapsible.classList.add('visuallyhidden');    
      collapsible.addEventListener('transitionend', function(e) {
        collapsible.classList.add('hidden');
      }, {
        capture: false,
        once: true,
        passive: false
      });
    }
    

  }

 

  render() {

    const { t } = this.props;
    const { menuCollapseID } = this.state; 

    var userType = 'client';
    if(this.state.user.id)
     userType = this.state.user.roles.includes('ROLE_CONSULTANT') ? 'consultant' : 'client';

    return (
      <>
          <MDBRow style={{backgroundColor:'lightgray',width:'100%', padding:0, margin:0}}>
              <MDBCol sm='12'>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <div>
                    <MDBBtn href="https://wa.me/33668824201?text=Bienvenu%20chez%20Faseya%20pro" className='f-top-btn' outline rounded><img className="f-lang-icon" src={whatsapp_icon} alt="top-bar-icon" /> +336 68 82 42 01</MDBBtn>
                    <MDBBtn href="mailto:contact@faseya.com" className='f-top-btn' outline rounded><img className="f-lang-icon" src={mail_icon} alt="top-bar-icon" /> contact@faseya.com</MDBBtn>
                  </div>
                  <MDBBtn href="https://calendly.com/yatma-gueye/15min?month=2022-10" className='f-top-btn' outline rounded><img className="f-lang-icon" src={calendrier} width="20" style={{marginRight: '10px'}} alt="top-bar-icon" />Prendre un Rdv</MDBBtn>
                </div>
               </MDBCol>
          </MDBRow>
          <MDBNavbar className="box" style={{backgroundColor : '#fff'}} dark  expand='md' >
            <MDBNavbarBrand href='/' className='py-0 font-weight-bold'>
              <img src={logo_faseya} alt="logo" className="f-logo-big" />
              <img src={f_logo} alt="logo" className="f-logo-small" />
            </MDBNavbarBrand>
            
            {/* <img className="f-login-icon" src={login_icon} /> */}
            <MDBNavLink className='f-btn f-login-icon'  style={{color:'#000'}}
              onClick={this.menuToggleCollapse('mainNavbarCollapse')}
              to='/inscription'
            >
              <strong>{t('faseya.navbar.list.items.item_9')}</strong>
            </MDBNavLink>
            

            <MDBNavbarToggler style={{backgroundColor:'olivedrab'}}
              onClick={this.menuToggleCollapse('mainNavbarCollapse')}
            />
            <MDBCollapse id="mainNavbarCollapse" className="mainNavbarCollapse hidden visuallyhidden" navbar>
              <MDBNavbarNav right>
              { auth.isAuthenticated() &&
              <>
              
              {this.state.user.id && this.state.user.roles.includes('ROLE_CLIENT') &&
                <>
                <MDBNavItem>
                  <MDBNavLink className={`f-btn-bare ${this.props.history.location.pathname == '/dashboard/client' ? "f-nav-active" : ""}`} style={{color:'#000'}}
                    onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                    to='/dashboard/client'
                  >
                    <strong>{t('faseya.navbar.list.items.item_10')}</strong>
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBNavLink  className={`f-btn-bare ${this.props.history.location.pathname == '/dashboard/client/missions' ? "f-nav-active" : ""}`} style={{color:'#000'}}
                    onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                    to='/dashboard/client/missions'
                  >
                    <strong>{t('faseya.navbar.list.items.item_11')}</strong>
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBNavLink  className={`f-btn-bare ${this.props.history.location.pathname == '/dashboard/client/consultants' ? "f-nav-active" : ""}`} style={{color:'#000'}}
                    onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                    to='/dashboard/client/consultants'
                  >
                    <strong>{t('faseya.navbar.list.items.item_8')}</strong>
                  </MDBNavLink>
                </MDBNavItem>

                </>
                }
                {/* Consultant Only */}

                {this.state.user.id && this.state.user.roles.includes('ROLE_CONSULTANT') &&
                  <>
                    <MDBNavItem>
                      <MDBNavLink  className={`f-btn-bare ${this.props.history.location.pathname == '/dashboard/consultant' ? "f-nav-active" : ""}`} style={{color:'#000'}}
                        onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                        to='/dashboard/consultant'
                      >
                        <strong>Missions</strong>
                      </MDBNavLink>
                    </MDBNavItem>

                    <MDBNavItem>
                      <MDBNavLink  className={`f-btn-bare ${this.props.history.location.pathname == '/dashboard/consultant/modify-cv' ? "f-nav-active" : ""}`} style={{color:'#000'}}
                        onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                        to='/dashboard/consultant/modify-cv'
                      >
                        <strong>{t('faseya.navbar.list.items.item_14')}</strong>
                      </MDBNavLink>
                    </MDBNavItem>
                  </>

                }

                {/* ------------------ */}


                <MDBDropdown>
                  <MDBDropdownToggle className="f-dropdown-ctrlr" color="white">
                  <img className="f-lang-icon" src={login_icon} alt="localization" /> 
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBNavItem >
                        <MDBNavLink  className={`f-btn-bare ${this.props.history.location.pathname == '/dashboard/'+userType+'/modification-mot-de-passe' ? "f-nav-active" : ""}`} style={{color:'#000'}}
                          onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                          to={`/dashboard/${userType}/modification-mot-de-passe`}
                        >
                          <strong>{t('faseya.navbar.list.items.item_12')}</strong>
                        </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBBtn className='f-btn-olive' style={{color:'#000'}}
                        onClick={()=>{auth.logout(()=>{ window.location.reload() })}}
                      >
                        <strong>{t('faseya.navbar.list.items.item_13')}</strong>
                      </MDBBtn>
                    </MDBNavItem>
                  </MDBDropdownMenu>
                </MDBDropdown>

                
              </>
              }

              { !auth.isAuthenticated() &&
              <>

                <MDBNavItem>
                  <MDBNavLink  className={`f-btn-bare ${this.props.history.location.pathname == '/concept' ? "f-nav-active" : ""}`} style={{color:'#000'}}
                    onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                    to='/concept'
                  >
                    <strong>{t('faseya.navbar.list.items.item_1')}</strong>
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBNavLink  className={`f-btn-bare ${this.props.history.location.pathname == '/pricing' ? "f-nav-active" : ""}`}  style={{color:'#000'}}
                    onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                    to='/pricing'
                  >
                    <strong>{t('faseya.navbar.list.items.item_2')}</strong>
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBNavLink  className={`f-btn-bare ${this.props.history.location.pathname == '/about' ? "f-nav-active" : ""}`}  style={{color:'#000'}}
                    onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                    to='/about'
                  >
                    <strong>{t('faseya.navbar.list.items.item_3')}</strong>
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBNavLink  className={`f-btn-bare ${this.props.history.location.pathname == '/metiers' ? "f-nav-active" : ""}`} style={{color:'#000'}}
                    onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                    to='/metiers'
                  >
                    <strong>{t('faseya.navbar.list.items.item_15')}</strong>
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBNavLink  className={`f-btn-bare ${this.props.history.location.pathname == '/blog' ? "f-nav-active" : ""}`}  style={{color:'#000'}}
                    onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                    to='/blog'
                  >
                    <strong>{t('faseya.navbar.list.items.item_4')}</strong>
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBNavLink className='f-btn' style={{color:'#000'}}
                    onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                    to='/se-connecter'
                  >
                    <strong>{t('faseya.navbar.list.items.item_5')}</strong>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink className='f-btn'  style={{color:'#000'}}
                    onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                    to='/inscription'
                  >
                    <strong>{t('faseya.navbar.list.items.item_6')}</strong>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink className='f-btn-olive'  style={{color:'#000'}}
                    onClick={this.menuToggleCollapse('mainNavbarCollapse')}
                    to='/inscription/consultants'
                  >
                    <strong>{t('faseya.navbar.list.items.item_7')}</strong>
                  </MDBNavLink>
                </MDBNavItem>

                </>
                }

            
                <MDBNavItem>
                  <MDBNavLink className={`${i18n.language == 'fr' ? "f-btn-olive" : "f-btn"}`}  style={{color:'#000' }}
                    onClick={()=>{this.changeLanguage('fr')}}
                    to='#'
                  >
                    <img className="f-lang-icon" src={fr_icon} alt="localization" /> 
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink  className={`${i18n.language == 'en' ? "f-btn-olive" : "f-btn"}`}  style={{color:'#000'}}
                      onClick={()=>{this.changeLanguage('en')}}
                      to='#'
                    >
                    <img className="f-lang-icon" src={en_icon} alt="localization" /> 
                  </MDBNavLink>
                </MDBNavItem>
                 

              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>
      </>
    );
  }
}


function mapStateToProps(state) {
  return {
    mainReduxState: state.mainReduxState
  }
}

function mapDispatchToProps(dispatch) {
  return {
    mainReduxActions: bindActionCreators(mainReduxActions, dispatch)
  }
}

export default connect(mapStateToProps , mapDispatchToProps)(withTranslation()(HeaderComponent));
