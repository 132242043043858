import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdbreact';
import './ExpertsComponent.css';

import php from '../../assets/images/techIcons/php.png';
import java from '../../assets/images/techIcons/java.png';
import flutter from '../../assets/images/techIcons/flutter.png';
import reactjs from '../../assets/images/techIcons/reactjs.png';
import python from '../../assets/images/techIcons/python.png';
import js from '../../assets/images/techIcons/js.png';
import ionic from '../../assets/images/techIcons/ionic.png';
import angular from '../../assets/images/techIcons/angular.png';
import bigdata from '../../assets/images/techIcons/bigdata.png';
import salesforce from '../../assets/images/techIcons/salesforce.png';
import rubyonrails from '../../assets/images/techIcons/rubyonrails.png';
import security from '../../assets/images/techIcons/security.png';
import { withTranslation } from 'react-i18next';

class ExpertsComponent extends React.Component {
 
  state = {
    collapseID: ''
  };

 
  render() {

    const { collapseID } = this.state;

    const {t} = this.props;

    const header = this.props.header || 'faseya.specialists';

    return (
      <MDBContainer style={{marginTop:30}}>
  
        <h4 className="font-light f-subtitle f-subheader text-center " style={{fontWeight:700}}>{/*  {t('Welcome to React')} */} {t(header)} </h4>

        <MDBRow>
          
          <MDBCol  size="4" xs="4" md="3"  className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/PHP`, state:{reload : true}})}} src={php} className="img-fluid z-depth-1 rounded-circle f-experts-img" alt="" />
          </MDBCol>
          <MDBCol size="4" xs="4" md="3" className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/Java`, state:{reload : true}})}} src={java} className="img-fluid z-depth-1 rounded-circle f-experts-img"
              alt="" />
          </MDBCol>
          <MDBCol size="4" xs="4" md="3" className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/flutter`, state:{reload : true}})}} src={flutter} className="img-fluid z-depth-1 rounded-circle f-experts-img" alt="" />
          </MDBCol>

          <MDBCol size="4" xs="4" md="3" className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/React JS`, state:{reload : true}})}} src={reactjs} className="img-fluid z-depth-1 rounded-circle f-experts-img" alt="" />
          </MDBCol>

          <MDBCol size="4" xs="4" md="3"  className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/Python`, state:{reload : true}})}} src={python} className="img-fluid z-depth-1 rounded-circle f-experts-img" alt="" />
          </MDBCol>
         
          <MDBCol size="4" xs="4" md="3" className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/Javascript`, state:{reload : true}})}} src={js} className="img-fluid z-depth-1 rounded-circle f-experts-img"
              alt="" />
          </MDBCol>

          <MDBCol size="4" xs="4" md="3" className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/ionic`, state:{reload : true}})}} src={ionic} className="img-fluid z-depth-1 rounded-circle f-experts-img"
              alt="" />
          </MDBCol>

          <MDBCol size="4" xs="4" md="3" className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/Angular`, state:{reload : true}})}} src={angular} className="img-fluid z-depth-1 rounded-circle f-experts-img" alt="" />
          </MDBCol>

          <MDBCol size="4" xs="4" md="3" className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/Big data`, state:{reload : true}})}} src={bigdata} className="img-fluid z-depth-1 rounded-circle f-experts-img" alt="" />
          </MDBCol>

          <MDBCol size="4" xs="4" md="3" className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/salesforce`, state:{reload : true}})}} src={salesforce} className="img-fluid z-depth-1 rounded-circle f-experts-img" alt="" />
          </MDBCol>

          <MDBCol size="4" xs="4" md="3" className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/ruby`, state:{reload : true}})}} src={rubyonrails} className="img-fluid z-depth-1 rounded-circle f-experts-img" alt="" />
          </MDBCol>

          <MDBCol size="4" xs="4" md="3" className="mb-4 f-experts-item d-flex align-items-center">
            <img onClick={()=>{this.props.history.push({pathname: `consultants/security`, state:{reload : true}})}} src={security} className="img-fluid z-depth-1 rounded-circle f-experts-img" alt="" />
          </MDBCol>

        </MDBRow>
      
      
      </MDBContainer>
    );
  }
}

export default withTranslation()(ExpertsComponent);
