import React, {Fragment}  from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBCard,MDBCardBody, MDBCardTitle, MDBCardImage, MDBCardText,
  MDBIcon,
  MDBFormInline,
  MDBBadge,
  MDBBtn,
  MDBView,
  MDBInput,
  MDBProgress,
  MDBContainer
} from 'mdbreact';

import { MDBCollapseHeader } from '../../mdbreact/dist/mdbreact';
import InfiniteScroll from 'react-infinite-scroll-component';

import './ConsultantsListPage.css';
 
import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
 
import concept_image from '../../assets/images/concept_image.png';
import concept_image2 from '../../assets/images/concept_image2.png';
import olive_loader from '../../assets/images/olive-loader.gif'; 

import avataaar_0 from '../../assets/images/avataaars/avataaars.png';
import avataaar_1 from '../../assets/images/avataaars/avataaars(1).png';
import avataaar_2 from '../../assets/images/avataaars/avataaars(2).png';
import avataaar_3 from '../../assets/images/avataaars/avataaars(3).png';
import avataaar_4 from '../../assets/images/avataaars/avataaars(4).png';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import consultantsService from '../../services/consultants.service';
import authService from '../../services/auth.service';
import auth from '../../services/auth';
import skillService from '../../services/skill.service';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainReduxActions from '../../store/mainRedux/mainReduxActions';


class ConsultantsListPage extends React.Component {

  
  state = {
    collapsed: false,
    page : 1,
    size: 15,
    tech : this.props.location.pathname.split('?results=').pop(),
    consultants : [],
    skills : [],
    hasMore : true,
    loadingMore : true,
    loadingMoreAbsolute : false,
    avatarBaseUrl : "https://api.faseya-dev.com/api/consultants/files/",
    collapseID: ""
}
 
  toggleCollapse = (collapseID) =>{
  this.setState({collapseID});
}

  handleTogglerClick = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });

    
  };

    componentDidMount() {

      console.log('oii')

    // console.log("location",this.props.location.search.split('?results=').pop())

    window.scrollTo(0, 0);

    this.setState({
      consultants : this.props.mainReduxState.consultants,
      page : this.props.mainReduxState.consultantsPage,
    })

    // console.log(this.props.)


    
    setTimeout(() => {
      
      //window.location.reload()
      if(this.state.consultants.length == 0)
      {
       this.fetchData();
      }
      else
      {
        this.setState({loadingMore:false})
      }

      console.log(this.props.history.location)

      if(this.props.history.location.state)
        if(this.props.history.location.state.reload)
        {

          console.log('ok cool')

          this.setState({hasMore :true, loadingMore: true, consultants : [], page:1})
          this.fetchData();
        }

    }, 50);




    // this.setState({tech : tech})

    document.querySelector('nav').style.height = '65px';


  //   authService.signin({email:"user_02@domain.com", password:"password_02"}).then(data=>{
  //     //console.log(data);
  //     localStorage.setItem('authToken', data.accessToken)
  //     auth.login();
  //  })
  //  .catch(err=>{

  //  })

    
  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
    
    // this.props.mainReduxActions.update_consultants_list([]);
    // this.props.mainReduxActions.update_consultants_page(1);
  }



  fetchData = () =>{

    
    this.setState({hasMore :true, loadingMore: true})
    // console.log("Fetching : "+this.state.page)
    // this.setState({consultants : this.state.consultants2, hasMore : false});

    // console.log("tech",   this.props.history)

    const key = this.props.location.search ? this.props.location.search.split('?results=').pop(): this.props.location.pathname.split('?results=').pop()
  
     consultantsService.searchConsultants({ keyword :  key , page: this.state.page, size: this.state.size }).then(data=>{
        // console.log(data);
        var hasMore = data.length == this.state.size ? true : false;

        var newConsultants = this.state.consultants.slice();
        newConsultants.push(...data);
      
        newConsultants.forEach(item => {
          this.setSkillsItems(item)
        });

        // if(this.state.page == 1) 
        // this.setState({consultants : []});
        
        // var _newConsultants = newConsultants.filter((item, index)=> newConsultants.indexOf(item) === index);

        // console.log(newConsultants)
        // console.log(_newConsultants)


        var nextPage = this.state.page+1;

        // Update redux state
        this.props.mainReduxActions.update_consultants_list(newConsultants);
        this.props.mainReduxActions.update_consultants_page(nextPage);

        this.setState({consultants : newConsultants, /* hasMore : hasMore, */ page : nextPage, loadingMore : false, loadingMoreAbsolute:true});

    })
    .catch(err=>{
      
      this.setState({hasMore : false, loadingMore : false})
      // console.log(err)

    })
    
  }

  loadMore(){ 
    this.fetchData();
  }


  setSkillsItems(item)
    {
        var skillsString = item.competence_technique;
        var skillsArray = skillsString.split(',');
        const _skillsArray =  skillsArray.filter(e =>  e);

        var _skills = [];
      
        var max = 3;
        if(_skillsArray.length < 3) max = _skillsArray.length;
        for (let i = 0; i < max; i++) {
          const el = _skillsArray[i];
            _skills.push(el); 
           
        }

       item.skills = _skills;

    }
  refreshData(){

  }

  discoverConsultant(item)
  {

    localStorage.setItem("consultant", JSON.stringify(item)); 
    localStorage.setItem("keyword", this.state.tech); 
    this.props.history.push({pathname:'/cv-consultant',state :{consultant : item}});

  }

  render() {
    const { collapsed } = this.state;
    const { collapseID } = this.state;
    const navStyle = { marginTop: '0rem' };

    const {t} = this.props;

    // const overlay = (
    //   <div
    //     id='sidenav-overlay'
    //     style={{ backgroundColor: 'transparent' }}
    //     onClick={this.handleTogglerClick}
    //   />
    // );


    
    const consultants = this.state.consultants.map((item,index) => 
      {

        // var avatar = this.state.avatarBaseUrl+item.photo;
        var avatar = item.photo;
        if(item.photo)
          if(!item.photo.includes('https://'))
            avatar = 'https://'+avatar;
        // if(item.photo)
        //   if(item.photo.includes("api"))
        //     avatar = "https://"+item.photo;

        return (
        <MDBCol key={index} xs="12" sm="12" md="4" lg="4" xl="3" className="mb-3 align-items-center" onClick={()=>this.discoverConsultant(item)} >
              <MDBCard className="p-r-0" style={{ width: "100%", height:'100%', position:'relative', cursor:'pointer' }}>
              
              <MDBView style={{position:'relative'}}>
                {!item.photo &&
                  <img className="f-fit-image"  src={avataaar_0} style={{height:240, width:'100%', objectFit:'cover' }} />
                }
                {item.photo &&
                  <img className="f-fit-image"  src={avatar} style={{height:240, width:'100%', objectFit:'cover' }} />
                }
                <MDBMask className="flex-center" style={{backgroundColor:'#00000093'}}>
                </MDBMask>
                
                  {/* Availability */}
                  <div style={{position:'absolute', display:'flex', alignItems:'center', top:10, left:10, padding:2, paddingLeft:6, paddingRight:6, borderRadius:15, backgroundColor:'#fff', }}>
                    <div style={{width:17, height:17, backgroundColor:'olivedrab', borderRadius:17, marginRight:5}}></div>
                    <span className="" style={{color:'#000', fontSize:14}}>{t('faseya.consultants_list.available')}</span>
                  </div>


                  {/* Name */}
                  <div style={{position:'absolute', display:'flex', alignItems:'center', top:'70%', left:10, padding:4, paddingLeft:10, paddingRight:10, borderRadius:15, }}>
                    <strong className="" style={{color:'#fff', fontSize:20, fontWeight:700, lineHeight:'normal'}}>{item.prenom}</strong>
                  </div>


              </MDBView>
            
              {/* <div style={{position:'absolute', top:0, left:0, width:'100%', height:'100%', backgroundColor:'#000000a3', }}>
                </div> */}
              <MDBCardBody style={{ paddingBottom:'auto', position:'relative'}}>
                <MDBCardTitle style={{color:'#000', fontWeight:700, fontSize:'100%'}}>{item.title}</MDBCardTitle>
                
        
                  {/* Biding */}

                  <div style={{position:'absolute', display:'flex', alignItems:'center', top:'-12%', left:10, padding:4, paddingLeft:10, paddingRight:10, borderRadius:15, backgroundColor:'#d1d5db', }}>
                    <strong className="" style={{color:'#000', fontSize:15, fontWeight:700}}>{item.tjm} € / {t('faseya.per_day')}</strong>
                  </div>

                {/* <MDBContainer>
  
    </MDBContainer> */}
                <MDBRow  style={{overflow:'hidden'}}>
                 { item.skills && 
                    item.skills.map((t, subindex) =>{ 
                      if(subindex >2) return;
                      return( 
                        <MDBCol size="auto" className="mb-1" key={subindex} >
                          <MDBBadge color="light" className="f-cv-badge"><strong>{t}</strong></MDBBadge> 
                        </MDBCol>)
                        }
                      )
                  }  

                </MDBRow>

              </MDBCardBody>
            </MDBCard>
            
      </MDBCol>)
      }
    );

 


    return (

      <>
      <HeaderComponent history={this.props.history} />

      <div>
        <MDBView >  
          <MDBRow className="" >
          <MDBCol xs="12" md="12">
             
            <MDBRow className="mt-4 d-flex justify-content-center">

                <MDBCol xs="12" md="12"  className='mb-4 d-flex justify-content-center'>
                  
                    <p style={{fontWeight:500, color:'#000'}}>
                    {t('faseya.consultants_list.header')}  <strong style={{color:'olivedrab', textDecoration:'underline'}}>{this.state.tech}</strong>
                    </p> 
                 
                </MDBCol>
 
                <MDBCol xs="12" md="2"  className='mb-4 '>
                  <MDBCol>
                
                  <MDBRow className="f-no-margin">

                  <MDBRow >
                    <MDBRow className='md-accordion'>
                      <MDBCard className='mt-3' style={{width:'100%'}}>
                        <MDBCollapseHeader style={{fontSize:12}} className="f-side-filter-header"
                          tagClassName='d-flex'
                          onClick={() => this.toggleCollapse('collapse1')}
                        ><MDBIcon style={{marginRight:5}}
                            icon={collapseID === 'collapse1' ? 'angle-down' : 'angle-right'}
                          />
                          {t('faseya.consultants_list.filter.mission_type.title')}
                          
                        </MDBCollapseHeader>
                        <MDBCollapse id='collapse1' isOpen={collapseID}>
                          <MDBCardBody>
                            <div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxMissionTypeRemote" />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxMissionTypeRemote">{t('faseya.consultants_list.filter.mission_type.items.item_1')}</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxMissionTypeOffice"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxMissionTypeOffice">{t('faseya.consultants_list.filter.mission_type.items.item_2')}</label>
                              </div>
                            </div>
                          </MDBCardBody>
                        </MDBCollapse>
                      </MDBCard>

                      <MDBCard style={{width:'100%'}}>
                        <MDBCollapseHeader style={{fontSize:12}} className="f-side-filter-header"
                          tagClassName='d-flex '
                          onClick={() => this.toggleCollapse('collapse2')}
                        ><MDBIcon style={{marginRight:5}}
                            icon={collapseID === 'collapse2' ? 'angle-down' : 'angle-right'}
                          />
                          {t('faseya.consultants_list.filter.level_of_experience.title')}
                          
                        </MDBCollapseHeader>
                        <MDBCollapse id='collapse2' isOpen={collapseID}>
                          <MDBCardBody>
                            <div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxLevelJunior" />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxLevelJunior">{t('faseya.consultants_list.filter.level_of_experience.items.item_1')}</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxLevelConfirmed"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxLevelConfirmed">{t('faseya.consultants_list.filter.level_of_experience.items.item_2')}</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxLevelExpert"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxLevelExpert">{t('faseya.consultants_list.filter.level_of_experience.items.item_3')}</label>
                              </div>
                            </div>
                          </MDBCardBody>
                        </MDBCollapse>
                      </MDBCard>

                      <MDBCard style={{width:'100%'}}>
                        <MDBCollapseHeader style={{fontSize:12}} className="f-side-filter-header"
                          tagClassName='d-flex '
                          onClick={() => this.toggleCollapse('collapse3')}
                        ><MDBIcon style={{marginRight:5}}
                            icon={collapseID === 'collapse3' ? 'angle-down' : 'angle-right'}
                          />
                          {t('faseya.consultants_list.filter.price_per_day.title')}
                          
                        </MDBCollapseHeader>
                        <MDBCollapse id='collapse3' isOpen={collapseID}>
                          <MDBCardBody>
                            <div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxTarifJunior" />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxTarifJunior">65 <sup>€ HT</sup>/{t('faseya.per_day')}</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxTarifConfirmed"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxTarifConfirmed">80 <sup>€ HT</sup>/{t('faseya.per_day')}</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxTarifExpert"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxTarifExpert">100 <sup>€ HT</sup>/{t('faseya.per_day')}</label>
                              </div>
                            </div>
                          </MDBCardBody>
                        </MDBCollapse>
                      </MDBCard>

                      <MDBCard style={{width:'100%'}}>
                        <MDBCollapseHeader style={{fontSize:12}} className="f-side-filter-header"
                          tagClassName='d-flex '
                          onClick={() => this.toggleCollapse('collapse4')}
                        > <MDBIcon style={{marginRight:5}}
                            icon={collapseID === 'collapse4' ? 'angle-down' : 'angle-right'}
                          />
                          {t('faseya.consultants_list.filter.availability.title')}
                         
                        </MDBCollapseHeader>
                        <MDBCollapse id='collapse4' isOpen={collapseID}>
                          <MDBCardBody>
                            <div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxAvailabilityFulltime" />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxAvailabilityFulltime">{t('faseya.consultants_list.filter.availability.items.item_1')}</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxAvailabilityPartialtime"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxAvailabilityPartialtime">{t('faseya.consultants_list.filter.availability.items.item_2')}</label>
                              </div>
                            </div>
                          </MDBCardBody>
                        </MDBCollapse>
                      </MDBCard>


                      <MDBCard style={{width:'100%'}}>
                        <MDBCollapseHeader style={{fontSize:12}} className="f-side-filter-header"
                          tagClassName='d-flex '
                          onClick={() => this.toggleCollapse('collapse5')}
                        > <MDBIcon style={{marginRight:5}}
                            icon={collapseID === 'collapse5' ? 'angle-down' : 'angle-right'}
                          />
                          {t('faseya.consultants_list.filter.languages.title')}
                         
                        </MDBCollapseHeader>
                        <MDBCollapse id='collapse5' isOpen={collapseID}>
                          <MDBCardBody>
                            <div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxLanguageAll" />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxLanguageAll">International</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxLanguageFr"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxLanguageFr">Français</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxLanguageEn"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxLanguageEn">English</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxLanguageEs"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxLanguageEs">Espanol</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxLanguageAr"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxLanguageAr">عَرَبٌ</label>
                              </div>
                            </div>
                          </MDBCardBody>
                        </MDBCollapse>
                      </MDBCard>

                      <MDBCard style={{width:'100%'}}>
                        <MDBCollapseHeader style={{fontSize:12}} className="f-side-filter-header"
                          tagClassName='d-flex '
                          onClick={() => this.toggleCollapse('collapse6')}
                        > <MDBIcon style={{marginRight:5}}
                            icon={collapseID === 'collapse6' ? 'angle-down' : 'angle-right'}
                          />
                          {t('faseya.consultants_list.filter.prerequisite.title')}
                         
                        </MDBCollapseHeader>
                        <MDBCollapse id='collapse6' isOpen={collapseID}>
                          <MDBCardBody>
                            <div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxRequestedHtml" />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxRequestedHtml">HTML</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxRequestedCss"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxRequestedCss">CSS</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxRequestedJava"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxRequestedJava">Java</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxRequestedPython"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxRequestedPython">Python</label>
                              </div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxRequestedReact"  />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxRequestedReact">React JS</label>
                              </div>
                            </div>
                          </MDBCardBody>
                        </MDBCollapse>
                      </MDBCard>

                      <MDBCard style={{width:'100%'}}>
                        <MDBCollapseHeader style={{fontSize:12}} className="f-side-filter-header"
                          tagClassName='d-flex '
                          onClick={() => this.toggleCollapse('collapse7')}
                        > <MDBIcon style={{marginRight:5}}
                            icon={collapseID === 'collapse7' ? 'angle-down' : 'angle-right'}
                          />
                          {t('faseya.consultants_list.filter.time_zone.title')}
                         
                        </MDBCollapseHeader>
                        <MDBCollapse id='collapse7' isOpen={collapseID}>
                          <MDBCardBody>
                            <div>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="checkboxRequestedGmt" />
                                <label style={{fontWeight:600}} className="custom-control-label" htmlFor="checkboxRequestedGmt">GMT</label>
                              </div>
                            </div>
                          </MDBCardBody>
                        </MDBCollapse>
                      </MDBCard>

                    </MDBRow>
                  </MDBRow>
                
                      
                    </MDBRow>
                  
                  </MDBCol>

                </MDBCol>

                <MDBCol xs="12" md="9"  className='mb-4 p-r-0 white-text consultants-container' style={{overflow:'hidden'}}>
                  <MDBCol>
                  
                    <MDBContainer style={{position:'relative', height:560, overflowY:'scroll', overflowX:'hidden', width:'100%'}}>
                    <MDBRow className="d-flex align-items-center">

                      <MDBRow style={{width:'100%'}}>
                        {consultants}
                      </MDBRow>
                     
                      {this.state.loadingMore &&

                        <img src={olive_loader} style={{position:'absolute', zIndex:1, bottom:this.state.loadingMoreAbsolute ? '11%' : '50%', left:'47.5%', width:40}} alt="" />
                      
                      }
                      {this.state.consultants.length > 0 && !this.state.loadingMore &&
                      <MDBCol className="d-flex justify-content-center" style={{paddingBottom:50}}>

                        <MDBBtn className='f-btn-olive' style={{color:'#000'}}
                          onClick={()=>this.loadMore()}
                        >
                          <strong>{t('faseya.consultants_list.load_more')}</strong>
                        </MDBBtn>

                      </MDBCol>
                      }
 
                      
                    </MDBRow>

                   </MDBContainer>

                  </MDBCol>

                </MDBCol>


            

           
          </MDBRow>
         
          </MDBCol>

</MDBRow>


            {/* ------------------------ */}




        </MDBView>

        
    



      </div>

        <PrefooterComponent />
      </>

    );
  }
}


function mapStateToProps(state) {
  return {
    mainReduxState: state.mainReduxState
  }
}

function mapDispatchToProps(dispatch) {
  return {
    mainReduxActions: bindActionCreators(mainReduxActions, dispatch)
  }
}


export default connect(mapStateToProps , mapDispatchToProps)(withTranslation()(ConsultantsListPage));