import React, {useState, useEffect, useCallback}  from 'react';
import { Link, useParams } from 'react-router-dom';
import { Line } from 'rc-progress';
// import Select from "react-dropdown-select";
import Select, { StylesConfig } from 'react-select'
import makeAnimated from 'react-select/animated';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput, 
    MDBNotification
} from 'mdbreact';

import { Wizard, Steps, Step } from 'react-albus';
import SponsorsComponent from '../../components/SponsorsComponent/SponsorsComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
//import skills from '../../services/mocks/skills';
import skillService from '../../services/skill.service';
import './CustomerStepperPage.scss';
import logo_faseya from '../../assets/images/logo_faseya.png';
import olive_loader from '../../assets/images/olive-loader.gif'; 
import { withTranslation } from 'react-i18next';
import * as emailjs from 'emailjs-com';
import questionnaireService from '../../services/questionnaire.service';

function CustomerStepperPage(props) {
    
    const params = useParams();
    const[state, setState] = useState('');
    const[budget, setBudget] = useState('');
    const[mission, setMission] = useState('');
    const[statusProject, setStatusProject] = useState('');
    const[book, setBook] = useState('');
    const[advisor, setAdvisor] = useState('');
    const[start, setStart] = useState('');
    const[society, setSociety] = useState('');
    const[name, setName] = useState('');
    const[email, setEmail] = useState('');
    const[phone, setPhone] = useState('');
    const[surname, setSurname] = useState('');
    const[calculateValue, setCalculateValue] = useState('');
    const[profile, setProfile] = useState('');
    const[ended, setEnded] = useState(false);
    const[choosenTechs, setChoosenTechs] = useState([]);
    const[techs, setTechs] = useState([]);

    const[sendingRequest, setSendingRequest] = useState(false);

    const[showNotif, setShowNotif] = useState(false);
    const[showNotifError, setShowNotifError] = useState(false);
    const[messError, setMessError] = useState('');

    const onClick = (value) => setState(value);
    const onChangeMission = (value) => setMission(value);
    const onClickBudget = (value) => setBudget(value);
    const onChangeStatusProject = (value) => setStatusProject(value);
    const onChangeBook = (value) => setBook(value);
    const onChangeAdvisor = (value) => setAdvisor(value);
    const onChangeStart = (value) => setStart(value);
    const onChangeSociety = (e) => setSociety(e.target.value);
    const onChangeName = (e) => setName(e.target.value);
    const onChangeSurname = (e) => setSurname(e.target.value);
    const onChangePhone = (e) => setPhone(e.target.value);
    const onChangeEmail = (e) => setEmail(e.target.value);
    const onChangeProfile = (value) =>{setProfile(value);}



        
    const animatedComponents = makeAnimated();

    const singleItem = (color = 'olivedrab', width = 110) => ({
        alignItems: 'center',
        width:'max-content',
        backgroundColor: color,
        color: 'white',
        borderRadius: 10,
        textIndent:10,
        paddingRight:5,
        marginRight:7
      });
    
    const customStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = 'olivedrab';
            return {
              ...styles,
              backgroundColor: 'white',
              color: 'black',
              cursor: isDisabled ? 'not-allowed' : 'default',
              ':active': {
                ...styles[':active'],
                backgroundColor: 'olivedrab',
              },
            };
          },
          multiValue: (styles, { data }) => {
            const color = 'olivedrab';
            return {
              ...styles,
              backgroundColor: color,
              borderRadius: 10,

            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: 'white',
            borderRadius: 10,

          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            backgroundColor:'#6b8e23bd',
            borderRadius: 10,

            color: 'white',
            ':hover': {
              backgroundColor: '#dc3545',
              color: 'white',
                borderRadius: 10,

            },
          }),
          singleValue: (styles, { data }) => 
          ({ ...styles, ...singleItem(data.color, data.width) }),
        
      
      }


    useEffect(()=>{

        window.scrollTo(0, 0);
        skillService.getSkills().then((res) => {
            var skillsData = res;
            skillsData.forEach(el => {
                el.value = el.name;
                el.label = el.name;
            });
            
            setTechs(skillsData);
        })

    },[])


    const onChangeCalculate = useCallback((value) => {
      
        if(value !== "14") {
            document.querySelector('.calculate-input').classList.remove('form-control');
            document.querySelector('.calculate-input').style.borderColor = 'red';
        }else {
            document.querySelector('.calculate-input').classList.add('form-control');
            document.querySelector('.calculate-input').style.borderColor = '';
        }
        
    },[])
    

    const submitHandler = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";
        
        if(calculateValue !== "14" || calculateValue === "")
        {
            document.querySelector('.calculate-input').classList.remove('form-control');
            document.querySelector('.calculate-input').style.borderColor = 'red';
        } else {

            console.log(props)

            const requestData = {
                service:{
                    id: parseInt(params.id,10),
                    needs: state,
                },
                budget:budget,
                mission_duration: mission,
                project_status: statusProject,
                specification_docs: book == "oui" ? true : false,
                talents_needed: advisor,
                start_project: start,
                company_type: profile,
                company_info: {
                  name: society,
                  first_name: surname,
                  last_name: name,
                  phone: phone,
                  email: email,
                }

            }


            if(params.name === "programmation") {
                // Hack
                // requestData.service.id = 3;
                ///////////
                var techs = []
                choosenTechs.map((item, index) => (
                    techs.push(item.name)
                ))
                requestData.service.technologies = techs
            }

            // const templateParams = {
            //     from_email: "petito.tapondjou@yahoo.fr",
            //     from_name: name,
            //     message: message,
            // }
            
            // emailjs.send(
            //     'service_eatl4bf',
            //     'template_a7182bv',
            //      templateParams,
            //     'user_MVCMETG15A44vBcUXt0nR'
            // ).then((result) => {
            //     console.log(result.text)
            //     setEnded(true);
            // }, (err) => {
            //     console.log(err.text)
            // })

            // console.log(requestData)
            setShowNotifError(false)
            setSendingRequest(true);
            questionnaireService.addQuestionnaire(requestData).then(data=>{

                // email js
                
                emailjs.send('service_kian0gc','template_41bb9e1',requestData.company_info, 'CQ_rLPb6EoSosAH_F')
                .then((result)=>{
                })
                .catch((error)=>console.log(error.text))
                
                
                setSendingRequest(false);
                // setShowNotif(true)
                setEnded(true);

                setTimeout(() => {
                    // setShowNotif(false)
                    // props.history.push({
                    //     pathname: "/",
                    // });
                }, 2400);
                // console.log(data)
                
            })
            .catch((err)=>{
                setMessError(err.report || err.Report)
                setSendingRequest(false);
                setShowNotifError(true)
                // setTimeout(() => {
                //     setShowNotifError(false)
                // }, 3000);
                // console.log(err)
            })
            
        }
       
    }

    function onChangeTechs(values)  {
        setChoosenTechs(values)
    }

    const {t} = props;

    return (
            <>
                <HeaderComponent  history={props.history} />

                <MDBRow >
                   

                <MDBNotification  
                    show
                    style={{position:'fixed', right:'4%', top:'4%', zIndex:888, backgroundColor:'olivedrab', color:'white', display:showNotif?'block':'none'}}
                    fade
                    icon='none'
                    iconClassName="text-primary"
                    title="Notification"
                    message={t('faseya.modify_cv.message_success')}
                />
                
                <MDBNotification  
                    show
                    style={{position:'fixed', right:'4%', top:'4%', zIndex:888, backgroundColor:'red', color:'white', display:showNotifError?'block':'none'}}
                    fade
                    icon='none'
                    iconClassName="text-primary"
                    title="Notification"
                    message={messError}
                />
                   
                     
                    <MDBCol xs="12" md="9" className="pb-5"  style={{ backgroundColor: '#e3e3e3'}}>
                            {sendingRequest &&
                                <div style={{position:'absolute', top:0, left:0, backgroundColor:'#678b2d8a', width:'100%', height:'100%', zIndex:3}}>
                                    <img src={olive_loader} style={{position:'absolute', zIndex:1, top:'40%', left:'47.5%', width:40}} alt="" />
                                </div>
                            }
                            <div className="d-flex ml-2">
                                <Link to="/">
                                        <p style={{color: 'olivedrab', fontWeight: '400', textDecoration: 'underline'}}>
                                           {t('faseya.back')}
                                        </p>
                                </Link>
                            </div>

                            {!ended ?
                            <>

                            <MDBRow className="my-5 justify-content-center">
                                <MDBCol md="8" className="">
                                    <div className="d-flex px-3 py-4 block-text">
                                       {t('faseya.interest')}
                                    </div>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="mt-5 justify-content-center">
                                <MDBCol md="8">
                                    <div className="d-flex">
                                    { params.name === "programmation" && (
                                        <Wizard
                                        render={({ step, steps }) => (
                                        <div>
                                            <h3 className="service-title">{t('faseya.it.title')}</h3>
                                            <Line
                                                percent={(steps.indexOf(step) + 1) / steps.length * 100}
                                                className="pad-b"
                                                strokeColor="#6b8e23"
                                                strokeWidth="2"
                                            />
                                        
                                            <Steps>
                                                <Step
                                                    id={`${params.name}1`}
                                                    render={({ next }) => (
                                                        <div className="d-flex flex-column mt-3">
                                                            <div className="ml-3 ml-md-0">
                                                                <span className="title-service"> 
                                                                   {t('faseya.it.need')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label" htmlFor="radio1">
                                                                        <input 
                                                                            onChange={() => onClick('developpement web')} 
                                                                            checked={state === 'developpement web' ? true : false}
                                                                            type="radio" 
                                                                            id="radio1"     
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.web')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   
                                                                    <label className="form-check-label" htmlFor="radio2">
                                                                    <input 
                                                                        onChange={() => onClick('developpement mobile')} 
                                                                        checked={state === 'developpement mobile' ? true : false}
                                                                        type="radio" 
                                                                        id="radio2"
                                                                    />   
                                                                    &nbsp;&nbsp;&nbsp; {t('faseya.it.mobile')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label" htmlFor="radio3">
                                                                        <input 
                                                                            onChange={() => onClick('developpement logiciel')} 
                                                                            checked={state === 'developpement logiciel' ? true : false}
                                                                            type="radio" 
                                                                            id="radio3"
                                                                        />   
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.software')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label" htmlFor="radio4">
                                                                        <input 
                                                                            onChange={() => onClick('autres')} 
                                                                            checked={state === 'autres' ? true : false}
                                                                            type="radio" 
                                                                            id="radio4"
                                                                        />
                                                                           &nbsp;&nbsp;&nbsp; {t('faseya.it.others')} 
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                            <MDBBtn 
                                                                className="btn-color" 
                                                                onClick={state !== '' ? next : () => {}}
                                                                style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                            >
                                                                {t('faseya.next')}
                                                            </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                <Step
                                                    id={`${params.name}2`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.customized_budget')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label" htmlFor="radio11">
                                                                        <input 
                                                                            onChange={() => onClickBudget('junior')} 
                                                                            checked={budget === 'junior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio11"
                                                                        
                                                                        />  
                                                                          &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_1')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label" htmlFor="radio21">
                                                                        <input 
                                                                            onChange={() => onClickBudget('confirme')} 
                                                                            checked={budget === 'confirme' ? true : false}
                                                                            type="radio" 
                                                                            id="radio21"
                                                                        />  
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_2')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio31">
                                                                        <input 
                                                                            onChange={() => onClickBudget('senior')} 
                                                                            checked={budget === 'senior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio31"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_3')}  
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                <label className="form-check-label " htmlFor="radio41">
                                                                    <input 
                                                                        onChange={() => onClickBudget('je ne sais pas')} 
                                                                        checked={budget === 'je ne sais pas' ? true : false}
                                                                        type="radio" 
                                                                        id="radio41"
                                                                    />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_4')}  
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}     
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={budget !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                
                                                <Step
                                                    id={`${params.name}3`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.duration_mission')} 
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio12">
                                                                        <input 
                                                                            onChange={() => onChangeMission('-1mois')} 
                                                                            checked={mission === '-1mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio12"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio22">
                                                                        <input 
                                                                            onChange={() => onChangeMission('1mois')} 
                                                                            checked={mission === '1mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio22"
                                                                        />  
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio32">
                                                                        <input 
                                                                            onChange={() => onChangeMission('entre 2 et 4 mois')} 
                                                                            checked={mission === 'entre 2 et 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio32"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_3')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio42">
                                                                        <input 
                                                                            onChange={() => onChangeMission('plus de 4 mois')} 
                                                                            checked={mission === 'plus de 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio42"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_4')}
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}     
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={mission !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />


                                                <Step
                                                    id={`${params.name}4`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                {t('faseya.it.project_status.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio13">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('idee')} 
                                                                            checked={statusProject === 'idee' ? true : false}
                                                                            type="radio" 
                                                                            id="radio13"
                                                                        /> 
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio23">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('refonte')} 
                                                                            checked={statusProject === 'refonte' ? true : false}
                                                                            type="radio" 
                                                                            id="radio23"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio33">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('je ne sais pas')} 
                                                                            checked={statusProject === 'je ne sais pas' ? true : false}
                                                                            type="radio" 
                                                                            id="radio33"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_3')}
                                                                    </label>
                                                                </MDBCol>
                                   
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                   
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={statusProject !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}5`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.specification_brief.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio14">
                                                                        <input 
                                                                            onChange={() => onChangeBook("oui")} 
                                                                            checked={book === 'oui' ? true : false}
                                                                            type="radio" 
                                                                            id="radio14"
                                                                        />  
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.yes')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio23">
                                                                        <input 
                                                                            onChange={() => onChangeBook("non")} 
                                                                            checked={book === 'non' ? true : false}
                                                                            type="radio" 
                                                                            id="radio23"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.no')}
                                                                        </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={book !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}6`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column w-100">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.select_technology.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2 mb-5">

                                                                <MDBCol size="12" className="mb-3">     
                                                                    {/* <Select 
                                                                        labelField="name"
                                                                        valueField="id"
                                                                        dropdownHandleRenderer={() => {}}
                                                                        options={skills} 
                                                                        searchable="true"
                                                                        multi="true"
                                                                        searchBy="name"
                                                                        value={choosenTechs}
                                                                        color="gray"
                                                                        placeholder="PHP - Laravel - React"
                                                                        className="form-control auto-complete"
                                                                        keepSelectedInList="false"
                                                                        onChange={(values) => onChangeAvailable(values)} 
                                                                    />         */}

                                                                    <Select
                                                                        styles={customStyles}
                                                                        closeMenuOnSelect={true}
                                                                        components={animatedComponents}
                                                                        isMulti
                                                                        value={choosenTechs}
                                                                        placeholder="React JS - Node JS ..."
                                                                        isSearchable
                                                                        options={techs}
                                                                        onChange={(values) => onChangeTechs(values)}  

                                                                        />

                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end mt-5">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={choosenTechs.length != 0 ? next : ()=> {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}7`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.talents_needed.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio16">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('un')} 
                                                                            checked={advisor === 'un' ? true : false}
                                                                            type="radio" 
                                                                            id="radio16"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.one_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio26">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('plusieurs')} 
                                                                            checked={advisor === 'plusieurs' ? true : false}
                                                                            type="radio" 
                                                                            id="radio26"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.several_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio36">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('equipe')} 
                                                                            checked={advisor === 'equipe' ? true : false}
                                                                            type="radio" 
                                                                            id="radio36"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.multi_skilled_teams')}
                                                                    </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={advisor != "" ? next : () => {}}
                                                                  
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}8`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.start_project.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio18">
                                                                        <input 
                                                                            onChange={() => onChangeStart('immediatement')} 
                                                                            checked={start === 'immediatement' ? true : false}
                                                                            type="radio" 
                                                                            id="radio18"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_1')}
                                                                        </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                        <label className="form-check-label " htmlFor="radio28">
                                                                        <input 
                                                                            onChange={() => onChangeStart('une ou deux')} 
                                                                            checked={start === 'une ou deux' ? true : false}
                                                                            type="radio" 
                                                                            id="radio28"
                                                                        />  
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio38">
                                                                        <input 
                                                                            onChange={() => onChangeStart('un mois')} 
                                                                            checked={start === 'un mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio38"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_3')}
                                                                    </label>
                                                                </MDBCol>

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                  
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={start != "" ? next : () => {}}
                                                                  
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                
                                                <Step
                                                    id={`${params.name}9`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column w-100">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.you_re.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2 mb-5">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio180">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('Start-up')} 
                                                                            checked={profile === 'Start-up' ? true : false}
                                                                            type="radio" 
                                                                            id="radio180"  
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.start_up')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio181">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('pme-pmi')} 
                                                                            checked={profile === 'pme-pmi' ? true : false}
                                                                            type="radio" 
                                                                            id="radio181"  
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.pme')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio182">
                                                                    <input 
                                                                        onChange={() => onChangeProfile('entreprise')} 
                                                                        checked={profile === 'entreprise' ? true : false}
                                                                        type="radio" 
                                                                        id="radio182"  
                                                                    /> 
                                                                    &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.large')}
                                                                    </label>      
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end mt-5">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={next}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}10`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <form className='needs-validation w-100' onSubmit={submitHandler} noValidate>
                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={society}
                                                                        onChange={onChangeSociety} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_9')}
                                                                        className="form-control auto-complete"
                                                                        required                                                                
                                                                    />
                                                                    
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <input  
                                                                        value={name}
                                                                        onChange={onChangeName} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_1')}
                                                                        className="form-control auto-complete"
                                                                        required                                                                
                                                                    />
                                                                   
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={surname}
                                                                        onChange={onChangeSurname} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_2')}
                                                                        className="form-control auto-complete"  
                                                                        required                                                              
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={phone}
                                                                        onChange={onChangePhone} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_4')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={email}
                                                                        onChange={onChangeEmail} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_3')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                </MDBCol>     

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                
                                                                <div className="d-flex flex-row align-items-center mr-3">
                                                                    <span className="calculate-text mx-3">
                                                                        9 + 5
                                                                    </span>
                                                                    <input
                                                                      type="text"
                                                                      value={calculateValue}
                                                                      value={calculateValue}
                                                                      onChange={(e) => {
                                                                         setCalculateValue(e.target.value)
                                                                         onChangeCalculate(e.target.value)
                                                                        }}
                                                                      className="calculate-input"
                                                                    />
                                                                </div>
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                  
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    type="submit"
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.send')}
                                                                </MDBBtn>
                                                            </div>
                                                            </form>
                                                        </div>
                                                    )}
                                                />


                                           </Steps>
                                        </div>
                                        )}
                                        />
                                        ) }


                                       {params.name === "design" && (
                                        <Wizard
                                        render={({ step, steps }) => (
                                        <div>
                                            <h3 className="service-title">{t('faseya.it.design.title')}</h3>
                                            <Line
                                                percent={(steps.indexOf(step) + 1) / steps.length * 100}
                                                className="pad-b"
                                                strokeColor="#6b8e23"
                                                strokeWidth="2"
                                            />
                                        
                                            <Steps>
                                                <Step
                                                    id={`${params.name}1`}
                                                    render={({ next }) => (
                                                        <div className="d-flex flex-column mt-3">
                                                            <div className="ml-3 ml-md-0">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.design.need')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio01">
                                                                        <input 
                                                                            onChange={() => onClick('ui ux')} 
                                                                            checked={state === 'ui ux' ? true : false}
                                                                            type="radio" 
                                                                            id="radio01"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.design.design_1')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio2">
                                                                        <input 
                                                                            onChange={() => onClick('illustrator')} 
                                                                            checked={state === 'illustrator' ? true : false}
                                                                            type="radio" 
                                                                            id="radio2"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.design.design_2')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio3">
                                                                        <input 
                                                                            onChange={() => onClick('graphiste')} 
                                                                            checked={state === 'graphiste' ? true : false}
                                                                            type="radio" 
                                                                            id="radio3"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.design.design_3')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio4">
                                                                        <input 
                                                                            onChange={() => onClick('concepteur')} 
                                                                            checked={state === 'concepteur' ? true : false}
                                                                            type="radio" 
                                                                            id="radio4"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.design.design_4')}
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                            <MDBBtn 
                                                                className="btn-color" 
                                                                onClick={state !== '' ? next : () => {}}
                                                                style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                            >
                                                                {t('faseya.next')}
                                                            </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                <Step
                                                    id={`${params.name}2`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.customized_budget')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio11">
                                                                        <input 
                                                                            onChange={() => onClickBudget('junior')} 
                                                                            checked={budget === 'junior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio11"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_1')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio21">
                                                                        <input 
                                                                            onChange={() => onClickBudget('confirme')} 
                                                                            checked={budget === 'confirme' ? true : false}
                                                                            type="radio" 
                                                                            id="radio21"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_2')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio31">
                                                                        <input 
                                                                            onChange={() => onClickBudget('senior')} 
                                                                            checked={budget === 'senior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio31"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_3')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio41">
                                                                        <input 
                                                                            onChange={() => onClickBudget('je ne sais pas')} 
                                                                            checked={budget === 'je ne sais pas' ? true : false}
                                                                            type="radio" 
                                                                            id="radio41"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_4')} 
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={budget !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                
                                                <Step
                                                    id={`${params.name}3`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.duration_mission')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio12">
                                                                        <input 
                                                                            onChange={() => onChangeMission('-1mois')} 
                                                                            checked={mission === '-1mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio12"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio22">
                                                                        <input 
                                                                            onChange={() => onChangeMission('1mois')} 
                                                                            checked={mission === '1mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio22"
                                                                        />
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio32">
                                                                        <input 
                                                                            onChange={() => onChangeMission('entre 2 et 4 mois')} 
                                                                            checked={mission === 'entre 2 et 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio32"
                                                                        /> 
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_3')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio42">
                                                                        <input 
                                                                            onChange={() => onChangeMission('plus de 4 mois')} 
                                                                            checked={mission === 'plus de 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio42"
                                                                        />  
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_4')} 
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={mission !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />


                                                <Step
                                                    id={`${params.name}4`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.project_status.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio13">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('idee')} 
                                                                            checked={statusProject === 'idee' ? true : false}
                                                                            type="radio" 
                                                                            id="radio13"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio23">
                                                                    <input 
                                                                        onChange={() => onChangeStatusProject('refonte')} 
                                                                        checked={statusProject === 'refonte' ? true : false}
                                                                        type="radio" 
                                                                        id="radio23"
                                                                    />  
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio33">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('je ne sais pas')} 
                                                                            checked={statusProject === 'je ne sais pas' ? true : false}
                                                                            type="radio" 
                                                                            id="radio33"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_3')}
                                                                    </label>
                                                                </MDBCol>
                                   
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                   
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={statusProject !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}5`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.specification_brief.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio14">
                                                                    <input 
                                                                        onChange={() => onChangeBook('oui')} 
                                                                        checked={book === 'oui' ? true : false}
                                                                        type="radio" 
                                                                        id="radio14" 
                                                                    />  
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.yes')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio23">
                                                                        <input 
                                                                            onChange={() => onChangeBook('non')} 
                                                                            checked={book === 'non' ? true : false}
                                                                            type="radio" 
                                                                            id="radio23"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.no')}
                                                                    </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={book !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                              
                                                <Step
                                                    id={`${params.name}7`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.talents_needed.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio16">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('un')} 
                                                                            checked={advisor === 'un' ? true : false}
                                                                            type="radio" 
                                                                            id="radio16"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.one_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio26">
                                                                    <input 
                                                                        onChange={() => onChangeAdvisor('plusieurs')} 
                                                                        checked={advisor === 'plusieurs' ? true : false}
                                                                        type="radio" 
                                                                        id="radio26"
                                                                    /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.several_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio36">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('equipe')} 
                                                                            checked={advisor === 'equipe' ? true : false}
                                                                            type="radio" 
                                                                            id="radio36"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.multi_skilled_teams')}
                                                                    </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={advisor != "" ? next : () => {}}
                                                                  
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}8`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                     {t('faseya.it.start_project.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio18">
                                                                    <input 
                                                                        onChange={() => onChangeStart('immediatement')} 
                                                                        checked={start === 'immediatement' ? true : false}
                                                                        type="radio" 
                                                                        id="radio18"  
                                                                    /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio28">
                                                                        <input 
                                                                            onChange={() => onChangeStart('une ou deux')} 
                                                                            checked={start === 'une ou deux' ? true : false}
                                                                            type="radio" 
                                                                            id="radio28"
                                                                        /> 
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio38">
                                                                        <input 
                                                                            onChange={() => onChangeStart('un mois')} 
                                                                            checked={start === 'un mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio38"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_3')}
                                                                    </label>
                                                                </MDBCol>

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                  
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={start != "" ? next : () => {}}
                                                                  
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />


                                                <Step
                                                    id={`${params.name}9`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column w-100">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.you_re.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2 mb-5">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio180">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('Start-up')} 
                                                                            checked={profile === 'Start-up' ? true : false}
                                                                            type="radio" 
                                                                            id="radio180"  
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.start_up')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio181">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('pme-pmi')} 
                                                                            checked={profile === 'pme-pmi' ? true : false}
                                                                            type="radio" 
                                                                            id="radio181"  
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.pme')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio182">
                                                                    <input 
                                                                        onChange={() => onChangeProfile('entreprise')} 
                                                                        checked={profile === 'entreprise' ? true : false}
                                                                        type="radio" 
                                                                        id="radio182"  
                                                                    /> 
                                                                    &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.large')}
                                                                    </label>      
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end mt-5">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={next}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}10`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <form className='needs-validation w-100' onSubmit={submitHandler} noValidate>
                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={society}
                                                                        onChange={onChangeSociety} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_9')}
                                                                        className="form-control auto-complete"  
                                                                        required                                                              
                                                                    />
                                                                    
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <input  
                                                                        value={name}
                                                                        onChange={onChangeName} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_1')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                   
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={surname}
                                                                        onChange={onChangeSurname} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_2')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={phone}
                                                                        onChange={onChangePhone} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_4')}
                                                                        className="form-control auto-complete"  
                                                                        required                                                              
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={email}
                                                                        onChange={onChangeEmail} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_3')}
                                                                        className="form-control auto-complete"
                                                                        required                                                                
                                                                    />
                                                                </MDBCol>

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <div className="d-flex flex-row align-items-center mr-3">
                                                                    <span className="calculate-text mx-3">
                                                                        9 + 5
                                                                    </span>
                                                                    <input
                                                                      type="text"
                                                                      value={calculateValue}
                                                                      value={calculateValue}
                                                                      onChange={(e) => {
                                                                         setCalculateValue(e.target.value)
                                                                         onChangeCalculate(e.target.value)
                                                                        }}
                                                                      className="calculate-input"
                                                                    />
                                                                </div>
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                  
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    type="submit"
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.send')}
                                                                </MDBBtn>
                                                            </div>
                                                            </form>
                                                        </div>
                                                    )}
                                                />


                                           </Steps>
                                        </div>
                                        )}
                                        />
                                        ) }


                                       { params.name === "reseaux" && (
                                        <Wizard
                                        render={({ step, steps }) => (
                                        <div>
                                            <h3 className="service-title">{t('faseya.it.network.title')}</h3>
                                            <Line
                                                percent={(steps.indexOf(step) + 1) / steps.length * 100}
                                                className="pad-b"
                                                strokeColor="#6b8e23"
                                                strokeWidth="2"
                                            />
                                        
                                            <Steps>
                                                <Step
                                                    id={`${params.name}1`}
                                                    render={({ next }) => (
                                                        <div className="d-flex flex-column mt-3">
                                                            <div className="ml-3 ml-md-0">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.network.need')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio1">
                                                                        <input 
                                                                            onChange={() => onClick('administrateur systeme')} 
                                                                            checked={state === 'administrateur systeme' ? true : false}
                                                                            type="radio" 
                                                                            id="radio1"
                                                                        /> 
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.network.item_1')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio2">
                                                                        <input 
                                                                            onChange={() => onClick('ingenieur')} 
                                                                            checked={state === 'ingenieur' ? true : false}
                                                                            type="radio" 
                                                                            id="radio2"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.network.item_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio3">
                                                                        <input 
                                                                            onChange={() => onClick('chef')} 
                                                                            checked={state === 'chef' ? true : false}
                                                                            type="radio" 
                                                                            id="radio3"
                                                                        />
                                                                       &nbsp;&nbsp;&nbsp; {t('faseya.it.network.item_3')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio444">
                                                                        <input 
                                                                            onChange={() => onClick('administrateur reseaux telecoms')} 
                                                                            checked={state === 'administrateur reseaux telecoms' ? true : false}
                                                                            type="radio" 
                                                                            id="radio444"
                                                                        />  
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.network.item_4')}
                                                                    </label>
                                                                </MDBCol>
                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio400">
                                                                        <input 
                                                                            onChange={() => onClick('administrateur systeme linus')} 
                                                                            checked={state === 'administrateur systeme linus' ? true : false}
                                                                            type="radio" 
                                                                            id="radio400"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.network.item_5')}
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                            <MDBBtn 
                                                                className="btn-color" 
                                                                onClick={state !== '' ? next : () => {}}
                                                                style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                            >
                                                                {t('faseya.next')}
                                                            </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                <Step
                                                    id={`${params.name}2`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.customized_budget')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio11">
                                                                        <input 
                                                                            onChange={() => onClickBudget('junior')} 
                                                                            checked={budget === 'junior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio11"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_1')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio21">
                                                                        <input 
                                                                            onChange={() => onClickBudget('confirme')} 
                                                                            checked={budget === 'confirme' ? true : false}
                                                                            type="radio" 
                                                                            id="radio21"
                                                                        />  
                                                                          &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_2')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio31">
                                                                        <input 
                                                                            onChange={() => onClickBudget('senior')} 
                                                                            checked={budget === 'senior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio31"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_3')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio41">
                                                                    <input 
                                                                        onChange={() => onClickBudget('je ne sais pas')} 
                                                                        checked={budget === 'je ne sais pas' ? true : false}
                                                                        type="radio" 
                                                                        id="radio41"
                                                                    />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_4')} 
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={budget !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                
                                                <Step
                                                    id={`${params.name}3`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.duration_mission')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio12">
                                                                        <input 
                                                                            onChange={() => onChangeMission('-1mois')} 
                                                                            checked={mission === '-1mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio12" 
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio22">
                                                                        <input 
                                                                            onChange={() => onChangeMission('1mois')} 
                                                                            checked={mission === '1mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio22"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio32">
                                                                        <input 
                                                                            onChange={() => onChangeMission('entre 2 et 4 mois')} 
                                                                            checked={mission === 'entre 2 et 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio32"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_3')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio42">
                                                                        <input 
                                                                            onChange={() => onChangeMission('plus de 4 mois')} 
                                                                            checked={mission === 'plus de 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio42"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_4')} 
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={mission !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />


                                                <Step
                                                    id={`${params.name}4`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.project_status.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio13">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('idee')} 
                                                                            checked={statusProject === 'idee' ? true : false}
                                                                            type="radio" 
                                                                            id="radio13"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio23">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('refonte')} 
                                                                            checked={statusProject === 'refonte' ? true : false}
                                                                            type="radio" 
                                                                            id="radio23"
                                                                        />    
                                                                                &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio33">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('je ne sais pas')} 
                                                                            checked={statusProject === 'je ne sais pas' ? true : false}
                                                                            type="radio" 
                                                                            id="radio33"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_3')}
                                                                    </label>
                                                                </MDBCol>
                                   
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                   
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={statusProject !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}5`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.specification_brief.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio14">
                                                                        <input 
                                                                            onChange={() => onChangeBook('oui')} 
                                                                            checked={book === 'oui' ? true : false}
                                                                            type="radio" 
                                                                            id="radio14"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.yes')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio23">
                                                                        <input 
                                                                            onChange={() => onChangeBook('non')} 
                                                                            checked={book === 'non' ? true : false}
                                                                            type="radio" 
                                                                            id="radio23"
                                                                        /> 
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.no')}
                                                                    </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={book !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                              
                                                <Step
                                                    id={`${params.name}7`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.talents_needed.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio16">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('un')} 
                                                                            checked={advisor === 'un' ? true : false}
                                                                            type="radio" 
                                                                            id="radio16" 
                                                                        />
                                                                       &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.one_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                <label className="form-check-label " htmlFor="radio26">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('plusieurs')} 
                                                                            checked={advisor === 'plusieurs' ? true : false}
                                                                            type="radio" 
                                                                            id="radio26"
                                                                        /> 
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.several_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio36">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('equipe')} 
                                                                            checked={advisor === 'equipe' ? true : false}
                                                                            type="radio" 
                                                                            id="radio36"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.multi_skilled_teams')}
                                                                    </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}     
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={advisor != "" ? next : () => {}}
                                                                  
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}8`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                     {t('faseya.it.start_project.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio18">
                                                                        <input 
                                                                            onChange={() => onChangeStart('immediatement')} 
                                                                            checked={start === 'immediatement' ? true : false}
                                                                            type="radio" 
                                                                            id="radio18"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio28">
                                                                        <input 
                                                                            onChange={() => onChangeStart('une ou deux')} 
                                                                            checked={start === 'une ou deux' ? true : false}
                                                                            type="radio" 
                                                                            id="radio28"
                                                                        />   
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio38">
                                                                    <input 
                                                                        onChange={() => onChangeStart('un mois')} 
                                                                        checked={start === 'un mois' ? true : false}
                                                                        type="radio" 
                                                                        id="radio38"
                                                                    /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_3')}
                                                                    </label>
                                                                </MDBCol>

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                  
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={start != "" ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}9`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column w-100">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.you_re.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2 mb-5">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio180">
                                                                    <input 
                                                                        onChange={() => onChangeProfile('Start-up')} 
                                                                        checked={profile === 'Start-up' ? true : false}
                                                                        type="radio" 
                                                                        id="radio180"  
                                                                    /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.start_up')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio181">
                                                                    <input 
                                                                        onChange={() => onChangeProfile('pme-pmi')} 
                                                                        checked={profile === 'pme-pmi' ? true : false}
                                                                        type="radio" 
                                                                        id="radio181"  
                                                                    /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.pme')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio182">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('entreprise')} 
                                                                            checked={profile === 'entreprise' ? true : false}
                                                                            type="radio" 
                                                                            id="radio182"  
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.large')}
                                                                    </label>      
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end mt-5">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={next}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />


                                                <Step
                                                    id={`${params.name}10`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <form className='needs-validation w-100' onSubmit={submitHandler} noValidate>
                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={society}
                                                                        onChange={onChangeSociety} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_9')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                    
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <input  
                                                                        value={name}
                                                                        onChange={onChangeName} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_1')}
                                                                        className="form-control auto-complete"  
                                                                        required                                                              
                                                                    />
                                                                   
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={surname}
                                                                        onChange={onChangeSurname} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_2')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={phone}
                                                                        onChange={onChangePhone} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_4')}
                                                                        className="form-control auto-complete"  
                                                                        required                                                              
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={email}
                                                                        onChange={onChangeEmail} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_3')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                </MDBCol>

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <div className="d-flex flex-row align-items-center mr-3">
                                                                    <span className="calculate-text mx-3">
                                                                        9 + 5
                                                                    </span>
                                                                    <input
                                                                      type="text"
                                                                      value={calculateValue}
                                                                      value={calculateValue}
                                                                      onChange={(e) => {
                                                                         setCalculateValue(e.target.value)
                                                                         onChangeCalculate(e.target.value)
                                                                        }}
                                                                      className="calculate-input"
                                                                    />
                                                                </div>
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                  
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    type="submit"
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.send')}
                                                                </MDBBtn>
                                                            </div>
                                                            </form>
                                                        </div>
                                                    )}
                                                />
                                           </Steps>
                                        </div>
                                        )}
                                        />
                                        ) }



                                        { params.name === "consultant" && (
                                        <Wizard
                                        render={({ step, steps }) => (
                                        <div>
                                            <h3 className="service-title">{t('faseya.it.consultant.title')}</h3>
                                            <Line
                                                percent={(steps.indexOf(step) + 1) / steps.length * 100}
                                                className="pad-b"
                                                strokeColor="#6b8e23"
                                                strokeWidth="2"
                                            />
                                        
                                            <Steps>
                                                <Step
                                                    id={`${params.name}1`}
                                                    render={({ next }) => (
                                                        <div className="d-flex flex-column mt-3">
                                                            <div className="ml-3 ml-md-0">
                                                                <span className="title-service"> 
                                                                    {/* Quel est votre besoin de &nbsp; 
                                                                    {props.history.location.full_name} ? */}
                                                                    
                                                                    {t('faseya.it.consultant.need')}
                                                                    
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio1">
                                                                    <input 
                                                                        onChange={() => onClick('saleforce')} 
                                                                        checked={state === 'saleforce' ? true : false}
                                                                        type="radio" 
                                                                        id="radio1"
                                                                    />  
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.consultant.item_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio2">
                                                                        <input 
                                                                            onChange={() => onClick('MOE/MOA')} 
                                                                            checked={state === 'MOE/MOA' ? true : false}
                                                                            type="radio" 
                                                                            id="radio2"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.consultant.item_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio3">
                                                                        <input 
                                                                            onChange={() => onClick('data-scientist')} 
                                                                            checked={state === 'data-scientist' ? true : false}
                                                                            type="radio" 
                                                                            id="radio3"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp;  {t('faseya.it.consultant.item_3')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio4">
                                                                        <input 
                                                                            onChange={() => onClick('ingenieur exploitation')} 
                                                                            checked={state === 'ingenieur exploitation' ? true : false}
                                                                            type="radio" 
                                                                            id="radio4"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.consultant.item_4')}
                                                                    </label>
                                                                </MDBCol>
                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio400">
                                                                        <input 
                                                                            onChange={() => onClick('bigdata')} 
                                                                            checked={state === 'bigdata' ? true : false}
                                                                            type="radio" 
                                                                            id="radio400"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.consultant.item_5')}
                                                                    </label>
                                                                </MDBCol>
                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio4020">
                                                                        <input 
                                                                            onChange={() => onClick('asap')} 
                                                                            checked={state === 'asap' ? true : false}
                                                                            type="radio" 
                                                                            id="radio4020"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp;  {t('faseya.it.consultant.item_6')}
                                                                    </label>
                                                                </MDBCol>
                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio401">
                                                                    <input 
                                                                        onChange={() => onClick('devops')} 
                                                                        checked={state === 'devops' ? true : false}
                                                                        type="radio" 
                                                                        id="radio401"
                                                                    />
                                                                       &nbsp;&nbsp;&nbsp;  {t('faseya.it.consultant.item_7')}
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                            <MDBBtn 
                                                                className="btn-color" 
                                                                onClick={state !== '' ? next : () => {}}
                                                                style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                            >
                                                                {t('faseya.next')}
                                                            </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                <Step
                                                    id={`${params.name}2`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.customized_budget')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio11">
                                                                        <input 
                                                                            onChange={() => onClickBudget('junior')} 
                                                                            checked={budget === 'junior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio11"
                                                                        />   
                                                                            &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_1')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio21">
                                                                        <input 
                                                                            onChange={() => onClickBudget('confirme')} 
                                                                            checked={budget === 'confirme' ? true : false}
                                                                            type="radio" 
                                                                            id="radio21"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_2')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio31">
                                                                        <input 
                                                                            onChange={() => onClickBudget('senior')} 
                                                                            checked={budget === 'senior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio31"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_3')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio41">
                                                                        <input 
                                                                            onChange={() => onClickBudget('je ne sais pas')} 
                                                                            checked={budget === 'je ne sais pas' ? true : false}
                                                                            type="radio" 
                                                                            id="radio41"
                                                                        />
                                                                       &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_4')} 
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={budget !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                
                                                <Step
                                                    id={`${params.name}3`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.duration_mission')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio12">
                                                                    <input 
                                                                        onChange={() => onChangeMission('-1mois')} 
                                                                        checked={mission === '-1mois' ? true : false}
                                                                        type="radio" 
                                                                        id="radio12"
                                                                    
                                                                    />
                                                                    &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio22">
                                                                        <input 
                                                                            onChange={() => onChangeMission('1mois')} 
                                                                            checked={mission === '1mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio22"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio32">
                                                                        <input 
                                                                            onChange={() => onChangeMission('entre 2 et 4 mois')} 
                                                                            checked={mission === 'entre 2 et 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio32"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_3')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio42">
                                                                        <input 
                                                                            onChange={() => onChangeMission('plus de 4 mois')} 
                                                                            checked={mission === 'plus de 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio42"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_4')} 
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={mission !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />


                                                <Step
                                                    id={`${params.name}4`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.project_status.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio13">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('idee')} 
                                                                            checked={statusProject === 'idee' ? true : false}
                                                                            type="radio" 
                                                                            id="radio13"
                                                                        
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio23">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('refonte')} 
                                                                            checked={statusProject === 'refonte' ? true : false}
                                                                            type="radio" 
                                                                            id="radio23"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio33">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('je ne sais pas')} 
                                                                            checked={statusProject === 'je ne sais pas' ? true : false}
                                                                            type="radio" 
                                                                            id="radio33"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_3')}
                                                                    </label>
                                                                </MDBCol>
                                   
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                   
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={statusProject !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}5`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.specification_brief.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio14">
                                                                    <input 
                                                                        onChange={() => onChangeBook('oui')} 
                                                                        checked={book === 'oui' ? true : false}
                                                                        type="radio" 
                                                                        id="radio14"
                                                                    />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.yes')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio23">
                                                                        <input 
                                                                            onChange={() => onChangeBook('non')} 
                                                                            checked={book === 'non' ? true : false}
                                                                            type="radio" 
                                                                            id="radio23"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.no')}
                                                                    </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={book !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                              
                                                <Step
                                                    id={`${params.name}7`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.talents_needed.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio16">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('un')} 
                                                                            checked={advisor === 'un' ? true : false}
                                                                            type="radio" 
                                                                            id="radio16"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.one_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio26">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('plusieurs')} 
                                                                            checked={advisor === 'plusieurs' ? true : false}
                                                                            type="radio" 
                                                                            id="radio26"
                                                                        /> 
                                                                       &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.several_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio36">
                                                                    <input 
                                                                        onChange={() => onChangeAdvisor('equipe')} 
                                                                        checked={advisor === 'equipe' ? true : false}
                                                                        type="radio" 
                                                                        id="radio36"
                                                                    />
                                                                       &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.multi_skilled_teams')}
                                                                    </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}     
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={advisor != "" ? next : () => {}}
                                                                  
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}8`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                     {t('faseya.it.start_project.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio18">
                                                                    <input 
                                                                        onChange={() => onChangeStart('immediatement')} 
                                                                        checked={start === 'immediatement' ? true : false}
                                                                        type="radio" 
                                                                        id="radio18"
                                                                    /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio28">
                                                                    <input 
                                                                        onChange={() => onChangeStart('une ou deux')} 
                                                                        checked={start === 'une ou deux' ? true : false}
                                                                        type="radio" 
                                                                        id="radio28"
                                                                    /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio38">
                                                                        <input 
                                                                            onChange={() => onChangeStart('un mois')} 
                                                                            checked={start === 'un mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio38"
                                                                        />  
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_3')}
                                                                    </label>
                                                                </MDBCol>

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                  
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={start != "" ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}9`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column w-100">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.you_re.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2 mb-5">

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio180">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('Start-up')} 
                                                                            checked={profile === 'Start-up' ? true : false}
                                                                            type="radio" 
                                                                            id="radio180"  
                                                                        />  
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.start_up')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio181">
                                                                    <input 
                                                                        onChange={() => onChangeProfile('pme-pmi')} 
                                                                        checked={profile === 'pme-pmi' ? true : false}
                                                                        type="radio" 
                                                                        id="radio181"  
                                                                    />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.pme')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio182">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('entreprise')} 
                                                                            checked={profile === 'entreprise' ? true : false}
                                                                            type="radio" 
                                                                            id="radio182"  
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.large')}
                                                                    </label>      
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end mt-5">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={next}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />


                                                <Step
                                                    id={`${params.name}10`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <form className='needs-validation w-100' onSubmit={submitHandler} noValidate>
                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={society}
                                                                        onChange={onChangeSociety} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_9')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                    
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <input  
                                                                        value={name}
                                                                        onChange={onChangeName} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_1')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                   
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={surname}
                                                                        onChange={onChangeSurname} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_2')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={phone}
                                                                        onChange={onChangePhone} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_4')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={email}
                                                                        onChange={onChangeEmail} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_3')}
                                                                        className="form-control auto-complete"
                                                                        required                                                                
                                                                    />
                                                                </MDBCol>

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <div className="d-flex flex-row align-items-center mr-3">
                                                                    <span className="calculate-text mx-3">
                                                                        9 + 5
                                                                    </span>
                                                                    <input
                                                                      type="text"
                                                                      value={calculateValue}
                                                                      onChange={(e) => {
                                                                         setCalculateValue(e.target.value)
                                                                         onChangeCalculate(e.target.value)
                                                                        }}
                                                                      className="calculate-input"
                                                                    />
                                                                </div>
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                  
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    type="submit"
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.send')}
                                                                </MDBBtn>
                                                            </div>
                                                            </form>
                                                        </div>
                                                    )}
                                                />
                                           </Steps>
                                        </div>
                                        )}
                                        />
                                        ) }




                                       { params.name === "marketing" && (
                                        <Wizard
                                        render={({ step, steps }) => (
                                        <div>
                                            <h3 className="service-title">E-Marketing</h3>
                                            <Line
                                                percent={(steps.indexOf(step) + 1) / steps.length * 100}
                                                className="pad-b"
                                                strokeColor="#6b8e23"
                                                strokeWidth="2"
                                            />
                                        
                                            <Steps>
                                                <Step
                                                    id={`${params.name}1`}
                                                    render={({ next }) => (
                                                        <div className="d-flex flex-column mt-3">
                                                            <div className="ml-3 ml-md-0">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.marketing.need')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio1">
                                                                        <input 
                                                                            onChange={() => onClick('community manager')} 
                                                                            checked={state === 'community manager' ? true : false}
                                                                            type="radio" 
                                                                            id="radio1"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.marketing.item_1')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio2">
                                                                        <input 
                                                                            onChange={() => onClick('seo')} 
                                                                            checked={state === 'seo' ? true : false}
                                                                            type="radio" 
                                                                            id="radio2"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.marketing.item_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio3">
                                                                        <input 
                                                                            onChange={() => onClick('e-mailing')} 
                                                                            checked={state === 'e-mailing' ? true : false}
                                                                            type="radio" 
                                                                            id="radio3"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.marketing.item_3')}
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                            <MDBBtn 
                                                                className="btn-color" 
                                                                onClick={state !== '' ? next : () => {}}
                                                                style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                            >
                                                                {t('faseya.next')}
                                                            </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                <Step
                                                    id={`${params.name}2`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.customized_budget')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio11">
                                                                        <input 
                                                                            onChange={() => onClickBudget('junior')} 
                                                                            checked={budget === 'junior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio11"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_1')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio21">
                                                                        <input 
                                                                            onChange={() => onClickBudget('confirme')} 
                                                                            checked={budget === 'confirme' ? true : false}
                                                                            type="radio" 
                                                                            id="radio21"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_2')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio31">
                                                                        <input 
                                                                            onChange={() => onClickBudget('senior')} 
                                                                            checked={budget === 'senior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio31"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_3')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio41">
                                                                        <input 
                                                                            onChange={() => onClickBudget('je ne sais pas')} 
                                                                            checked={budget === 'je ne sais pas' ? true : false}
                                                                            type="radio" 
                                                                            id="radio41"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_4')} 
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={budget !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                
                                                <Step
                                                    id={`${params.name}3`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.duration_mission')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio12">
                                                                        <input 
                                                                            onChange={() => onChangeMission('-1mois')} 
                                                                            checked={mission === '-1mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio12"
                                                                        />  
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio22">
                                                                        <input 
                                                                            onChange={() => onChangeMission('1mois')} 
                                                                            checked={mission === '1mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio22"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio32">
                                                                        <input 
                                                                            onChange={() => onChangeMission('entre 2 et 4 mois')} 
                                                                            checked={mission === 'entre 2 et 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio32"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_3')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio42">
                                                                        <input 
                                                                            onChange={() => onChangeMission('plus de 4 mois')} 
                                                                            checked={mission === 'plus de 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio42"
                                                                        />  
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_4')} 
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={mission !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />


                                                <Step
                                                    id={`${params.name}4`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.project_status.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio13">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('idee')} 
                                                                            checked={statusProject === 'idee' ? true : false}
                                                                            type="radio" 
                                                                            id="radio13" 
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio23">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('refonte')} 
                                                                            checked={statusProject === 'refonte' ? true : false}
                                                                            type="radio" 
                                                                            id="radio23"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio33">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('je ne sais pas')} 
                                                                            checked={statusProject === 'je ne sais pas' ? true : false}
                                                                            type="radio" 
                                                                            id="radio33"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_3')}
                                                                    </label>
                                                                </MDBCol>
                                   
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                   
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={statusProject !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}5`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.specification_brief.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio14">
                                                                    <input 
                                                                        onChange={() => onChangeBook('oui')} 
                                                                        checked={book === 'oui' ? true : false}
                                                                        type="radio" 
                                                                        id="radio14"
                                                                    />   
                                                                       &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.yes')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio23">
                                                                        <input 
                                                                            onChange={() => onChangeBook('non')} 
                                                                            checked={book === 'non' ? true : false}
                                                                            type="radio" 
                                                                            id="radio23"
                                                                        />
                                                                       &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.no')}
                                                                    </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={book !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                              
                                                <Step
                                                    id={`${params.name}7`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.talents_needed.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio16">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('un')} 
                                                                            checked={advisor === 'un' ? true : false}
                                                                            type="radio" 
                                                                            id="radio16"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.one_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio26">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('plusieurs')} 
                                                                            checked={advisor === 'plusieurs' ? true : false}
                                                                            type="radio" 
                                                                            id="radio26"
                                                                        /> 
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.several_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio36">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('equipe')} 
                                                                            checked={advisor === 'equipe' ? true : false}
                                                                            type="radio" 
                                                                            id="radio36"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.multi_skilled_teams')}
                                                                    </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}     
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={advisor != "" ? next : () => {}}
                                                                  
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}8`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                     {t('faseya.it.start_project.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio18">
                                                                        <input 
                                                                            onChange={() => onChangeStart('immediatement')} 
                                                                            checked={start === 'immediatement' ? true : false}
                                                                            type="radio" 
                                                                            id="radio18"
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio28">
                                                                        <input 
                                                                            onChange={() => onChangeStart('une ou deux')} 
                                                                            checked={start === 'une ou deux' ? true : false}
                                                                            type="radio" 
                                                                            id="radio28"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio38">
                                                                        <input 
                                                                            onChange={() => onChangeStart('un mois')} 
                                                                            checked={start === 'un mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio38"
                                                                        />  
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_3')}
                                                                    </label>
                                                                </MDBCol>

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                  
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={start != "" ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}9`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column w-100">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.you_re.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2 mb-5">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio180">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('Start-up')} 
                                                                            checked={profile === 'Start-up' ? true : false}
                                                                            type="radio" 
                                                                            id="radio180"  
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.start_up')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio181">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('pme-pmi')} 
                                                                            checked={profile === 'pme-pmi' ? true : false}
                                                                            type="radio" 
                                                                            id="radio181"  
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.pme')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio182">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('entreprise')} 
                                                                            checked={profile === 'entreprise' ? true : false}
                                                                            type="radio" 
                                                                            id="radio182"  
                                                                        /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.large')}
                                                                    </label>      
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end mt-5">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={next}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />


                                                <Step
                                                    id={`${params.name}10`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <form className='needs-validation w-100' onSubmit={submitHandler} noValidate>
                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={society}
                                                                        onChange={onChangeSociety} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_9')}
                                                                        className="form-control auto-complete"
                                                                        required                                                                
                                                                    />
                                                                    
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <input  
                                                                        value={name}
                                                                        onChange={onChangeName} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_1')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                   
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={surname}
                                                                        onChange={onChangeSurname} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_2')}
                                                                        className="form-control auto-complete"
                                                                        required                                                                
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={phone}
                                                                        onChange={onChangePhone} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_4')}
                                                                        className="form-control auto-complete"   
                                                                        required                                                             
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={email}
                                                                        onChange={onChangeEmail} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_3')}
                                                                        className="form-control auto-complete"
                                                                        required                                                                 
                                                                    />
                                                                </MDBCol>

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <div className="d-flex flex-row align-items-center mr-3">
                                                                    <span className="calculate-text mx-3">
                                                                        9 + 5
                                                                    </span>
                                                                    <input
                                                                      type="text"
                                                                      value={calculateValue}
                                                                      onChange={(e) => {
                                                                         setCalculateValue(e.target.value)
                                                                         onChangeCalculate(e.target.value)
                                                                        }}
                                                                      className="calculate-input"
                                                                      required
                                                                    />
                                                                </div>
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                  
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    type="submit"
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.send')}
                                                                </MDBBtn>
                                                            </div>
                                                            </form>
                                                        </div>
                                                    )}
                                                />
                                           </Steps>
                                        </div>
                                        )}
                                        />
                                        ) }


                                        { params.name === "projets" && (
                                        <Wizard
                                        render={({ step, steps }) => (
                                        <div>
                                            <h3 className="service-title">{t('faseya.it.project.title')}</h3>
                                            <Line
                                                percent={(steps.indexOf(step) + 1) / steps.length * 100}
                                                className="pad-b"
                                                strokeColor="#6b8e23"
                                                strokeWidth="2"
                                            />
                                        
                                            <Steps>
                                                <Step
                                                    id={`${params.name}1`}
                                                    render={({ next }) => (
                                                        <div className="d-flex flex-column mt-3">
                                                            <div className="ml-3 ml-md-0">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.project.need')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio1">
                                                                        <input 
                                                                            onChange={() => onClick('projet amoa')} 
                                                                            checked={state === 'projet amoa' ? true : false}
                                                                            type="radio" 
                                                                            id="radio1"
                                                                        />   
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project.item_1')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio2">
                                                                        <input 
                                                                            onChange={() => onClick('projet moe')} 
                                                                            checked={state === 'projet moe' ? true : false}
                                                                            type="radio" 
                                                                            id="radio2"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project.item_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio322">
                                                                        <input 
                                                                            onChange={() => onClick('projet moa')} 
                                                                            checked={state === 'projet moa' ? true : false}
                                                                            type="radio" 
                                                                            id="radio322"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project.item_3')}
                                                                    </label>
                                                                </MDBCol>
                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio301">
                                                                        <input 
                                                                            onChange={() => onClick('projet product owner')} 
                                                                            checked={state === 'projet product owner' ? true : false}
                                                                            type="radio" 
                                                                            id="radio301"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project.item_4')}
                                                                    </label>
                                                                </MDBCol>
                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio378">
                                                                        <input 
                                                                            onChange={() => onClick('projet pmo')} 
                                                                            checked={state === 'projet pmo' ? true : false}
                                                                            type="radio" 
                                                                            id="radio378"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project.item_5')}
                                                                    </label>
                                                                </MDBCol>
                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio300">
                                                                        <input 
                                                                            onChange={() => onClick('projet scrum master')} 
                                                                            checked={state === 'projet scrum master' ? true : false}
                                                                            type="radio" 
                                                                            id="radio300"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.project.item_6')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio321">
                                                                    <input 
                                                                        onChange={() => onClick('je ne sais pas')} 
                                                                        checked={state === 'je ne sais pas' ? true : false}
                                                                        type="radio" 
                                                                        id="radio321"
                                                                    /> 
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project.item_7')}
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                            <MDBBtn 
                                                                className="btn-color" 
                                                                onClick={state !== '' ? next : () => {}}
                                                                style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                            >
                                                                {t('faseya.next')}
                                                            </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                <Step
                                                    id={`${params.name}2`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.customized_budget')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio11">
                                                                        <input 
                                                                            onChange={() => onClickBudget('junior')} 
                                                                            checked={budget === 'junior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio11"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_1')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio21">
                                                                        <input 
                                                                            onChange={() => onClickBudget('confirme')} 
                                                                            checked={budget === 'confirme' ? true : false}
                                                                            type="radio" 
                                                                            id="radio21"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_2')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio31">
                                                                        <input 
                                                                            onChange={() => onClickBudget('senior')} 
                                                                            checked={budget === 'senior' ? true : false}
                                                                            type="radio" 
                                                                            id="radio31"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.budget_3')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio41">
                                                                        <input 
                                                                            onChange={() => onClickBudget('je ne sais pas')} 
                                                                            checked={budget === 'je ne sais pas' ? true : false}
                                                                            type="radio" 
                                                                            id="radio41"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp;  {t('faseya.it.budget_4')} 
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={budget !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                
                                                <Step
                                                    id={`${params.name}3`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.duration_mission')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio12">
                                                                    <input 
                                                                        onChange={() => onChangeMission('-1mois')} 
                                                                        checked={mission === '-1mois' ? true : false}
                                                                        type="radio" 
                                                                        id="radio12"
                                                                    />
                                                                       &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio22">
                                                                        <input 
                                                                            onChange={() => onChangeMission('1mois')} 
                                                                            checked={mission === '1mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio22"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio32">
                                                                        <input 
                                                                            onChange={() => onChangeMission('entre 2 et 4 mois')} 
                                                                            checked={mission === 'entre 2 et 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio32"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_3')} 
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio42">
                                                                        <input 
                                                                            onChange={() => onChangeMission('plus de 4 mois')} 
                                                                            checked={mission === 'plus de 4 mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio42"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.duration_4')} 
                                                                    </label>
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={mission !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />


                                                <Step
                                                    id={`${params.name}4`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.project_status.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio13">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('idee')} 
                                                                            checked={statusProject === 'idee' ? true : false}
                                                                            type="radio" 
                                                                            id="radio13"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio23">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('refonte')} 
                                                                            checked={statusProject === 'refonte' ? true : false}
                                                                            type="radio" 
                                                                            id="radio23"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio33">
                                                                        <input 
                                                                            onChange={() => onChangeStatusProject('je ne sais pas')} 
                                                                            checked={statusProject === 'je ne sais pas' ? true : false}
                                                                            type="radio" 
                                                                            id="radio33"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.project_status.status_3')}
                                                                    </label>
                                                                </MDBCol>
                                   
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                   
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={statusProject !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}5`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.specification_brief.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio14">
                                                                        <input 
                                                                            onChange={() => onChangeBook('oui')} 
                                                                            checked={book === 'oui' ? true : false}
                                                                            type="radio" 
                                                                            id="radio14"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.yes')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio23">
                                                                        <input 
                                                                            onChange={() => onChangeBook('non')} 
                                                                            checked={book === 'non' ? true : false}
                                                                            type="radio" 
                                                                            id="radio23"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.specification_brief.no')}
                                                                    </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={book !== '' ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                              
                                                <Step
                                                    id={`${params.name}7`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.talents_needed.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio16">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('un')} 
                                                                            checked={advisor === 'un' ? true : false}
                                                                            type="radio" 
                                                                            id="radio16"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.one_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio26">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('plusieurs')} 
                                                                            checked={advisor === 'plusieurs' ? true : false}
                                                                            type="radio" 
                                                                            id="radio26"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.several_dev')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio36">
                                                                        <input 
                                                                            onChange={() => onChangeAdvisor('equipe')} 
                                                                            checked={advisor === 'equipe' ? true : false}
                                                                            type="radio" 
                                                                            id="radio36"
                                                                        />
                                                                         &nbsp;&nbsp;&nbsp; {t('faseya.it.talents_needed.multi_skilled_teams')}
                                                                    </label>
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}     
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={advisor != "" ? next : () => {}}
                                                                  
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}8`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                     {t('faseya.it.start_project.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio18">
                                                                        <input 
                                                                            onChange={() => onChangeStart('immediatement')} 
                                                                            checked={start === 'immediatement' ? true : false}
                                                                            type="radio" 
                                                                            id="radio18"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_1')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio28">
                                                                        <input 
                                                                            onChange={() => onChangeStart('une ou deux')} 
                                                                            checked={start === 'une ou deux' ? true : false}
                                                                            type="radio" 
                                                                            id="radio28"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_2')}
                                                                    </label>
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio38">
                                                                        <input 
                                                                            onChange={() => onChangeStart('un mois')} 
                                                                            checked={start === 'un mois' ? true : false}
                                                                            type="radio" 
                                                                            id="radio38"
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.start_project.start_3')}
                                                                    </label>
                                                                </MDBCol>

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                  
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={start != "" ? next : () => {}}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />

                                                <Step
                                                    id={`${params.name}9`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column w-100">
                                                            <div className="p-1">
                                                                <span className="title-service"> 
                                                                    {t('faseya.it.you_re.title')}
                                                                </span>
                                                            </div>

                                                            <MDBRow className="mt-2 mb-5">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio180">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('Start-up')} 
                                                                            checked={profile === 'Start-up' ? true : false}
                                                                            type="radio" 
                                                                            id="radio180"  
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.start_up')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <label className="form-check-label " htmlFor="radio181">
                                                                        <input 
                                                                            onChange={() => onChangeProfile('pme-pmi')} 
                                                                            checked={profile === 'pme-pmi' ? true : false}
                                                                            type="radio" 
                                                                            id="radio181"  
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.pme')}
                                                                    </label>      
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <label className="form-check-label " htmlFor="radio182">
                                                                    <input 
                                                                        onChange={() => onChangeProfile('entreprise')} 
                                                                        checked={profile === 'entreprise' ? true : false}
                                                                        type="radio" 
                                                                        id="radio182"  
                                                                    /> 
                                                                    &nbsp;&nbsp;&nbsp; {t('faseya.it.you_re.large')}
                                                                    </label>      
                                                                </MDBCol>
                
                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end mt-5">
                                                                <MDBBtn 
                                                                    className="btn-previous" 
                                                                    onClick={previous}
                                                                    
                                                                >
                                                                    {t('faseya.prev')}
                                                                </MDBBtn>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    onClick={next}
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.next')}
                                                                </MDBBtn>
                                                            </div>
                                                        </div>
                                                    )}
                                                />


                                                <Step
                                                    id={`${params.name}10`}
                                                    render={({ next, previous }) => (
                                                        <div className="d-flex-flex-column">
                                                            <form className='needs-validation w-100' onSubmit={submitHandler} noValidate>
                                                            <MDBRow className="mt-2">

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={society}
                                                                        required
                                                                        onChange={onChangeSociety} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_9')}
                                                                        className="form-control auto-complete"                                                                
                                                                    />
                                                                    
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                   <input  
                                                                        value={name}
                                                                        required
                                                                        onChange={onChangeName} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_1')}
                                                                        className="form-control auto-complete"                                                                
                                                                    />
                                                                   
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={surname}
                                                                        onChange={onChangeSurname} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_2')}
                                                                        className="form-control auto-complete"
                                                                        required                                                                
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={phone}
                                                                        required
                                                                        onChange={onChangePhone} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_4')}
                                                                        className="form-control auto-complete"                                                                
                                                                    />
                                                                </MDBCol>

                                                                <MDBCol size="12" className="mb-3">
                                                                    <input  
                                                                        value={email}
                                                                        onChange={onChangeEmail} 
                                                                        placeholder={t('faseya.ask_demo.forms.item_3')}
                                                                        className="form-control auto-complete" 
                                                                        required                                                               
                                                                    />
                                                                </MDBCol>

                                                            </MDBRow>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <div className="d-flex flex-row align-items-center mr-3">
                                                                    <span className="calculate-text mx-3">
                                                                        9 + 5
                                                                    </span>
                                                                    <input
                                                                      type="text"    
                                                                      value={calculateValue}
                                                                      onChange={(e) => {
                                                                         setCalculateValue(e.target.value)
                                                                         onChangeCalculate(e.target.value)
                                                                        }}
                                                                      required
                                                                      className="form-control calculate-input"
                                                                    />
                                                                </div>
                                                                <MDBBtn 
                                                                    className="btn-color" 
                                                                    type="submit"
                                                                    style={{borderRadius: '20px',textTransform: 'capitalize', paddingTop: '3px', paddingBottom: '3px'}}
                                                                >
                                                                    {t('faseya.send')}
                                                                </MDBBtn>
                                                            </div>
                                                            </form>
                                                        </div>
                                                    )}
                                                />
                                           </Steps>
                                        </div>
                                        )}
                                        />
                                        ) }
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </>:

                        <div className='d-flex flex-column align-items-center' style={{marginTop: "10%"}}>
                            <div className="p-1">
                                <img src={logo_faseya} />
                            </div>
                            <div className="p-1 text-tanks">
                                {t('faseya.message')}
                            </div>
                        </div>
                        }

                    </MDBCol>

                    <MDBCol  md="3" className='hide-mobile' style={{backgroundColor: 'white'}}>
                        <MDBRow className='d-flex justify-content-center align-items-center'>
                            <SponsorsComponent />
                        </MDBRow>
                    </MDBCol>
                  
                </MDBRow>
            </>
    )
}

export default withTranslation()(CustomerStepperPage);