import { api } from "./api.config";

/**
 * uploadService 
 * 
 * Only pdf, doc, docx, image/* files can be uploaded.
 * You can upload single file at once.
 */
const uploadService = {
  uploadImage,
  uploadDocument,
};

const resource = "/file";

/**
 * uploadImage method
 *
 * @param {FormData} imageFormData
 * @returns {Promise<{ link: string; }>}
 * 
 * This is how to upload file from frontend UI
 * First of all, make an import of upload service
 *
 * ```js
 * 
 * import uploadService from "your_relative_path/upload.service";
 * 
 * // Inside your react js code, you can do like this 🔽🔽🔽
 * 
 * var imageFormData = new FormData();
 * 
 * // You can use js querySelector to get DOM node reference of your file input or use React ref
 * // Assuming you use js querySelector, you can have the script below
 * 
 * const imageFile = document.querySelector('#file');
 * 
 * // Make sure that you append your file from "file" key in your formData, otherwise your request will be rejected.
 * 
 * imageFormData.append("file", imageFile.files[0]);
 * 
 * // After, you can use upload service to upload your file
 * uploadService.uploadImage(imageFormData).then(({ link }) => {
 *   // Your code here !!
 * });
 * ```
 * 
 */
function uploadImage(imageFormData) {
  return api.uploadFiles(resource + "/image", imageFormData);
}

/**
 * uploadDocument method
 *
 * @param {FormData} documentFormData
 * @returns {Promise<{ link: string; }>}
 * * 
 * This is how to upload file from frontend UI
 * First of all, make an import of upload service
 *
 * ```js
 * 
 * import uploadService from "your_relative_path/upload.service";
 * 
 * // Inside your react js code, you can do like this 🔽🔽🔽
 * 
 * var documentFormData = new FormData();
 * 
 * // You can use js querySelector to get DOM node reference of your file input or use React ref
 * // Assuming you use js querySelector, you can have the script below
 * 
 * const documentFile = document.querySelector('#file');
 * 
 * // Make sure that you append your file from "file" key in your formData, otherwise your request will be rejected.
 * 
 * documentFormData.append("file", documentFile.files[0]);
 * 
 * // After, you can use upload service to upload your file
 * uploadService.uploadDocument(documentFormData).then(({ link }) => {
 *   // Your code here !!
 * });
 * ```
 * 
 */
function uploadDocument(documentFormData) {
  return api.uploadFiles(resource + "/document", documentFormData);
}

export default uploadService;