import { api } from "./api.config";

const serviceService = {
  addService,
  getServices,
  getService,
  getAllPublishedService,
  updateService,
  deleteService,
  deleteServices,
};

const resource = "/services";

/**
 * addService method
 *
 * @param {{
 *   title: string;
 *   type: string;
 *   description: string;
 *   status: boolean;
 * }} payload
 *
 * @returns
 */
function addService(payload) {
  return api.postData(resource, payload);
}

/**
 * getServices method
 *
 * @returns
 */
function getServices() {
  return api.getData(resource);
}

/**
 * getService method
 *
 * @param {number} id
 *
 * @returns
 */
function getService(id) {
  return api.getData(resource + "/" + id);
}

/**
 * getAllPublishedService method
 *
 * @returns
 */
function getAllPublishedService() {
    return api.getData(resource + "/status");
}

/**
 * updateService method
 *
 * @param {{
 *   title?: string;
 *   type?: string;
 *   description?: string;
 *   status?: boolean;
 * }} payload
 * @param {number} id
 *
 * @returns
 */
function updateService(id, payload) {
  return api.putData(resource + "/" + id, payload);
}

/**
 * deleteService method
 *
 * @param {number} id
 *
 * @returns
 */
function deleteService(id) {
  return api.deleteData(resource + "/" + id);
}

/**
 * deleteServices method
 *
 * @returns
 */
function deleteServices() {
  return api.deleteData(resource);
}

export default serviceService;
