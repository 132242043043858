import React, {Fragment}  from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBCard,MDBCardBody, MDBCardTitle, MDBCardImage, MDBCardText,
  MDBFormInline,
  MDBBtn,
  MDBView,
  MDBBadge,
  MDBInput,
  MDBIcon,
  MDBContainer
} from 'mdbreact';

import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBTabPane, MDBTabContent, MDBNav} from '../../mdbreact/dist/mdbreact'


import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ChatBot from 'react-simple-chatbot';
import PropTypes from 'prop-types';
import './DashboardConsultantsPage.css';

import banner_faseya from '../../assets/images/banner_faseya.jpg';
import world_bg from '../../assets/images/world_bg.png';
import chatbot_icon from '../../assets/images/chatbot_icon.png';
import avataaar_0 from '../../assets/images/avataaars/avataaars.png';
import avataaar_1 from '../../assets/images/avataaars/avataaars(1).png';
import avataaar_2 from '../../assets/images/avataaars/avataaars(2).png';
import avataaar_3 from '../../assets/images/avataaars/avataaars(3).png';
import avataaar_4 from '../../assets/images/avataaars/avataaars(4).png'; 
import olive_loader from '../../assets/images/olive-loader.gif'; 

import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import auth from '../../services/auth';
import { ThemeProvider } from 'styled-components';
import consultantsService from '../../services/consultants.service';
import { withTranslation } from 'react-i18next';
import loader from 'sass-loader';
import activities from '../../services/mocks/activities';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainReduxActions from '../../store/mainRedux/mainReduxActions';



const theme = {
  background: '#f5f8fb',
  fontFamily: 'Montserrat',
  headerBgColor: '#11b981',
  headerFontColor: '#ffffff !important',
  headerFontSize: '15px',
  botBubbleColor: '#11b981',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class DashboardConsultantsPage extends React.Component {
  state = {
    collapsed: false, 
    activeItem: "1",
    consultantsExperts : this.props.mainReduxState.consultantsExperts,
    consultantsAvailable : this.props.mainReduxState.consultantsAvailable,
    consultantsNew : this.props.mainReduxState.consultantsNew,
    loadingconsultantsExperts : false,
    loadingconsultantsAvailable : false,
    loadingconsultantsNew : false,
    profiles : [
      { 
          name: 'Developpeur'
      },
      { 
          name: 'Designer'
      }, 
      { 
          name: 'E-Marketing',
      },
      { 
          name: 'Reseau'
      },
      { 
          name: 'Consultant'
      },
      
      { 
          name: 'Gestion'
      },
     
      { 
          name: 'Business'
      }
  ],
      consultants : [
      {id:0,  name : "Habibe", avatar : avataaar_0,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:1,  name : "Petito", avatar : avataaar_1,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:2,  name : "Lamine", avatar : avataaar_2,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:3,  name : "Ange", avatar : avataaar_3,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:4,  name : "Marie", avatar : avataaar_4,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:5,  name : "Mamadou", avatar : avataaar_2, price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:6,  name : "Saliou", avatar : avataaar_0, price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:7,  name : "Henry", avatar : avataaar_1,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:8,  name : "Eric", avatar : avataaar_2,   price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:10, name : "Jules", avatar : avataaar_3,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:11, name : "Serge", avatar : avataaar_2,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:12, name : "Hamel", avatar : avataaar_4,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]},
      {id:13, name : "Alfred", avatar : avataaar_1,  price : 80, title : "Full stack Developer", techs : ["ReactJs", "NodeJs", "Angular"]}
    ],
  
  };

  toggle = tab => () => {
    if (this.state.activeItem !== tab) {
      
      this.setState({
        activeItem: tab,
        consultantsExperts : [],
        consultantsAvailable : [],
        consultantsNew : [],
      });

      var profileIndex = parseInt(tab) - 1;
      var profile = this.state.profiles[profileIndex].name;

      // console.log(profile);

      this.fetchTypeConsultants(profile);

    }
  }

  handleTogglerClick = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });
  };

  componentDidMount() {

    window.scrollTo(0, 0);

    document.querySelector('nav').style.height = '65px';

    var profile = this.state.profiles[0].name;

    if(this.state.consultantsExperts.length == 0 && this.state.consultantsAvailable.length == 0 && this.state.consultantsNew.length == 0)
      this.fetchTypeConsultants(profile);

  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  discoverConsultant(item)
  {

    localStorage.setItem("consultant", JSON.stringify(item)); 
    localStorage.setItem("keyword", this.state.tech); 
    this.props.history.push({pathname:'/cv-consultant',state :{consultant : item}});

  }

  fetchData = (profil, arrayName, page) =>{

    var loader = "loading"+arrayName;
    
    this.setState({[loader] : true})

    consultantsService.searchConsultantsWithFilters({ keyword : "all", page: page, size: 6 , filters:{profil : profil }}).then(data=>{
        
        // console.log(data);

        var newConsultants = data;
        
        newConsultants.forEach(item => {
          this.setSkillsItems(item)
        });
       
        this.setState({[arrayName] : newConsultants, [loader] : false});

        switch (arrayName) {
          case "consultantsExperts":
            this.props.mainReduxActions.update_consultants_experts(newConsultants);
            break;

          case "consultantsAvailable":
          this.props.mainReduxActions.update_consultants_available(newConsultants);
          break;

          case "consultantsNew":
            this.props.mainReduxActions.update_consultants_new(newConsultants);
            break;
        
          default:
            break;
        }

    })
    .catch(err=>{
      
      this.setState({[loader] : false})
      // console.log(err)

    })
    
  }

  fetchTypeConsultants = (profil)=>{

    var arrays = ["consultantsExperts", "consultantsAvailable", "consultantsNew"];

    arrays.forEach((el,ind) => {
      this.fetchData(profil, el, ind+1);
    });
   
  }

  setSkillsItems(item)
    {
        var skillsString = item.competence_technique;
        var skillsArray = skillsString.split(',');
        const _skillsArray =  skillsArray.filter(e =>  e);

        var _skills = [];
      
        var max = 3;
        if(_skillsArray.length < 3) max = _skillsArray.length;
        for (let i = 0; i < max; i++) {
          const el = _skillsArray[i];
            _skills.push(el); 
           
        }

       item.skills = _skills;

    }


    displayConsultants(cs){
      const s = this.props.t;
      return(
        <Carousel responsive={responsive}>
        {
        cs.map((item,index) =>
      
            <MDBCol key={index} xs="12" sm="12" md="12" lg="12" xl="12" className="mb-3 align-items-center" onClick={()=>this.discoverConsultant(item)} >
              <MDBCard className="p-r-0" style={{ width: "100%", height:'100%', position:'relative', cursor:'pointer' }}>
              
              <MDBView style={{position:'relative'}}>
                {!item.photo &&
                  <img className="f-fit-image"  src={avataaar_0} style={{height:240, width:'100%', objectFit:'cover' }} />
                }
                {item.photo &&
                  <img className="f-fit-image"  src={item.photo} style={{height:240, width:'100%', objectFit:'cover' }} />
                }
                <MDBMask className="flex-center" style={{backgroundColor:'#00000093'}}>
                </MDBMask>
                
                  {/* Availability */}
                  <div style={{position:'absolute', display:'flex', alignItems:'center', top:10, left:10, padding:2, paddingLeft:6, paddingRight:6, borderRadius:15, backgroundColor:'#fff', }}>
                    <div style={{width:17, height:17, backgroundColor:'olivedrab', borderRadius:17, marginRight:5}}></div>
                    <span className="" style={{color:'#000', fontSize:14}}>{s('faseya.consultants_list.available')}</span>
                  </div>


                  {/* Name */}
                  <div style={{position:'absolute', display:'flex', alignItems:'center', top:'70%', left:10, padding:4, paddingLeft:10, paddingRight:10, borderRadius:15, }}>
                    <strong className="" style={{color:'#fff', fontSize:20, fontWeight:700, lineHeight:'normal'}}>{item.prenom}</strong>
                  </div>


              </MDBView>
            
              {/* <div style={{position:'absolute', top:0, left:0, width:'100%', height:'100%', backgroundColor:'#000000a3', }}>
                </div> */}
              <MDBCardBody style={{ paddingBottom:'auto', position:'relative'}}>
                <MDBCardTitle style={{color:'#000', fontWeight:700, fontSize:'100%'}}>{item.title}</MDBCardTitle>
                
        
                  {/* Biding */}

                  <div style={{position:'absolute', display:'flex', alignItems:'center', top:'-12%', left:10, padding:4, paddingLeft:10, paddingRight:10, borderRadius:15, backgroundColor:'#d1d5db', }}>
                    <strong className="" style={{color:'#000', fontSize:15, fontWeight:700}}>{item.tjm} € / {s('faseya.per_day')}</strong>
                  </div>

                {/* <MDBContainer>
  
    </MDBContainer> */}
                <MDBRow  style={{overflow:'hidden'}}>
                 { item.skills && 
                    item.skills.map((t, subindex) =>  
                    { 
                      if(subindex >2) return;
                      return( 
                      <MDBCol size="auto" className="mb-1" key={subindex} >
                        <MDBBadge color="light" className="f-cv-badge"><strong>{t}</strong></MDBBadge> 
                      </MDBCol>)
                      }
                    )
                  }  

                </MDBRow>

              </MDBCardBody>
            </MDBCard>
            
      </MDBCol>
     
      
      )
    }
    
    </Carousel>
    )

      
    }
  

  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: '0rem' };
 
    const consultants = this.state.consultants.map((item,index) =>
      
            <MDBCol key={index} xs="12" sm="12" md="12" lg="12" xl="12" className="mb-3 align-items-center" onClick={()=>{this.props.history.push({pathname:'/cv-consultant',state :{consultant : item}});}} >
                <MDBCard className="p-r-0" style={{ width: "100%", position:'relative', cursor:'pointer' }}>
                
                <MDBView style={{position:'relative'}} className="f-card-view">
                  <MDBCardImage className="img-fluid" src={item.avatar} style={{width:"100%"}} waves />
                  <MDBMask className="flex-center" style={{backgroundColor:'#00000093'}}>
                  </MDBMask>
                    {/* Availability */}
                    <div style={{position:'absolute', display:'flex', alignItems:'center', top:10, left:10, padding:2, paddingLeft:6, paddingRight:6, borderRadius:15, backgroundColor:'#fff', }}>
                      <div style={{width:17, height:17, backgroundColor:'olivedrab', borderRadius:17, marginRight:5}}></div>
                      <span className="" style={{color:'#000', fontSize:14}}>Disponible</span>
                    </div>

                    {/* Name */}
                    <div style={{position:'absolute', display:'flex', alignItems:'center', top:'70%', left:10, padding:4, paddingLeft:10, paddingRight:10, borderRadius:15, }}>
                      <strong className="" style={{color:'#fff', fontSize:20, fontWeight:700}}>{item.name}</strong>
                    </div>

                </MDBView>
              
                <MDBCardBody style={{ paddingBottom:'auto', position:'relative'}}>
                  <MDBCardTitle style={{color:'#000', fontWeight:700, fontSize:'100%'}}>{item.title}</MDBCardTitle>
          
                    {/* Biding */}

                    <div style={{position:'absolute', display:'flex', alignItems:'center', top:'-12%', left:10, padding:4, paddingLeft:10, paddingRight:10, borderRadius:15, backgroundColor:'#d1d5db', }}>
                      <strong className="" style={{color:'#000', fontSize:15, fontWeight:700}}>80 € / jour</strong>
                    </div>
  
                  <MDBRow  >
                    { 
                      item.techs.map((t, subindex) =>
                      <MDBCol size="auto" className="mb-1" key={subindex} >
                        <MDBBadge color="light" className="f-cv-badge" ><strong>{t}</strong></MDBBadge>
                      </MDBCol>
                        )
                    }

                  </MDBRow>

                </MDBCardBody>
              </MDBCard>
              
        </MDBCol>
     
      
    );


    const {t} = this.props;


    return (

      <>
      
      <HeaderComponent  history={this.props.history} />

      <div id='minimalistintro'>
         
        <MDBRow style={{color:'white'}} >
          <MDBCol size="12" xs="12" md="12" xl="12" >
            <MDBNav tabs style={{backgroundColor:'olivedrab', width:'100%', margin:0, display:'flex', justifyContent:'center'}}>
              <MDBNavItem style={{marginRight:10 , fontWeight:400}}>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItem === "1"}
                  onClick={this.toggle("1")}
                  role="tab"
                >
                  {t('faseya.Register.consultants.skilled.Développement informatique ')}
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem style={{marginRight:10 , fontWeight:400}}>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItem === "2"}
                  onClick={this.toggle("2")}
                  role="tab"
                >
                  {t('faseya.Register.consultants.skilled.Design & Creativite')}
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem style={{marginRight:10 , fontWeight:400}}>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItem === "3"}
                  onClick={this.toggle("3")}
                  role="tab"
                >
                  {t('faseya.Register.consultants.skilled.E-Marketing')}
                </MDBNavLink>
              </MDBNavItem>

              <MDBNavItem style={{marginRight:10 , fontWeight:400}}>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItem === "4"}
                  onClick={this.toggle("4")}
                  role="tab"
                >
                  {t('faseya.Register.consultants.skilled.Reseaux & IT')}
                </MDBNavLink>
              </MDBNavItem>
              
              <MDBNavItem style={{marginRight:10 , fontWeight:400}}>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItem === "5"}
                  onClick={this.toggle("5")}
                  role="tab"
                >
                  {t('faseya.Register.consultants.skilled.Consultant IT')}
                </MDBNavLink>
              </MDBNavItem>
   
              <MDBNavItem style={{marginRight:10 , fontWeight:400}}>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItem === "6"}
                  onClick={this.toggle("6")}
                  role="tab"
                >
                  {t('faseya.Register.consultants.skilled.Gestions de Projets')}
                </MDBNavLink>
              </MDBNavItem>

              <MDBNavItem style={{marginRight:10 , fontWeight:400}}>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItem === "7"}
                  onClick={this.toggle("7")}
                  role="tab"
                >
                  {t('faseya.Register.consultants.skilled.Business Developper')}
                </MDBNavLink>
              </MDBNavItem>


            </MDBNav>
            <MDBTabContent
              className="card"
              activeItem={this.state.activeItem}
          
            >

              {[
                {index: 1, title:'faseya.Register.consultants.skills.Développement informatique '},
                {index: 2, title:'faseya.Register.consultants.skills.Design & Creativite'},
                {index: 3, title:'faseya.Register.consultants.skills.E-Marketing'},
                {index: 4, title:'faseya.Register.consultants.skills.Reseaux & IT'},
                {index: 5, title:'faseya.Register.consultants.skills.Consultant IT'},
                {index: 6, title:'faseya.Register.consultants.skills.Gestions de Projets'},
                {index: 7, title:'faseya.Register.consultants.skills.Business Developper'}
               ].map((item,index) =>
              <MDBTabPane key={item.index}  style={{color:'#000'}} tabId={item.index.toString()} role="tabpanel">
                <MDBRow style={{marginTop:20, color:'white', marginBottom:50}} className="d-flex justify-content-center">
                  
                  {/* Experts */}
                  <MDBCol size="12" xs="12" md="10" xl="10" >
                    <MDBCol size="12">
                      <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}> {t('faseya.dashboard.consultants.title_1', {what:t(item.title)})}</h4>
                    </MDBCol>
                    <MDBCol size="12">
                        {this.state.loadingconsultantsExperts &&
                          <img src={olive_loader} style={{position:'absolute', zIndex:1, bottom:this.state.loadingMoreAbsolute ? '11%' : '50%', left:'47.5%', width:40}} alt="" />
                        }
                        {this.displayConsultants(this.state.consultantsExperts)}
                    </MDBCol>
                  </MDBCol>

                  {/* Finished mission */}

                  <MDBCol size="12" xs="12" md="10" xl="10" className="mt-4" >
                    <MDBCol size="12">
                      <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}> {t('faseya.dashboard.consultants.title_2')}</h4>
                    </MDBCol>
                    <MDBCol size="12">
                        {this.state.loadingconsultantsAvailable &&
                          <img src={olive_loader} style={{position:'absolute', zIndex:1, bottom:this.state.loadingMoreAbsolute ? '11%' : '50%', left:'47.5%', width:40}} alt="" />
                        }
                        {this.displayConsultants(this.state.consultantsAvailable)}
                    </MDBCol>
                  </MDBCol>


                  {/* Newly subscribed */}

                  <MDBCol size="12" xs="12" md="10" xl="10" className="mt-4" >
                    <MDBCol size="12">
                      <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}>{t('faseya.dashboard.consultants.title_3')}</h4>
                    </MDBCol>
                    <MDBCol size="12">
                      
                        {this.state.loadingconsultantsNew &&
                          <img src={olive_loader} style={{position:'absolute', zIndex:1, bottom:this.state.loadingMoreAbsolute ? '11%' : '50%', left:'47.5%', width:40}} alt="" />
                        }
                        {this.displayConsultants(this.state.consultantsNew)}
                      
                    </MDBCol>
                  </MDBCol>

                </MDBRow>
              </MDBTabPane>
              )}
       
            </MDBTabContent>
            </MDBCol>
        </MDBRow>


          
      </div>

 
      <PrefooterComponent />
        
      </>

    );
  }
}


function mapStateToProps(state) {
  return {
    mainReduxState: state.mainReduxState
  }
}

function mapDispatchToProps(dispatch) {
  return {
    mainReduxActions: bindActionCreators(mainReduxActions, dispatch)
  }
}

export default connect(mapStateToProps , mapDispatchToProps)(withTranslation()(DashboardConsultantsPage));