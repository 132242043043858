import { api } from "./api.config";

const skillService = {
  addSkill,
  getSkills,
  getSkill,
  updateSkill,
  deleteSkill,
  deleteSkills,
};

const resource = "/skills";

/**
 * addSkill method
 *
 * @param {{ name: string }} payload
 *
 * @returns
 */
function addSkill(payload) {
  return api.postData(resource, payload);
}

/**
 * getSkills method
 *
 * @returns
 */
function getSkills() {
  return api.getData(resource);
}

/**
 * getSkill method
 *
 * @param {number} id
 *
 * @returns
 */
function getSkill(id) {
  return api.getData(resource + "/" + id);
}

/**
 * updateSkill method
 *
 * @param {{ name: string }} payload
 * @param {number} id
 *
 * @returns
 */
function updateSkill(id, payload) {
  return api.putData(resource + "/" + id, payload);
}

/**
 * deleteSkill method
 *
 * @param {number} id
 *
 * @returns
 */
function deleteSkill(id) {
  return api.deleteData(resource + "/" + id);
}

/**
 * deleteSkills method
 *
 * @returns
 */
function deleteSkills() {
  return api.deleteData(resource);
}

export default skillService;
