import * as actionTypes from './mainReduxActionTypes'; 

export function update_active(data) {
  return {
    type: actionTypes.UPDATE_ACTIVE,
    data
  }
}


export function set_user(data) {
  return {
    type: actionTypes.SET_USER,
    data
  }
}

export function update_consultants_list(data) {
  return {
    type: actionTypes.UPDATE_CONSULTANTS_LIST,
    data
  }
}

export function update_consultants_page(data) {
  return {
    type: actionTypes.UPDATE_CONSULTANTS_PAGE,
    data
  }
}

export function update_consultants_experts(data) {
  return {
    type: actionTypes.UPDATE_CONSULTANTS_EXPERTS,
    data
  }
}

export function update_consultants_available(data) {
  return {
    type: actionTypes.UPDATE_CONSULTANTS_AVAILABLE,
    data
  }
}

export function update_consultants_new(data) {
  return {
    type: actionTypes.UPDATE_CONSULTANTS_NEW,
    data
  }
}


export function update_services(data) {
  return {
    type: actionTypes.UPDATE_SERVICES,
    data
  }
}


