import React from 'react';
import { Route,Switch } from 'react-router-dom';

import NavigationNavPage from './pages/Others/NavigationNavPage';
import FormsNavPage from './pages/Others/FormsNavPage';
import TablesNavPage from './pages/Others/TablesNavPage';
import AddonsNavPage from './pages/Others/AddonsNavPage';
import ModalsNavPage from './pages/Others/ModalsNavPage';
import AdvancedNavPage from './pages/Others/AdvancedNavPage';
import ComponentsNavPage from './pages/Others/ComponentsNavPage';

// FREE
import AnimationPage from './pages/Others/AnimationPage';
import AlertPage from './pages/Others/AlertPage';
import ButtonPage from './pages/Others/ButtonPage';
import CSSNavPage from './pages/Others/CSSNavPage';
import TablePage from './pages/Others/TablePage';
import TableResponsivePage from './pages/Others/TableResponsivePage';
import TableScrollPage from './pages/Others/TableScrollPage';
import TableStylesPage from './pages/Others/TableStylesPage';
import BadgePage from './pages/Others/BadgePage';
import BreadcrumbPage from './pages/Others/BreadcrumbPage';
import FaPage from './pages/Others/FaPage';
import DatatablePage from './pages/Others/DatatablePage';
// import DatatablePageV5 from './pages/Others/DatatablePage-2';
import DatatableApiPage from './pages/Others/DatatableApiPage';
import ModalPage from './pages/Others/ModalPage';
import ModalFormPage from './pages/Others/ModalFormPage';
import ModalExamplesPage from './pages/Others/ModalExamplesPage';
import ProgressPage from './pages/Others/ProgressPage';
import InputPage from './pages/Others/InputPage';
import MediaPage from './pages/Others/MediaPage';
import JumbotronPage from './pages/Others/JumbotronPage';
import CardsPage from './pages/Others/CardsPage';
import PaginationPage from './pages/Others/PaginationPage';
import PopoverPage from './pages/Others/PopoverPage';
import ListGroupPage from './pages/Others/ListGroupPage';
import CarouselPage from './pages/Others/CarouselPage';
import PanelPage from './pages/Others/PanelPage';
import CollapsePage from './pages/Others/CollapsePage';
import TooltipsPage from './pages/Others/TooltipsPage';
import FooterPage from './pages/Others/FooterPage';
import MasksPage from './pages/Others/MasksPage';
import DropdownPage from './pages/Others/DropdownPage';
import VideoCarouselPage from './pages/Others/VideoCarouselPage';
import HoverPage from './pages/Others/HoverPage';
import FormsPage from './pages/Others/FormsPage';
import ChartsPage from './pages/Others/ChartsPage';
import SearchPage from './pages/Others/SearchPage';
import ValidationPage from './pages/Others/ValidationPage';
import NavbarPage from './pages/Others/NavbarPage';
import IframePage from './pages/Others/IframePage';
import EdgeHeaderPage from './pages/Others/EdgeHeaderPage';
import SpinnerPage from './pages/Others/SpinnerPage';
import MasonryPage from './pages/Others/MasonryPage';
import ScrollBarPage from './pages/Others/ScrollBarPage';
import NavsPage from './pages/Others/NavsPage';
import TabsPage from './pages/Others/TabsPage';
import PillsPage from './pages/Others/PillsPage';
import NotificationPage from './pages/Others/NotificationPage';
import InputGroupPage from './pages/Others/InputGroupPage';
import TreeviewPage from './pages/Others/TreeviewPage';
import RatingPage from './pages/Others/RatingPage';
import PricingPage from './pages/PricingPage/PricingPage';
import AboutPage from './pages/AboutPage/AboutPage';
import ConceptPage from './pages/ConceptPage/ConceptPage';

import AccountTypePage from './pages/AccountTypePage/AccountTypePage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import SignUpAdvisorPage from './pages/SignUpAdvisorPage/SignUpAdvisorPage';
import SignInPage from './pages/SigInPage/SigInPage';
import PasswordForgetPage from './pages/PasswordForgetPage/PasswordForgetPage';
import SignUpValalidationPage from './pages/SignUpValalidationPage/SignUpValalidationPage';
import UpdatePasswordPage from './pages/UpdatePasswordPage/UpdatePasswordPage';
import CustomerStepperPage from './pages/CustomerStepperPage/CustomerStepperPage';
import DemoPage from './pages/DemoPage/DemoPage';
import ResetPasswordVerificationCode from './pages/ResetPasswordVerificationCode/ResetPasswordVerificationCode';
import ResetNewPassword from './pages/ResetNewPassword/ResetNewPassword';

import ConsultantsListPage from './pages/ConsultantsListPage/ConsultantsListPage';
import ConsultantCvPage from './pages/ConsultantCvPage/ConsultantCvPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import { ProtectedRoute } from './ProtectedRoute';
import DashboardMissionsPage from './pages/DashboardMissionsPage/DashboardMissionsPage';
import DashboardConsultantsPage from './pages/DashboardConsultantsPage/DashboardConsultantsPage';
import RecruitConsultantPage from './pages/RecruitConsultantPage/RecruitConsultantPage';
import PostProjectPage from './pages/PostProjectPage/PostProjectPage';
import LandingPage from './pages/LandingPage/LandingPage';
import ConsultantMissionsPage from './pages/ConsultantMissionsPage/ConsultantMissionsPage';
import ConsultantModifyCVPage from './pages/ConsultantModifyCVPage/ConsultantModifyCVPage';
import BlogPage from './pages/BlogPage/BlogPage';
import ConfidentialityPage from './pages/ConfidentialityPage/ConfidentialityPage';
import CGUPage from './pages/CGUPage/CGUPage';
import BusinessCardsPage from './pages/BusinessCardsPage/BusinessCardsPage';
import BusinessCardPreviewPage from './pages/BusinessCardPreviewPage/BusinessCardPreviewPage';

class Routes extends React.Component {
  render() {
    return (
    
      <Switch>
        <Route exact path='/' component={LandingPage} />
        <Route exact path='/pricing' component={PricingPage} />
        <Route exact path='/about' component={AboutPage} />
        <Route exact path='/concept' component={ConceptPage} />
        <Route path='/consultants/' component={ConsultantsListPage} />
        <Route path='/cv-consultant' component={ConsultantCvPage} />
        <Route  exact path='/blog' component={BlogPage}  />
        <Route  exact path='/metiers' component={BusinessCardsPage}  />
        // <Route  exact path='/fiche-metier' component={BusinessCardPreviewPage}  />
        <Route  exact path='/fiches-metiers-tech/:keywords' component={BusinessCardPreviewPage}  />
        <Route  exact path='/confidentiality' component={ConfidentialityPage}  />
        <Route  exact path='/terms-of-use' component={CGUPage}  />

        <ProtectedRoute  exact path='/dashboard/client' type="ROLE_CLIENT" component={DashboardPage}  />
        <ProtectedRoute  exact path='/dashboard/client/missions' type="ROLE_CLIENT" component={DashboardMissionsPage}  />
        <ProtectedRoute  exact path='/dashboard/client/consultants' type="ROLE_CLIENT" component={DashboardConsultantsPage}  />
        <ProtectedRoute  exact path='/dashboard/client/recruit' type="ROLE_CLIENT" component={RecruitConsultantPage}  />
        <ProtectedRoute  exact path='/dashboard/consultant/recruit' type="ROLE_CONSULTANT" component={RecruitConsultantPage}  />

        <ProtectedRoute  exact path='/dashboard/client/modification-mot-de-passe' type="ROLE_CLIENT" component={UpdatePasswordPage}  />
        <ProtectedRoute  exact path='/dashboard/consultant/modification-mot-de-passe' type="ROLE_CONSULTANT" component={UpdatePasswordPage}  />

        <ProtectedRoute  exact path='/dashboard/consultant' type="ROLE_CONSULTANT" component={ConsultantMissionsPage}  />
        <ProtectedRoute  exact path='/dashboard/consultant/modify-cv' type="ROLE_CONSULTANT" component={ConsultantModifyCVPage}  />


        <Route exact path='/addons' component={AddonsNavPage} />
        <Route exact path='/advanced' component={AdvancedNavPage} />
        <Route exact path='/components' component={ComponentsNavPage} />
        <Route exact path='/css' component={CSSNavPage} />
        <Route exact path='/forms' component={FormsNavPage} />
        <Route exact path='/modals' component={ModalsNavPage} />
        <Route exact path='/navigation' component={NavigationNavPage} />
        <Route exact path='/tables' component={TablesNavPage} />
        {/* FREE */}
        <Route path='/addons/iframe' component={IframePage} />
        <Route path='/addons/edge-header' component={EdgeHeaderPage} />
        <Route path='/addons/notifications' component={NotificationPage} />
        <Route path='/addons/treeview' component={TreeviewPage} />
        <Route path='/addons/Rating' component={RatingPage} />
        <Route path='/advanced/carousel' component={CarouselPage} />
        <Route path='/advanced/collapse' component={CollapsePage} />
        <Route path='/advanced/videocarousel' component={VideoCarouselPage} />
        <Route path='/advanced/videocarousel' component={VideoCarouselPage} />
        <Route path='/advanced/alerts' component={AlertPage} />
        <Route path='/advanced/popover' component={PopoverPage} />
        <Route path='/advanced/tooltips' component={TooltipsPage} />
        <Route path='/advanced/charts' component={ChartsPage} />
        <Route path='/advanced/scrollbar' component={ScrollBarPage} />
        <Route path='/css/animations' component={AnimationPage} />
        <Route path='/css/icons' component={FaPage} />
        <Route path='/css/jumbotron' component={JumbotronPage} />
        <Route path='/css/masks' component={MasksPage} />
        <Route path='/css/hover' component={HoverPage} />
        <Route path='/css/masonry' component={MasonryPage} />
        <Route path='/components/media' component={MediaPage} />
        <Route path='/components/badge' component={BadgePage} />
        <Route path='/components/cards' component={CardsPage} />
        <Route path='/components/buttons' component={ButtonPage} />
        <Route path='/components/dropdown' component={DropdownPage} />
        <Route path='/components/progress' component={ProgressPage} />
        <Route path='/components/pagination' component={PaginationPage} />
        <Route path='/components/list-group' component={ListGroupPage} />
        <Route path='/components/panels' component={PanelPage} />
        <Route path='/components/search' component={SearchPage} />
        <Route path='/components/spinner' component={SpinnerPage} />
        <Route path='/components/tabs' component={TabsPage} />
        <Route path='/components/pills' component={PillsPage} />
        <Route path='/forms/forms' component={FormsPage} />
        <Route path='/forms/validation' component={ValidationPage} />
        <Route path='/forms/input' component={InputPage} />
        <Route path='/forms/inputgroup' component={InputGroupPage} />
        <Route path='/modals/modal' component={ModalPage} />
        <Route path='/modals/modal-form' component={ModalFormPage} />
        <Route path='/modals/modal-examples' component={ModalExamplesPage} />
        <Route path='/navigation/navbar' component={NavbarPage} />
        <Route path='/navigation/breadcrumb' component={BreadcrumbPage} />
        <Route path='/navigation/navs' component={NavsPage} />
        <Route path='/navigation/footer' component={FooterPage} />
        <Route path='/tables/table' component={TablePage} />
        <Route path='/tables/table-responsive' component={TableResponsivePage} />
        <Route path='/tables/table-scroll' component={TableScrollPage} />
        <Route path='/tables/table-styles' component={TableStylesPage} />
        <Route path='/tables/datatable-api' component={DatatableApiPage} />
        <Route path='/tables/datatable' component={DatatablePage} />
        {/* <Route path='/tables/datatable-2' component={DatatablePageV5} /> */}

        <Route exact path='/inscription' component={AccountTypePage} />
        <Route  path='/inscription/:profile' component={SignUpPage} />
        <Route  path='/register/consultant' component={SignUpAdvisorPage} />
        <Route exact path='/se-connecter' component={SignInPage} />
        <Route exact path='/mot-de-passe-oublie' component={PasswordForgetPage} />
        <Route exact path='/validation-compte' component={SignUpValalidationPage} />
        <Route exact path='/services/:name/:id' component={CustomerStepperPage} />
        <Route exact path='/demander-une-demo' component={DemoPage} />
        <Route exact path='/poster-un-projet' component={PostProjectPage} />
        <Route exact path='/code-de-verification' component={ResetPasswordVerificationCode} />
        <Route exact path='/nouveau-mot-de-passe' component={ResetNewPassword} />
        <Route
          render={function() {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
