import React, {useState, useEffect}  from 'react';
import { Link } from 'react-router-dom';
import {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBIcon,
} from 'mdbreact';
import PartnerShipComponent from '../../components/PartnerShipComponent/PartnerShipComponent';
import logo_faseya from '../../assets/images/logo_faseya.png';
import AuthService from '../../services/auth.service';
import olive_loader from '../../assets/images/olive-loader.gif';

export default function ResetPasswordVerificationCode(props) {

    const[code, setCode] = useState('');
    const[email, setEmail] = useState('');
    const[displayResult, setDisplayResult] = useState(false);
    const[error, setError] = useState('');
    const[connecting, setConnecting] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const changeHandler = (e) => {
        setCode(e.target.value)
    }

    const changeHandlerEmail = (e) => {
        setEmail(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";
         
        if(code && email) {
            
            setConnecting(true)
            AuthService.verifyResetPasswordCode({
                email: email,
                code: code
            }).then((res) => {

                if(res) {
                    props.history.push({
                        pathname:'/nouveau-mot-de-passe',
                        state : {
                            code: code,
                            email: email
                        }
                        
                    }); 
                } else {
                    setDisplayResult(true);
                    setConnecting(false);
                    setError("Code doesn't match with email");
                }
               
            }).catch((err) => {
                setDisplayResult(true);
                setConnecting(false);

                if(err.code == 404)
                setError(err.message);
                else setError(err.report);
                if(err.msg) setError(err.msg);
            })
        }
        
    }

    return (
                <MDBRow style={{height:'90vh'}}>
                    <MDBCol xs="12" md="5" className='' style={{ background: '#f3f4f6'}}>
                            <MDBRow className="d-flex justify-content-center flex-column my-5 mx-5">
                                <div className="d-flex flex-row">
                                    <div className="p-1">
                                        <Link to="/">
                                            <img src={logo_faseya} />
                                        </Link>
                                    </div>
                                </div>

                                <div className="p-1 mt-5">
                                    <span className="text-connect">
                                        Code de vérification
                                    </span>
                                </div>

                                {displayResult && (
                                    <div  className="p-1 mt-2">
                                        <span className={error ? "error-text" : "success-text"}>
                                           { error }
                                        </span>
                                    </div>
                                )}

                                <div className="d-flex justify-content-center pt-3">
                                    <form className='needs-validation w-100' onSubmit={submitHandler} noValidate>

                                        <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <input
                                                        type="text"
                                                        value={code}
                                                        onChange={changeHandler}
                                                        id='validationCode'
                                                        required
                                                        className="form-control"
                                                        placeholder="Entrez le code de vérification"
                                                        validation='Looks good!'
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        Remplissez le code de verification.
                                                    </div>
                                                </MDBCol>
                                        </MDBRow>

                                        <MDBRow >
                                                <MDBCol md="12" className="mb-3">
                                                    <input
                                                        type="email"
                                                        value={email}
                                                        onChange={changeHandlerEmail}
                                                        id='emailCode'
                                                        required
                                                        className="form-control"
                                                        placeholder="Entrez l'adresse email"
                                                        validation='Looks good!'
                                                    /> 
                                                    <div className="invalid-feedback">
                                                        Remplissez l'adresse email'.
                                                    </div>
                                                </MDBCol>
                                        </MDBRow>

                                        <MDBRow>
                                                <MDBCol md="12 mt-2">
                                                    {connecting &&
                                                        <img src={olive_loader} style={{position:'absolute', zIndex:1, left:'47.5%', width:40}} alt="" />
                                                    }
                                                    {!connecting && 
                                                        <MDBBtn type="submit"  className="btn-block btn-color">
                                                           Valider le code
                                                        </MDBBtn>
                                                    }
                                                </MDBCol>
                                        </MDBRow>
                                    </form>
                                </div>
                            </MDBRow>
                    </MDBCol>

                    <MDBCol  className='d-flex justify-content-center align-items-center hide-mobile'>
                       <PartnerShipComponent />
                   </MDBCol>
                </MDBRow>
    )
}