import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,MDBCardBody, MDBCardTitle, MDBCardImage, MDBCardText
} from 'mdbreact';
import './TalentsComponent.css';
 
import puzzle_icon from '../../assets/images/puzzle_icon.png';
import { withTranslation } from 'react-i18next';

class TalentsComponent extends React.Component {
 
  state = {
    collapseID: '',
    
  };

 
  render() {

    const {t} = this.props;
    const talents =
      [

        {
          "id" : 1,
          "key" : "developer",
          "name": "Developpeurs",
          "header1" : "Qu'est ce que le développeur web ? ",
          "text1" : "Réaliser les fonctionnalités techniques d’un site web ou d’une application mobile donnent une large définition du développeur ou développeuse web. C'est un métier plus ou moins polyvalent. Car le développeur / la développeuse est tenu d’avoir des capacités communicationnelles. Ce poste est flexible en fonction des responsabilités du projet et des conséquences du cahier de charge.",
          "header2": "Les développeurs(euses) web chez FASEYA",
          "text2" : "Chez faseya nos talents en développement de solutions numérique sont spécialisés en : <ul> <li>Développement Backend </li>, <li>Développement Frontend</li>, <li>Développement Fullstack </li>. </ul> <br /> Par conséquent, ils peuvent intervenir dans tous les projets IT. Ainsi, les développeur(se)s web de FASEYA par leur professionnalisme sont tenus régulièrement à jour des nouveautés technologiques, afin de vous donner plus de satisfaction sur vos projets.",
          "header3" : "Comment trouver un développeur freelance chez FASEYA ?",
          "text3" : "",
          "header4" : "",
          "text4" : "",
          "cta" : "Trouvez un developpeur"
        },
        {
          "id" : 2,
          "key" : "digital",
          "name": "Digital Manager",
          "header1" : "Comment trouver votre manager digital ?",
          "text1" : "Vous venez de lancer un nouveau projet, vous êtes à la recherche d’un digital manager en freelance, car vous n’avez pas les moyens de recruter un salarié ? Faseya est la solution à tous vos projets digitaux et notre capital humain met en avant la qualité de travail et le respect des délais exigés. ",
          "header2": "FASEYA est la solution pour tous vos projets numériques/Digital Manager",
          "text2" : "Avec l’évolution et l'expansion du numérique, les compétences orientées digitales sont très demandés par le marché dû à la mutation des processus de digitalisation de plusieurs entreprises. Ce qui encourage les entreprises à recruter des digitaux managers autrement dit responsable marketing digital. Faseya votre solution de freelance idéale pour vos projets IT, vous proposent des digitaux managers compétents.",
          "header3" : "Qu'est-ce qu'un digital manager chez FASEYA ?",
          "text3" : "Il est non seulement chargé de la notoriété de l’entreprise sur le web, mais il doit établir la stratégie pour cette dernière d'être pérenne et d’avoir une position favorable sur le marché. Cela dit, il a plusieurs missions qui font de lui un collaborateur indispensable pour le développement de votre projet digital.",
          "header4" : "Sa valeur ajoutée :",
          "text4" : "",
          "cta" : "Contacter votre digital manager"
        },
        {
          "id" : 3,
          "key" : "manager",
          "name": "Chef de projet IT",
          "header1" : "Qu’est-ce qu’un Chef de Projet Digital ?",
          "text1" : "Avec le développement de l’internet et de la technologie de dernière génération, on assiste à la naissance de nouveaux métiers axés sur le numérique. Ainsi pour mener à bien les actions des projets digitaux nous avons besoin d’un chef de projet digital. Il orchestre les actions du projet liées aux marketing digital et précisément à la transformation digitale. En interne, il est chargé des projets digitaux. En ce sens, ses compétences en web et en management doivent être rassurantes et sensibles à l'innovation. Sa culture doit être orientée vers le digital, le numérique et la technique.",
          "header2": "Qu’est-ce que le métier de chef de projet chez Faseya ?",
          "text2" : "Le chef de projet digital (CPD) est le couteau à suisse de l’entreprise ou du projet qu’il porte, il doit cumuler les compétences et connaissances techniques et managériales. En ses compétences, le chef de projet digital est obligé de d’y connaitre en marketing communication et la gestion de la relation client ou service client. La créativité doit animer ses réflexions, car elle doit être capable de générer des idées déduisant à des solutions ou stratégies pertinentes pour résoudre des problèmes. Le chef de projet digital doit en informer au mieux les besoins digitaux du clients ou de la cible du projet. Ceci faisant l’objet principal de sa mission pour un projet digital.",
          "header3" : "La mission du chef de projet digital chez FASEYA",
          "text3" : "Il est à la tête, c’est le commandant, le pilote, le chef d’orchestre du projet digital. Il a pour mission de de moderniser, innover et rendre concret les idées principales du projet digital. Parmi ses missions, il va manager une équipe tout au long du processus de la réalisation du projet digital. Ce dernier peut être la création d’un site web, application mobile… En partant d’un cahier de charges, le chef de projet digital exécute ou fait exécuter toutes les exigences du clients en fonction du projet dans la mesure du possible. ",
          "header4" : "",
          "text4" : "",
          "cta" : "Choisissez votre CPD "
        },
        {
          "id" : 4,
          "key" : "designer",
          "name": "Designer UI/UX",
          "header1" : "Qu’est-ce que le designer UI\UX ?",
          "text1" : 
          `En fonction des nouvelles technologies de navigation tactile, la rotation des supports mobiles, les designers UI/UX construisent des interfaces pour les solutions de communications digitales tel que les sites web et applications mobiles. 
          <br /><br />Ainsi les deux concepts ne peuvent pas être dissociés car ils améliorent et organisent le design d’une plateforme web. 
          <br /><br />On fait souvent appel à ces compétences, ils sont importants pour tout projet IT. Car par ses deux compétences complémentaires l’action commerciale est plus facile, cela dit ils assistent et appuient la vente des produits ou services au niveau des plateformes E-COMMERCE.  En général, les sites informatifs et ceux de vente en ligne en ont plus besoin.`,
          "header2": "Comment trouver un designer UI/UX chez FASEYA ?",
          "text2" : "Pour un projet IT (Site web ou Application mobile) Faseya est la meilleure plateforme de freelance, ainsi nous proposons des designers expérimentés et qualifiés. Des experts UI/UX talentueux et attitrés sont disponibles pour améliorer l'interface et l'utilisation de vos utilisateurs . Tout ceci pour vous aider à sélectionner le meilleur profil qui correspond le plus à vos différents projets IT sur le court ou le long terme. Riche d’au moins 1 année d’expérience effective dans le domaine, le designer proposé sur la plateforme de développement web Faseya peut se charger de vos différentes missions, même les plus complexes. Le consultant UI/UX reste disponible et flexible sur les tâches et missions qui lui seront affectées que ce soit sur la durée ou sur la fréquence. Ainsi vous pourrez passer en mode agile et vous concentrer davantage sur votre cœur de métier. Ainsi nous vous permettons de passer en mode agile et de vous concentrer dans votre cœur de métier. ",
          "header3" : "La mission du designer UI/UX chez FASEYA",
          "text3" : `Il est important pour le designer UI/UX d'identifier les principaux enjeux d’une plateforme web (site web ou application mobile).
          <br />Ils doivent faire des études qui lui permettront de relever les blocages et les points d’amélioration d’une plateforme web. Parmi ses missions il est noté aussi d’élaborer d’un profil type de l’utilisateur (buyers persona) qui viendra visiter la plateforme web. Ainsi la création du storytelling en conformité avec l’image de la marque, visant à susciter l’émotion chez les internautes.
          <br />Le designer fera aussi une étude de design UX/UI portant sur la problématique du projet seront réalisés et différentes propositions (mock-ups) afin définir les lignes du projet. 
          À partir d’une maquette démontrer l’interface future du projet.
          `,
          "header4" : "",
          "text4" : "",
          "cta" : "Contactez votre designer"
        },
        {
          "id" : 5,
          "key" : "graphiste",
          "name": "Graphiste",
          "header1" : "Qu'est ce que le graphiste ?",
          "text1" : `“Le design est l’ambassadeur silencieux de la marque” - PAUL RAND. 
          <br /><br />Concevoir et réaliser les éléments visuels ( images, charte graphique, logos etc) sont les principales fonctions du graphique afin de participer à l’amélioration de l’image de l’entreprise ou la marque. 
          <br /><br />D'abord il doit être un artiste qui réveille la créativité pour satisfaire les besoins du client et répondre à ses attentes. En ce sens, il doit savoir innover et suivre les tendances. Chez faseya nos graphistes on la culture du digital cela dit vos besoins seront exécutés en fonction du cahier de charge.  
          <br /><br />Ensuite, le graphiste doit maîtriser les logiciels de création de contenus graphique tels que Photoshop, Première Pro, Illustrator… Le dessin et la combinaison des couleurs sont des notions directrices pour parfaire son travail. Il peut être dans certaines circonstances que l’on fasse appelle à sa polyvalence c'est-à-dire, il peut intervenir sur des médias offline et sur des supports online. 
          <br /><br />Enfin, le graphiste est demandé aussi à analyser la demande du client avant exécutions et traduire les idées en images et la mise au point d’un concept. 
          `,
          "header2": "Comment trouver un graphiste chez FASEYA ?",
          "text2" : `Le graphiste est un acteur incontournable dans toutes les campagnes de communication (online / offline). En ces raisons, faseya se présente affirme sa volonté de vous accompagner afin d'extérioriser votre marque en touchant un public plus large et avertis. 
            <br />Ainsi la société de conseil numérique spécialisée dans le développement de logiciels, site web et application mobile vous propose des graphistes expérimentés (freelance) selon la dimension de votre projet avec une flexibilité dans le budget. défiant toute concurrence. 
            <br />Nous faire confiance c’est faire confiance à l’expertise, au travail bien fait car nous avons pu nous faire remarquer par le dynamisme et la détermination de nos freelances qui sont avertis sur les responsables de chaque mission. 
          `,
          "header3" : "La mission du graphiste chez Faseya.",
          "text3" : `Véhiculer les informations par le biais d'images est la principale mission du graphiste. 
          <br /><br />Ainsi parmi ses missions on peut noter :
            <ul>
            
            <li>Bien étudier ou analyser la demande du client</li>
            <li>Créer des concepts en fonction de ses besoins</li>
            <li>Élaborer et réaliser la charte graphique y inclure les couleurs à utiliser, la typographie et d’autres..</li>
            <li>Montage de la page à l’écran</li>
            <li>Incorporation des images et des textes</li>
            <li>Déclinaisons et impression</li>
            </ul>`
          ,
          "header4" : "",
          "text4" : "",
          "cta" : "Contactez votre graphiste"
        },
        {
          "id" : 6,
          "key" : "consultant",
          "name": "Consultant IT",
          "header1" : "Comment trouver votre manager digital ?",
          "text1" : "Vous venez de lancer un nouveau projet, vous êtes à la recherche d’un digital manager en freelance, car vous n’avez pas les moyens de recruter un salarié ? Faseya est la solution à tous vos projets digitaux et notre capital humain met en avant la qualité de travail et le respect des délais exigés. ",
          "header2": "FASEYA est la solution pour tous vos projets numériques/Digital Manager",
          "text2" : "Avec l’évolution et l'expansion du numérique, les compétences orientées digitales sont très demandés par le marché dû à la mutation des processus de digitalisation de plusieurs entreprises. Ce qui encourage les entreprises à recruter des digitaux managers autrement dit responsable marketing digital. Faseya votre solution de freelance idéale pour vos projets IT, vous proposent des digitaux managers compétents.",
          "header3" : "Qu'est-ce qu'un digital manager chez FASEYA ?",
          "text3" : "Il est non seulement chargé de la notoriété de l’entreprise sur le web, mais il doit établir la stratégie pour cette dernière d'être pérenne et d’avoir une position favorable sur le marché. Cela dit, il a plusieurs missions qui font de lui un collaborateur indispensable pour le développement de votre projet digital.",
          "header4" : "Sa valeur ajoutée :",
          "text4" : "",
          "cta" : "Trouvez un expert"

        },
          
      ]
    const _renderMainTalents = talents.map((item,index) => 
 
      <MDBCol key={index} size="12" xs="12" md="6"  className="mb-3"  >
        <MDBCard className="f-testimony-item" style={{height:'100%', paddingBottom : 20}}>
          <MDBCol size="auto" className="d-flex align-items-center">
            <img src={puzzle_icon} style={{width:35, marginTop:10}} alt="" />
            <h4 style={{fontSize:22, fontWeight:"bolder", marginTop:15, marginLeft:5}} >{item.name}</h4>
          </MDBCol>
          <MDBCol style={{marginTop:15}}>
            <h1 className="" style={{margin:0, fontWeight:700, fontSize:16, color:'#000'}} dangerouslySetInnerHTML={{__html:item.header1}} ></h1> <br />
            <span className="" style={{margin:0, fontWeight:500, fontSize:16, color:'#000'}} dangerouslySetInnerHTML={{__html:item.text1}} ></span>
          </MDBCol>

          <MDBCol style={{marginTop:15}}>
            <h1 className="" style={{margin:0, fontWeight:700, fontSize:16, color:'#000'}} dangerouslySetInnerHTML={{__html:item.header2}} ></h1> <br />
            <span className="" style={{margin:0, fontWeight:500, fontSize:16, color:'#000'}} dangerouslySetInnerHTML={{__html:item.text2}} ></span>
          </MDBCol>

          <MDBCol style={{marginTop:15}}>
            <h1 className="" style={{margin:0, fontWeight:700, fontSize:16, color:'#000'}} dangerouslySetInnerHTML={{__html:item.header3}}></h1> <br />
            <span className="" style={{margin:0, fontWeight:500, fontSize:16, color:'#000'}} dangerouslySetInnerHTML={{__html:item.text3}} ></span>
          </MDBCol>

          <MDBCol style={{marginTop:15}}>
            <MDBBtn className="f-btn-olive"  onClick={() => this.props.history.push({pathname: `consultants/${item.key}`, state:{reload : true}}) }>{item.cta}</MDBBtn>
          </MDBCol>
          
          {/* 
          <MDBCol style={{marginTop:15}}>
            <span className="" style={{margin:0, fontWeight:700, fontSize:16, color:'#000'}}>{item.header4}</span>
            <span className="" style={{margin:0, fontWeight:500, fontSize:16, color:'#000'}}>{item.text4}</span>
          </MDBCol> */}

        </MDBCard>
      </MDBCol>

    );

    return (

      <div style={{padding:10, marginTop:30, backgroundColor : '#ecf3f4'}}>
  
        <h4 className="font-light f-subtitle f-subheader text-center " style={{fontWeight:'bolder'}}>{t('faseya.our_talents.title')} </h4>

        <MDBRow>
            
              <MDBCol size="12" sm="12" md="10"  className="offset-md-1 mb-4" style={{}} >
                <MDBRow>

                  {_renderMainTalents}

                </MDBRow>
              </MDBCol>            

        </MDBRow>
   
      </div>
    );
  }
}

export default withTranslation()(TalentsComponent);
