import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,MDBCardBody, MDBCardTitle, MDBCardImage, MDBCardText
} from 'mdbreact';
import './TestimoniesComponent.css';
 
import quote from '../../assets/images/quote.png';
import { withTranslation } from 'react-i18next';

class TestimoniesComponent extends React.Component {
 
  state = {
    collapseID: '',
    
  };

 
  render() {

    const {t} = this.props;
    const testimonies =
      [

        {
          "id" : 1,
          "name": "User 1",
          "text" : t('faseya.testimonials.item_1')
        },
        {
          "id" : 2,
          "name": "User 2",
          "text" : t('faseya.testimonials.item_2')
        },
        {
          "id" : 3,
          "name": "User 3",
          "text" : t('faseya.testimonials.item_3')
        },
          
      ]
    const _renderMainTestimonies = testimonies.map((item,index) => 
 
      <MDBCol key={index} size="12" xs="12" md="4"  className="mb-3" >
        <MDBCard className="f-testimony-item" style={{height:'100%'}}>
          <MDBCol size="auto" className="d-flex align-items-center">
            <img src={quote} style={{width:40, marginTop:15}} alt="" />
          </MDBCol>
          <MDBCol>
            <span className="" style={{margin:0, fontWeight:500, fontSize:16, color:'#000'}}>{item.text}</span>
          </MDBCol>
        </MDBCard>
      </MDBCol>

    );

    return (

      <div style={{padding:10, marginTop:30, backgroundColor : '#ecf3f4'}}>
  
        <h4 className="font-light f-subtitle f-subheader text-center " style={{fontWeight:'bolder'}}>{t('faseya.testimonials.title')} </h4>

        <MDBRow>

            
              <MDBCol size="12" sm="12" md="10"  className="offset-md-1 mb-4" style={{}} >
                <MDBRow>

                  {_renderMainTestimonies}

                </MDBRow>
              </MDBCol>            

        </MDBRow>
      
      

      </div>
    );
  }
}

export default withTranslation()(TestimoniesComponent);
