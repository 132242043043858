import { api } from "./api.config";

const consultantsService = {
  getConsultants,
  getConsultant,
  addConsultant,
  deleteAllConsultants,
  updateConsultant,
  searchConsultants,
  searchConsultantsWithFilters,
};

const resource = "/consultants";

/**
 * getConsultants method
 *
 * @param {{ page: number, size: number }} payload
 *
 * @returns
 */
function getConsultants(payload = undefined) {
  return api.getData(resource, !payload.page || !payload.size ? {} : payload);
}

/**
 * getConsultants method
 *
 * @param {number} id
 * @returns
 */
function getConsultant(id) {
  return api.getData(resource + "/" + id);
}

/**
 * updateConsultant method
 *
 * @param {number} id
 * @param {{
 *   prenom?: string;
 *   nom?: string;
 *   telephone?: string;
 *   adresse?: string;
 *   photo?: string;
 *   disponibilite?: string;
 *   nbr_annee_experience?: number;
 *   competence_technique?: string;
 *   titre?: string;
 *   serviceId?: number;
 *   position?: number;
 *   profil?: string;
 *   presentation?: string;
 *   pretentionSalariale?: number;
 * }} payload
 * @returns
 */
function updateConsultant(id, payload) {
  return api.putData(resource + "/" + id, payload);
}

/**
 * addConsultant method
 *
 * @param {{
 *  username: string;
 *  prenom: string;
 *  nom: string;
 *  email: string;
 *  telephone: string;
 *  adresse: string;
 *  photo: string;
 *  disponibilite: string;
 *  nbr_annee_experience: number;
 *  competence_technique: string;
 *  titre: string;
 *  serviceId: number;
 *  position: number;
 *  profil: string;
 *  diplomes: {
 *    libelle: string;
 *    etablissement: string;
 *    mois_debut: string;
 *    annee_debut: string;
 *    mois_obtention: string;
 *    annee_obtention: string;
 *    description: string;
 *  }[];
 *  experiences: {
 *    societe: string;
 *    intitule_poste: string;
 *    mois_debut: string;
 *    annee_debut: string;
 *    mois_fin: string;
 *    annee_fin: string;
 *    realisation: string;
 *    environement_technique: string;
 *  }[];
 *  password: string;
 *  presentation?: string;
 *  pretentionSalariale?: number;
 * }} payload
 * @returns
 */
function addConsultant(payload) {
  return api.postData(resource, payload);
}

/**
 * deleteAllConsultants method
 *
 * @returns
 */
function deleteAllConsultants() {
  return api.deleteData(resource);
}

/**
 * searchConsultants method
 *
 * @param {{ keyword: string; page: number; size: number; }} payload
 * @returns
 */
function searchConsultants(payload) {
  return api.getData(resource + "/search/" + payload.keyword, {
    page: payload.page,
    size: payload.size,
  });
}

/**
 * searchConsultantsWithFilters method
 *
 * @param {{
 *   keyword: string;
 *   page: number;
 *   size: number;
 *   filters: {
 *     profil: string;
 *   }; 
 * }} payload
 * @returns
 */
function searchConsultantsWithFilters(payload) {
  return api.postData(
    resource + "/search",
    { keyword: payload.keyword, filters: payload.filters },
    {
      page: payload.page,
      size: payload.size,
    }
  );
}

export default consultantsService;
