import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdbreact';
import './PrefooterCvComponent.css';

import light_icon from '../../assets/images/light_icon.png';
import puzzle_icon from '../../assets/images/puzzle_icon.png';
import quality_icon from '../../assets/images/quality_icon.png';
import prefooter_banner from '../../assets/images/prefooter_banner.png';
import { withTranslation } from 'react-i18next';

class PrefooterCvComponent extends React.Component {
 
  state = {
    collapseID: ''
  };

 
  render() {

    const {t} = this.props;
    return (

      <div style={{padding:10, backgroundColor : 'olivedrab'}}>
  
        <MDBRow>
            
            <MDBCol size="12" sm="12" md="12"  className="mb-1 d-flex mb-1 align-items-center justify-content-center" style={{}} >

              <MDBContainer>
                <MDBRow className="mb-1 d-flex align-items-center justify-content-center">

                  <MDBCol size="12" xs="12" md="12"  className=" mb-1 f-how-item " >
                      <h2 className="f-title" style={{color:'#fff', textAlign:'center'}}>{t('faseya.consultant_cv.find_more')}</h2>
                  </MDBCol>
                  
                  <MDBCol size="12" xs="12" md="12"  className="d-flex align-items-center justify-content-center" >
                    <MDBBtn className="f-btn-white" style={{paddingLeft:15, paddingRight:15, fontSize:20}} href="#/consultants/all">{t('faseya.consultant_cv.see_more')}</MDBBtn>
                  </MDBCol>

                </MDBRow>
              </MDBContainer>
            </MDBCol>            


        </MDBRow>
      
      </div>
    );
  }
}

export default withTranslation()(PrefooterCvComponent);
