import React, { Component } from 'react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBNavLink,
  MDBTooltip,
  MDBIcon
} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import HeaderComponent from './components/HeaderComponent/HeaderComponent.jsx';

import logo_faseya from './assets/images/logo_faseya.png';

import './style.scss';

import Routes from './Routes';
import FooterComponent from './components/FooterComponent/FooterComponent.jsx';

class App extends Component {
  state = {
    collapseID: ''
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));

  closeCollapse = collID => () => {
    const { collapseID } = this.state;
    window.scrollTo(0, 0);
    collapseID === collID && this.setState({ collapseID: '' });
  };

  render() {
    const overlay = (
      <div
        id='sidenav-overlay'
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('mainNavbarCollapse')}
      />
    );

    const { collapseID } = this.state;
    

    return (
      // <Provider store={store}>
        <Router>
          <div className='flyout'>
            
            
            {/* {collapseID && overlay} */}
            <main style={{ marginTop: '0rem' }}>
              
              {/* <HamburgerMenuPage /> */}
              <Routes />
            </main>

            <FooterComponent />

          </div>
        </Router>
      // </Provider>
    );
  }
}

export default App;
