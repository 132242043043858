import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdbreact';
import './ParticularityComponent.css';

import light_icon from '../../assets/images/light_icon.png';
import puzzle_icon from '../../assets/images/puzzle_icon.png';
import quality_icon from '../../assets/images/quality_icon.png';
import { withTranslation } from 'react-i18next';

class ParticularityComponent extends React.Component {
 
  state = {
    collapseID: ''
  };

 
  render() {

    const { collapseID } = this.state;
    const {t} = this.props;
    return (
      <MDBContainer style={{marginTop:80}}>
  
        <h4 className="font-light f-subtitle f-subheader-2" style={{fontWeight:'bolder'}}> {t('faseya.particularity.title')} </h4>

        <MDBRow>
          
          <MDBCol size="12" xs="12" md="4"  className="mb-4 f-particularity-item" >
            <MDBCol size="12" className="mb-3">
              <img src={light_icon} style={{width:20, marginRight:8}} alt="" />
              <strong className="f-subtitle-2" style={{margin:0, marginBottom:-8}}>{t('faseya.particularity.item_1')}</strong>
            </MDBCol>
            <MDBCol size="12" className="mb-1">
              <p style={{color:'black', fontSize:18, fontWeight:400}}>
              {t('faseya.particularity.item_2')}
              </p>
            </MDBCol>
          </MDBCol>

          
          <MDBCol size="12" xs="12" md="4"  className="mb-4 f-particularity-item" >
            <MDBCol size="12" className="mb-3">
              <img src={puzzle_icon} style={{width:20, marginRight:8}} alt="" />
              <strong className="f-subtitle-2" style={{margin:0, marginBottom:-8}}>{t('faseya.particularity.item_3')}</strong>
            </MDBCol>
            <MDBCol size="12" className="mb-1">
              <p style={{color:'black', fontSize:18, fontWeight:400}}>
              {t('faseya.particularity.item_4')}
              </p>
            </MDBCol>
          </MDBCol>

          
          <MDBCol size="12" xs="12" md="4"  className="mb-4 f-particularity-item" >
            <MDBCol size="12" className="mb-3">
              <img src={quality_icon} style={{width:20, marginRight:8}} alt="" />
              <strong className="f-subtitle-2" style={{margin:0, marginBottom:-8}}>{t('faseya.particularity.item_5')}</strong>
            </MDBCol>
            <MDBCol size="12" className="mb-1">
              <p style={{color:'black', fontSize:18, fontWeight:400}}>
                {t('faseya.particularity.item_6')}
              </p>
            </MDBCol>
          </MDBCol>



        </MDBRow>
      
      
      </MDBContainer>
    );
  }
}

export default withTranslation()(ParticularityComponent);
