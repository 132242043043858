import React, {Fragment}  from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBFormInline,
  MDBBtn,
  MDBView,
  MDBInput,
  MDBContainer,
  MDBAutocomplete,
  MDBModal, 
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact';
import './LandingPage.css';

import banner_faseya from '../../assets/images/banner_faseya.jpg';
import PartnersComponent from '../../components/PartnersComponent/PartnersComponent';
import ParticularityComponent from '../../components/ParticularityComponent/ParticularityComponent';
import ServicesComponent from '../../components/ServicesComponent/ServicesComponent';
import ExpertsComponent from '../../components/ExpertsComponent/ExpertsComponent';
import HowComponent from '../../components/HowComponent/HowComponent';
import ParcoursComponent from '../../components/ParcoursComponent/ParcoursComponent';
import PacksComponent from '../../components/PacksComponent/PacksComponent';
import PrefooterComponent from '../../components/PrefooterComponent/PrefooterComponent';
import TestomiesComponent from '../../components/TestimoniesComponent/TestimoniesComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import { withTranslation } from 'react-i18next';
import skillService from '../../services/skill.service';
import auth from '../../services/auth';
import Partners2Component from '../../components/Partners2Component/Partners2Component';


class LandingPage extends React.Component {
  state = {
    collapsed: false,
    searchKeyword : '',
    techs : [],
    user: {},
    showNotif : false
  };

  handleTogglerClick = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });
  };

  componentDidMount() {

    window.scrollTo(0, 0);

    document.querySelector('nav').style.height = '65px';

    var user = JSON.parse(localStorage.getItem("user")) || {id : null}
    this.setState({user : user});

    this.getSkills();
  }

  getSkills = () =>
    {
        skillService.getSkills().then(data=>{
            var techs = [];

            data.forEach(el => {
              techs.push(el.name)
            });
            this.setState({techs : techs})
         })
         .catch(err=>{
      
         })
    }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  onHandleClik = (name, fullName, id) => {
    this.props.history.push({pathname: `services/${name}/${id}`, state:{full_name: fullName}});
  }

  breakString = (str, limit) => {

    let brokenString = '';

    for(let i = 0, count = 0; i < str.length; i++){
      if(count >= limit && str[i] === ' '){

              count = 0;
              brokenString += '\n' ;
      }else{
              count++;
              brokenString += str[i];
            }
      }
    //console.log(brokenString)
    return brokenString;
}

  getSearchKeyword = (value) => {
    this.setState({searchKeyword : value})
  }

  gotoConsultants = () => {
    // if(this.state.searchKeyword.trim() == "") return;
    var s = "all";
    if(this.state.searchKeyword.trim() != "") s = this.state.searchKeyword;

    this.props.history.push({pathname: `consultants/?results=${s}`, state:{reload : true}});
  }

  gotoPostProject = () => {
    
    var path =/*  auth.isAuthenticated() ?  */"/poster-un-projet"/* :"/se-connecter" */;

    // if(auth.isAuthenticated())
    // {
      this.props.history.push({pathname: path});
    // }
    // else
    // {
    //   this.setState({showNotif : true});

    //   setTimeout(() => {
    //     this.setState({showNotif : false});
    //     this.props.history.push({pathname: path});
    //   }, 4000);
    // }
  }

  gotoRequestDemo = () => {
    
    // var path = auth.isAuthenticated() ? "/demander-une-demo":"/se-connecter";
    var path = "/demander-une-demo";

    // if(auth.isAuthenticated())
    // {
      this.props.history.push({pathname: path});
    // }
    // else
    // {
    //   this.setState({showNotif : true});

    //   setTimeout(() => {
    //     this.setState({showNotif : false});
    //     this.props.history.push({pathname: path});
    //   }, 4000);
    // }

  }  

  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: '0rem' };

    const {t} = this.props;

    // const overlay = (
    //   <div
    //     id='sidenav-overlay'
    //     style={{ backgroundColor: 'transparent' }}
    //     onClick={this.handleTogglerClick}
    //   />
    // );
    return (

      <>
      
      <HeaderComponent history={this.props.history} />

      <div id='minimalistintro'>
        <MDBView src={banner_faseya}>
          <MDBMask className='rgba-black-light' />
          <MDBContainer
            className='d-flex f-hero-margin'
            style={{ height: '100%', width: '100%', paddingTop: '17px' }}
          >
            <MDBRow>
              <MDBCol xs="12" md="12"  className='mb-4 white-text'>
                <h1 className="title f-title">{/* Le talent dont vous avez besoin. <br /> La flexibilité que vous voulez. */}
                  {t('faseya.banner.title')} <br /> {t('faseya.banner.subtitle')}
                </h1>
                          
                <h4 className="subtitle font-light f-subtitle" style={{fontWeight:'bolder', width:'70%'}}>
                  {t('faseya.banner.description')}
                </h4>
              
                <MDBBtn outline color='white' className="btn btn-success-gradiant font-14 f-btn-olive" onClick={()=>this.gotoPostProject()}>
                  {t('faseya.banner.post_project')} 
                </MDBBtn>
                <MDBBtn outline color='white' className="btn btn-success-gradiant font-14 f-btn-white" onClick={()=>this.gotoRequestDemo()}>
                  {t('faseya.banner.ask_demo')}
                </MDBBtn>
             
                <MDBRow>
                  <MDBCol xs="10" md="9"  className='offset-xs-1 f-search-box'>
                    <form>
                    <MDBAutocomplete
                      data={this.state.techs}
                      label={t('faseya.banner.find_dev')}
                      clear
                      // id="input"
                      style={{color:'black'}}
                      className='f-input-autocomplete' 
                      getValue={(v)=>this.getSearchKeyword(v)}
                    />
                      {/* <input className="form-control f-input" type="search" name="search" placeholder={t('faseya.banner.find_dev')} onChange={(e)=>this.getSearchKeyword(e)}
                          /> */}
                        <MDBBtn className="f-btn-olive" onClick={this.gotoConsultants} style={{marginTop:18, paddingTop:13,paddingBottom:13}}>{t('faseya.banner.find_a_consultant')}</MDBBtn>
                    </form>
                  </MDBCol>
              </MDBRow>
             
              </MDBCol>


            </MDBRow>
            
          </MDBContainer>
        </MDBView>

        
    



      </div>
        <PartnersComponent />

        <ParticularityComponent />

        <ServicesComponent navigation={(value, fullName, id) => this.onHandleClik(value, fullName, id)} />

        <ExpertsComponent history={this.props.history} />

        <HowComponent />

        <ParcoursComponent />

        <PacksComponent history={this.props.history} />
        
        <TestomiesComponent />


        <Partners2Component />


        <PrefooterComponent />


        <MDBModal isOpen={this.state.showNotif} toggle={this.showNotif}  size="md" centered modalStyle='warning'>
          <MDBModalHeader ><span style={{fontWeight:500}}>Notification</span></MDBModalHeader>

              <MDBModalBody>
                  <MDBRow>
                      <MDBCol size="12">
                          <p style={{color:'black', fontWeight:500}}> {t('faseya.must_be_logged')} </p>
                      </MDBCol>
                  </MDBRow>
              </MDBModalBody>  
          </MDBModal>

      </>

    );
  }
}

export default withTranslation()(LandingPage);