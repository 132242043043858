import { api } from "./api.config";

const projectService = {
  addProject,
  getProjects,
  getProject,
  updateProject,
  deleteProject,
  deleteProjects,
};

const resource = "/projects";

/**
 * addProject method
 *
 * @param {{
 *   description: string;
 *   adresse: string;
 *   code_postal: string;
 *   ville: string;
 *   nbre_consultant: number;
 *   date_debut: string;
 *   date_fin: string;
 *   telephone: string;
 *   email: string;
 *   serviceId: number;
 * }} payload
 *
 * @returns
 */
function addProject(payload) {
  return api.postData(resource, payload);
}

/**
 * getProjects method
 *
 * @returns
 */
function getProjects() {
  return api.getData(resource);
}

/**
 * getProject method
 *
 * @param {number} id
 *
 * @returns
 */
function getProject(id) {
  return api.getData(resource + "/" + id);
}

/**
 * updateProject method
 *
 * @param {{
 *   description?: string;
 *   adresse?: string;
 *   code_postal?: string;
 *   ville?: string;
 *   nbre_consultant?: number;
 *   date_debut?: string;
 *   date_fin?: string;
 *   telephone?: string;
 *   email?: string;
 *   serviceId?: number;
 * }} payload
 * @param {number} id
 *
 * @returns
 */
function updateProject(id, payload) {
  return api.putData(resource + "/" + id, payload);
}

/**
 * deleteProject method
 *
 * @param {number} id
 *
 * @returns
 */
function deleteProject(id) {
  return api.deleteData(resource + "/" + id);
}

/**
 * deleteProjects method
 *
 * @returns
 */
function deleteProjects() {
  return api.deleteData(resource);
}

export default projectService;
