import React, {useState, useEffect}  from 'react';
import { Link } from 'react-router-dom';
import {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBIcon,
  } from 'mdbreact';
import PartnerShipComponent from '../../components/PartnerShipComponent/PartnerShipComponent';
import logo_faseya from '../../assets/images/logo_faseya.png';
import AuthService from '../../services/auth.service';
import { withTranslation } from 'react-i18next';
import olive_loader from '../../assets/images/olive-loader.gif';

const SignUpValalidationPage = (props) => {

    const[user, setUser] = useState({
        email: '', 
        code: '',  
    })
    const[error, setError] = useState('');
    const[connecting, setConnecting] = useState(false);

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])

    const {t} = props;

    const submitHandler = (e) => {
        e.preventDefault();
        e.target.className += " was-validated";

        if(!user.email || !user.code ) return;
        setConnecting(true)

        AuthService.activateAccount(user).then((res) => {
            setConnecting(false);
            props.history.push({pathname:'/se-connecter'});
            // console.log(res)

        }).catch((err) => {
            setConnecting(false);
            if(err.code == 404)
            setError(err.message);
            else setError(err.report)
            /* if (error.response) {
                setError(error.response.data.message);
            }else if(error.request) {
                // console.log(error.request);
                setError('Une erreur innattendue est survenue');
            }else {
                // console.log("Error", error);
                setError('Une erreur innattendue est survenue');
            } */
        })
    }

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value
        })
    }

    const resendCode = () => {
        //AuthService.resendCode
        
    }

    
    return (

        <MDBRow style={{height: '100vh'}}>
                <MDBCol xs="12" md="5" className='' style={{ background: '#f3f4f6'}}>
                    <MDBRow className="d-flex justify-content-center flex-column my-5 mx-5">
                         
                            <div className="d-flex flex-row">
                                <div className="p-1">
                                    <Link to="/">
                                      <img src={logo_faseya} />
                                    </Link>
                                </div>
                            </div>

                            <div className="p-1 mt-5">
                                <span className="text-connect">
                                    {t(`faseya.validation_account.title`)}
                                </span>
                            </div>
                            

                            <div className="d-flex justify-content-center pt-5">

                                <form className='needs-validation w-100' onSubmit={submitHandler} noValidate>
                                
                                    { error  && (
                                        <MDBRow className="mb-3 text-center">
                                            <MDBCol>
                                                <span className="error-text">{error}</span>
                                            </MDBCol>
                                        </MDBRow>
                                    )}

                                    <MDBRow >
                                        <MDBCol md="12" className="mb-3">
                                            <input
                                                type="email"
                                                value={user.email}
                                                name='email'
                                                onChange={changeHandler}
                                                id='validationEmail'
                                                required
                                                className="form-control"
                                                placeholder={t(`faseya.validation_account.email_placeholder`)}
                                                validation='Looks good!'
                                            /> 
                                            <div className="invalid-feedback">
                                                {t(`faseya.validation_account.required_text_error`)}
                                            </div>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow >
                                        <MDBCol md="12" className="">
                                                <input
                                                    type="text"  
                                                    value={user.code}                                                  value={user.password}
                                                    name='code'
                                                    onChange={changeHandler}
                                                    id='validationCode'
                                                    required
                                                    className="form-control"
                                                    placeholder={t(`faseya.validation_account.title`)}
                                                    validation='Looks good!'
                                                /> 
                                                <div className="invalid-feedback">
                                                    {t(`faseya.validation_account.code_placeholder`)}
                                                </div>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="12 mb-5">       
                                            <span className="text-password-forget pointer" onClick={resendCode}>
                                                {t(`faseya.validation_account.item_resend`)}
                                            </span>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="12 mt-2">
                                            {connecting &&
                                                    <img src={olive_loader} style={{position:'absolute', zIndex:1, left:'47.5%', width:40}} alt="" />
                                            }
                                            {!connecting && 
                                                    <MDBBtn type="submit"  className="btn-block btn-color">
                                                        {t(`faseya.validation_account.text_submit_button`)}
                                                    </MDBBtn>
                                            }
                                        </MDBCol>
                                    </MDBRow>

                                </form>
                                
                            </div>
                           
                    </MDBRow>
                </MDBCol>

                <MDBCol  className='d-flex justify-content-center align-items-center hide-mobile'>
                    <PartnerShipComponent />
                </MDBCol>

        </MDBRow>
    )
}

export default withTranslation()(SignUpValalidationPage);