import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./services/auth";


export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {

    const user = JSON.parse(localStorage.getItem("user")) || {id : null}
    
    return (
        <Route
        {...rest}
        render={props => {
            if (auth.isAuthenticated() && user.roles.includes(rest.type)) {
            return <Component {...props} />;
            } else {
            return (
                <Redirect
                to={{
                    pathname: "/",
                    state: {
                    from: props.location
                    }
                }}
                />
            );
            }
        }}
        />
    );
};
