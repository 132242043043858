import { api } from "./api.config";

const clientsService = {
  addClient,
  getClients,
  getClient,
  updateClient,
  deleteClient,
  deleteClients,
};

const resource = "/clients";

/**
 * addClient method
 *
 * @param {{
 *   prenom: string;
 *   nom: string;
 *   username: string;
 *   telephone: string;
 *   societe: string;
 *   ville: string;
 *   email: string;
 *   adresse: string;
 *   info: string;
 *   password: string;
 * }} payload
 *
 * @returns
 */
function addClient(payload) {
  return api.postData(resource, payload);
}

/**
 * getClients method
 *
 * @returns
 */
function getClients() {
  return api.getData(resource);
}

/**
 * getClient method
 *
 * @param {number} id
 *
 * @returns
 */
function getClient(id) {
  return api.getData(resource + "/" + id);
}

/**
 * updateClient method
 *
 * @param {{
 *   prenom?: string;
 *   nom?: string;
 *   username?: string;
 *   telephone?: string;
 *   societe?: string;
 *   ville?: string;
 *   adresse?: string;
 *   info?: string;
 * }} payload
 * @param {number} id
 *
 * @returns
 */
function updateClient(id, payload) {
  return api.putData(resource + "/" + id, payload);
}

/**
 * deleteClient method
 *
 * @param {number} id
 *
 * @returns
 */
function deleteClient(id) {
  return api.deleteData(resource + "/" + id);
}

/**
 * deleteClients method
 *
 * @returns
 */
function deleteClients() {
  return api.deleteData(resource);
}

export default clientsService;
