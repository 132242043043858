import React, {Fragment} from 'react';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdbreact';
import './PrefooterComponent.css';

import light_icon from '../../assets/images/light_icon.png';
import puzzle_icon from '../../assets/images/puzzle_icon.png';
import quality_icon from '../../assets/images/quality_icon.png';
import prefooter_banner from '../../assets/images/prefooter_banner.png';
import { withTranslation } from 'react-i18next';

class PrefooterComponent extends React.Component {
 
  state = {
    collapseID: ''
  };

 
  render() {

    const {t} = this.props;

    return (

      <div style={{padding:10, backgroundColor : 'olivedrab'}}>
  
        <MDBRow>
            
            <MDBCol size="12" sm="12" md="6"  className="mb-1 d-flex mb-1 f-how-item align-items-center" style={{}} >

              <MDBContainer>
                <MDBRow>

                  <MDBCol size="12" xs="12" md="12"  className=" mb-1 f-how-item " >
                      <h2 className="f-title" style={{color:'#fff'}}>{t('faseya.footer.banner.title')} </h2>
                  </MDBCol>
                  
                  <MDBCol size="12" xs="12" md="12"  className="mt-4 " >
                    <MDBBtn className="f-btn-white" style={{paddingLeft:15, paddingRight:15, fontSize:20}} href="#/se-connecter">{t('faseya.footer.banner.discover_consultants')}</MDBBtn>
                  </MDBCol>





                </MDBRow>
              </MDBContainer>
            </MDBCol>            

            <MDBCol xs="12" md="6"  className="mb-1" >
              <img src={prefooter_banner} className="img-fluid" style={{width:'90%'}} alt="" />
            </MDBCol>            
  

        </MDBRow>
      
      </div>
    );
  }
}

export default withTranslation()(PrefooterComponent);
